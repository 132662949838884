export const FETCH_CONTRACTS__REQUEST = '@@CONTRACTS/FETCH_CONTRACTS__REQUEST';
export const FETCH_CONTRACTS__SUCCESS = '@@CONTRACTS/FETCH_CONTRACTS__SUCCESS';
export const FETCH_CONTRACTS__FAIL = '@@CONTRACTS/FETCH_CONTRACTS__FAIL';

export const FETCH_CONTRACT_FILES__REQUEST =
  '@@CONTRACTS/FETCH_CONTRACT_FILES__REQUEST';
export const FETCH_CONTRACT_FILES__SUCCESS =
  '@@CONTRACTS/FETCH_CONTRACT_FILES__SUCCESS';
export const FETCH_CONTRACT_FILES__FAIL =
  '@@CONTRACTS/FETCH_CONTRACT_FILES__FAIL';

export const DELETE_CONTRACT__REQUEST = '@@CONTRACTS/DELETE_CONTRACT__REQUEST';
export const DELETE_CONTRACT__SUCCESS = '@@CONTRACTS/DELETE_CONTRACT__SUCCESS';
export const DELETE_CONTRACT__FAIL = '@@CONTRACTS/DELETE_CONTRACT__FAIL';

export const SET_CONTRACT = '@@CONTRACTS/SET_CONTRACT';

export const fetchContracts = () => ({
  type: FETCH_CONTRACTS__REQUEST,
});

export const setContract = contract => ({
  type: FETCH_CONTRACTS__REQUEST,
  payload: contract,
});

export const fetchContractFiles = contractId => ({
  type: FETCH_CONTRACT_FILES__REQUEST,
  payload: contractId,
});

export const deleteContract = contractId => ({
  type: DELETE_CONTRACT__REQUEST,
  payload: contractId,
});
