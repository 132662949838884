import React from 'react';

import ProductsType from '../ProductsType';

const ProductsListingLoading = () => (
  <div className="ProductsListing">
    <div className="ProductsListing__container">
      {[1, 2, 3].map(key => (
        <div className="ProductsListing__type" key={key}>
          <ProductsType loading />
        </div>
      ))}
    </div>
  </div>
);

export default ProductsListingLoading;
