import React from 'react';

import ProductsContext from '../../contexts/ProductsContext';

/* eslint-disable react/display-name */
const withProductsContext = Component => props => (
  <ProductsContext.Consumer>
    {context => <Component {...props} context={context} />}
  </ProductsContext.Consumer>
);

export default withProductsContext;
