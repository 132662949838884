import React, { useState, useEffect } from 'react';
import PreFooter from './PreFooter';
import FooterMenu from './Menu';
import FooterContact from './Contact';
import FooterGuides from './Guides';
import FooterCorporate from './Corporate';
import Mentions from './Mentions';

import '../../../styles/components/Layout/Footer/Footer.css';

function Footer() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  function handleResize() {
    setTimeout(() => {
      if (document.body.clientWidth <= 900) {
        if (document.body.clientWidth <= 700) {
          if (document.body.clientWidth <= 600) {
            setIsTablet(false);
            setIsDesktop(false);
          } else {
            setIsTablet(false);
            setIsDesktop(false);
          }
        } else {
          setIsTablet(true);
          setIsDesktop(false);
        }
      } else {
        setIsDesktop(true);
        setIsTablet(false);
      }
    }, 300);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <footer className="Footer">
      <div className="Footer__container Footer__container--desktop">
        <PreFooter />
        <div className="Footer__content">
          <div className="Footer__column">
            <FooterMenu isVisible />
          </div>
          <div className="Footer__column">
            <FooterContact isVisible />
          </div>
          <div className="Footer__column Footer__column--flexible">
            <FooterGuides isVisible />
          </div>
          <div className="Footer__separator-container">
            <span className="Footer__separator" />
          </div>
          <div className="Footer__column">
            <FooterCorporate isVisible />
          </div>
        </div>
        <Mentions />
      </div>
      <div className="Footer__container Footer__container--tablet">
        <div className="Footer__content">
          <div className="Footer__column">
            <FooterMenu isVisible={isDesktop} />
            <FooterContact />
          </div>
          <div className="Footer__separator-container Footer__separator-container--1">
            <span className="Footer__separator" />
          </div>
          <div className="Footer__column">
            <FooterGuides isVisible={isDesktop} />
            <FooterCorporate isVisible={isTablet || isDesktop} />
            <div className="Footer__mobile-mentions">
              <Mentions />
              <PreFooter />
            </div>
          </div>
          <div className="Footer__separator-container Footer__separator-container--2">
            <span className="Footer__separator" />
          </div>
          <div className="Footer__column Footer__column--mentions">
            <Mentions />
            <PreFooter />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
