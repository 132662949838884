import { Map } from 'immutable';

import {
  FETCH_RESET_PASSWORD__REQUEST,
  FETCH_RESET_PASSWORD__SUCCESS,
  FETCH_RESET_PASSWORD__FAIL,
  FETCH_EMAIL_VERIFICATION__REQUEST,
  FETCH_EMAIL_VERIFICATION__SUCCESS,
  FETCH_EMAIL_VERIFICATION__FAIL,
  LOGIN_USER,
  USER_IS_VERIFIED,
  OPEN_AUTH_MODAL,
  CLOSE_AUTH_MODAL,
  OPEN_VERIFICATION_MODAL,
  CLOSE_VERIFICATION_MODAL,
} from '../actions/AuthActions';

const initialState = Map({
  // Reset password
  resetPasswordLoading: false,
  resetPasswordError: false,
  resetPasswordErrorMessage: Map({}),
  // Verify email
  emailVerificationLoading: false,
  emailVerificationError: false,
  emailVerificationErrorMessage: Map({}),
  // Modals
  authModalIsOpen: false,
  verificationModalIsOpen: false,
  initialEmail: '',
  authModalIsBlocking: true,
  updateAnswersOnLogin: false,
  noRecommendation: false,
  recommendationToFetchOnLogin: '',
});

const AuthState = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RESET_PASSWORD__REQUEST:
      return state
        .set('resetPasswordLoading', true)
        .set('resetPasswordError', false)
        .set('resetPasswordErrorMessage', Map({}));

    case FETCH_RESET_PASSWORD__SUCCESS:
      return state.set('resetPasswordLoading', false);

    case FETCH_RESET_PASSWORD__FAIL:
      return state
        .set('resetPasswordLoading', false)
        .set('resetPasswordError', true)
        .set('resetPasswordErrorMessage', payload);

    case FETCH_EMAIL_VERIFICATION__REQUEST:
      return state
        .set('emailVerificationLoading', true)
        .set('emailVerificationError', false)
        .set('emailVerificationErrorMessage', Map({}));

    case FETCH_EMAIL_VERIFICATION__SUCCESS:
      return state.set('emailVerificationLoading', false);

    case FETCH_EMAIL_VERIFICATION__FAIL:
      return state
        .set('emailVerificationLoading', false)
        .set('emailVerificationError', true)
        .set('emailVerificationErrorMessage', payload);

    case LOGIN_USER:
      return state.set('authModalIsOpen', false);

    case USER_IS_VERIFIED:
      return state.set('verificationModalIsOpen', false);

    case OPEN_AUTH_MODAL:
      return state
        .set('authModalIsOpen', true)
        .set('initialEmail', payload.initialEmail)
        .set('authModalIsBlocking', payload.isBlockingModal)
        .set('updateAnswersOnLogin', payload.updateAnswersOnLogin)
        .set('noRecommendation', payload.noRecommendation)
        .set(
          'recommendationToFetchOnLogin',
          payload.recommendationToFetchOnLogin,
        );

    case CLOSE_AUTH_MODAL:
      return state
        .set('authModalIsOpen', false)
        .set('initialEmail', '')
        .set('authModalIsBlocking', true)
        .set('updateAnswersOnLogin', false)
        .set('noRecommendation', false)
        .set('recommendationToFetchOnLogin', '');

    case OPEN_VERIFICATION_MODAL:
      return state.set('verificationModalIsOpen', true);

    case CLOSE_VERIFICATION_MODAL:
      return state.set('verificationModalIsOpen', false);

    default:
      return initialState.merge(state);
  }
};

export default AuthState;
