import { Map, fromJS } from 'immutable';

import {
  FETCH_HOME__REQUEST,
  FETCH_HOME__SUCCESS,
  FETCH_HOME__FAIL,
  FETCH_HOME__NOTFOUND,
} from '../actions/HomeActions';

const initialState = Map({
  homeLoading: true,
  homeError: false,
  homeNotFound: false,
  home: Map({}),
});

const homeState = (state, { type, payload }) => {
  switch (type) {
    case FETCH_HOME__REQUEST:
      return state
        .set('homeLoading', true)
        .set('homeError', false)
        .set('homeNotFound', false);

    case FETCH_HOME__SUCCESS:
      return state.set('homeLoading', false).set('home', fromJS(payload));

    case FETCH_HOME__FAIL:
      return state.set('homeLoading', false).set('homeError', true);

    case FETCH_HOME__NOTFOUND:
      return state.set('homeLoading', false).set('homeNotFound', true);

    default:
      return initialState.merge(state);
  }
};

export default homeState;
