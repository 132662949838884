import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as TippyTooltip } from 'react-tippy';

import productShape from '../../../shapes/productShape';
import typeShape from '../../../shapes/typeShape';
import supplierShape from '../../../shapes/supplierShape';

import Tag from '../../Core/Tag';
import Link from '../../Core/Link';
import RichText from '../../Core/RichText';
import ProductCardLoading from './Loading/ProductCardLoading';

import '../../../styles/components/Blocks/Product/ProductCard.css';

const ProductCard = ({ product, type, supplier, loading, onClick }) => {
  if (loading) {
    return <ProductCardLoading />;
  }

  if (!product || !type) {
    return null;
  }

  return (
    <Link
      className="ProductCard__link"
      to={`/produits/${type.slug}/${product.slug}`}
      onClick={onClick}
    >
      <article className="ProductCard">
        <div className="ProductCard__header">
          <div className="ProductCard__image">
            {supplier && supplier.logo && supplier.logo.url && (
              <img src={supplier.logo.url} alt={supplier.logo.alt} />
            )}
          </div>
          {type &&
            (type.tooltip ? (
              <TippyTooltip
                title={type.tooltip}
                position="top"
                interactiveBorder="10"
                animateFill={false}
              >
                <Tag
                  small
                  label={type.label}
                  color={type.color}
                  backgroundColor={type.backgroundColor}
                />
              </TippyTooltip>
            ) : (
              <Tag
                small
                label={type.label}
                color={type.color}
                backgroundColor={type.backgroundColor}
              />
            ))}
          <h3 className="ProductCard__title">
            {supplier && supplier.name} {product.title}
          </h3>
        </div>
        <div className="ProductCard__content">
          <RichText paragraphs={product.summary} />
        </div>
        <div className="ProductCard__footer">
          <span>Voir la fiche produit</span>
          {type && (
            <div
              className="ProductCard__arrow-background"
              style={{ backgroundColor: type.backgroundColor }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 6 9"
              >
                <path
                  fill={type.color}
                  fillRule="evenodd"
                  d="M.204 1.47C-.134 1.063-.042.488.408.184c.363-.245.86-.245 1.223 0l4.077 3.673a.854.854 0 0 1 0 1.286L1.631 8.816c-.451.305-1.089.222-1.427-.183a.845.845 0 0 1 0-1.102L3.558 4.5.194 1.47h.01z"
                />
              </svg>
            </div>
          )}
        </div>
      </article>
    </Link>
  );
};

ProductCard.defaultProps = {
  product: null,
  type: null,
  supplier: null,
  loading: false,
  onClick: null,
};

ProductCard.propTypes = {
  product: PropTypes.shape(productShape),
  type: PropTypes.shape(typeShape),
  supplier: PropTypes.shape(supplierShape),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ProductCard;
