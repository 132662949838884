import { Map, fromJS, List } from 'immutable';

import {
  FETCH_CUSTOMER_FILES__REQUEST,
  FETCH_CUSTOMER_FILES__SUCCESS,
  FETCH_CUSTOMER_FILES__FAIL,
  ADD_CUSTOMER_FILES,
} from '../actions/SupportingDocumentsActions';

import { LOGOUT_USER } from '../actions/AuthActions';

const initialState = Map({
  loading: false,
  error: false,
  documents: List([]),
});

const supportingDocumentsState = (state = initialState, { type, payload }) => {
  let newDocuments = [];

  switch (type) {
    case FETCH_CUSTOMER_FILES__REQUEST:
      return state.set('loading', true).set('error', false);

    case FETCH_CUSTOMER_FILES__SUCCESS:
      return state.set('loading', false).set('documents', fromJS(payload));

    case FETCH_CUSTOMER_FILES__FAIL:
      return state.set('loading', false).set('error', true);

    case ADD_CUSTOMER_FILES:
      newDocuments = state.get('documents').merge(fromJS(payload));

      return state.set('documents', newDocuments);

    case LOGOUT_USER:
      return initialState;

    default:
      return initialState.merge(state);
  }
};

export default supportingDocumentsState;
