import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../assets/images/logo-mieuxplacer-light.svg';

import '../../../styles/components/Layout/Footer/Mentions.css';

function Mentions() {
  return (
    <div className="FooterMentions">
      <div className="FooterMentions__logo-container">
        <img
          className="FooterMentions__logo"
          src={logo}
          alt="Logo Mieuxplacer"
        />
      </div>
      <nav className="FooterMentions__links">
        <Link
          id="Footer__securite"
          className="FooterMentions__link"
          to="/securite-mieuxplacer.com"
        >
          Sécurité
        </Link>
        <a
          id="Footer__cgu"
          href="/documents/CGU-MP.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="FooterMentions__link"
        >
          CGU - CGV
        </a>
        <a
          id="Footer__mentions-legales"
          href="/documents/Mentions-Legales-MP.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="FooterMentions__link"
        >
          Mentions légales
        </a>
        <a
          id="Footer__cgu"
          href="/documents/Charte-donnees-personnelles-MP.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="FooterMentions__link"
        >
          Charte des données personnelles
        </a>
        <p className="FooterMentions__link">
          ©{new Date().getFullYear()} Mieuxplacer.com
        </p>
      </nav>
    </div>
  );
}

export default Mentions;
