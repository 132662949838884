import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import routerShape from './shapes/routerShape';
import routesConfig from './config/pages';

import Home from './containers/Home';
import Page from './containers/Page';
import Product from './containers/Product';
import Products from './containers/Products';
import Contact from './containers/Contact';
import Guide from './containers/Guide';
import FourOFour from './components/Layout/FourOFour';
import FiveHundred from './components/Layout/FiveHundred';
import OnboardingStop from './components/Blocks/Onboarding/OnboardingStop';
import LoginStop from './components/Modules/Auth/LoginStop';

export const urls = [
  { path: '/', breadcrumb: 'Accueil' },
  { path: '/produits/:type/:slug', breadcrumb: 'Produit' },
  { path: '/produits/:slug', breadcrumb: 'Produit' },
  { path: '/produits', breadcrumb: 'Produits' },
  { path: '/types/:slug', breadcrumb: 'Type de produit' },
  { path: '/guide/:slug', breadcrumb: 'Guide' },
  { path: '/guide', breadcrumb: 'Guide' },
  { path: '/contactez-nous', breadcrumb: 'Contactez-nous' },
  { path: '/qui-sommes-nous/:slug', breadcrumb: 'Qui sommes-nous ?' },
  { path: '/connaissance-client', breadcrumb: 'Connaissance client' },
  { path: '/onboarding', breadcrumb: 'Onboarding' },
  { path: '/questionnaire', breadcrumb: 'Questionnaire' },
  { path: '/espace-client', breadcrumb: 'Espace Client' },
  { path: '/404', breadcrumb: '404' },
  { path: '/500', breadcrumb: '500' },
  { path: '/:slug', breadcrumb: 'Page' },
  { path: '*', breadcrumb: '404' },
];

function getPageRoutes() {
  let pageRoutes = [];

  routesConfig.pages.forEach(page => {
    pageRoutes.push(page);

    if (page.subRoutes) {
      page.subRoutes.forEach(subRoute => {
        pageRoutes.push(subRoute);

        if (subRoute.subRoutes) {
          pageRoutes = pageRoutes.concat(subRoute.subRoutes);
        }
      });
    }
  });

  return pageRoutes;
}

const redirections = {
  '/qui-sommes-nous/ou-placer-mon-argent': '/placements-financiers',
  '/qui-sommes-nous/equipe': '/qui-sommes-nous',
  '/contactez-nous': '/qui-sommes-nous/contactez-nous',
};

export const Routes = ({ location }) => (
  <Switch location={location}>
    <Route exact path="/" component={Home} name="Home" />

    <Route exact path="/produits/:type/:slug" component={Product} />
    <Route exact path="/produits/:slug" component={Product} />
    <Route exact path="/produits" component={Products} />

    <Route
      exact
      path="/types/:slug"
      render={({
        match: {
          params: { slug },
        },
      }) => <Redirect to={`/guide/${slug}`} />}
    />
    <Route exact path="/guide/:slug" component={Guide} />
    <Route
      exact
      path="/guide"
      render={() => <Page id="W6I3zSEAACMA_fGj" url="/guide" />}
    />

    <Route exact path="/qui-sommes-nous/contactez-nous" component={Contact} />

    <Route path="/onboarding/" component={OnboardingStop} />
    <Route path="/connaissance-client/" component={OnboardingStop} />
    <Route path="/questionnaire/" component={OnboardingStop} />

    <Route path="/auth/" component={LoginStop} />
    <Route path="/espace-client" component={LoginStop} />

    <Route exact path="/404" component={FourOFour} />
    <Route exact path="/500" component={FiveHundred} />

    {getPageRoutes().map(page => (
      <Route
        exact
        key={page.id}
        path={page.to}
        render={() => <Page id={page.id} url={page.to} />}
      />
    ))}

    {routesConfig.otherPages.map(page => (
      <Route
        exact
        key={page.id}
        path={page.to}
        render={() => <Page id={page.id} url={page.to} />}
      />
    ))}

    {Object.keys(redirections).map(oldUrl => (
      <Route
        exact
        key={oldUrl}
        path={oldUrl}
        render={() => <Redirect to={redirections[oldUrl]} />}
      />
    ))}

    <Route path="*" component={FourOFour} />
  </Switch>
);

Routes.propTypes = {
  location: PropTypes.shape(routerShape).isRequired,
};
