import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LoadingGradient from '../../../Core/LoadingGradient';

const PrismicTextImageLoading = ({ inverted }) => {
  const containerClass = classNames('PrismicTextImage', {
    'PrismicTextImage--inversed': inverted,
  });

  return (
    <div className={containerClass}>
      <div className="PrismicTextImage__content-container">
        <div className="PrismicBlockTitle">
          <LoadingGradient width="300px" height="42px" />
        </div>
        <div className="PrismicTextImage__description">
          <div className="RichText__paragraph">
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="20px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="20px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="20px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="300px"
              height="20px"
            />
          </div>
          <div className="RichText__paragraph">
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="18px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="18px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="300px"
              height="18px"
            />
          </div>
          <div className="RichText__paragraph">
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="20px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="20px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="20px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="300px"
              height="20px"
            />
          </div>
          <div className="RichText__paragraph">
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="18px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="300px"
              height="18px"
            />
          </div>
        </div>
      </div>
      <div className="PrismicTextImage__image-container">
        <LoadingGradient
          className="PrismicTextImage__image"
          width="500px"
          height="250px"
        />
      </div>
    </div>
  );
};

PrismicTextImageLoading.defaultProps = {
  inverted: false,
};

PrismicTextImageLoading.propTypes = {
  inverted: PropTypes.bool,
};

export default PrismicTextImageLoading;
