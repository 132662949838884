import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@robinfinance/ui';

import { SELECTION_ENTRY_TYPE } from '../../../config/onboarding';

import productShape from '../../../shapes/productShape';
import supplierShape from '../../../shapes/supplierShape';

import withHeaderContext from '../../HOC/withHeaderContext';
import CartLoading from './Loading/CartLoading';

import deleteIcon from '../../../assets/icons/delete-icon.svg';

import '../../../styles/components/Blocks/Cart/Cart.css';

class Cart extends PureComponent {
  componentDidUpdate(prevProps) {
    const {
      context: { cartProducts: prevCartProducts },
    } = prevProps;
    const {
      context: { cartProducts },
      onToggle,
    } = this.props;

    if (onToggle && prevCartProducts.length < cartProducts.length) {
      onToggle();
    }
  }

  handleClick = () => {
    const {
      context: { setOnboardingStartingPoint },
      onToggle,
    } = this.props;

    setOnboardingStartingPoint(SELECTION_ENTRY_TYPE);
    onToggle();
  };

  renderProduct = (product, supplier) => {
    const {
      context: { removeProductFromCart },
    } = this.props;

    return (
      <div key={product.id} className="Cart__product">
        {supplier && supplier.logo && (
          <div className="Cart__supplier">
            <img src={supplier.logo.url} alt="supplier_logo" />
          </div>
        )}
        <div className="Cart__product-container">
          <div className="Cart__product-title">{product.title}</div>
          <div
            className="Cart__product-delete"
            onMouseUp={() => removeProductFromCart(product.id)}
            role="button"
            tabIndex="0"
          >
            <img
              className="Cart__delete-icon"
              src={deleteIcon}
              alt="delete-icon"
            />
            <span>Supprimer de la sélection</span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      context: { cartProducts, suppliers, products, productsLoading },
    } = this.props;

    if (productsLoading) {
      return <CartLoading items={cartProducts} />;
    }

    if (
      products &&
      products.length > 0 &&
      cartProducts &&
      cartProducts.length > 0
    ) {
      const productIds = cartProducts.map(product => product.id);
      const cart = products.filter(product => productIds.includes(product.id));

      return (
        <div className="Cart">
          <div className="Cart__products">
            {cart.map(product => {
              const supplier = suppliers.find(
                supplier => supplier.id === product.supplier,
              );

              return this.renderProduct(product, supplier);
            })}
          </div>

          <p className="Cart__explanation">
            Pour une souscription en toute sérénité, nous devons vous poser
            quelques questions afin d’assurer notre obligation de conseil
          </p>
          <Button
            scope="button"
            type="secondary"
            to="/connaissance-client"
            onClick={this.handleClick}
            id="SimulationButtonCartButtonHeaderDropDown"
          >
            Commencer ma simulation
          </Button>
        </div>
      );
    }

    return (
      <span className="Cart__empty">
        Il n’y a aucun produit dans votre sélection
      </span>
    );
  }
}

Cart.defaultProps = {
  onToggle: null,
};

Cart.propTypes = {
  context: PropTypes.shape({
    cartProducts: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, identifier: PropTypes.string }),
    ),
    products: PropTypes.arrayOf(PropTypes.shape(productShape)),
    productsLoading: PropTypes.bool.isRequired,
    suppliers: PropTypes.arrayOf(PropTypes.shape(supplierShape)),
    removeProductFromCart: PropTypes.func.isRequired,
    setOnboardingStartingPoint: PropTypes.func.isRequired,
  }).isRequired,
  onToggle: PropTypes.func,
};

export default withHeaderContext(Cart);
