import { put, call, takeLatest } from 'redux-saga/effects';

import MieuxplacerAPI from '../api/Mieuxplacer';

import {
  FETCH_OBJECTIVES__REQUEST,
  FETCH_OBJECTIVES__SUCCESS,
  FETCH_OBJECTIVES__FAIL,
} from '../actions/ObjectiveActions';

function* fetchObjectives() {
  const params = {
    orderBy: 'label',
  };

  try {
    const apiCall = () => MieuxplacerAPI.Api.getPrismicObjectives(params);
    const data = yield call(apiCall);

    yield put({
      type: FETCH_OBJECTIVES__SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_OBJECTIVES__FAIL,
      payload: e.message,
    });
  }
}

const objectiveSagas = [takeLatest(FETCH_OBJECTIVES__REQUEST, fetchObjectives)];

export default objectiveSagas;
