import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, LinkedIn, Twitter, Accordion } from '@robinfinance/ui';

import '../../../styles/components/Layout/Footer/Contact.css';

function FooterContact() {
  return (
    <div className="FooterContact">
      <Accordion
        defaultOpen
        showLabel="Contact"
        hideLabel="Contact"
        togglePosition="top"
        toggleAlign="left"
      >
        <div className="FooterContact__content">
          <div className="FooterContact__address">
            <p className="FooterContact__line FooterContact__line--bold">
              06 01 73 09 58
            </p>

            <p className="FooterContact__line">
              70 Avenue de Branne, 33370 Tresses
            </p>
          </div>
          <div className="FooterContact__social">
            <a
              href="https://twitter.com/mieux_placer"
              title="Twitter Mieuxplacer.com"
              target="_blank"
              rel="noopener noreferrer"
              className="FooterContact__social-link"
            >
              <Twitter width="16px" height="16px" color="#1da1f2" />
            </a>
            <a
              href="https://www.facebook.com/Mieuxplacer"
              title="Facebook Mieuxplacer.com"
              target="_blank"
              rel="noopener noreferrer"
              className="FooterContact__social-link"
            >
              <Facebook width="16px" height="16px" color="#3b5998" />
            </a>
            <a
              href="https://fr.linkedin.com/company/mieuxplacer-com"
              title="LinkedIn Elwin"
              target="_blank"
              rel="noopener noreferrer"
              className="FooterContact__social-link"
            >
              <LinkedIn width="16px" height="16px" color="#0077b5" />
            </a>
          </div>
          <Link
            id="Footer__contact"
            className="FooterMenu__item FooterMenu__item--bold"
            to="qui-sommes-nous/contactez-nous"
          >
            Contactez-nous
          </Link>
        </div>
      </Accordion>
    </div>
  );
}

export default FooterContact;
