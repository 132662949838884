import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../../styles/components/Blocks/Product/RiskBar.css';

class RiskBar extends PureComponent {
  renderBoxes = () => {
    const { number } = this.props;

    return [1, 2, 3, 4, 5, 6, 7].map(key => {
      const riskBarBoxClass = classNames('RiskBar__box', {
        'RiskBar__box--active': key === number,
        'RiskBar__box--left': key === 1,
        'RiskBar__box--right': key === 7,
      });

      return (
        <div key={key} className={riskBarBoxClass}>
          {key}
        </div>
      );
    });
  };

  render() {
    const { small } = this.props;

    const riskBarClass = classNames('RiskBar', {
      'RiskBar--small': small,
    });

    return <div className={riskBarClass}>{this.renderBoxes()}</div>;
  }
}

RiskBar.defaultProps = {
  small: false,
};

RiskBar.propTypes = {
  number: PropTypes.number.isRequired,
  small: PropTypes.bool,
};

export default RiskBar;
