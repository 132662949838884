import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { stepsShape } from '../../../shapes/prismicShape';

import PrismicBlockTitle from '../Core/PrismicBlockTitle';

import '../../../styles/components/Prismic/PrismicSteps.css';
import RichText from '../../Core/RichText';

class PrismicSteps extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicSteps">
        {data.primary.steps_title && data.primary.steps_title.length > 0 && (
          <div className="PrismicSteps__title">
            <PrismicBlockTitle title={data.primary.steps_title} />
          </div>
        )}
        <div className="PrismicSteps__steps">
          {data.items.map((step, index) => (
            <div key={index} className="PrismicSteps__step">
              <RichText paragraphs={step.steps_step_label} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

PrismicSteps.propTypes = {
  data: PropTypes.shape(stepsShape).isRequired,
};

export default PrismicSteps;
