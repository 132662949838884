import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';

import { contentShape } from '../../../shapes/prismicShape';
import { isInViewport } from '../../../helpers/domHelpers';

import PrismicLink from '../Core/PrismicLink';
import RichText from '../../Core/RichText';

import '../../../styles/components/Prismic/PrismicCounter.css';

class PrismicCounter extends PureComponent {
  counterRefs = [];

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);

    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const element = document.querySelector('.PrismicCounter');

    if (element) {
      const inViewport = isInViewport(element);

      if (inViewport) {
        window.removeEventListener('scroll', this.handleScroll);

        this.counterRefs.forEach(counterRef => {
          if (counterRef) {
            counterRef.start();
          }
        });
      }
    }
  };

  render() {
    const { data } = this.props;

    return (
      <div className="PrismicCounter">
        <div className="PrismicCounter__items">
          {data.items.map((item, index) => (
            <div
              key={index}
              className="PrismicCounter__item"
              style={{ borderColor: item.counter_color }}
            >
              <div className="PrismicCounter__item-header">
                <span
                  className="PrismicCounter__item-header-title"
                  style={{ color: item.counter_color }}
                >
                  <CountUp
                    ref={ref => {
                      this.counterRefs[index] = ref;
                    }}
                    start={0}
                    end={item.counter_value}
                    decimal="0"
                    suffix={item.counter_devise && ` ${item.counter_devise}`}
                    duration={2.5}
                    separator=" "
                  >
                    {({ countUpRef }) => <span ref={countUpRef} />}
                  </CountUp>
                </span>
                <span className="PrismicCounter__item-header-description">
                  {item.counter_description}
                </span>
                <RichText paragraphs={item.counter_text} />
              </div>
              {item.counter_link_title && item.counter_link && (
                <PrismicLink
                  className="PrismicCounter__link"
                  link={item.counter_link}
                >
                  <div
                    className="PrismicCounter__item-footer"
                    style={{ backgroundColor: item.counter_background }}
                  >
                    {item.counter_link_title}
                    <svg
                      className="PrismicCounter__link-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                    >
                      <path
                        fill={item.counter_color}
                        d="M15.047 6.161H1.534c-.478 0-.867.375-.867.84 0 .462.389.838.867.838h13.513l-4.594 4.448a.82.82 0 0 0 .103 1.181.89.89 0 0 0 1.128-.007l6.068-5.873a.82.82 0 0 0 0-1.175L11.684.539a.885.885 0 0 0-1.222.083.818.818 0 0 0-.009 1.092l4.603 4.447h-.009z"
                      />
                    </svg>
                  </div>
                </PrismicLink>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

PrismicCounter.propTypes = {
  data: PropTypes.shape(contentShape).isRequired,
};

export default PrismicCounter;
