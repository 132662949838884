import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as AuthAPI from '../api/Auth';

import {
  FETCH_RESET_PASSWORD__REQUEST,
  FETCH_RESET_PASSWORD__SUCCESS,
  FETCH_RESET_PASSWORD__FAIL,
  FETCH_EMAIL_VERIFICATION__REQUEST,
  FETCH_EMAIL_VERIFICATION__SUCCESS,
  FETCH_EMAIL_VERIFICATION__FAIL,
} from '../actions/AuthActions';

function* fetchResetPassword({ payload }) {
  try {
    yield call(AuthAPI.fetchResetPassword, payload);

    yield put({ type: FETCH_RESET_PASSWORD__SUCCESS });
    yield put(push('/auth/confirmation-mot-de-passe'));
  } catch (e) {
    yield put({
      type: FETCH_RESET_PASSWORD__FAIL,
      payload: e.error || {},
    });
  }
}

function* fetchEmailVerification({ payload }) {
  try {
    yield call(AuthAPI.fetchEmailVerification, payload);

    yield put({ type: FETCH_EMAIL_VERIFICATION__SUCCESS });
  } catch (e) {
    yield put({
      type: FETCH_EMAIL_VERIFICATION__FAIL,
      payload: e.error || {},
    });
  }
}

const authSagas = [
  takeLatest(FETCH_RESET_PASSWORD__REQUEST, fetchResetPassword),
  takeLatest(FETCH_EMAIL_VERIFICATION__REQUEST, fetchEmailVerification),
];

export default authSagas;
