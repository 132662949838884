import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import SearchInput from 'react-search-input';

import { RECOMMENDATION_ENTRY_TYPE } from '../config/onboarding';

import productShape from '../shapes/productShape';
import objectiveShape from '../shapes/objectiveShape';
import typeShape from '../shapes/typeShape';
import supplierShape from '../shapes/supplierShape';
import pageShape from '../shapes/pageShape';

import {
  formatFilterParams,
  formatFilterQuery,
  getFilterParamsFromQuery,
  filtersAreNotDefault,
} from '../helpers/filterHelpers';

import { fetchFilteredProducts } from '../actions/ProductActions';
import {
  addFilter,
  removeFilter,
  setFilter,
  setFilters,
  initFilters,
} from '../actions/FilterActions';
import { fetchPage } from '../actions/PageActions';
import { setOnboardingStartingPoint } from '../actions/OnboardingActions';
import { pageIsLoaded } from '../actions/AppActions';

import ProductsContext from '../contexts/ProductsContext';

import toJS from '../components/HOC/toJS';
import Icon from '../components/Core/Icon';
import Link from '../components/Core/Link';
import LoadingGradient from '../components/Core/LoadingGradient';
import Breadcrumbs from '../components/Core/Breadcrumbs';
import ProductsListing from '../components/Blocks/Products/ProductsListing';
import FilterSidebar from '../components/Blocks/Filter/FilterSidebar';
import MobileFilter from '../components/Blocks/Filter/MobileFilter';
import FilterTags from '../components/Blocks/Filter/FilterTags';
import FourOFour from '../components/Layout/FourOFour';
import FiveHundred from '../components/Layout/FiveHundred';

import loupe from '../assets/icons/loupe.svg';

import '../styles/containers/Products.css';

class Products extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
    };
  }

  componentDidMount() {
    const {
      pageLoading,
      page,
      filteredProducts,
      filters,
      setFilters,
      fetchFilteredProducts,
      fetchPage,
      pageIsLoaded,
      location: { search },
      history,
    } = this.props;

    if (!pageLoading && !page) {
      fetchPage({ slug: 'produits' });
    }

    if (page) {
      pageIsLoaded(page.gaTitle);
    }

    const queryFilters = getFilterParamsFromQuery(search);

    // If the filter information is in the URL, we initialize those filters
    // Else, if filters are set in the store, we update the URL with those filters
    // Else, we initialise the page without any filters
    if (Object.keys(queryFilters).length > 0) {
      setFilters(queryFilters);
    } else if (filtersAreNotDefault(filters)) {
      const params = formatFilterParams(filters);
      const query = formatFilterQuery(params);

      if (query) {
        history.push(`/produits?${query}`);
      }
    } else if (filteredProducts.length === 0) {
      fetchFilteredProducts();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      pageLoading,
      page,
      filters,
      setFilters,
      fetchFilteredProducts,
      pageIsLoaded,
      location: { search },
      history,
    } = this.props;

    if (prevProps.pageLoading && !pageLoading && page) {
      pageIsLoaded(page.gaTitle);
    }

    let filtersHaveChanged = false;

    // We check if the filters have changed
    Object.keys(filters).forEach(key => {
      if (Array.isArray(filters[key])) {
        if (filters[key].length !== prevProps.filters[key].length) {
          filtersHaveChanged = true;
        } else {
          filters[key].forEach((filter, index) => {
            if (filter.id !== prevProps.filters[key][index].id) {
              filtersHaveChanged = true;
            }
          });
        }
      } else if (filters[key].id !== prevProps.filters[key].id) {
        filtersHaveChanged = true;
      }
    });

    // If the filters in the URL have changed, we check if there needs to be an update to the store
    if (search !== prevProps.location.search) {
      const queryFilters = getFilterParamsFromQuery(search);
      if (Object.keys(queryFilters).length > 0) {
        setFilters(queryFilters);
      }
    }

    // If the filters in the store have changed, we fetch the new products and we update the URL
    if (filtersHaveChanged) {
      const params = formatFilterParams(filters);
      const query = formatFilterQuery(params);

      fetchFilteredProducts(params);
      history.push(`/produits?${query}`);
    }
  }

  handleReinitializeFilters = () => {
    const { initFilters } = this.props;

    initFilters();
  };

  handleAddFilterChange = (type, id, value, checked) => {
    const { addFilter, removeFilter } = this.props;

    if (checked) {
      addFilter(type, id, value);
    } else {
      removeFilter(type, id, value);
    }
  };

  handleSetFilterChange = (type, id, value) => {
    const { setFilter } = this.props;

    setFilter(type, id, value);
  };

  handleSearch = (products, searchTerm) => {
    const { suppliers } = this.props;

    return products.filter(product => {
      if (searchTerm.length === 0) {
        return true;
      }

      const formattedSearchTerm = searchTerm
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const formattedTitle = product.title
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      const supplier = suppliers.find(
        supplier => supplier.id === product.supplier,
      );

      if (!supplier) {
        return formattedTitle.includes(formattedSearchTerm);
      }

      const formattedSupplierName =
        supplier &&
        supplier.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

      return (
        formattedTitle.includes(formattedSearchTerm) ||
        formattedSupplierName.includes(formattedSearchTerm)
      );
    });
  };

  searchUpdated = term => {
    this.setState({ searchTerm: term });
  };

  render() {
    const {
      loading,
      error,
      filterLoading,
      filterError,
      filteredProducts,
      objectives,
      objectivesLoading,
      types,
      typesLoading,
      suppliers,
      filters,
      products,
      page,
      pageError,
      pageNotFound,
      setOnboardingStartingPoint,
    } = this.props;
    const { searchTerm } = this.state;
    const fullFilteredProducts = [];

    if (pageNotFound) {
      return <FourOFour pageIsLoaded={pageIsLoaded} />;
    }

    if (pageError || error) {
      return <FiveHundred pageIsLoaded={pageIsLoaded} />;
    }

    products.forEach(product => {
      if (filteredProducts.includes(product.id)) {
        fullFilteredProducts.push(product);
      }
    });

    const searchedAndFilteredProducts = this.handleSearch(
      fullFilteredProducts,
      searchTerm,
    );

    return (
      <div className="Products">
        <Helmet>
          <title>{page && page.title}</title>
          <meta name="description" content={page && page.description} />

          <meta property="og:title" content={page && page.title} />
          <meta property="og:description" content={page && page.description} />
          <meta
            property="og:url"
            content={`https://www.mieuxplacer.com/${page && page.slug}`}
          />
          <meta property="og:type" content="page" />

          <link
            rel="canonical"
            href={`https://www.mieuxplacer.com/${page && page.slug}`}
          />
        </Helmet>

        <ProductsContext.Provider
          value={{
            reinitializeFilters: this.handleReinitializeFilters,
            handleAddFilterChange: this.handleAddFilterChange,
            handleSetFilterChange: this.handleSetFilterChange,
            objectives,
            objectivesLoading,
            types,
            typesLoading,
            filters,
            setOnboardingStartingPoint,
          }}
        >
          <Breadcrumbs />

          <div className="Products__header">
            <h2 className="Products__title">Nos produits</h2>
            <div className="Products__search-container">
              <div className="Products__result-text">
                {!loading && !filterLoading && !error && !filterError ? (
                  <p>
                    Nous avons trouvé{' '}
                    <b>{searchedAndFilteredProducts.length}</b> produit(s)
                    correspondant à votre recherche
                  </p>
                ) : (
                  <LoadingGradient width="500px" height="22px" />
                )}
                <p>
                  Besoin de conseil ? Décrivez-nous votre projet et découvrez le
                  placement le plus adapté à votre profil.
                  <Link
                    link
                    to="/connaissance-client"
                    onClick={() =>
                      setOnboardingStartingPoint(RECOMMENDATION_ENTRY_TYPE)
                    }
                  >
                    Lancer mon étude
                  </Link>
                </p>
              </div>
              <div className="Products__filter-toggle">
                <MobileFilter />
              </div>
              <div className="Products__search">
                <SearchInput
                  placeholder="Rechercher un produit"
                  onChange={this.searchUpdated}
                />
                <Icon src={loupe} alt="loupe" height="18px" width="18px" />
              </div>
            </div>
          </div>
          <div className="Products__container">
            <FilterSidebar />
            <div className="Products__tagsAndProducts">
              <FilterTags />
              <ProductsListing
                products={searchedAndFilteredProducts}
                types={types}
                suppliers={suppliers}
                loading={loading || filterLoading}
                error={error || filterError}
                searching={searchTerm.length > 0}
                setOnboardingStartingPoint={setOnboardingStartingPoint}
              />
            </div>
          </div>
        </ProductsContext.Provider>
      </div>
    );
  }
}

Products.defaultProps = {
  filteredProducts: [],
  products: [],
  objectives: [],
  types: [],
  suppliers: [],
  filters: {},
  page: null,
  pageLoading: false,
  pageError: false,
  pageNotFound: false,
};

Products.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  filterLoading: PropTypes.bool.isRequired,
  filterError: PropTypes.bool.isRequired,
  filteredProducts: PropTypes.arrayOf(PropTypes.string),
  products: PropTypes.arrayOf(PropTypes.shape(productShape)),
  objectives: PropTypes.arrayOf(PropTypes.shape(objectiveShape)),
  objectivesLoading: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape(typeShape)),
  typesLoading: PropTypes.bool.isRequired,
  suppliers: PropTypes.arrayOf(PropTypes.shape(supplierShape)),
  filters: PropTypes.shape({}),
  page: PropTypes.shape(pageShape),
  pageLoading: PropTypes.bool,
  pageError: PropTypes.bool,
  pageNotFound: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  fetchFilteredProducts: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  initFilters: PropTypes.func.isRequired,
  pageIsLoaded: PropTypes.func.isRequired,
  fetchPage: PropTypes.func.isRequired,
  setOnboardingStartingPoint: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  page: state.get('pageState').get('pages').get('produits'),
  pageLoading:
    state
      .get('pageState')
      .get('loading')
      .find(item => item === 'produits') !== undefined,
  pageError:
    state
      .get('pageState')
      .get('error')
      .find(item => item === 'produits') !== undefined,
  pageNotFound:
    state
      .get('pageState')
      .get('notFound')
      .find(item => item === 'produits') !== undefined,
  loading: state.get('productState').get('productsLoading'),
  error: state.get('productState').get('productsError'),
  filterLoading: state.get('productState').get('productsFilterLoading'),
  filterError: state.get('productState').get('productsFilterError'),
  filteredProducts: state.get('productState').get('filteredProducts'),
  objectives: state.get('objectiveState').get('objectives'),
  objectivesLoading: state.get('objectiveState').get('objectivesLoading'),
  types: state.get('typeState').get('types'),
  typesLoading: state.get('typeState').get('typesLoading'),
  suppliers: state.get('supplierState').get('suppliers'),
  filters: state.get('filterState').get('filters'),
  products: state.get('productState').get('products'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addFilter,
      removeFilter,
      setFilter,
      setFilters,
      initFilters,
      fetchFilteredProducts,
      fetchPage,
      pageIsLoaded,
      setOnboardingStartingPoint,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Products));
