import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from '../../Core/Tooltip';
import Accordion from '../../Core/Accordion/Accordion';

import FilterBlockLoading from './Loading/FilterBlockLoading';

import '../../../styles/components/Blocks/Filter/FilterBlock.css';

const ITEMS_LIST = 5;

class FilterMultipleBlock extends PureComponent {
  state = {
    accordionIsOpen: false,
    isMobile: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleDimensionChange);

    this.handleDimensionChange();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleDimensionChange);
  }

  handleDimensionChange = () => {
    const { isMobile } = this.state;

    if (isMobile && document.body.clientWidth > 900) {
      this.setState({ isMobile: false });
    } else if (!isMobile && document.body.clientWidth <= 900) {
      this.setState({ isMobile: true });
    }
  };

  handleAccordionChange = value => {
    this.setState({ accordionIsOpen: value });
  };

  handleChange = (e, item) => {
    const { type, onChange } = this.props;

    const value = item.value || item.id;

    onChange(type, item.id, value, e.target.checked);
  };

  renderCheckbox = item => {
    const { checkedItems } = this.props;
    let isChecked = false;

    checkedItems.forEach(checkedItem => {
      if (checkedItem.id === item.id) {
        isChecked = true;
      }
    });

    const itemClass = classNames('FilterBlock__item', {
      'FilterBlock__item--checked': isChecked,
    });

    return (
      <div key={item.id} className={itemClass}>
        <label className="FilterBlock__label" htmlFor={item.id}>
          <input
            className="FilterBlock__input"
            type="checkbox"
            name={item.id}
            id={item.id}
            onChange={e => this.handleChange(e, item)}
            checked={isChecked}
          />
          <span className="FilterMultipleBlock__check" />
          <div className="FilterBlock__value">
            {item.label}
            {item.tooltip && (
              <Tooltip
                className="FilterBlock__value-tooltip"
                title={item.tooltip}
              />
            )}
          </div>
        </label>
      </div>
    );
  };

  render() {
    const { loading, list, checkedItems } = this.props;
    const { accordionIsOpen, isMobile } = this.state;

    const firstList = [...list].slice(0, ITEMS_LIST);
    const secondList = [...list].slice(ITEMS_LIST);
    const checkedSecondList = [];

    if (checkedItems && secondList) {
      checkedItems.forEach(checkedItem => {
        secondList.forEach(secondListItem => {
          if (checkedItem.id === secondListItem.id) {
            checkedSecondList.push(checkedItem.id);
          }
        });
      });
    }

    if (loading) {
      return <FilterBlockLoading />;
    }

    const accordionClasses = classNames('FilterBlock__lists', {
      'FilterBlock__lists--is-closed':
        secondList.length > 0 && !accordionIsOpen,
    });

    const accordionContent = (
      <div className="FilterBlock__lists">
        {secondList.map(item => this.renderCheckbox(item))}
      </div>
    );

    return (
      <div className="FilterBlock">
        <div className={accordionClasses}>
          {firstList.map(item => this.renderCheckbox(item))}
        </div>
        {secondList.length > 0 && (
          <Accordion
            content={accordionContent}
            inversed
            title={
              accordionIsOpen ? 'Replier les types' : 'Voir tous les types'
            }
            manual
            color="#616c7a"
            displayIconLeft={false}
            opacityHeight="80px"
            opacityColor={isMobile ? '255, 255, 255' : '248, 249, 250'}
            initialExpand={checkedSecondList.length > 0}
            onChange={this.handleAccordionChange}
          />
        )}
      </div>
    );
  }
}

FilterMultipleBlock.defaultProps = {
  list: [],
  loading: false,
  checkedItems: [],
};

FilterMultipleBlock.propTypes = {
  type: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  checkedItems: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, value: PropTypes.string }),
  ),
  onChange: PropTypes.func.isRequired,
};

export default FilterMultipleBlock;
