import { BASE_API_URL } from '../config/api';

export const prevalidateContract = async data => {
  const url = new URL(`${BASE_API_URL}/2.0/contract/prevalidate`);

  const response = await fetch(url.href, {
    method: 'POST',
    body: JSON.stringify({ product: data.productId, funds: data.funds }),
    credentials: 'include',
  });

  const responseData = await response.json();

  if (response.ok) {
    return responseData;
  }

  throw responseData;
};

export const validateContract = async data => {
  const url = new URL(`${BASE_API_URL}/2.0/contract/validate`);

  const response = await fetch(url.href, {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
  });

  const responseData = await response.json();

  if (response.ok) {
    return responseData;
  }

  throw responseData;
};

export const deleteContract = async id => {
  const url = new URL(`${BASE_API_URL}/2.0/contract/${id}`);

  const response = await fetch(url.href, {
    method: 'DELETE',
    credentials: 'include',
  });

  const responseData = await response.json();

  if (response.ok) {
    return responseData;
  }

  throw responseData;
};
