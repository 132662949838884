import React from 'react';
import PropTypes from 'prop-types';

import pageShape from '../../../shapes/pageShape';

import withPageContext from '../../HOC/withPageContext';

const PageBreadcrumb = ({ context }) => {
  const { page } = context;

  return <span className="Breadcrumbs__item">{page && page.name}</span>;
};

PageBreadcrumb.defaultProps = {
  context: {
    page: {},
  },
};

PageBreadcrumb.propTypes = {
  context: PropTypes.shape({
    page: PropTypes.shape(pageShape),
  }),
};

export default withPageContext(PageBreadcrumb);
