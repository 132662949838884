/* eslint-disable camelcase */
import React from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { Button, ArrowRight } from '@robinfinance/ui';

import Phone1 from '../../../assets/images/phone-1.png';
import Phone1_2x from '../../../assets/images/phone-1@2x.png';
import Phone1_200w from '../../../assets/images/phone-1-200w.png';
import Phone1_250w from '../../../assets/images/phone-1-250w.png';
import Phone1_350w from '../../../assets/images/phone-1-350w.png';
import Phone1_500w from '../../../assets/images/phone-1-500w.png';
import Phone1_700w from '../../../assets/images/phone-1-700w.png';

import Phone2 from '../../../assets/images/phone-2.png';
import Phone2_2x from '../../../assets/images/phone-2@2x.png';
import Phone2_200w from '../../../assets/images/phone-2-200w.png';
import Phone2_250w from '../../../assets/images/phone-2-250w.png';
import Phone2_350w from '../../../assets/images/phone-2-350w.png';
import Phone2_500w from '../../../assets/images/phone-2-500w.png';
import Phone2_700w from '../../../assets/images/phone-2-700w.png';

import '../../../styles/components/Blocks/Home/HowDoesItWork.css';

function HowDoesItWork() {
  return (
    <ParallaxProvider>
      <div className="HowDoesItWork">
        <div className="HowDoesItWork__container">
          <div className="HowDoesItWork__content-container">
            <div className="HowDoesItWork__image-container">
              <Parallax
                className="HowDoesItWork__image HowDoesItWork__image--left"
                y={['40px', '-40px']}
              >
                <img
                  src={Phone2}
                  srcSet={`${Phone2_2x} 2x, ${Phone2_200w} 200w, ${Phone2_250w} 250w, ${Phone2_350w} 350w, ${Phone2} 400w, ${Phone2_500w} 500w, ${Phone2_700w} 700w`}
                  alt="Smartphone avec la simulation Mieuxplacer.com"
                />
              </Parallax>
              <Parallax
                className="HowDoesItWork__image HowDoesItWork__image--right"
                y={['-40px', '40px']}
              >
                <img
                  src={Phone1}
                  srcSet={`${Phone1_2x} 2x, ${Phone1_200w} 200w, ${Phone1_250w} 250w, ${Phone1_350w} 350w, ${Phone1} 400w, ${Phone1_500w} 500w, ${Phone1_700w} 700w`}
                  alt="Smartphone avec la simulation Mieuxplacer.com"
                />
              </Parallax>
            </div>
            <div className="HowDoesItWork__content">
              <div className="HowDoesItWork__header">
                <h2 className="HowDoesItWork__title">
                  Comment ça marche&nbsp;?
                </h2>
                <Button
                  id="Home-HowDoesItWork-CTA-1"
                  scope="link"
                  type="primary"
                  className="HowDoesItWork__header-link"
                  to="/qui-sommes-nous/comment-ca-marche"
                >
                  En savoir plus
                </Button>
              </div>
              <div className="HowDoesItWork__content-body">
                <div className="HowDoesItWork__list-container">
                  <ul className="HowDoesItWork__list">
                    <li className="HowDoesItWork__list-item">
                      Répondez à notre <b>questionnaire en ligne</b> : nous
                      étudions votre projet d&apos;épargne et votre situation.
                    </li>
                    <li className="HowDoesItWork__list-item">
                      Nous comparons les 150 meilleurs contrats du marché pour
                      trouver <b>le placement le plus adapté</b> à vos besoins.
                    </li>
                    <li className="HowDoesItWork__list-item">
                      Si vous souhaitez souscrire, <b>nous vous accompagnons</b>{' '}
                      pour la constitution et le suivi de votre contrat
                    </li>
                  </ul>
                </div>
                <div className="HowDoesItWork__footer">
                  <Button
                    id="Home-HowDoesItWork-CTA-2"
                    scope="button"
                    type="primary"
                    iconSide="right"
                    icon={<ArrowRight color="#ffc501" />}
                    className="HowDoesItWork__footer-link"
                    to="/connaissance-client/1"
                  >
                    Je réponds aux questions
                  </Button>
                  <div className="HowDoesItWork__footer-tag">
                    C&apos;est <b>gratuit</b> et <b>sans engagement</b> !
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default HowDoesItWork;
