import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { bonusShape } from '../../../shapes/prismicShape';

import RichText from '../../Core/RichText';

import '../../../styles/components/Prismic/PrismicBonus.css';

class PrismicBonus extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicBonus">
        <div className="PrismicBonus__block">
          {data.primary.bonus_icon && data.primary.bonus_icon.url && (
            <div className="PrismicBonus__icon">
              <img
                src={data.primary.bonus_icon.url}
                alt={data.primary.bonus_icon.alt}
              />
            </div>
          )}
          <div className="PrismicBonus__content">
            <h3 className="PrismicBonus__title">{data.primary.bonus_title}</h3>
            <div className="PrismicBonus__description">
              <RichText paragraphs={data.primary.bonus_text} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrismicBonus.propTypes = {
  data: PropTypes.shape(bonusShape).isRequired,
};

export default PrismicBonus;
