import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { format } from 'date-fns';

import { fetchTrustpilot } from '../../../actions/TrustpilotActions';

import trustpilotShape from '../../../shapes/trustpilotShape';
import { trustpilotShape as trustpilotSliceShape } from '../../../shapes/prismicShape';

import toJS from '../../HOC/toJS';

import starIcon from '../../../assets/icons/star.svg';
import logo from '../../../assets/icons/logo-trustpilot.png';

import '../../../styles/components/Prismic/PrismicTrustpilot.css';

const SLIDER_SETTINGS = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  swipeToSlide: false,
  className: 'PrismicTrustpilot__slick',
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class PrismicTrustpilot extends PureComponent {
  constructor(props) {
    super(props);

    this.sliderRef = React.createRef();
    this.state = {
      isTablet: false,
      isMobile: false,
    };
  }

  componentDidMount() {
    const { trustpilot, fetchTrustpilot, trustpilotLoading } = this.props;

    if (!trustpilotLoading && !trustpilot) {
      fetchTrustpilot();
    }

    window.addEventListener('resize', this.handleDimensionChange);

    this.handleDimensionChange();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleDimensionChange);
  }

  handleDimensionChange = () => {
    const { isTablet, isMobile } = this.state;

    if ((isTablet || isMobile) && document.body.clientWidth > 800) {
      this.setState({ isTablet: false, isMobile: false });
    } else if (
      !isTablet &&
      document.body.clientWidth <= 800 &&
      document.body.clientWidth > 550
    ) {
      this.setState({ isTablet: true, isMobile: false });
    } else if (!isMobile && document.body.clientWidth <= 550) {
      this.setState({ isTablet: false, isMobile: true });
    }
  };

  handleNext = () => {
    this.sliderRef.slickNext();
  };

  handlePrevious = () => {
    this.sliderRef.slickPrev();
  };

  renderStarsBlocksGrey = review => {
    switch (review.stars) {
      case 1:
        return [1, 2, 3, 4];
      case 2:
        return [1, 2, 3];
      case 3:
        return [1, 2];
      case 4:
        return [1];
      case 5:
        return [];
      default:
        return null;
    }
  };

  renderColor = review => {
    switch (review.stars) {
      case 1:
        return '#ff3722';
      case 2:
        return '#ff8622';
      case 3:
        return '#ffce00';
      case 4:
        return '#73cf11';
      case 5:
        return '#00b67a';
      default:
        return null;
    }
  };

  renderStarsBlocks = review => {
    switch (review.stars) {
      case 5:
        return [1, 2, 3, 4, 5];
      case 4:
        return [1, 2, 3, 4];
      case 3:
        return [1, 2, 3];
      case 2:
        return [1, 2];
      case 1:
        return [1];
      default:
        return null;
    }
  };

  renderStarContainer = review => (
    <div className="PrismicTrustpilot__stars__container">
      {review &&
        this.renderStarsBlocks(review).map(key => (
          <div
            key={key}
            style={{
              backgroundColor: `${this.renderColor(review)}`,
            }}
            className="PrismicTrustpilot__stars"
          >
            <img
              src={starIcon}
              alt="star"
              className="PrismicTrustpilot__star"
            />
          </div>
        ))}
      {review &&
        this.renderStarsBlocksGrey(review).map(key => (
          <div
            key={key}
            className="PrismicTrustpilot__stars PrismicTrustpilot__grey"
          >
            <img
              src={starIcon}
              alt="star"
              className="PrismicTrustpilot__star"
            />
          </div>
        ))}
    </div>
  );

  renderProfil = trustpilot => {
    const review = trustpilot.businessUnit;

    return (
      <Fragment>
        <div className="PrismicTrustpilot__starsString">
          {trustpilot.starsString}
        </div>
        {this.renderStarContainer(review)}
        <div> Noté {trustpilot.businessUnit.trustScore} sur 10</div>
        <div className="PrismicTrustpilot__margin__text PrismicTrustpilot__links">
          Basé sur
          <a
            className="PrismicTrustpilot__links"
            target="_blank"
            rel="nofollow noopener noreferrer"
            href={trustpilot.links.profileUrl}
          >
            {trustpilot.businessUnit.numberOfReviews.total} avis
          </a>
        </div>
        <a
          target="_blank"
          rel="nofollow noopener noreferrer"
          href={trustpilot.links.profileUrl}
        >
          <img
            className="PrismicTrustpilot__logo"
            src={logo}
            alt="Avis Trustpilot"
          />
        </a>
      </Fragment>
    );
  };

  renderReviews = trustpilot => {
    const { isTablet, isMobile } = this.state;

    let reviews = [...trustpilot.reviews];

    if (isTablet) {
      reviews = reviews.slice(0, 10);
    } else if (isMobile) {
      reviews = reviews.slice(0, 5);
    }

    return (
      <Fragment>
        <Slider
          ref={ref => {
            this.sliderRef = ref;
          }}
          {...SLIDER_SETTINGS}
        >
          {reviews.map(review => {
            if (review.stars >= 3) {
              return (
                <a
                  href={review.reviewUrl}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  key={review.reviewUrl}
                >
                  <div className="PrismicTrustpilot__container__review">
                    <div className="PrismicTrustpilot__review__date">
                      <div>{this.renderStarContainer(review)}</div>
                      <div className="PrismicTrustpilot__review__date">
                        {format(new Date(review.createdAt), 'dd/MM/yyyy')}
                      </div>
                    </div>
                    <div className="PrismicTrustpilot__review__title__container">
                      <div className="PrismicTrustpilot__review__title">
                        {review.title.length < 30 ? (
                          <div>{review.title}</div>
                        ) : (
                          <div>{review.title.substr(0, 30)}...</div>
                        )}
                      </div>
                      <div className="PrismicTrustpilot__review__text">
                        {review.text.length < 100 ? (
                          <div>{review.text}</div>
                        ) : (
                          <div>{review.text.substr(0, 100)}...</div>
                        )}
                      </div>
                    </div>
                    <div className="PrismicTrustpilot__review__name">
                      {review.consumer.displayName}
                    </div>
                  </div>
                </a>
              );
            }

            return null;
          })}
        </Slider>
      </Fragment>
    );
  };

  render() {
    const { data, trustpilot } = this.props;

    if (!trustpilot) {
      return null;
    }

    return (
      <div className="PrismicTrustpilot">
        <div className="PrismicTrustpilot__header">
          <h3 className="PrismicTrustpilot__title__trustpilot">
            {data.primary.trustpilot_title}
          </h3>
          <div className="PrismicTrustpilot__btn">
            <button
              type="button"
              className="PrismicTrustpilot__btn__arrow"
              onClick={this.handlePrevious}
            >
              <div className="PrismicTrustpilot__arrow__left">&nbsp;</div>
            </button>
            <button
              type="button"
              className="PrismicTrustpilot__btn__arrow"
              onClick={this.handleNext}
            >
              <div className="PrismicTrustpilot__arrow__right">&nbsp;</div>
            </button>
          </div>
        </div>
        <div className="PrismicTrustpilot__reviews__profile">
          <div className="PrismicTrustpilot__profile">
            {this.renderProfil(trustpilot)}
          </div>
          <div className="PrismicTrustpilot__reviews">
            {this.renderReviews(trustpilot)}
          </div>
        </div>
      </div>
    );
  }
}

PrismicTrustpilot.defaultProps = {
  trustpilot: {},
};

PrismicTrustpilot.propTypes = {
  data: PropTypes.shape(trustpilotSliceShape).isRequired,
  trustpilotLoading: PropTypes.bool.isRequired,
  trustpilot: PropTypes.shape(trustpilotShape),
  fetchTrustpilot: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  trustpilot: state.get('trustpilotState').get('trustpilot'),
  trustpilotLoading: state.get('trustpilotState').get('trustpilotLoading'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTrustpilot,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(PrismicTrustpilot));
