import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FilterBlockLoading from './Loading/FilterBlockLoading';

import '../../../styles/components/Blocks/Filter/FilterBlock.css';

class FilterSingleBlock extends PureComponent {
  handleChange = item => {
    const { type, onChange } = this.props;

    const value = item.value || item.id;

    onChange(type, item.id, value);
  };

  render() {
    const { list, loading, checkedItem } = this.props;

    if (loading) {
      return <FilterBlockLoading />;
    }

    return (
      <div className="FilterBlock">
        {list &&
          list.length > 0 &&
          list.map(item => {
            const isChecked = item.id === checkedItem.id;

            const itemClass = classNames('FilterBlock__item', {
              'FilterBlock__item--checked': isChecked,
            });

            return (
              <div key={item.id} className={itemClass}>
                <label className="FilterBlock__label" htmlFor={item.id}>
                  <input
                    className="FilterBlock__input"
                    type="radio"
                    name={item.id}
                    id={item.id}
                    onChange={() => this.handleChange(item)}
                    checked={isChecked}
                  />
                  <span className="FilterSingleBlock__check" />
                  <div className="FilterBlock__value">{item.label}</div>
                </label>
              </div>
            );
          })}
      </div>
    );
  }
}

FilterSingleBlock.defaultProps = {
  list: [],
  loading: false,
  checkedItem: {
    id: 'tous',
    value: '',
  },
};

FilterSingleBlock.propTypes = {
  type: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  checkedItem: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

export default FilterSingleBlock;
