import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import { commissionFeesShape } from '../../../shapes/prismicShape';

import RichText from '../../Core/RichText';

import logo from '../../../assets/icons/logo-mieuxplacer-com.svg';

import '../../../styles/components/Prismic/PrismicCommissionsTable.css';

class PrismicCommissionsTable extends PureComponent {
  renderRangeBar = (minimum, maximum, value) => {
    let percentage;

    if (minimum == null || maximum == null) {
      percentage = 50;
    } else {
      percentage = ((value - minimum) / (maximum - minimum)) * 100;
    }

    return (
      <div className="PrismicCommissionsTable__range-bar-container">
        <div
          className="PrismicCommissionsTable__range-bar-value-container"
          style={{ left: `calc(${percentage}% - 13px)` }}
        >
          <span className="PrismicCommissionsTable__range-bar-logo" />
          <span className="PrismicCommissionsTable__range-bar-value">
            {numeral(value).format('0.00')} %
          </span>
        </div>
        <div className="PrismicCommissionsTable__range-bar">
          <span
            className="PrismicCommissionsTable__range-bar-pointer"
            style={{ left: `${percentage}%` }}
          />
        </div>
      </div>
    );
  };

  renderColumn = (minimum, maximum, average, value) => {
    if (value == null) {
      return (
        <span className="PrismicCommissionsTable__empty">
          Il n&apos;existe pas de commissions sur encours pour ce type de
          produit
        </span>
      );
    }

    return (
      <Fragment>
        <div className="PrismicCommissionsTable__minimum">
          <span className="PrismicCommissionsTable__minimum-value">
            {minimum == null ? '-' : `${numeral(minimum).format('0.00')} %`}
          </span>
          <span className="PrismicCommissionsTable__minimum-label">
            Min. marché
          </span>
        </div>
        <div className="PrismicCommissionsTable__bar">
          {this.renderRangeBar(minimum, maximum, value)}
          <span className="PrismicCommissionsTable__average">
            Moyenne du marché : {numeral(average).format('0.00')} %
          </span>
        </div>
        <div className="PrismicCommissionsTable__maximum">
          <span className="PrismicCommissionsTable__maximum-value">
            {maximum == null ? '-' : `${numeral(maximum).format('0.00')} %`}
          </span>
          <span className="PrismicCommissionsTable__maximum-label">
            Max. marché
          </span>
        </div>
      </Fragment>
    );
  };

  renderRow = (index, row) => (
    <div key={index} className="PrismicCommissionsTable__row">
      <div className="PrismicCommissionsTable__column PrismicCommissionsTable__column--label">
        <span className="PrismicCommissionsTable__type-label">
          {row.product_type_label}
        </span>
      </div>
      <div className="PrismicCommissionsTable__column">
        {this.renderColumn(
          row.subscription_minimum_fee,
          row.subscription_maximum_fee,
          row.subscription_average_fee,
          row.subscription_value,
        )}
      </div>
      <div className="PrismicCommissionsTable__column">
        {this.renderColumn(
          row.outstanding_minimum_fee,
          row.outstanding_maximum_fee,
          row.outstanding_average_fee,
          row.outstanding_value,
        )}
      </div>
    </div>
  );

  render() {
    const { data } = this.props;

    return (
      <div className="PrismicCommissionsTable">
        <div className="PrismicCommissionsTable__table">
          <div className="PrismicCommissionsTable__header">
            <span className="PrismicCommissionsTable__header-item PrismicCommissionsTable__header-item--spacer" />
            <div className="PrismicCommissionsTable__header-item">
              {data.primary.column_one_title}
            </div>
            <div className="PrismicCommissionsTable__header-item">
              {data.primary.column_two_title}
            </div>
          </div>
          <div className="PrismicCommissionsTable__rows">
            {data.items.map((item, index) => this.renderRow(index, item))}
          </div>
        </div>

        <div className="PrismicCommissionsTable__table PrismicCommissionsTable__table--mobile">
          <div className="PrismicCommissionsTable__header">
            <span className="PrismicCommissionsTable__header-item PrismicCommissionsTable__header-item--spacer" />
            <div className="PrismicCommissionsTable__header-item">
              {data.primary.column_one_title}
            </div>
            <div className="PrismicCommissionsTable__header-item">
              {data.primary.column_two_title}
            </div>
          </div>
          <div className="PrismicCommissionsTable__rows">
            {data.items.map((item, index) => this.renderRow(index, item))}
          </div>
        </div>

        <div className="PrismicCommissionsTable__footer">
          <div className="PrismicCommissionsTable__annotations">
            {data.items.map((item, index) => {
              if (
                item.product_type_annotation &&
                item.product_type_annotation.length > 0
              ) {
                return (
                  <div
                    key={index}
                    className="PrismicCommissionsTable__annotation"
                  >
                    <RichText paragraphs={item.product_type_annotation} />
                  </div>
                );
              }

              return null;
            })}
          </div>
          <div className="PrismicCommissionsTable__legend">
            <img
              src={logo}
              alt="Logo Mieuxplacer.com"
              width="27px"
              height="28px"
            />
            <span>: commission moyenne touchée par mieuxplacer.com</span>
          </div>
        </div>
      </div>
    );
  }
}

PrismicCommissionsTable.propTypes = {
  data: PropTypes.shape(commissionFeesShape).isRequired,
};

export default PrismicCommissionsTable;
