import {
  OPEN_BURGER_MENU,
  CLOSE_BURGER_MENU,
  OPEN_PRODUCTS_SUBMENU,
  CLOSE_PRODUCTS_SUBMENU,
  OPEN_ABOUT_SUBMENU,
  CLOSE_ABOUT_SUBMENU,
} from '../actions/AppActions';
import { OPEN_CART, CLOSE_CART } from '../actions/CartActions';

const openCart = (action, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Toggle cart',
    eventCategory: 'Cart',
    eventAction: 'Open',
    page: nextRouterState.location.pathname,
    pageName,
  };
};

const closeCart = (action, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Toggle cart',
    eventCategory: 'Cart',
    eventAction: 'Close',
    page: nextRouterState.location.pathname,
    pageName,
  };
};

const openBurgerMenu = (action, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Toggle mobile menu',
    eventCategory: 'Mobile menu',
    eventAction: 'Open',
    page: nextRouterState.location.pathname,
    pageName,
  };
};

const closeBurgerMenu = (action, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Toggle mobile menu',
    eventCategory: 'Mobile menu',
    eventAction: 'Close',
    page: nextRouterState.location.pathname,
    pageName,
  };
};

const openProductsSubmenu = (action, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Toggle products submenu',
    eventCategory: 'Mobile menu',
    eventAction: 'Open products submenu',
    page: nextRouterState.location.pathname,
    pageName,
  };
};

const closeProductsSubmenu = (action, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Toggle products submenu',
    eventCategory: 'Mobile menu',
    eventAction: 'Close products submenu',
    page: nextRouterState.location.pathname,
    pageName,
  };
};

const openAboutSubmenu = (action, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Toggle products submenu',
    eventCategory: 'Mobile menu',
    eventAction: 'Open about submenu',
    page: nextRouterState.location.pathname,
    pageName,
  };
};

const closeAboutSubmenu = (action, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Toggle products submenu',
    eventCategory: 'Mobile menu',
    eventAction: 'Close about submenu',
    page: nextRouterState.location.pathname,
    pageName,
  };
};

const headerEventsMap = {
  [OPEN_CART]: openCart,
  [CLOSE_CART]: closeCart,
  [OPEN_BURGER_MENU]: openBurgerMenu,
  [CLOSE_BURGER_MENU]: closeBurgerMenu,
  [OPEN_PRODUCTS_SUBMENU]: openProductsSubmenu,
  [CLOSE_PRODUCTS_SUBMENU]: closeProductsSubmenu,
  [OPEN_ABOUT_SUBMENU]: openAboutSubmenu,
  [CLOSE_ABOUT_SUBMENU]: closeAboutSubmenu,
};

export default headerEventsMap;
