import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Button, Filter } from '@robinfinance/ui';

import Icon from '../../Core/Icon';
import FilterSidebar from './FilterSidebar';

import close from '../../../assets/icons/close-icon.svg';

import '../../../styles/components/Blocks/Filter/MobileFilter.css';

class MobileFilter extends PureComponent {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleDimensionChange);
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('no-scroll');

    window.removeEventListener('resize', this.handleDimensionChange);
  }

  handleDimensionChange = () => {
    const { isOpen } = this.state;

    if (isOpen && document.body.clientWidth > 900) {
      this.handleToggle();
    }
  };

  handleToggle = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });

    document.documentElement.classList.toggle('no-scroll');
  };

  render() {
    const { isOpen } = this.state;

    const panelClasses = classNames('MobileFilter__panel', {
      'MobileFilter__panel--is-open': isOpen,
    });

    return (
      <div className="MobileFilter">
        <div className="MobileFilter__actions">
          <Button
            scope="button"
            type="ghost"
            iconSide="left"
            icon={<Filter color="#ffc501" />}
            onClick={this.handleToggle}
          >
            Filtrer les produits
          </Button>
        </div>
        <div className={panelClasses}>
          <div className="MobileFilter__panel-container">
            <button
              type="button"
              className="MobileFilter__close"
              onClick={this.handleToggle}
            >
              <Icon src={close} alt="Fermer" width="18px" height="18px" />
            </button>
            <FilterSidebar onToggle={this.handleToggle} />
          </div>
        </div>
      </div>
    );
  }
}

export default MobileFilter;
