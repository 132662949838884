import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import '../../styles/components/Core/Button.css';

class Button extends PureComponent {
  render() {
    const {
      label,
      icon,
      iconSide,
      size,
      type,
      buttonType,
      link,
      to,
      href,
      target,
      color,
      rounded,
      bold,
      centered,
      className,
      disabled,
      loading,
      hasLoadingState,
      onClick,
      id,
    } = this.props;

    const buttonClasses = classNames('Button', {
      [`Button--${type}`]: true,
      [`Button--${size}`]: true,
      'Button--rounded': rounded,
      'Button--bold': bold,
      'Button--centered': centered,
      'Button--icon-only': icon && !label,
      'Button--disabled': disabled,
      'Button--loading': loading,
      'Button--no-icon': !icon,
      [className]: className,
    });

    const buttonContainerClasses = classNames('Button__container', {
      [`Button__container--icon-${iconSide}`]: icon,
    });

    if (link) {
      if (href) {
        return (
          <a
            href={href}
            target={target}
            rel={target ? 'noopener noreferrer' : null}
            className={buttonClasses}
            style={{ backgroundColor: color }}
            id={id}
          >
            <span className={buttonContainerClasses}>
              <span className="Button__label">{label}</span>
              {icon && <span className="Button__icon">{icon}</span>}
            </span>
          </a>
        );
      }

      return (
        <Link
          to={to}
          className={buttonClasses}
          style={{ backgroundColor: color }}
          onClick={onClick}
          id={id}
        >
          <span className={buttonContainerClasses}>
            <span className="Button__label">{label}</span>
            {icon && <span className="Button__icon">{icon}</span>}
          </span>
        </Link>
      );
    }

    /* eslint-disable react/button-has-type */
    return (
      <button
        type={buttonType}
        className={buttonClasses}
        onClick={onClick}
        style={{ backgroundColor: color }}
        disabled={disabled}
        id={id}
      >
        <span className={buttonContainerClasses}>
          <span className="Button__label">{label}</span>
          {icon && <span className="Button__icon">{icon}</span>}
          {hasLoadingState && <span className="Button__loader" />}
        </span>
      </button>
    );
  }
}

Button.defaultProps = {
  label: null,
  icon: null,
  iconSide: 'left',
  type: 'primary',
  buttonType: 'button',
  size: 'normal',
  link: false,
  to: '/',
  href: null,
  target: '_self',
  color: null,
  rounded: false,
  bold: false,
  centered: false,
  className: null,
  disabled: false,
  loading: false,
  hasLoadingState: false,
  onClick: () => {},
  id: null,
};

Button.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  iconSide: PropTypes.oneOf(['left', 'right']),
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'grey',
    'white',
    'transparent',
  ]),
  buttonType: PropTypes.oneOf(['button', 'submit']),
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  link: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.oneOf(['_self', '_blank']),
  color: PropTypes.string,
  rounded: PropTypes.bool,
  bold: PropTypes.bool,
  centered: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  hasLoadingState: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

export default Button;
