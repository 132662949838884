import appEventsMap from './appEvents';
import routingEventsMap from './routingEvents';
import headerEventsMap from './headerEvents';
import productEventsMap from './productEvents';
import recommendationEventsMap from './recommendationEvents';
import onboardingEventsMap from './onboardingEvents';

const eventsMap = {
  ...appEventsMap,
  ...routingEventsMap,
  ...headerEventsMap,
  ...productEventsMap,
  ...recommendationEventsMap,
  ...onboardingEventsMap,
};

export default eventsMap;
