import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@robinfinance/ui';

import { shuffle } from '../../../helpers/arrayHelpers';

import { featuredProductsShape } from '../../../shapes/prismicShape';
import productShape from '../../../shapes/productShape';
import supplierShape from '../../../shapes/supplierShape';
import typeShape from '../../../shapes/typeShape';

import withHomeContext from '../../HOC/withHomeContext';
import FeaturedProductsList from '../../Blocks/Products/FeaturedProductsList';

import '../../../styles/components/Prismic/PrismicFeaturedProducts.css';

class PrismicFeaturedProducts extends PureComponent {
  render() {
    const {
      data,
      context: { products, productsLoading, productsError, types, suppliers },
    } = this.props;

    if (productsError) {
      return null;
    }

    const featuredProductsIds = data.items.map(
      product => product.featured_products_product.id,
    );

    let fullProducts = products.filter(product =>
      featuredProductsIds.includes(product.id),
    );

    if (fullProducts.length > 3) {
      fullProducts = shuffle(fullProducts).slice(0, 3);
    }

    return (
      <div className="PrismicFeaturedProducts">
        <div className="PrismicFeaturedProducts__header">
          <h3 className="PrismicFeaturedProducts__title">
            {data.primary.featured_products_title}
          </h3>
          <Button scope="link" type="primary" to="/produits">
            {data.primary.featured_products_link}
          </Button>
        </div>
        <FeaturedProductsList
          products={fullProducts}
          types={types}
          suppliers={suppliers}
          loading={productsLoading}
        />
      </div>
    );
  }
}

PrismicFeaturedProducts.defaultProps = {
  context: {
    products: [],
  },
};

PrismicFeaturedProducts.propTypes = {
  data: PropTypes.shape(featuredProductsShape).isRequired,
  context: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.shape(productShape)),
    productsLoading: PropTypes.bool.isRequired,
    productsError: PropTypes.bool.isRequired,
    types: PropTypes.arrayOf(PropTypes.shape(typeShape)),
    suppliers: PropTypes.arrayOf(PropTypes.shape(supplierShape)),
  }),
};

export default withHomeContext(PrismicFeaturedProducts);
