import { PAGE_LOADED, PAGE_VIEW, PAGE_SCROLLED } from '../actions/AppActions';

const pageView = ({ payload: { pageName, page } }, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const nextAppState = nextState.get('appState').toJS();
  const nextUserState = nextState.get('userState').toJS();

  let formattedPage = nextRouterState.location.pathname;

  if (page) {
    formattedPage = page;
  }

  return {
    event: 'Page View - React',
    page: formattedPage,
    pageName,
    utmCampaign: nextAppState.utmCampaign,
    utmSource: nextAppState.utmSource,
    utmMedium: nextAppState.utmMedium,
    authenticated: nextUserState.isAuthenticated,
  };
};

const pageScroll = ({ payload }, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Scroll Depth',
    page: nextRouterState.location.pathname,
    pageName,
    pageScroll: payload,
  };
};

const routingEventsMap = {
  [PAGE_LOADED]: pageView,
  [PAGE_VIEW]: pageView,
  [PAGE_SCROLLED]: pageScroll,
};

export default routingEventsMap;
