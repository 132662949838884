import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import productShape from '../../../shapes/productShape';
import supplierShape from '../../../shapes/supplierShape';
import typeShape from '../../../shapes/typeShape';

import { getShortWithdrawLimitLabel } from '../../../helpers/prismicContentHelpers';

import Link from '../../Core/Link';
import Tooltip from '../../Core/Tooltip';
import RichText from '../../Core/RichText';
import RiskBar from './RiskBar';
import ProductListingLoading from './Loading/ProductListingLoading';

import shape from '../../../assets/icons/shape.svg';

import '../../../styles/components/Blocks/Product/ProductListing.css';

class ProductListing extends PureComponent {
  renderFeatureBlock = (label, value, tooltip) => (
    <div key={label} className="ProductListing__feature">
      <div className="ProductListing__feature-label">
        {label}
        {tooltip && (
          <Tooltip
            className="ProductListing__feature-tooltip"
            title={tooltip}
          />
        )}
      </div>
      <div className="ProductListing__feature-content">{value}</div>
    </div>
  );

  renderGuaranteedCapitalOrLevelOfTaxAdvantage = () => {
    const { product } = this.props;

    if (product.levelOfTaxAdvantage) {
      return this.renderFeatureBlock(
        "Niveau de l'avantage fiscal",
        product.levelOfTaxAdvantage,
        'Montant maximum que vous pouvez déduire de vos impôts.',
      );
    }

    if (product.guaranteedCapital) {
      return this.renderFeatureBlock(
        'Capital garanti',
        product.guaranteedCapital,
        'Part maximum de votre placement pour laquelle votre capital est garanti. Elle dépend du montant qui sera investi sur le fonds euro du contrat.',
      );
    }

    return null;
  };

  renderInvestorTypologies = () => {
    const { product } = this.props;

    if (product.investorTypologies && product.investorTypologies.length > 0) {
      return this.renderFeatureBlock(
        "Typ. d'investisseurs",
        product.investorTypologies[0],
        "Types d'investisseurs à qui s'adresse ce produit en termes d'expérience en placements financiers.",
      );
    }

    return null;
  };

  renderFirstFeature = () => {
    const { product } = this.props;

    if (product.listingFirstFeatureLabel && product.listingFirstFeatureValue) {
      return this.renderFeatureBlock(
        product.listingFirstFeatureLabel,
        product.listingFirstFeatureValue,
      );
    }

    return null;
  };

  renderMinimumInvestment = () => {
    const { product } = this.props;

    if (product.minimumInvestment) {
      return this.renderFeatureBlock(
        'Placement Min.',
        `${numeral(product.minimumInvestment).format()} €`,
        'Montant minimum du 1er versement.',
      );
    }

    return null;
  };

  renderWithdrawLimit = () => {
    const { product } = this.props;

    if (product.withdrawLimit) {
      return this.renderFeatureBlock(
        'Liquidité',
        getShortWithdrawLimitLabel(product.withdrawLimit),
        'Indique si votre placement est bloqué ou si vous pouvez récupérer facilement votre argent.',
      );
    }

    return null;
  };

  renderRisk = () => {
    const { product } = this.props;

    if (product.riskLevelEnabled && product.riskLevel) {
      return (
        <div className="ProductListing__riskBar">
          <div className="ProductListing__feature">
            <div className="ProductListing__feature-label">
              Risque
              <Tooltip
                className="ProductListing__feature-tooltip"
                title="Niveau de risque auquel ce placement vous expose"
              />
            </div>
            <RiskBar number={product.riskLevel} small />
          </div>
        </div>
      );
    }
    return null;
  };

  renderProductFlag = () => {
    const { product } = this.props;
    if (product.subscribable) {
      return (
        <div className="ProductListing__flag ProductListing__flag--is-subscribable">
          <img src={shape} alt="shape" className="ProductListing__check" />
          <span>Produit disponible</span>
        </div>
      );
    }
    return (
      <div className="ProductListing__flag ProductListing__flag--is-not-subscribable">
        Non disponible
      </div>
    );
  };

  render() {
    const { product, supplier, type, loading, index } = this.props;

    if (loading) {
      return <ProductListingLoading />;
    }

    if (!product || !type) {
      return null;
    }

    return (
      <div
        className="ProductListing"
        style={{
          transition: `opacity .3s ease-out ${
            index * 0.1
          }s, transform .3s ease-out ${index * 0.1}s`,
        }}
      >
        <Link
          className="ProductListing__link"
          to={`/produits/${type.slug}/${product.slug}`}
        >
          <Fragment>
            {product.returnedBonusesAlert &&
              product.returnedBonusesAlert.length > 0 && (
                <div className="ProductListing__alert">
                  <RichText paragraphs={product.returnedBonusesAlert} />
                </div>
              )}
            <div className="ProductListing__container">
              <div className="ProductListing__logo">
                {supplier && supplier.logo && supplier.logo.url && (
                  <img src={supplier.logo.url} alt="logo" />
                )}
              </div>
              <div className="ProductListing__content">
                <div className="ProductListing__title">
                  <div className="ProductListing__label">{product.title}</div>
                  {this.renderProductFlag()}
                </div>
                <div className="ProductListing__features">
                  {this.renderGuaranteedCapitalOrLevelOfTaxAdvantage()}
                  {this.renderInvestorTypologies()}
                  {this.renderFirstFeature()}
                  {this.renderMinimumInvestment()}
                  {this.renderWithdrawLimit()}
                </div>
                {this.renderRisk()}
              </div>
              <div
                className="ProductListing__link-icon"
                style={{
                  backgroundColor: type && type.backgroundColor,
                }}
              >
                <div className="ProductListing__link-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                  >
                    <path
                      fill={type && type.color}
                      fillRule="evenodd"
                      d="M.272 2.286c-.45-.632-.328-1.526.272-2a1.305 1.305 0 0 1 1.631 0L7.611 6a1.48 1.48 0 0 1 0 2l-5.436 5.714c-.602.474-1.452.345-1.903-.285a1.485 1.485 0 0 1 0-1.715L4.744 7 .259 2.286h.013z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </Fragment>
        </Link>
      </div>
    );
  }
}

ProductListing.defaultProps = {
  product: {},
  supplier: {},
  type: {},
  loading: false,
  index: 0,
};

ProductListing.propTypes = {
  product: PropTypes.shape(productShape),
  supplier: PropTypes.shape(supplierShape),
  type: PropTypes.shape(typeShape),
  loading: PropTypes.bool,
  index: PropTypes.number,
};

export default ProductListing;
