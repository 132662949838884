import React, { useState, useEffect } from 'react';

import { createObserver, onVideoInView } from '../../../helpers/observer';

import KnifeVideoMP4 from '../../../assets/images/couteau.mp4';
import KnifeVideoOGV from '../../../assets/images/couteau.ogv';
import KnifeVideoWebM from '../../../assets/images/couteau.webm';
import BalanceVideoMP4 from '../../../assets/images/balance.mp4';
import BalanceVideoOGV from '../../../assets/images/balance.ogv';
import BalanceVideoWebM from '../../../assets/images/balance.webm';
import PurseVideoMP4 from '../../../assets/images/monnaie.mp4';
import PurseVideoOGV from '../../../assets/images/monnaie.ogv';
import PurseVideoWebM from '../../../assets/images/monnaie.webm';

import '../../../styles/components/Blocks/Home/Why.css';
import LazyVideo from '../../Core/LazyVideo';

function Why() {
  const [videoObserver, setVideoObserver] = useState(null);

  useEffect(() => {
    const observer = createObserver(onVideoInView);
    setVideoObserver(observer);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="Why">
      <div className="Why__container">
        <div className="Why__columns">
          <div className="Why__column">
            <h2 className="Why__title">Pourquoi choisir mieuxplacer.com</h2>
            <div className="Why__list">
              <div className="Why__list-item">
                <div className="Why__list-item-icon">
                  <LazyVideo
                    observer={videoObserver}
                    className="Why__list-item-icon-video"
                    sources={[
                      { src: KnifeVideoMP4, type: 'video/mp4' },
                      { src: KnifeVideoWebM, type: 'video/webm' },
                      { src: KnifeVideoOGV, type: 'video/ogg' },
                    ]}
                  />
                </div>
                <div className="Why__list-item-content">
                  <p className="Why__list-item-title">100% personnalisé</p>
                  <p className="Why__list-item-text">
                    Chez nous, pas d’offre packagée mais uniquement du
                    sur-mesure. Quel que soit votre budget !
                  </p>
                </div>
              </div>
              <div className="Why__list-item">
                <div className="Why__list-item-icon">
                  <LazyVideo
                    observer={videoObserver}
                    className="Why__list-item-icon-video"
                    sources={[
                      { src: BalanceVideoMP4, type: 'video/mp4' },
                      { src: BalanceVideoWebM, type: 'video/webm' },
                      { src: BalanceVideoOGV, type: 'video/ogg' },
                    ]}
                  />
                </div>
                <div className="Why__list-item-content">
                  <p className="Why__list-item-title">Impartial</p>
                  <p className="Why__list-item-text">
                    Nous ne privilégions pas un fournisseur plus qu&apos;un
                    autre. Seuls vos besoins sont pris en compte.
                  </p>
                </div>
              </div>
              <div className="Why__list-item">
                <div className="Why__list-item-icon">
                  <LazyVideo
                    observer={videoObserver}
                    className="Why__list-item-icon-video"
                    sources={[
                      { src: PurseVideoMP4, type: 'video/mp4' },
                      { src: PurseVideoWebM, type: 'video/webm' },
                      { src: PurseVideoOGV, type: 'video/ogg' },
                    ]}
                  />
                </div>
                <div className="Why__list-item-content">
                  <p className="Why__list-item-title">
                    Les tarifs les plus bas
                  </p>
                  <p className="Why__list-item-text">
                    Nous négocions pour vous les frais les plus bas auprès de
                    nos partenaires.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Why;
