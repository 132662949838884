export const FETCH_QUESTIONS__REQUEST =
  '@@PERSONAL_INFORMATION/FETCH_QUESTIONS__REQUEST';
export const FETCH_QUESTIONS__SUCCESS =
  '@@PERSONAL_INFORMATION/FETCH_QUESTIONS__SUCCESS';
export const FETCH_QUESTIONS__FAIL =
  '@@PERSONAL_INFORMATION/FETCH_QUESTIONS__FAIL';

export const fetchQuestions = () => ({
  type: FETCH_QUESTIONS__REQUEST,
});
