import mieuxplacerAPI from '../api/Mieuxplacer';

export const FETCH_RESET_PASSWORD__REQUEST =
  '@@AUTH/FETCH_RESET_PASSWORD__REQUEST';
export const FETCH_RESET_PASSWORD__SUCCESS =
  '@@AUTH/FETCH_RESET_PASSWORD__SUCCESS';
export const FETCH_RESET_PASSWORD__FAIL = '@@AUTH/FETCH_RESET_PASSWORD__FAIL';

export const FETCH_EMAIL_VERIFICATION__REQUEST =
  '@@AUTH/FETCH_EMAIL_VERIFICATION__REQUEST';
export const FETCH_EMAIL_VERIFICATION__SUCCESS =
  '@@AUTH/FETCH_EMAIL_VERIFICATION__SUCCESS';
export const FETCH_EMAIL_VERIFICATION__FAIL =
  '@@AUTH/FETCH_EMAIL_VERIFICATION__FAIL';

export const LOGIN_USER = '@@AUTH/LOGIN';
export const LOGOUT_USER = '@@AUTH/LOGOUT';

export const USER_IS_VERIFIED = '@@AUTH/USER_IS_VERIFIED';
export const USER_IS_NOT_VERIFIED = '@@AUTH/USER_IS_NOT_VERIFIED';

export const OPEN_AUTH_MODAL = '@@AUTH/OPEN_AUTH_MODAL';
export const CLOSE_AUTH_MODAL = '@@AUTH/CLOSE_AUTH_MODAL';

export const OPEN_VERIFICATION_MODAL = '@@AUTH/OPEN_VERIFICATION_MODAL';
export const CLOSE_VERIFICATION_MODAL = '@@AUTH/CLOSE_VERIFICATION_MODAL';

export const loginUser = ({ email, password }) => async dispatch => {
  try {
    const data = await mieuxplacerAPI.Api.login(email, password);
    dispatch({ type: LOGIN_USER, payload: data });
  } catch (exception) {
    throw exception;
  }
};

export const fetchResetPassword = (uid, token, password) => ({
  type: FETCH_RESET_PASSWORD__REQUEST,
  payload: { uid, token, password },
});

export const fetchEmailVerification = token => ({
  type: FETCH_EMAIL_VERIFICATION__REQUEST,
  payload: token,
});

export const openAuthModal = (
  isBlockingModal = true,
  initialEmail = '',
  updateAnswersOnLogin = false,
  noRecommendation = false,
  recommendationToFetchOnLogin = '',
) => ({
  type: OPEN_AUTH_MODAL,
  payload: {
    isBlockingModal,
    initialEmail,
    updateAnswersOnLogin,
    noRecommendation,
    recommendationToFetchOnLogin,
  },
});

export const closeAuthModal = () => ({ type: CLOSE_AUTH_MODAL });

export const openVerificationModal = () => ({ type: OPEN_VERIFICATION_MODAL });

export const closeVerificationModal = () => ({
  type: CLOSE_VERIFICATION_MODAL,
});
