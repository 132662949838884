import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { format } from 'date-fns';
import { Button, LoadingGradient } from '@robinfinance/ui';

import { BLOG_POSTS_SETTINGS } from '../../../config/blogPosts';

import { createObserver, onImageInView } from '../../../helpers/observer';

import { fetchBlogPosts } from '../../../actions/BlogPostsActions';

import toJS from '../../HOC/toJS';
import LazyImage from '../../Core/LazyImage';

import '../../../styles/components/Prismic/PrismicBlogPosts.css';

function PrismicBlogPosts({ blogPosts, blogPostsLoading, fetchBlogPosts }) {
  const [imageObserver, setImageObserver] = useState(null);

  useEffect(() => {
    const imageObserver = createObserver(onImageInView);
    setImageObserver(imageObserver);

    return () => {
      imageObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (blogPosts.length === 0 && !blogPostsLoading) {
      fetchBlogPosts();
    }
  }, [blogPosts.length]);

  if (blogPostsLoading) {
    return (
      <div className="PrismicBlogPosts PrismicBlogPosts--loading">
        <div className="PrismicBlogPosts__header">
          <LoadingGradient
            className="PrismicBlogPosts__title"
            width="200px"
            height="40px"
          />
          <LoadingGradient
            className="PrismicBlogPosts__header-link"
            width="120px"
            height="22px"
          />
        </div>
        <div className="PrismicBlogPosts__items">
          {[1, 2, 3].map(key => (
            <div key={key} className="PrismicBlogPosts__item">
              <div className="PrismicBlogPosts__item-container">
                <div className="PrismicBlogPosts__post-header">
                  <LoadingGradient
                    className="PrismicBlogPosts__post-author"
                    width="170px"
                    height="27px"
                  />
                </div>
                <div className="PrismicBlogPosts__post-content">
                  <div className="PrismicBlogPosts__post-title">
                    <LoadingGradient
                      className="MP-LoadingGradient--text-line"
                      width="100%"
                      height="28px"
                    />
                    <LoadingGradient
                      className="MP-LoadingGradient--text-line"
                      width="80%"
                      height="28px"
                    />
                  </div>
                  <LoadingGradient
                    className="PrismicBlogPosts__post-image"
                    width="100%"
                    height="150px"
                  />
                </div>
                <div className="PrismicBlogPosts__post-footer">
                  <LoadingGradient
                    className="PrismicBlogPosts__post-date"
                    width="50px"
                    height="21px"
                  />
                  <LoadingGradient
                    className="PrismicBlogPosts__post-action"
                    width="100px"
                    height="21px"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (blogPosts.lenght === 0) {
    return null;
  }

  return (
    <div className="PrismicBlogPosts">
      <div className="PrismicBlogPosts__header">
        <div className="PrismicBlogPosts__title">Nos derniers articles</div>
        <Button
          id="Home-Blog-CTA-1"
          scope="link"
          type="primary"
          href="https://blog.mieuxplacer.com"
          target="_blank"
          className="PrismicBlogPosts__header-link"
        >
          Voir tous les articles
        </Button>
      </div>
      <div className="PrismicBlogPosts__items">
        <Slider {...BLOG_POSTS_SETTINGS}>
          {blogPosts.map((post, index) => (
            <a
              key={index}
              className="PrismicBlogPosts__item"
              href={post.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="PrismicBlogPosts__item-container">
                <div className="PrismicBlogPosts__post-header">
                  <span className="PrismicBlogPosts__post-author">
                    {post.author}
                  </span>
                </div>
                <div className="PrismicBlogPosts__post-content">
                  <div className="PrismicBlogPosts__post-title">
                    {post.title}
                  </div>
                  <LazyImage
                    className="PrismicBlogPosts__post-image"
                    observer={imageObserver}
                    src={post.imageUrl}
                    alt={post.imageAlt}
                  />
                </div>
                <div className="PrismicBlogPosts__post-footer">
                  <div className="PrismicBlogPosts__post-date">
                    {format(new Date(post.created), 'dd/MM/yyyy')}
                  </div>
                  <Button scope="link" type="secondary">
                    Lire l&apos;article
                  </Button>
                </div>
              </div>
            </a>
          ))}
        </Slider>
      </div>
    </div>
  );
}

PrismicBlogPosts.defaultProps = {
  blogPosts: [],
};

PrismicBlogPosts.propTypes = {
  fetchBlogPosts: PropTypes.func.isRequired,
  blogPostsLoading: PropTypes.bool.isRequired,
  blogPosts: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
  blogPosts: state.get('blogPostsState').get('blogPosts'),
  blogPostsLoading: state.get('blogPostsState').get('blogPostsLoading'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBlogPosts,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(PrismicBlogPosts));
