import React from 'react';

import LoadingGradient from '../../../Core/LoadingGradient';

const FilterBlockLoading = () => (
  <div className="FilterBlock FilterBlock--loading">
    {[1, 2, 3, 4, 5].map(key => (
      <div key={key} className="FilterBlock__item">
        <LoadingGradient
          width="18px"
          height="18px"
          className="FilterBlock__loading-check"
        />
        <LoadingGradient width="100px" height="18px" />
      </div>
    ))}
  </div>
);

export default FilterBlockLoading;
