import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import downloadIcon from '../../assets/icons/icon-download.svg';

import '../../styles/components/Core/FileDownload.css';

class FileDownload extends PureComponent {
  render() {
    const { title, url, icon, size } = this.props;

    if (!title || !url) {
      return null;
    }

    return (
      <a
        className="FileDownload"
        href={url}
        title={title}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <span className="FileDownload__label">
          <span className="FileDownload__title">{title}</span>
          {size && (
            <span className="FileDownload__size">
              {numeral(size).format()}Ko
            </span>
          )}
        </span>
        <img
          className="FileDownload__icon"
          src={icon || downloadIcon}
          alt="Download icon"
          title="Download icon"
        />
      </a>
    );
  }
}

FileDownload.defaultProps = {
  title: null,
  url: null,
  icon: null,
  size: null,
};

FileDownload.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.number,
};

export default FileDownload;
