import PropTypes from 'prop-types';

import { imageShape } from './prismicShape';

const rewardShape = {
  label: PropTypes.string,
  logo: PropTypes.shape(imageShape),
};

export default rewardShape;
