import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Core/Icon';

import { multiblocksiconsShape } from '../../../shapes/prismicShape';
import '../../../styles/components/Prismic/PrismicBlockList.css';

class PrismicBlockList extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicBlockList">
        <div className="PrismicBlockList_left">
          <div className="PrismicBlockList_title">
            {data.primary.block_list_title[0].text}
          </div>
          <div className="PrismicBlockList_text PrismicBlockList_description">
            {data.primary.block_list_text[0].text}
          </div>
          <div className="PrismicBlockList_text">
            {data.items.map(item => (
              <div
                key={item.block_list_listing[0].text}
                className="PrismicBlockList_list"
              >
                <div className="PrismicBlockList_background">
                  <div className="PrismicBlockList_dot">&nbsp;</div>
                </div>
                <div className="PrismicBlockList_list_text">
                  {item.block_list_listing[0].text}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="PrismicBlockList_right">
          <Icon
            src={data.primary.block_list_icon.url}
            alt={data.primary.block_list_icon.alt}
            width="520px"
            height="592px"
          />
        </div>
      </div>
    );
  }
}

PrismicBlockList.propTypes = {
  data: PropTypes.shape(multiblocksiconsShape).isRequired,
};

export default PrismicBlockList;
