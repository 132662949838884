import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import typeShape from '../../../shapes/typeShape';

import withProductContext from '../../HOC/withProductContext';
import RichText from '../../Core/RichText';
import Alert from '../../Core/Alert';

import '../../../styles/components/Prismic/PrismicLegalNotice.css';

class PrismicLegalNotice extends PureComponent {
  render() {
    const {
      context: { type },
    } = this.props;

    if (
      type &&
      type.mandatoryInformation &&
      type.mandatoryInformation.length > 0
    ) {
      return (
        <div className="PrismicLegalNotice">
          <Alert type="info" title="Points d'attention">
            <RichText paragraphs={type.mandatoryInformation} />
          </Alert>
        </div>
      );
    }

    return null;
  }
}

PrismicLegalNotice.propTypes = {
  context: PropTypes.shape({
    type: PropTypes.shape(typeShape),
  }).isRequired,
};

export default withProductContext(PrismicLegalNotice);
