import React from 'react';

import HeaderContext from '../../contexts/HeaderContext';

/* eslint-disable react/display-name */
const withHeaderContext = Component => props => (
  <HeaderContext.Consumer>
    {context => <Component {...props} context={context} />}
  </HeaderContext.Consumer>
);

export default withHeaderContext;
