import React from 'react';
import PropTypes from 'prop-types';

import { sliceShape } from '../../../shapes/prismicShape';

import RichText from '../../Core/RichText';
import SimulationTitle from './SimulationTitle';

import '../../../styles/components/Blocks/Simulation/SimulationDescription.css';

const SimulationDescription = ({ data }) => (
  <div className="SimulationDescription">
    <SimulationTitle />
    {data.primary.paragraph_title &&
      data.primary.paragraph_content &&
      data.primary.paragraph_content.length > 0 && (
        <div className="SimulationDescription__paragraph-container">
          <p className="SimulationDescription__paragraph-title">
            {data.primary.paragraph_title}
          </p>
          <RichText paragraphs={data.primary.paragraph_content} />
        </div>
      )}
    {data.primary.highlight_1_block_title &&
      data.primary.highlight_1_block_content &&
      data.primary.highlight_1_block_content.length > 0 && (
        <div className="SimulationDescription__headline-container">
          <p className="SimulationDescription__headline-title">
            {data.primary.highlight_1_block_title}
          </p>
          <RichText paragraphs={data.primary.highlight_1_block_content} />
        </div>
      )}
    {data.primary.highlight_2_block_title &&
      data.primary.highlight_2_block_content &&
      data.primary.highlight_2_block_content.length > 0 && (
        <div className="SimulationDescription__headline-container">
          <p className="SimulationDescription__headline-title">
            {data.primary.highlight_2_block_title}
          </p>
          <RichText paragraphs={data.primary.highlight_2_block_content} />
        </div>
      )}
  </div>
);

SimulationDescription.propTypes = {
  data: PropTypes.shape(sliceShape).isRequired,
};

export default SimulationDescription;
