import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Button, ArrowRight } from '@robinfinance/ui';

import productShape from '../../../shapes/productShape';
import objectiveShape from '../../../shapes/objectiveShape';

import Icon from '../../Core/Icon';
import Tooltip from '../../Core/Tooltip';
import ProductAddSelection from './ProductAddSelection';
import ProductDetailsLoading from './Loading/ProductDetailsLoading';

import yellowArrowDown from '../../../assets/icons/arrow-down-yellow.svg';

import '../../../styles/components/Blocks/Product/ProductDetails.css';

const yellowArrowDownIcon = (
  <div className="ProductDetails__arrow-icon">
    <Icon src={yellowArrowDown} alt="yellow-arrow-down" />
  </div>
);

class ProductDetails extends PureComponent {
  hasSimulatorBlock = blocks =>
    blocks.find(block => block.slice_type === 'simulation');

  handleScrollToSimulator = () => {
    const target = document.querySelector('.PrismicSimulation');

    if (target) {
      target.parentNode.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  handleScrollToCallBackForm = () => {
    const target = document.querySelector('.PrismicCallBackForm');

    if (target) {
      target.parentNode.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  renderFeature = (label, value, tooltip) => (
    <div className="ProductDetails__feature">
      <div className="ProductDetails__feature-title">
        {label}
        {tooltip && (
          <Tooltip
            className="ProductDetails__feature-tooltip"
            title={tooltip}
          />
        )}
      </div>
      <div className="ProductDetails__feature-value">{value}</div>
    </div>
  );

  renderObjectives = () => {
    const { objectives } = this.props;

    if (objectives && objectives.length > 0) {
      return (
        <div className="ProductDetails__feature ProductDetails__feature--column">
          <div className="ProductDetails__feature-title">Objectifs</div>
          <div className="ProductDetails__objectives">
            {objectives.map(objective => (
              <div key={objective.id} className="ProductDetails__objective">
                <div className="ProductDetails__objective-image">
                  {objective.icon && objective.icon.url && (
                    <img src={objective.icon.url} alt={objective.icon.alt} />
                  )}
                </div>
                <p className="ProductDetails__objective-label">
                  {objective.shortLabel}
                </p>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  renderPerformances = () => {
    const { product } = this.props;

    if (
      product.performancesLabel &&
      product.performances &&
      product.performances.length > 0 &&
      product.performances[0].label &&
      product.performances[0].value
    ) {
      return this.renderFeature(
        product.performancesLabel,
        `${product.performances[0].label} : ${product.performances[0].value}`,
        product.performancesTooltip,
      );
    }

    return null;
  };

  renderGuaranteedCapital = () => {
    const { product } = this.props;

    if (product.guaranteedCapital) {
      return this.renderFeature(
        'Capital Garanti maximum',
        product.guaranteedCapital,
        'Part maximum de votre placement pour laquelle votre capital est garanti. Elle dépend du montant qui sera investi sur le fonds euro du contrat.',
      );
    }

    return null;
  };

  renderSubscriptionPeriod = () => {
    const { product } = this.props;

    if (product.subscriptionPeriod) {
      return this.renderFeature(
        'Période de souscription',
        product.subscriptionPeriod,
        'Indique la date limite pour souscrire au contrat.',
      );
    }

    return null;
  };

  renderMinimumInvestment = () => {
    const { product } = this.props;

    if (product.minimumInvestment) {
      return this.renderFeature(
        'Investissement minimum',
        `${numeral(product.minimumInvestment).format()} €`,
        'Montant minimum du 1er versement.',
      );
    }

    return null;
  };

  renderOccupancyRates = () => {
    const { product } = this.props;

    if (
      product.occupancyRates &&
      product.occupancyRates.length > 0 &&
      product.occupancyRates[0].label &&
      product.occupancyRates[0].value
    ) {
      return this.renderFeature(
        "Taux d'occupation",
        `${product.occupancyRates[0].label} : ${product.occupancyRates[0].value}`,
        'Rapport entre les loyers perçus et les loyers qui seraient facturés si les locaux étaient intégralement loués. Il donne une indication sur le du taux de vacance locative du parc immobilier.',
      );
    }

    return null;
  };

  renderDistributedIncomesPerUnit = () => {
    const { product } = this.props;

    if (
      product.distributedIncomesPerUnit &&
      product.distributedIncomesPerUnit.length > 0 &&
      product.distributedIncomesPerUnit[0].label &&
      product.distributedIncomesPerUnit[0].value
    ) {
      return this.renderFeature(
        'Revenu distribué par part (Dividende)',
        `${product.distributedIncomesPerUnit[0].label} : ${product.distributedIncomesPerUnit[0].value}`,
        'Loyers que vous percevez. Ils s&#39;endentent net de frais de gestion.',
      );
    }

    return null;
  };

  renderTdvms = () => {
    const { product } = this.props;

    if (
      product.tdvms &&
      product.tdvms.length > 0 &&
      product.tdvms[0].label &&
      product.tdvms[0].value
    ) {
      return this.renderFeature(
        'Le taux de distribution sur la valeur de marché (DVM)',
        `${product.tdvms[0].label} : ${product.tdvms[0].value}`,
        'Rapport entre l&#39;ensemble des dividendes versés au titre de l&#39;année N et le prix moyen de la part au titre de l&#39;année N. Il donne une indication du rendement de la SCPI.',
      );
    }

    return null;
  };

  renderLevelOfTaxAdvantage = () => {
    const { product } = this.props;

    if (product.levelOfTaxAdvantage) {
      return this.renderFeature(
        "Niveau de l'avantage fiscal",
        `${product.levelOfTaxAdvantage}`,
        'Montant maximum que vous pouvez déduire de vos impôts.',
      );
    }

    return null;
  };

  renderYield = () => {
    const { product } = this.props;

    if (product.yield) {
      return this.renderFeature(
        'Rendement',
        product.yield,
        'Indique combien ce placement a rapporté dans le passé.',
      );
    }

    return null;
  };

  render() {
    const { product, loading, showPopup, showActions } = this.props;

    if (loading) {
      return <ProductDetailsLoading />;
    }

    if (!product) {
      return null;
    }

    return (
      <div className="ProductDetails">
        <div className="ProductDetails__features">
          {this.renderPerformances()}
          {this.renderGuaranteedCapital()}
          {this.renderSubscriptionPeriod()}
          {this.renderMinimumInvestment()}
          {this.renderOccupancyRates()}
          {this.renderDistributedIncomesPerUnit()}
          {this.renderTdvms()}
          {this.renderLevelOfTaxAdvantage()}
          {this.renderYield()}
          {this.renderObjectives()}
        </div>
        {showActions && (
          <div className="ProductDetails__actions">
            {product.subscribable ? (
              <Fragment>
                {this.hasSimulatorBlock(product.blocks) && (
                  <Button
                    scope="button"
                    type="ghost"
                    iconSide="right"
                    icon={yellowArrowDownIcon}
                    onClick={this.handleScrollToSimulator}
                    className="ProductDetails__action"
                    id="HowMuchWinButtonProductDetails"
                  >
                    Combien vais-je gagner ?
                  </Button>
                )}
                {product.availableForSubscription ? (
                  <ProductAddSelection className="ProductDetails__action" />
                ) : (
                  product.calandlyLabel &&
                  document.querySelector('.PrismicCallBackForm') && (
                    <Button
                      scope="button"
                      type="ghost"
                      iconSide="right"
                      icon={yellowArrowDownIcon}
                      onClick={this.handleScrollToCallBackForm}
                      id="CallMeButtonProductDetails"
                    >
                      {product.calandlyLabel}
                    </Button>
                  )
                )}
              </Fragment>
            ) : (
              <Button
                scope="button"
                type="primary"
                iconSide="right"
                icon={<ArrowRight color="#ffc501" width="19" height="14" />}
                onClick={showPopup}
                className="ProductDetails__actions-button"
                id="BeContactedButtonProductdetails"
              >
                Être recontacté(e)
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
}

ProductDetails.defaultProps = {
  product: null,
  objectives: [],
  loading: false,
  showPopup: () => {},
  showActions: false,
};

ProductDetails.propTypes = {
  product: PropTypes.shape(productShape),
  objectives: PropTypes.arrayOf(PropTypes.shape(objectiveShape)),
  loading: PropTypes.bool,
  showPopup: PropTypes.func,
  showActions: PropTypes.bool,
};

export default ProductDetails;
