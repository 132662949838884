import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { sliceShape } from '../../../shapes/prismicShape';

import PrismicArticles from '../Slices/PrismicArticles';
import PrismicBlockList from '../Slices/PrismicBlockList';
import PrismicBonus from '../Slices/PrismicBonus';
import PrismicHeadlineWithBlocks from '../Slices/PrismicHeadlineWithBlocks';
import PrismicContent from '../Slices/PrismicContent';
import PrismicCounter from '../Slices/PrismicCounter';
import PrismicDownloads from '../Slices/PrismicDownloads';
import PrismicFiles from '../Slices/PrismicFiles';
import PrismicFourBlocks from '../Slices/PrismicFourBlocks';
import PrismicHighlight from '../Slices/PrismicHighlight';
import PrismicImportantInfo from '../Slices/PrismicImportantInfo';
import PrismicMultipleBlocks from '../Slices/PrismicMultipleBlocks';
import PrismicPageTitle from '../Slices/PrismicPageTitle';
import PrismicFeaturedProducts from '../Slices/PrismicFeaturedProducts';
import PrismicSmallBlockList from '../Slices/PrismicSmallBlockList';
import PrismicTextImage from '../Slices/PrismicTextImage';
import PrismicTextNumber from '../Slices/PrismicTextNumber';
import PrismicTrustpilot from '../Slices/PrismicTrustpilot';
import PrismicTableTwoColumns from '../Slices/PrismicTableTwoColumns';
import PrismicTableThreeColumns from '../Slices/PrismicTableThreeColumns';
import PrismicTableFourColumns from '../Slices/PrismicTableFourColumns';
import PrismicTableFiveColumns from '../Slices/PrismicTableFiveColumns';
import PrismicSimulation from '../Slices/PrismicSimulation';
import PrismicSteps from '../Slices/PrismicSteps';
import PrismicLegalNotice from '../Slices/PrismicLegalNotice';
import PrismicCommissionsTable from '../Slices/PrismicCommissionsTable';
import PrismicCallBackForm from '../Slices/PrismicCallBackForm';
import PrismicNewsletter from '../Slices/PrismicNewsletter';
import PrismicSimilarProducts from '../Slices/PrismicSimilarProducts';
import PrismicProductFees from '../Slices/PrismicProductFees';
import PrismicBlogPosts from '../Slices/PrismicBlogPosts';

import '../../../styles/components/Prismic/Core/PrismicBlock.css';

const COMPONENT_MAP = {
  articles: PrismicArticles,
  block_list: PrismicBlockList,
  bonus: PrismicBonus,
  container_blocks: PrismicHeadlineWithBlocks,
  content: PrismicContent,
  counter: PrismicCounter,
  downloads: PrismicDownloads,
  files: PrismicFiles,
  four_blocks: PrismicFourBlocks,
  highlight: PrismicHighlight,
  important_info: PrismicImportantInfo,
  multi_blocks_icon: PrismicMultipleBlocks,
  page_title: PrismicPageTitle,
  products_one: PrismicFeaturedProducts,
  small_block_list: PrismicSmallBlockList,
  text_image: PrismicTextImage,
  text_number: PrismicTextNumber,
  trustpilot: PrismicTrustpilot,
  two_column_table: PrismicTableTwoColumns,
  three_column_table: PrismicTableThreeColumns,
  four_column_table: PrismicTableFourColumns,
  five_column_table: PrismicTableFiveColumns,
  simulation: PrismicSimulation,
  steps: PrismicSteps,
  legal_notice: PrismicLegalNotice,
  product_type_fees_table: PrismicCommissionsTable,
  lead_form: PrismicCallBackForm,
  newsletter: PrismicNewsletter,
  similar_products: PrismicSimilarProducts,
  fees: PrismicProductFees,
  blog_posts: PrismicBlogPosts,
};

class PrismicMapper extends PureComponent {
  render() {
    const { blocks } = this.props;

    return (
      <Fragment>
        {blocks &&
          blocks.map((block, index) => {
            const type = block.slice_type || block.type;

            if (COMPONENT_MAP[type]) {
              const Component = COMPONENT_MAP[type];

              return (
                <section className="PrismicBlock" key={index}>
                  <div className="PrismicBlock__container">
                    <Component data={block} />
                  </div>
                </section>
              );
            }

            return null;
          })}
      </Fragment>
    );
  }
}

PrismicMapper.defaultProps = {
  blocks: [],
};

PrismicMapper.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape(sliceShape)),
};

export default PrismicMapper;
