import PropTypes from 'prop-types';

export const simulationShape = {
  bonus_block_euro_value: PropTypes.number,
  bonus_block_label: PropTypes.string,
  bonus_block_purcent_value: PropTypes.number,
  fee_1_euro_value: PropTypes.number,
  fee_1_label: PropTypes.string,
  fee_1_purcent_value: PropTypes.number,
  fee_2_euro_value: PropTypes.number,
  fee_2_label: PropTypes.string,
  fee_2_purcent_value: PropTypes.number,
  fee_3_euro_value: PropTypes.number,
  fee_3_label: PropTypes.string,
  fee_3_purcent_value: PropTypes.number,
  fee_4_euro_value: PropTypes.number,
  fee_4_label: PropTypes.string,
  fee_4_purcent_value: PropTypes.number,
  fee_5_euro_value: PropTypes.number,
  fee_5_label: PropTypes.string,
  fee_5_purcent_value: PropTypes.number,
  fee_6_euro_value: PropTypes.number,
  fee_6_label: PropTypes.string,
  fee_6_purcent_value: PropTypes.number,
  final_block_euro_value: PropTypes.number,
  final_block_label: PropTypes.string,
  final_block_purcent_value: PropTypes.number,
  net_of_profit_fees_euro_value: PropTypes.number,
  net_of_profit_fees_label: PropTypes.string,
  net_of_profit_fees_purcent_value: PropTypes.number,
  public_profit_euro_value: PropTypes.number,
  public_profit_label: PropTypes.string,
  public_profit_purcent_value: PropTypes.number,
};

export default simulationShape;
