import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import routesConfig from '../../config/pages';

import ProductBreadcrumb from '../Blocks/Breadcrumbs/ProductBreadcrumb';
import ProductTypeBreadcrumb from '../Blocks/Breadcrumbs/ProductTypeBreadcrumb';
import GuideItemBreadcrumb from '../Blocks/Breadcrumbs/GuideItemBreadcrumb';
import PageBreadcrumb from '../Blocks/Breadcrumbs/PageBreadcrumb';

import '../../styles/components/Core/Breadcrumbs.css';

function getPageRouteBreadcrumbs() {
  let pageRouteBreadcrumbs = [];

  routesConfig.pages.forEach(page => {
    pageRouteBreadcrumbs.push({
      path: page.to,
      breadcrumb: () => <NavLink to={page.to}>{page.label}</NavLink>,
    });

    if (page.subRoutes) {
      page.subRoutes.forEach(subRoute => {
        pageRouteBreadcrumbs.push({
          path: subRoute.to,
          breadcrumb: () => (
            <NavLink to={subRoute.to}>{subRoute.label}</NavLink>
          ),
        });

        if (subRoute.subRoutes) {
          pageRouteBreadcrumbs = pageRouteBreadcrumbs.concat(
            subRoute.subRoutes.map(route => ({
              path: route.to,
              breadcrumb: () => <NavLink to={route.to}>{route.label}</NavLink>,
            })),
          );
        }
      });
    }

    routesConfig.otherPages.forEach(page => {
      pageRouteBreadcrumbs.push({
        path: page.to,
        breadcrumb: () => <span>{page.label}</span>,
      });
    });
  });

  return pageRouteBreadcrumbs;
}

const routes = [
  { path: '/', breadcrumb: () => <NavLink to="/">Accueil</NavLink> },
  {
    path: '/produits/:type/:slug',
    breadcrumb: ProductBreadcrumb,
  },
  {
    path: '/produits/:type',
    breadcrumb: ProductTypeBreadcrumb,
  },
  {
    path: '/produits',
    breadcrumb: () => <NavLink to="/produits">Produits</NavLink>,
  },
  { path: '/guide', breadcrumb: () => <NavLink to="/guide">Guide</NavLink> },
  { path: '/guide/:slug', breadcrumb: GuideItemBreadcrumb },
  ...getPageRouteBreadcrumbs(),
  { path: '/:slug', breadcrumb: PageBreadcrumb },
];

const Breadcrumbs = ({ breadcrumbs }) => (
  <div className="Breadcrumbs__container">
    <div className="Breadcrumbs">
      {breadcrumbs.map((item, index) => (
        <span key={item.key} className="Breadcrumbs__links">
          {item.breadcrumb}
          {index < breadcrumbs.length - 1 && <span> / </span>}
        </span>
      ))}
    </div>
  </div>
);

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withBreadcrumbs(routes)(Breadcrumbs);
