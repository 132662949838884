import { BASE_API_URL } from '../config/api';

function formatAnswers(answers) {
  const body = { ...answers };
  const formattedBody = {};

  Object.keys(body).forEach(key => {
    if (Array.isArray(body[key])) {
      if (body[key].length > 0) {
        formattedBody[key] = body[key];
      }
    } else if (body[key] !== null && body[key] !== '') {
      formattedBody[key] = body[key];
    }
  });

  return formattedBody;
}

export const updateAnswers = async answers => {
  const url = new URL(`${BASE_API_URL}/2.0/onboarding/update`);

  const formattedBody = formatAnswers(answers);

  const response = await fetch(url.href, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(formattedBody),
  });

  const data = await response.json();

  if (response.ok) {
    return data;
  }

  throw data;
};
