import PropTypes from 'prop-types';

import {
  textImageShape,
  downloadsShape,
  objectivesAndTypesShape,
  multiblocksiconsShape,
  featuredProductsShape,
  imageShape,
  urlShape,
  paragraphShape,
} from './prismicShape';

const homeShape = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  bannerIsActivated: PropTypes.bool,
  bannerText: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
  bannerIcon: PropTypes.shape(imageShape),
  bannerLink: PropTypes.shape(urlShape),
  bannerLinkText: PropTypes.string,
  bannerColor: PropTypes.string,
  blocks: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(textImageShape),
      PropTypes.shape(downloadsShape),
      PropTypes.shape(objectivesAndTypesShape),
      PropTypes.shape(multiblocksiconsShape),
      PropTypes.shape(featuredProductsShape),
    ]),
  ),
};

export default homeShape;
