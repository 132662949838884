import { Map, List, fromJS } from 'immutable';

import {
  INVEST_PERIOD_FILTER_KEY,
  MINIMUM_INVESTMENT_FILTER_KEY,
  TYPE_FILTER_KEY,
  OBJECTIVE_FILTER_KEY,
} from '../config/product';

import {
  ADD_FILTER,
  SET_FILTER,
  SET_FILTERS,
  REMOVE_FILTER,
  INIT_FILTERS,
} from '../actions/FilterActions';

const initialState = Map({
  filters: Map({
    [TYPE_FILTER_KEY]: List([]),
    [OBJECTIVE_FILTER_KEY]: Map({ id: 'all-objectives', value: 'all' }),
    [INVEST_PERIOD_FILTER_KEY]: Map({ id: 'all-invest-periods', value: 'all' }),
    [MINIMUM_INVESTMENT_FILTER_KEY]: Map({
      id: 'all-minimum-investments',
      value: 'all',
    }),
  }),
});

const filterState = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_FILTER:
      return state.set(
        'filters',
        state.get('filters').set(
          payload.type,
          state
            .get('filters')
            .get(payload.type)
            .push(fromJS({ id: payload.id, value: payload.value })),
        ),
      );

    case SET_FILTER:
      return state.set(
        'filters',
        state
          .get('filters')
          .set(payload.type, fromJS({ id: payload.id, value: payload.value })),
      );

    case SET_FILTERS:
      return state.set(
        'filters',
        initialState.get('filters').merge(fromJS(payload)),
      );

    case REMOVE_FILTER:
      return state.set(
        'filters',
        state.get('filters').set(
          payload.type,
          state
            .get('filters')
            .get(payload.type)
            .filter(item => item.get('id') !== payload.id),
        ),
      );

    case INIT_FILTERS:
      return initialState;

    default:
      return initialState.merge(state);
  }
};

export default filterState;
