import { put, call, takeLatest } from 'redux-saga/effects';

import {
  SEND_CONTACT_EMAIL__REQUEST,
  SEND_CONTACT_EMAIL__SUCCESS,
  SEND_CONTACT_EMAIL__FAIL,
  SEND_LEAD_EMAIL__REQUEST,
  SEND_LEAD_EMAIL__SUCCESS,
  SEND_LEAD_EMAIL__FAIL,
  SEND_CALL_BACK_EMAIL__REQUEST,
  SEND_CALL_BACK_EMAIL__SUCCESS,
  SEND_CALL_BACK_EMAIL__FAIL,
  SEND_CALL_BACK_PRODUCT_EMAIL__REQUEST,
  SEND_CALL_BACK_PRODUCT_EMAIL__SUCCESS,
  SEND_CALL_BACK_PRODUCT_EMAIL__FAIL,
  SEND_TESTING_EMAIL__REQUEST,
  SEND_TESTING_EMAIL__SUCCESS,
  SEND_TESTING_EMAIL__FAIL,
} from '../actions/ContactActions';

import { LAUNCH_CONVERSION_LINKER } from '../actions/AppActions';

import * as EmailApi from '../api/Email';

const handleError = e => {
  if (e.message) {
    const error = JSON.parse(e.message);

    if (typeof error === 'object') {
      return {
        errors: error,
      };
    }

    return {
      message: error,
    };
  }

  return {};
};

function* sendContactEmail(data) {
  try {
    const responseData = yield call(EmailApi.sendContactEmail, data.payload);

    yield put({
      type: SEND_CONTACT_EMAIL__SUCCESS,
      payload: responseData,
    });
  } catch (e) {
    yield put({
      type: SEND_CONTACT_EMAIL__FAIL,
      payload: handleError(e),
    });
  }
}

function* sendLeadEmail(data) {
  try {
    const responseData = yield call(EmailApi.sendLeadEmail, data.payload);

    yield put({
      type: SEND_LEAD_EMAIL__SUCCESS,
      payload: responseData,
    });
  } catch (e) {
    yield put({
      type: SEND_LEAD_EMAIL__FAIL,
      payload: handleError(e),
    });
  }
}

function* sendCallBackEmail(data) {
  try {
    const responseData = yield call(EmailApi.sendCallBackEmail, data.payload);

    yield put({
      type: SEND_CALL_BACK_EMAIL__SUCCESS,
      payload: responseData,
    });
    yield put({ type: LAUNCH_CONVERSION_LINKER });
  } catch (e) {
    yield put({
      type: SEND_CALL_BACK_EMAIL__FAIL,
      payload: handleError(e),
    });
  }
}

function* sendCallBackProductEmail(data) {
  try {
    const responseData = yield call(
      EmailApi.sendCallBackProductEmail,
      data.payload,
    );

    yield put({
      type: SEND_CALL_BACK_PRODUCT_EMAIL__SUCCESS,
      payload: responseData,
    });
    yield put({ type: LAUNCH_CONVERSION_LINKER });
  } catch (e) {
    yield put({
      type: SEND_CALL_BACK_PRODUCT_EMAIL__FAIL,
      payload: handleError(e),
    });
  }
}

function* sendTestingEmail(data) {
  try {
    const responseData = yield call(EmailApi.sendTestingEmail, data.payload);

    yield put({
      type: SEND_TESTING_EMAIL__SUCCESS,
      payload: responseData,
    });
  } catch (e) {
    yield put({
      type: SEND_TESTING_EMAIL__FAIL,
      payload: handleError(e),
    });
  }
}

const contactSagas = [
  takeLatest(SEND_CONTACT_EMAIL__REQUEST, sendContactEmail),
  takeLatest(SEND_LEAD_EMAIL__REQUEST, sendLeadEmail),
  takeLatest(SEND_CALL_BACK_EMAIL__REQUEST, sendCallBackEmail),
  takeLatest(SEND_CALL_BACK_PRODUCT_EMAIL__REQUEST, sendCallBackProductEmail),
  takeLatest(SEND_TESTING_EMAIL__REQUEST, sendTestingEmail),
];

export default contactSagas;
