import qs from 'qs';

import {
  INVEST_PERIODS,
  INVEST_PERIOD_FILTER_KEY,
  MINIMUM_INVESTMENT_FILTER_KEY,
  MINIMUM_INVESTMENT,
  TYPE_FILTER_KEY,
  OBJECTIVE_FILTER_KEY,
} from '../config/product';

const formatValue = (value, key) => {
  let matchedInvestPeriod;
  let matchedMinimumInvestment;

  switch (key) {
    case TYPE_FILTER_KEY:
      return value.map(item => ({ value: item, id: item }));

    case OBJECTIVE_FILTER_KEY:
      if (value.length > 0) {
        return { value: value[0], id: value[0] };
      }

      return { value: 'all', id: 'all-objectives' };

    case INVEST_PERIOD_FILTER_KEY:
      matchedInvestPeriod = INVEST_PERIODS.find(
        investPeriod => investPeriod.value === value,
      );

      return {
        value: matchedInvestPeriod ? value : 'all',
        id: matchedInvestPeriod ? matchedInvestPeriod.id : 'all-invest-periods',
      };

    case MINIMUM_INVESTMENT_FILTER_KEY:
      matchedMinimumInvestment = MINIMUM_INVESTMENT.find(
        minimumInvestment => minimumInvestment.value === value,
      );

      return {
        value: matchedMinimumInvestment ? value : 'all',
        id: matchedMinimumInvestment
          ? matchedMinimumInvestment.id
          : 'all-minimum-investments',
      };

    default:
      return { value, id: value };
  }
};

export const formatFilterParams = filters => {
  const params = {};

  Object.keys(filters).forEach(key => {
    if (Array.isArray(filters[key])) {
      const linkedValues = filters[key]
        .map(item => item.value)
        .filter(value => value !== 'all');

      if (params.linked) {
        params.linked[key] = linkedValues;
      } else {
        params.linked = { [key]: linkedValues };
      }
    } else if (key === 'objective' && filters[key].value !== 'all') {
      if (params.linked) {
        params.linked[key] = [filters[key].value];
      } else {
        params.linked = { [key]: [filters[key].value] };
      }
    } else if (filters[key].value !== 'all') {
      if (params.filters) {
        params.filters[key] = filters[key].value;
      } else {
        params.filters = { [key]: filters[key].value };
      }
    }
  });

  return params;
};

export const formatFilterQuery = params => {
  const formattedParams = {};

  Object.keys(params).forEach(key => {
    Object.keys(params[key]).forEach(filter => {
      formattedParams[filter] = params[key][filter];
    });
  });

  return qs.stringify(formattedParams, { arrayFormat: 'brackets' });
};

export const getFilterQuery = query =>
  qs.parse(query, { arrayFormat: 'brackets' });

export const getFilterParamsFromQuery = query => {
  const params = qs.parse(query.substring(1), { arrayFormat: 'brackets' });

  const formattedParams = {};

  Object.keys(params).forEach(key => {
    formattedParams[key] = formatValue(params[key], key);
  });

  return formattedParams;
};

export const filtersAreNotDefault = filters =>
  filters[INVEST_PERIOD_FILTER_KEY].value !== 'all' ||
  filters[MINIMUM_INVESTMENT_FILTER_KEY].value !== 'all' ||
  filters[OBJECTIVE_FILTER_KEY].value !== 'all' ||
  filters[TYPE_FILTER_KEY].length > 0;
