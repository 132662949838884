import React from 'react';

import LoadingGradient from '../../../Core/LoadingGradient';

const ProductProfileLoading = () => (
  <div className="ProductProfile ProductProfile--loading">
    <LoadingGradient
      className="ProductProfile__title"
      width="300px"
      height="42px"
    />
    <div className="ProductProfile__content">
      <div className="ProductProfile__columns">
        <div className="ProductProfile__risk-profiles">
          <LoadingGradient
            className="ProductProfile__progress-bar-title"
            width="180px"
            height="24px"
          />
          <div className="ProductProfile__progress-bar-items">
            <LoadingGradient className="ProductProfile__progress-bar-empty" />
            {[1, 2, 3, 4, 5].map(key => (
              <div key={key} className="ProductProfile__progress-bar-item">
                <LoadingGradient
                  className="ProductProfile__progress-bar-item-check"
                  width="18px"
                  height="18px"
                />
                <LoadingGradient
                  className="ProductProfile__progress-bar-item-label"
                  width="100px"
                  height="22px"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="ProductProfile__investor-typologies">
          <LoadingGradient
            className="ProductProfile__progress-bar-title"
            width="180px"
            height="24px"
          />
          <div className="ProductProfile__progress-bar-items">
            <LoadingGradient className="ProductProfile__progress-bar-empty" />
            {[1, 2, 3, 4].map(key => (
              <div key={key} className="ProductProfile__progress-bar-item">
                <LoadingGradient
                  className="ProductProfile__progress-bar-item-check"
                  width="18px"
                  height="18px"
                />
                <LoadingGradient
                  className="ProductProfile__progress-bar-item-label"
                  width="100px"
                  height="22px"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="ProductProfile__objectives">
        <LoadingGradient
          className="ProductProfile__objectives-title"
          width="100px"
          height="24px"
        />
        <div className="ProductProfile__objectives-items">
          {[1, 2, 3, 4].map(key => (
            <div key={key} className="ProductProfile__objectives-item">
              <LoadingGradient
                className="ProductProfile__objectives-item-image"
                width="42px"
                height="42px"
              />
              <LoadingGradient
                className="ProductProfile__objectives-item-label"
                width="200px"
                height="24px"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="ProductProfile__actions">
        <LoadingGradient
          className="ProductProfile__action"
          width="240px"
          height="55px"
        />
        <LoadingGradient
          className="ProductProfile__action"
          width="205px"
          height="55px"
        />
      </div>
    </div>
  </div>
);

export default ProductProfileLoading;
