import { put, call, takeLatest } from 'redux-saga/effects';

import MieuxplacerAPI from '../api/Mieuxplacer';

import {
  FETCH_SUPPLIERS__REQUEST,
  FETCH_SUPPLIERS__SUCCESS,
  FETCH_SUPPLIERS__FAIL,
} from '../actions/SupplierActions';

function* fetchSuppliers() {
  const params = {
    orderBy: 'label',
  };

  try {
    const apiCall = () => MieuxplacerAPI.Api.getPrismicSuppliers(params);
    const data = yield call(apiCall);

    yield put({
      type: FETCH_SUPPLIERS__SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_SUPPLIERS__FAIL,
      payload: e.message,
    });
  }
}

const supplierSagas = [takeLatest(FETCH_SUPPLIERS__REQUEST, fetchSuppliers)];

export default supplierSagas;
