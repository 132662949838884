import { Map, List } from 'immutable';

import {
  SET_TRACKING_SOURCE,
  SET_UNIVERSE,
  PAGE_LOADED,
  ADD_USER_VISIT,
} from '../actions/AppActions';

const initialState = Map({
  pageName: '',
  utmCampaign: '',
  utmSource: '',
  utmMedium: '',
  universe: 'MIEUXPLACER',
  visits: List([]),
});

const appState = (state = initialState, { type, payload }) => {
  switch (type) {
    case PAGE_LOADED:
      return state.set('pageName', payload);

    case SET_TRACKING_SOURCE:
      return state
        .set('utmCampaign', payload.campaign)
        .set('utmSource', payload.source)
        .set('utmMedium', payload.medium);

    case SET_UNIVERSE:
      return state.set('universe', payload);

    case ADD_USER_VISIT:
      return state.set('visits', state.get('visits').push(payload));

    default:
      return initialState.merge(state);
  }
};

export default appState;
