import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/components/Core/Icon.css';

const Icon = ({ src, alt, height, width, className }) => (
  <span className={`Icon ${className}`}>
    <img src={src} alt={alt} height={height} width={width} />
  </span>
);

Icon.defaultProps = {
  height: '100%',
  width: '100%',
  className: '',
};

Icon.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
