import PropTypes from 'prop-types';

const routerShape = {
  pathname: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  key: PropTypes.string,
};

export default routerShape;
