import { connectRouter } from 'connected-react-router/immutable';
import { Record } from 'immutable';
import { combineReducers } from 'redux-immutable';

import appReducer from './AppReducer';
import productReducer from './ProductReducer';
import typeReducer from './TypeReducer';
import objectiveReducer from './ObjectiveReducer';
import supplierReducer from './SupplierReducer';
import filterReducer from './FilterReducer';
import homeReducer from './HomeReducer';
import pageReducer from './PageReducer';
import cartReducer from './CartReducer';
import trustpilotReducer from './TrustpilotReducer';
import contactReducer from './ContactReducer';
import onboardingReducer from './OnboardingReducer';
import userReducer from './UserReducer';
import authReducer from './AuthReducer';
import contractsReducer from './ContractsReducer';
import supportingDocumentsReducer from './SupportingDocumentsReducer';
import signatureReducer from './SignatureReducer';
import personalInformationReducer from './PersonalInformationReducer';
import blogPostsReducer from './BlogPostsReducer';

const StateRecord = Record({
  appState: undefined,
  router: undefined,
  productState: undefined,
  typeState: undefined,
  objectiveState: undefined,
  supplierState: undefined,
  filterState: undefined,
  homeState: undefined,
  pageState: undefined,
  cartState: undefined,
  trustpilotState: undefined,
  contactState: undefined,
  onboardingState: undefined,
  userState: undefined,
  authState: undefined,
  contractsState: undefined,
  supportingDocumentsState: undefined,
  signatureState: undefined,
  personalInformationState: undefined,
  blogPostsState: undefined,
});

const rootReducer = history =>
  combineReducers(
    {
      router: connectRouter(history),
      appState: appReducer,
      productState: productReducer,
      homeState: homeReducer,
      typeState: typeReducer,
      objectiveState: objectiveReducer,
      supplierState: supplierReducer,
      filterState: filterReducer,
      pageState: pageReducer,
      cartState: cartReducer,
      trustpilotState: trustpilotReducer,
      contactState: contactReducer,
      onboardingState: onboardingReducer,
      userState: userReducer,
      authState: authReducer,
      contractsState: contractsReducer,
      supportingDocumentsState: supportingDocumentsReducer,
      signatureState: signatureReducer,
      personalInformationState: personalInformationReducer,
      blogPostsState: blogPostsReducer,
    },
    StateRecord,
  );

export default rootReducer;
