import { Map, fromJS, List } from 'immutable';

import { LOGOUT_USER } from '../actions/AuthActions';
import {
  FETCH_ONBOARDING__REQUEST,
  FETCH_ONBOARDING__SUCCESS,
  FETCH_ONBOARDING__FAIL,
  FETCH_GENERATE_PROPOSITION__REQUEST,
  FETCH_GENERATE_PROPOSITION__SUCCESS,
  FETCH_GENERATE_PROPOSITION__FAIL,
  FETCH_PROPOSITION__REQUEST,
  FETCH_PROPOSITION__SUCCESS,
  FETCH_PROPOSITION__FAIL,
  FETCH_PROPOSITION__NOT_FOUND,
  FETCH_PROPOSITION__UNAUTHORIZED,
  FETCH_PROPOSITION__WRONG_USER,
  FETCH_UPDATE_ANSWERS__REQUEST,
  FETCH_UPDATE_ANSWERS__SUCCESS,
  FETCH_UPDATE_ANSWERS__FAIL,
  SET_ANSWER,
  RESET_ONBOARDING,
  SET_ONBOARDING_STARTING_POINT,
} from '../actions/OnboardingActions';

const initialState = Map({
  loading: false,
  error: false,
  saving: false,
  savingError: false,
  generationLoading: false,
  generationError: false,
  propositionLoading: false,
  propositionError: false,
  propositionNotFound: false,
  propositionUnauthorized: false,
  propositionWrongUser: false,
  isRegenerating: false,
  steps: List([]),
  blocks: Map({}),
  questions: Map({}),
  answers: List([]),
  hasChangedAnswers: false,
  proposition: Map({}),
  onboardingStartingPoint: null,
});

const onboardingState = (state = initialState, { type, payload }) => {
  let hasFoundAnswer = false;
  const newAnswers = [];

  switch (type) {
    case FETCH_ONBOARDING__REQUEST:
      return state.set('loading', true).set('error', false);

    case FETCH_ONBOARDING__SUCCESS:
      return state
        .set('loading', false)
        .set('steps', fromJS(payload.steps))
        .set('blocks', fromJS(payload.blocks))
        .set('questions', fromJS(payload.questions))
        .set('answers', fromJS(payload.answers));

    case FETCH_ONBOARDING__FAIL:
      return state.set('loading', false).set('error', true);

    case FETCH_UPDATE_ANSWERS__REQUEST:
      return state.set('saving', true).set('savingError', false);

    case FETCH_UPDATE_ANSWERS__SUCCESS:
      return state.set('saving', false);

    case FETCH_UPDATE_ANSWERS__FAIL:
      return state.set('saving', false).set('savingError', true);

    case SET_ANSWER:
      state
        .get('answers')
        .toJS()
        .forEach(item => {
          if (item.question === payload.key) {
            if (!hasFoundAnswer) {
              hasFoundAnswer = true;

              if (Array.isArray(payload.value)) {
                payload.value.forEach(value => {
                  newAnswers.push({ ...item, value });
                });
              } else {
                newAnswers.push({ ...item, value: payload.value });
              }
            }
          } else {
            newAnswers.push(item);
          }
        });

      if (!hasFoundAnswer) {
        if (Array.isArray(payload.value)) {
          payload.value.forEach(value => {
            newAnswers.push({ question: payload.key, value });
          });
        } else {
          newAnswers.push({ question: payload.key, value: payload.value });
        }
      }

      return state
        .set('hasChangedAnswers', true)
        .set('answers', fromJS(newAnswers));

    case RESET_ONBOARDING:
      return state.set('hasChangedAnswers', false);

    case FETCH_GENERATE_PROPOSITION__REQUEST:
      return state.set('generationLoading', true).set('generationError', false);

    case FETCH_GENERATE_PROPOSITION__SUCCESS:
      return state
        .set('generationLoading', false)
        .set('proposition', fromJS(payload));

    case FETCH_GENERATE_PROPOSITION__FAIL:
      return state.set('generationLoading', false).set('generationError', true);

    case FETCH_PROPOSITION__REQUEST:
      return state
        .set('propositionLoading', true)
        .set('propositionError', false)
        .set('propositionNotFound', false)
        .set('propositionUnauthorized', false)
        .set('propositionWrongUser', false);

    case FETCH_PROPOSITION__SUCCESS:
      return state
        .set('propositionLoading', false)
        .set('proposition', fromJS(payload));

    case FETCH_PROPOSITION__FAIL:
      return state
        .set('propositionLoading', false)
        .set('propositionError', true);

    case FETCH_PROPOSITION__NOT_FOUND:
      return state
        .set('propositionLoading', false)
        .set('propositionNotFound', true);

    case FETCH_PROPOSITION__UNAUTHORIZED:
      return state
        .set('propositionLoading', false)
        .set('propositionUnauthorized', true);

    case FETCH_PROPOSITION__WRONG_USER:
      return state
        .set('propositionLoading', false)
        .set('propositionWrongUser', true);

    case SET_ONBOARDING_STARTING_POINT:
      return state.set('onboardingStartingPoint', payload);

    case LOGOUT_USER:
      return state
        .set('answers', List([]))
        .set('advices', Map({}))
        .set('recommendation', Map({}));

    default:
      return initialState.merge(state);
  }
};

export default onboardingState;
