export const FETCH_PRODUCTS__REQUEST = '@@PRODUCT/FETCH_PRODUCTS__REQUEST';
export const FETCH_PRODUCTS__SUCCESS = '@@PRODUCT/FETCH_PRODUCTS__SUCCESS';
export const FETCH_PRODUCTS__FAIL = '@@PRODUCT/FETCH_PRODUCTS__FAIL';

export const FETCH_FILTERED_PRODUCTS__REQUEST =
  '@@PRODUCT/FETCH_FILTERED_PRODUCTS__REQUEST';
export const FETCH_FILTERED_PRODUCTS__SUCCESS =
  '@@PRODUCT/FETCH_FILTERED_PRODUCTS__SUCCESS';
export const FETCH_FILTERED_PRODUCTS__FAIL =
  '@@PRODUCT/FETCH_FILTERED_PRODUCTS__FAIL';

export const CLICK_FEATURED_PRODUCT = '@@GTM/CLICK_FEATURED_PRODUCT';
export const CLICK_SIMILAR_PRODUCT = '@@GTM/CLICK_SIMILAR_PRODUCT';
export const OPEN_LEAD_POPUP = '@@GTM/OPEN_LEAD_POPUP';
export const CLOSE_LEAD_POPUP = '@@GTM/CLOSE_LEAD_POPUP';

export const fetchProducts = params => ({
  type: FETCH_PRODUCTS__REQUEST,
  payload: params,
});

export const fetchFilteredProducts = (params = {}) => ({
  type: FETCH_FILTERED_PRODUCTS__REQUEST,
  payload: params,
});

export const clickFeaturedProduct = (id, title) => ({
  type: CLICK_FEATURED_PRODUCT,
  payload: { id, title },
});

export const clickSimilarProduct = (id, title) => ({
  type: CLICK_SIMILAR_PRODUCT,
  payload: { id, title },
});

export const openLeadPopup = (id, title) => ({
  type: OPEN_LEAD_POPUP,
  payload: { id, title },
});

export const closeLeadPopup = (id, title) => ({
  type: CLOSE_LEAD_POPUP,
  payload: { id, title },
});
