import PropTypes from 'prop-types';

import { imageShape } from './prismicShape';

const supplierShape = {
  id: PropTypes.string,
  slug: PropTypes.string,
  contentType: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.shape(imageShape),
  link: PropTypes.string,
};

export default supplierShape;
