export const ADD_PRODUCT_TO_CART = '@@CART/ADD_PRODUCT';
export const REMOVE_PRODUCT_FROM_CART = '@@CART/REMOVE_PRODUCT';

export const UPDATE_FUND = '@@CART/UPDATE_FUND';
export const REMOVE_FUND = '@@CART/REMOVE_FUND';

export const FETCH_INITIAL_PRODUCT_FUNDS__REQUEST =
  '@@CART/FETCH_INITIAL_PRODUCT_FUNDS__REQUEST';
export const FETCH_INITIAL_PRODUCT_FUNDS__SUCCESS =
  '@@CART/FETCH_INITIAL_PRODUCT_FUNDS__SUCCESS';
export const FETCH_INITIAL_PRODUCT_FUNDS__FAIL =
  '@@CART/FETCH_INITIAL_PRODUCT_FUNDS__FAIL';

export const FETCH_CONTRACT_PREVALIDATION__REQUEST =
  '@@CART/FETCH_CONTRACT_PREVALIDATION__REQUEST';
export const FETCH_CONTRACT_PREVALIDATION__SUCCESS =
  '@@CART/FETCH_CONTRACT_PREVALIDATION__SUCCESS';
export const FETCH_CONTRACT_PREVALIDATION__ERROR =
  '@@CART/FETCH_CONTRACT_PREVALIDATION__ERROR';
export const FETCH_CONTRACT_PREVALIDATION__FAIL =
  '@@CART/FETCH_CONTRACT_PREVALIDATION__FAIL';

export const VALIDATE_SELECTION__REQUEST =
  '@@ONBOARDING/VALIDATE_SELECTION__REQUEST';
export const VALIDATE_SELECTION__SUCCESS =
  '@@ONBOARDING/VALIDATE_SELECTION__SUCCESS';
export const VALIDATE_SELECTION__FAIL = '@@ONBOARDING/VALIDATE_SELECTION__FAIL';
export const VALIDATE_SELECTION__ERROR =
  '@@ONBOARDING/VALIDATE_SELECTION__ERROR';

export const OPEN_CART = '@@GTM/OPEN_CART';
export const CLOSE_CART = '@@GTM/CLOSE_CART';

export const addProductToCart = product => dispatch => {
  dispatch({
    type: ADD_PRODUCT_TO_CART,
    payload: product,
  });

  if (document.body.clientWidth > 900) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

export const removeProductFromCart = productId => ({
  type: REMOVE_PRODUCT_FROM_CART,
  payload: productId,
});

export const fetchInitialFunds = identifier => ({
  type: FETCH_INITIAL_PRODUCT_FUNDS__REQUEST,
  payload: identifier,
});

export const prevalidateContract = (productId, funds) => ({
  type: FETCH_CONTRACT_PREVALIDATION__REQUEST,
  payload: { productId, funds },
});

export const updateFund = (productId, fund, value) => ({
  type: UPDATE_FUND,
  payload: { productId, fund, value },
});

export const removeFund = (productId, fundId) => ({
  type: REMOVE_FUND,
  payload: { productId, fundId },
});

export const openCart = () => ({ type: OPEN_CART });

export const closeCart = () => ({ type: CLOSE_CART });

export const validateSelection = (proposition, products) => ({
  type: VALIDATE_SELECTION__REQUEST,
  payload: { proposition, products },
});
