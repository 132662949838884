import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Joi from 'joi-browser';
import classNames from 'classnames';
import { format, isToday } from 'date-fns';
import { Button, ArrowRight } from '@robinfinance/ui';

import {
  INITIAL_TESTING_FORM_DATA,
  EMPTY_ERROR_MESSAGES,
  INCORRECT_ERROR_MESSAGES,
} from '../../../config/emailForms';

import { shouldOpenTestingForm } from '../../../helpers/visitsHelpers';

import testingFormSchema, {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  phoneNumberSchema,
} from '../../../helpers/schema/testingFormSchema';

import OldButton from '../../Core/Button';
import Input from '../../Core/Input';
import Alert from '../../Core/Alert';

import withAppContext from '../../HOC/withAppContext';

import routerShape from '../../../shapes/routerShape';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

import '../../../styles/components/Blocks/Enroll/Enroll.css';

class Enroll extends PureComponent {
  schemas = {
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    email: emailSchema,
    phoneNumber: phoneNumberSchema,
  };

  state = {
    isOpen: false,
    isHidden: true,
    formIsVisible: false,
    hideContent: false,
    showFormContent: false,

    // FORM
    formData: INITIAL_TESTING_FORM_DATA,
    errors: {},
    submitted: false,
    clickSubmit: false,
  };

  componentDidMount() {
    const { visits, addUserVisit } = this.props;

    if (visits.length > 0) {
      const lastVisit = visits[visits.length - 1];

      if (!isToday(new Date(lastVisit))) {
        addUserVisit(new Date().toString());
      }

      if (shouldOpenTestingForm(visits)) {
        setTimeout(() => {
          this.handleOpen();
        }, 20000);
      }
    } else {
      addUserVisit(new Date().toString());
    }
  }

  componentDidUpdate(prevProps) {
    const { loading, error } = this.props;

    if (prevProps.loading && !loading && !error) {
      this.handleSubmitted();
    }
  }

  componentWillUnmount() {
    const { resetForm } = this.props;

    resetForm();
  }

  handleOpen = () => {
    const { showTestingOverlay } = this.props;

    this.setState({ isOpen: true, isHidden: false });
    showTestingOverlay();
  };

  handleClose = () => {
    this.setState({ isOpen: false });
    localStorage.setItem('hasClosedTestingForm', new Date().toString());

    setTimeout(() => {
      this.setState({
        formIsVisible: false,
        hideContent: false,
        showFormContent: false,
      });
      setTimeout(() => {
        this.setState({ isHidden: true });
      }, 300);
    }, 600);
  };

  handleShowForm = () => {
    const { showTestingForm } = this.props;

    this.setState({ hideContent: true });
    showTestingForm();

    setTimeout(() => {
      this.setState({ formIsVisible: true });

      setTimeout(() => {
        this.setState({ showFormContent: true });
      });
    }, 400);
  };

  hasError = () => {
    const { error } = this.props;
    const { clickSubmit, errors } = this.state;

    return (
      error ||
      (clickSubmit &&
        ((errors.email && errors.email.length > 0) ||
          (errors.lastName && errors.lastName.length > 0) ||
          (errors.firstName && errors.firstName.length > 0) ||
          (errors.phoneNumber && errors.phoneNumber.length > 0)))
    );
  };

  validateInput = async (name, value) => {
    const { errors } = this.state;
    const data = { [name]: value };
    const schema = this.schemas[name];

    try {
      await Joi.validate(data, schema);
      this.setState({ errors: { ...errors, [name]: [] } });
    } catch (error) {
      if (
        error.details[0].type === 'any.empty' ||
        error.details[0].type === 'array.min'
      ) {
        this.setState({
          errors: { ...errors, [name]: [EMPTY_ERROR_MESSAGES[name]] },
        });
      } else {
        this.setState({
          errors: { ...errors, [name]: [INCORRECT_ERROR_MESSAGES[name]] },
        });
      }
    }
  };

  handleSubmitted = () => {
    this.setState({
      submitted: true,
      formData: INITIAL_TESTING_FORM_DATA,
    });

    localStorage.setItem('hasSendTestingForm', format(new Date()));
  };

  handleChange = (name, value) => {
    const { formData } = this.state;

    this.setState({ formData: { ...formData, [name]: value } });
  };

  handleBlur = (name, value) => {
    this.validateInput(name, value);
  };

  handleSubmit = async e => {
    e.preventDefault();

    const {
      sendEmail,
      context: { utmCampaign, utmSource, utmMedium },
    } = this.props;
    const { formData } = this.state;
    const data = {
      ...formData,
    };

    this.setState({ submitted: false, clickSubmit: true });

    try {
      const validatedData = await Joi.validate(data, testingFormSchema, {
        abortEarly: false,
      });

      sendEmail({
        ...validatedData,
        utmCampaign,
        utmSource,
        utmMedium,
      });
    } catch (error) {
      const inputErrors = {};

      error.details.forEach(inputError => {
        const name = inputError.context.key;

        if (
          inputError.type === 'any.empty' ||
          inputError.type === 'array.min'
        ) {
          inputErrors[name] = [EMPTY_ERROR_MESSAGES[name]];
        } else if (!inputErrors[name]) {
          inputErrors[name] = [INCORRECT_ERROR_MESSAGES[name]];
        }
      });

      this.setState({ errors: inputErrors });
    }
  };

  renderMessages = () => {
    const { error, errorMessage } = this.props;

    if (this.hasError()) {
      return (
        <div className="Enroll__messages">
          <Alert type="error">
            {(error && errorMessage) ||
              'Merci de vérifier que vous avez correctement rempli tous les champs du formulaire.'}
          </Alert>
        </div>
      );
    }

    return null;
  };

  renderContent = () => {
    const { loading } = this.props;
    const { formIsVisible, showFormContent, formData, errors } = this.state;

    if (formIsVisible || showFormContent) {
      return (
        <Fragment>
          <div className="Enroll__form-text">
            <p>
              Pour participer à une de nos sessions de tests utilisateur,
              remplissez ce formulaire.
            </p>
            <p>
              Un membre de notre équipe produit vous contactera afin de fixer un
              rendez-vous.
            </p>
          </div>
          <form className="Enroll__form" onSubmit={this.handleSubmit}>
            <Input
              name="firstName"
              value={formData.firstName}
              errors={errors.firstName}
              type="text"
              label="Votre prénom"
              iconClass="iconStyle profilIcon"
              hideOnYandex
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            <Input
              name="lastName"
              value={formData.lastName}
              errors={errors.lastName}
              type="text"
              label="Votre nom"
              iconClass="iconStyle profilIcon"
              hideOnYandex
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            <Input
              name="email"
              value={formData.email}
              errors={errors.email}
              type="text"
              label="Votre e-mail"
              iconClass="iconStyle emailIcon"
              hideOnYandex
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            <Input
              name="phoneNumber"
              value={formData.phoneNumber}
              errors={errors.phoneNumber}
              type="tel"
              label="Votre numéro de téléphone"
              iconClass="iconStyle phoneIcon"
              hideOnYandex
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            {this.renderMessages()}
            <Button
              id="Enroll-CTA-2"
              scope="button"
              type="primary"
              iconSide="right"
              icon={<ArrowRight color="#ffc501" />}
              onClick={this.handleSubmit}
              disabled={loading}
              loading={loading}
              hasLoadingState
            >
              Je me fais recontacter
            </Button>
          </form>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <p className="Enroll__text">
          Participez à nos sessions de tests, et repartez avec une carte cadeau
          d&apos;une valeur de 50€
        </p>
        <Button
          id="Enroll-CTA-1"
          scope="button"
          type="primary"
          iconSide="right"
          icon={<ArrowRight color="#ffc501" />}
          onClick={this.handleShowForm}
        >
          En savoir plus
        </Button>
      </Fragment>
    );
  };

  render() {
    const {
      location,
      authModalIsOpen,
      verificationModalIsOpen,
      leadModalIsOpen,
    } = this.props;
    const {
      isOpen,
      isHidden,
      formIsVisible,
      hideContent,
      showFormContent,
      submitted,
    } = this.state;

    const routeRegex = [
      /^\/connaissance-client/g,
      /^\/questionnaire/g,
      /^\/onboarding/g,
    ];

    let shouldHideEnroll = false;

    routeRegex.forEach(regex => {
      if (regex.test(location.pathname)) {
        shouldHideEnroll = true;
      }
    });

    const containerClass = classNames('Enroll', {
      'Enroll--is-open': isOpen && !shouldHideEnroll,
      'Enroll--is-hidden':
        isHidden ||
        authModalIsOpen ||
        verificationModalIsOpen ||
        leadModalIsOpen,
      'Enroll--show-form': formIsVisible,
      'Enroll--hide-content': hideContent,
      'Enroll--show-form-content': showFormContent,
      'Enroll--submitted': submitted,
      'Enroll--has-errors': this.hasError(),
    });

    return (
      <div className={containerClass}>
        <span className="Enroll__mask" />
        <div className="Enroll__overlay">
          <OldButton
            id="Enroll-Close"
            className="Enroll__close"
            type="white"
            label="Fermer"
            icon={<CloseIcon className="Enroll__close-icon" />}
            onClick={this.handleClose}
          />
          <div className="Enroll__container">
            <div className="Enroll__title">
              Vous appréciez notre site ?
              <br />
              Vous souhaitez nous aider à nous améliorer ?
            </div>
            {submitted ? (
              <div className="Enroll__messages">
                <Alert type="success">Votre message a bien été envoyé.</Alert>
              </div>
            ) : (
              <div className="Enroll__content">{this.renderContent()}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Enroll.defaultProps = {
  errorMessage: null,
};

Enroll.propTypes = {
  context: PropTypes.shape({
    utmCampaign: PropTypes.string.isRequired,
    utmSource: PropTypes.string.isRequired,
    utmMedium: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  visits: PropTypes.arrayOf(PropTypes.string).isRequired,
  sendEmail: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  addUserVisit: PropTypes.func.isRequired,
  showTestingOverlay: PropTypes.func.isRequired,
  showTestingForm: PropTypes.func.isRequired,
  location: PropTypes.shape(routerShape).isRequired,
  authModalIsOpen: PropTypes.bool.isRequired,
  verificationModalIsOpen: PropTypes.bool.isRequired,
  leadModalIsOpen: PropTypes.bool.isRequired,
};

export default withAppContext(Enroll);
