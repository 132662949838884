import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { smallBlockListShape } from '../../../shapes/prismicShape';

import PrismicBlockTitle from '../Core/PrismicBlockTitle';
import RichText from '../../Core/RichText';
import PrismicLink from '../Core/PrismicLink';

import '../../../styles/components/Prismic/PrismicSmallBlockList.css';

class PrismicSmallBlockList extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicSmallBlockList">
        <div className="PrismicSmallBlockList__header">
          <PrismicBlockTitle
            title={data.primary.small_block_list_title}
            preTitle={data.primary.small_block_list_pre_title}
            color={data.primary.small_block_list_color}
          />
        </div>
        <div className="PrismicSmallBlockList__content">
          {data.items.map((block, index) => (
            <div key={index} className="PrismicSmallBlockList__block">
              <div className="PrismicSmallBlockList__block-image">
                {block.small_block_list_block_icon &&
                  block.small_block_list_block_icon.url && (
                    <img
                      src={block.small_block_list_block_icon.url}
                      alt={block.small_block_list_block_icon.alt}
                    />
                  )}
              </div>
              <div className="PrismicSmallBlockList__block-content">
                <div className="PrismicSmallBlockList__block-title">
                  {block.small_block_list_block_title}
                </div>
                <div className="PrismicSmallBlockList__block-description">
                  <RichText
                    paragraphs={block.small_block_list_block_description}
                  />
                </div>
                {block.small_block_list_url_label &&
                  block.small_block_list_url && (
                    <PrismicLink
                      className="PrismicSmallBlockList__block-link"
                      link={block.small_block_list_url}
                    >
                      <Fragment>
                        {block.small_block_list_url_label}
                        <span className="PrismicSmallBlockList__block-link-image">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="5"
                            height="8"
                            viewBox="0 0 5 8"
                          >
                            <path
                              fill="#ffc501"
                              fillRule="evenodd"
                              d="M.17 1.306A.796.796 0 0 1 .34.164a.876.876 0 0 1 1.02 0l3.397 3.265a.793.793 0 0 1 0 1.143L1.359 7.837A.872.872 0 0 1 .17 7.674a.79.79 0 0 1 0-.98L2.965 4 .162 1.306H.17z"
                            />
                          </svg>
                        </span>
                      </Fragment>
                    </PrismicLink>
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

PrismicSmallBlockList.propTypes = {
  data: PropTypes.shape(smallBlockListShape).isRequired,
};

export default PrismicSmallBlockList;
