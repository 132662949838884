import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Cross } from '@robinfinance/ui';

import '../../styles/components/Core/CloseModal.css';

class CloseModal extends PureComponent {
  render() {
    const { label, onClick } = this.props;

    return (
      <span role="presentation" className="CloseModal" onClick={onClick}>
        <Cross color="#616c7a" width="15" height="15" />
        <div className="CloseModal-text">{label}</div>
      </span>
    );
  }
}

CloseModal.defaultProps = {
  label: '',
  onClick: null,
};

CloseModal.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default CloseModal;
