import React from 'react';

import ProductContext from '../../contexts/ProductContext';

/* eslint-disable react/display-name */
const withProductContext = Component => props => (
  <ProductContext.Consumer>
    {context => <Component {...props} context={context} />}
  </ProductContext.Consumer>
);

export default withProductContext;
