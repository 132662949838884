import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { fetchSendEmailVerification } from '../../../api/Auth';

function VerifyEmailErrorMessage({ email }) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isDone, setIsDone] = useState(false);

  async function handleClick() {
    setIsLoading(true);
    setHasError(false);

    try {
      await fetchSendEmailVerification(email);

      setIsDone(true);
    } catch (error) {
      setHasError(true);
    }

    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <span className="ErrorMessage__action-message">
        Votre e-mail de vérification est en cours d&apos;envoi...
      </span>
    );
  }

  if (hasError) {
    return (
      <span className="ErrorMessage__action-message">
        Il semblerait que nous ayons un problème. Merci de réessayer dans
        quelques instants ou de nous contacter à l&apos;adresse
        hello@mieuxplacer.com.
      </span>
    );
  }

  if (isDone) {
    return (
      <span className="ErrorMessage__action-message">
        Nous vous avons renvoyé un e-mail de vérification
      </span>
    );
  }

  return (
    <button
      type="button"
      className="ErrorMessage__action"
      onClick={handleClick}
    >
      Je n&apos;ai pas reçu l&apos;e-mail
    </button>
  );
}

VerifyEmailErrorMessage.propTypes = {
  email: PropTypes.string.isRequired,
};

export default VerifyEmailErrorMessage;
