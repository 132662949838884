import React from 'react';

import LoadingGradient from '../../../Core/LoadingGradient';

const ProductFeaturesLoading = () => (
  <div className="ProductFeatures ProductFeatures--loading">
    <LoadingGradient
      className="ProductFeatures__title"
      width="300px"
      height="42px"
    />
    <div className="ProductFeatures__features">
      {[1, 2, 3, 4, 5].map(key => (
        <div key={key} className="ProductFeatures__feature">
          <LoadingGradient
            className="ProductFeatures__feature-title"
            width="300px"
            height="24px"
          />
          <LoadingGradient
            className="ProductFeatures__feature-value"
            width="150px"
            height="24px"
          />
        </div>
      ))}
    </div>
  </div>
);

export default ProductFeaturesLoading;
