import React from 'react';

import cncgp from '../../../assets/images/cncgp.svg';
import oriasLogo from '../../../assets/images/orias.png';
import oriasLogo2X from '../../../assets/images/orias@2x.png';

import '../../../styles/components/Layout/Footer/PreFooter.css';

function PreFooter() {
  return (
    <div className="PreFooter">
      <div className="PreFooter__content">
        <div className="PreFooter__column">
          <div className="PreFooter__icon ">
            <img
              className="PreFooter__logo cgpSvg"
              src={cncgp}
              alt="Logo CNCGP"
            />
          </div>
          <div className="PreFooter__text">
            <p className="PreFooter__paragraph">
              Adhérent et membre de la CNCGP, en tant que Conseiller
              en Investissement Financier (CIF). La CNCGP est une association
              professionnelle agréée par l&#39;Autorité des Marchés Financiers
              (AMF).
            </p>
          </div>
        </div>
        <div className="PreFooter__column">
          <div className="PreFooter__icon">
            <img
              className="PreFooter__logo"
              src={oriasLogo}
              srcSet={`${oriasLogo2X} 2x`}
              alt="Logo Orias"
            />
          </div>
          <div className="PreFooter__text">
            <p className="PreFooter__paragraph">
              Orias N° 23003266, en tant que Courtier en
              Assurance (COA). Cette activité est régulée par l&#39;Autorité de
              Contrôle Prudentiel et de Résolution (ACPR).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreFooter;
