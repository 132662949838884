import * as Joi from 'joi-browser';

const contactSchema = Joi.object().keys({
  subject: Joi.string().required(),
  source: Joi.string().valid('mieuxplacer-com').required(),
  page: Joi.string().valid('contact-page').required(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string()
    .email({
      minDomainAtoms: 2,
    })
    .required(),
  phoneNumber: Joi.string()
    .min(10)
    .max(10)
    .regex(
      /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm,
    )
    .required(),
  message: Joi.string().required(),
});

export const subjectSchema = Joi.object().keys({
  subject: Joi.string().required(),
});

export const firstNameSchema = Joi.object().keys({
  firstName: Joi.string().required(),
});

export const lastNameSchema = Joi.object().keys({
  lastName: Joi.string().required(),
});

export const emailSchema = Joi.object().keys({
  email: Joi.string()
    .email({
      minDomainAtoms: 2,
    })
    .required(),
});

export const phoneNumberSchema = Joi.object().keys({
  phoneNumber: Joi.string()
    .min(10)
    .max(10)
    .regex(
      /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm,
    )
    .required(),
});

export const messageSchema = Joi.object().keys({
  message: Joi.string().required(),
});

export default contactSchema;
