export const INITIAL_CONTACT_FORM_DATA = {
  subject: '',
  source: 'mieuxplacer-com',
  page: 'contact-page',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  message: '',
};

export const INITIAL_LEAD_FORM_DATA = {
  subject: 'product-or-service-information',
  source: 'mieuxplacer-com',
  page: 'product-page',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
};

export const INITIAL_CALL_BACK_FORM_DATA = {
  subject: 'contact',
  source: 'mieuxplacer-com',
  page: 'app-page',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  time: [],
};

export const INITIAL_CALL_BACK_PRODUCT_FORM_DATA = {
  subject: 'available-product-contact',
  source: 'mieuxplacer-com',
  page: 'product-page',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  time: [],
};

export const INITIAL_TESTING_FORM_DATA = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
};

export const EMPTY_ERROR_MESSAGES = {
  subject: 'Quel est le motif de votre demande ?',
  firstName: 'Quel est votre prénom ?',
  lastName: 'Quel est votre nom ?',
  email: 'A quelle adresse pourrions-nous vous répondre ?',
  phoneNumber: 'À quel numéro pourrions-nous vous rappeler ?',
  message: 'Merci de préciser votre demande / question ci-dessus.',
  time: 'À quel moment pourrions-nous vous rappeler ?',
};

export const INCORRECT_ERROR_MESSAGES = {
  subject: 'Quel est le motif de votre demande ?',
  firstName: 'Quel est votre prénom ?',
  lastName: 'Quel est votre nom ?',
  email: 'Votre adresse e-mail semble incorrecte, pouvez-vous vérifier ?',
  phoneNumber:
    'votre numéro de téléphone semble incorrect, pouvez-vous vérifier ?',
  message: 'Merci de préciser votre demande / question ci-dessus.',
};

export const MOTIVE_OPTIONS = [
  {
    value: 'product-or-service-information',
    label: 'Information sur nos produits',
  },
  {
    value: 'customer-service',
    label: 'Service client',
  },
  {
    value: 'partnership-request',
    label: 'Demande de partenariat',
  },
  {
    value: 'providers',
    label: 'Prestataires',
  },
  {
    value: 'press-request',
    label: 'Communication / Presse',
  },
  {
    value: 'recruitment',
    label: 'Recrutement',
  },
  {
    value: 'other',
    label: 'Autre',
  },
];

export const TIME_OPTIONS = [
  {
    value: 'morning',
    label: 'Matin',
  },
  {
    value: 'noon',
    label: 'Midi',
  },
  {
    value: 'afternoon',
    label: 'Après-midi',
  },
];
