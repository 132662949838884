import React from 'react';

import LoadingGradient from '../../../Core/LoadingGradient';

const ProductSummaryLoading = () => (
  <div className="ProductSummary ProductSummary--loading">
    <LoadingGradient
      className="ProductSummary__supplier-logo-container"
      width="120px"
      height="120px"
    />
    <div className="ProductSummary__main">
      <div className="ProductSummary__type">
        <LoadingGradient width="100px" height="30px" />
        <LoadingGradient
          className="ProductSummary__type-link"
          width="200px"
          height="22px"
        />
      </div>
      <div className="ProductSummary__info">
        <LoadingGradient
          className="ProductSummary__title"
          width="250px"
          height="63px"
        />
        <LoadingGradient
          className="ProductSummary__supplier"
          width="150px"
          height="24px"
        />
        <div className="ProductSummary__description">
          <div className="RichText__paragraph">
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="20px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="20px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="350px"
              height="20px"
            />
          </div>
          <div className="RichText__paragraph">
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="18px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="18px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="18px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="18px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="450px"
              height="18px"
            />
            <LoadingGradient
              className="LoadingGradient--text-line"
              width="350px"
              height="18px"
            />
          </div>
        </div>
        <div className="ProductSummary__rewards">
          <div className="ProductSummary__reward">
            <LoadingGradient
              className="ProductSummary__reward-image"
              width="45px"
              height="45px"
            />
            <LoadingGradient
              className="ProductSummary__reward-label"
              width="300px"
              height="20px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProductSummaryLoading;
