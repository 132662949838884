import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Accordion } from '@robinfinance/ui';

import '../../../styles/components/Layout/Footer/Guides.css';

function FooterGuides({ isVisible }) {
  return (
    <div className="FooterGuides">
      <Accordion
        defaultOpen={isVisible}
        showLabel="Guides"
        hideLabel="Guides"
        togglePosition="top"
        toggleAlign="left"
      >
        <div className="FooterMenu__content">
          <Link id="Footer__guide" className="FooterMenu__item" to="/guide">
            Guides pratiques gratuits (PDF)
          </Link>
          <a
            id="Footer__guide-epargne"
            href="https://manuel-epargnant.mieuxplacer.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="FooterMenu__item FooterMenu__item--bold"
          >
            Livre à télécharger : Guide de l’épargnant
          </a>
        </div>
      </Accordion>
    </div>
  );
}

FooterGuides.defaultProps = {
  isVisible: false,
};

FooterGuides.propTypes = {
  isVisible: PropTypes.bool,
};

export default FooterGuides;
