import { Map, List, fromJS } from 'immutable';

import {
  FETCH_OBJECTIVES__REQUEST,
  FETCH_OBJECTIVES__SUCCESS,
  FETCH_OBJECTIVES__FAIL,
} from '../actions/ObjectiveActions';

const initialState = Map({
  objectivesLoading: false,
  objectivesError: false,
  objectives: List([]),
});

const objectiveState = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_OBJECTIVES__REQUEST:
      return state.set('objectivesLoading', true).set('objectivesError', false);

    case FETCH_OBJECTIVES__SUCCESS:
      return state
        .set('objectivesLoading', false)
        .set('objectives', fromJS(payload));

    case FETCH_OBJECTIVES__FAIL:
      return state.set('objectivesLoading', false).set('objectivesError', true);

    default:
      return initialState.merge(state);
  }
};

export default objectiveState;
