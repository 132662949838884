export const VALIDATE_SIGNATURE_TASK__REQUEST =
  '@@SIGNATURE/VALIDATE_SIGNATURE_TASK__REQUEST';
export const VALIDATE_SIGNATURE_TASK__SUCCESS =
  '@@SIGNATURE/VALIDATE_SIGNATURE_TASK__SUCCESS';
export const VALIDATE_SIGNATURE_TASK__FAIL =
  '@@SIGNATURE/VALIDATE_SIGNATURE_TASK__FAIL';

export const validateSignatureTask = contractId => ({
  type: VALIDATE_SIGNATURE_TASK__REQUEST,
  payload: contractId,
});
