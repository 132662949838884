import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip as TippyTooltip } from 'react-tippy';

import productShape from '../../../shapes/productShape';
import typeShape from '../../../shapes/typeShape';
import supplierShape from '../../../shapes/supplierShape';

import Tag from '../../Core/Tag';
import Link from '../../Core/Link';
import RichText from '../../Core/RichText';
import ProductSummaryLoading from './Loading/ProductSummaryLoading';
import Reward from './Reward';

import calendarIcon from '../../../assets/icons/calendar-icon.svg';

import '../../../styles/components/Blocks/Product/ProductSummary.css';

class ProductSummary extends PureComponent {
  handleScrollDown = () => {
    const target = document.querySelector('.PrismicBonus');

    if (target) {
      target.parentNode.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  renderType = isMobile => {
    const { type } = this.props;

    if (type) {
      return (
        <div className="ProductSummary__type">
          {type.tooltip ? (
            <TippyTooltip
              title={type.tooltip}
              position="top"
              interactiveBorder="10"
              animateFill={false}
            >
              <Tag
                size="small"
                label={type.label}
                color={type.color}
                backgroundColor={type.backgroundColor}
              />
            </TippyTooltip>
          ) : (
            <Tag
              size="small"
              label={type.label}
              color={type.color}
              backgroundColor={type.backgroundColor}
            />
          )}
          {this.renderTypeExplanation(isMobile)}
        </div>
      );
    }

    return null;
  };

  renderTypeExplanation = isMobile => {
    const { type } = this.props;

    const typeExplanationClasses = classNames('ProductSummary__type-link', {
      'ProductSummary__type-link--is-mobile': isMobile,
    });

    if (type && type.explanation) {
      return (
        <div
          className={typeExplanationClasses}
          style={{ backgroundColor: isMobile && type.backgroundColor }}
        >
          {this.renderTypeExplanationLink(isMobile, type)}
        </div>
      );
    }

    return null;
  };

  renderTypeExplanationLink = (isMobile, type) => (
    <Link to={`/guide/${type.slug}`} animated>
      <Fragment>
        <span style={{ color: isMobile && type.color }}>
          {type.explanation}
        </span>
        <span
          className="ProductSummary__type-link-image"
          style={{ backgroundColor: type.backgroundColor }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="8"
            viewBox="0 0 5 8"
          >
            <path
              fill={type.color}
              fillRule="evenodd"
              d="M.17 1.306A.796.796 0 0 1 .34.164a.876.876 0 0 1 1.02 0l3.397 3.265a.793.793 0 0 1 0 1.143L1.359 7.837A.872.872 0 0 1 .17 7.674a.79.79 0 0 1 0-.98L2.965 4 .162 1.306H.17z"
            />
          </svg>
        </span>
        <span
          className="ProductSummary__type-mobile-image"
          style={{ backgroundColor: type.color }}
        />
      </Fragment>
    </Link>
  );

  renderSupplier = () => {
    const { supplier } = this.props;

    if (supplier) {
      return (
        <p className="ProductSummary__supplier">
          Proposé par{' '}
          <a
            className="ProductSummary__supplier-link"
            href={supplier.link}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            {supplier.name}
          </a>
        </p>
      );
    }

    return null;
  };

  renderDescription = () => {
    const { product } = this.props;

    if (product.description) {
      return (
        <div className="ProductSummary__description">
          <RichText paragraphs={product.description} />
        </div>
      );
    }

    return null;
  };

  renderRewards = () => {
    const { product } = this.props;

    if (
      product.rewards &&
      product.rewards.length > 0 &&
      product.rewards[0].label
    ) {
      return (
        <div className="ProductSummary__rewards">
          {product.rewards.map((reward, index) => (
            <Reward key={index} reward={reward} />
          ))}
        </div>
      );
    }

    return null;
  };

  render() {
    const { product, supplier, loading } = this.props;

    if (loading) {
      return <ProductSummaryLoading />;
    }

    if (!product) {
      return null;
    }

    return (
      <div className="ProductSummary">
        <div className="ProductSummary__supplier-logo-container">
          {supplier && supplier.logo && supplier.logo.url && (
            <img
              className="Product__supplier-logo"
              src={supplier.logo.url}
              alt={supplier.logo.alt}
            />
          )}
        </div>
        <div className="ProductSummary__main">
          {this.renderType(false)}
          <div className="ProductSummary__info">
            <h1 className="ProductSummary__title">{product.title}</h1>
            {this.renderSupplier()}
            {this.renderDescription()}
            {this.renderRewards()}
            {product.subscribable ? (
              product.blocks.map(block => {
                if (block.slice_type === 'bonus') {
                  return (
                    <div
                      className="ProductSummary__bonus"
                      key={block.slice_type}
                      role="button"
                      tabIndex="0"
                      onMouseUp={this.handleScrollDown}
                    >
                      <span className="ProductSummary__bonus-euro">€</span>
                      <p className="ProductSummary__bonus-text">
                        Jusqu&#39;à {block.primary.bonus_price} € de{' '}
                        {block.primary.bonus_prime}
                      </p>
                      <span className="ProductSummary__bonus-arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="14"
                          viewBox="0 0 18 14"
                        >
                          <path
                            fill="#FFC501"
                            fillRule="evenodd"
                            d="M15.047 6.161H1.534c-.478 0-.867.375-.867.84 0 .462.389.838.867.838h13.513l-4.594 4.448a.82.82 0 0 0 .103 1.181.89.89 0 0 0 1.128-.007l6.068-5.873a.82.82 0 0 0 0-1.175L11.684.539a.885.885 0 0 0-1.222.083.818.818 0 0 0-.009 1.092l4.603 4.447h-.009z"
                          />
                        </svg>
                      </span>
                    </div>
                  );
                }
                return null;
              })
            ) : (
              <div className="ProductSummary__subscription-closed">
                <img src={calendarIcon} alt="calendar_icon" />
                <p>
                  Ce produit n&apos;est pas disponible. Demandez-nous à être
                  recontacté(e) quand il le sera.
                </p>
              </div>
            )}
            {this.renderTypeExplanation(true)}
          </div>
        </div>
      </div>
    );
  }
}

ProductSummary.defaultProps = {
  product: null,
  type: null,
  supplier: null,
  loading: false,
};

ProductSummary.propTypes = {
  product: PropTypes.shape(productShape),
  type: PropTypes.shape(typeShape),
  supplier: PropTypes.shape(supplierShape),
  loading: PropTypes.bool,
};

export default ProductSummary;
