import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Accordion } from '@robinfinance/ui';

import '../../../styles/components/Layout/Footer/Menu.css';

function FooterMenu({ isVisible }) {
  return (
    <div className="FooterMenu">
      <Accordion
        defaultOpen={isVisible}
        showLabel="Mieuxplacer.com"
        hideLabel="Mieuxplacer.com"
        togglePosition="top"
        toggleAlign="left"
      >
        <nav className="FooterMenu__content">
          <Link id="Footer__accueil" className="FooterMenu__item" to="/">
            Accueil
          </Link>
          <Link
            id="Footer__equipe"
            className="FooterMenu__item"
            to="/qui-sommes-nous"
          >
            L&apos;équipe
          </Link>
          <Link
            id="Footer__notre-mission"
            className="FooterMenu__item"
            to="/qui-sommes-nous/notre-mission"
          >
            Notre mission
          </Link>
          <Link
            id="Footer__comment-ca-marche"
            className="FooterMenu__item"
            to="/qui-sommes-nous/comment-ca-marche"
          >
            Comment ça marche ?
          </Link>
          <Link
            id="Footer__produits"
            className="FooterMenu__item"
            to="/produits"
          >
            Nos produits
          </Link>
          <Link
            id="Footer__tarifs"
            className="FooterMenu__item"
            to="/qui-sommes-nous/tarifs"
          >
            Tarifs
          </Link>
          <Link
            id="Footer__temoignages"
            className="FooterMenu__item"
            to="/qui-sommes-nous/temoignages"
          >
            Témoignages
          </Link>
        </nav>
      </Accordion>
    </div>
  );
}

FooterMenu.defaultProps = {
  isVisible: true,
};

FooterMenu.propTypes = {
  isVisible: PropTypes.bool,
};

export default FooterMenu;
