import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../../styles/components/Core/Accordion.css';

const AccordionBlock = ({
  buttonLabel,
  expand,
  content,
  color,
  bgColor,
  onClick,
  colorLabel,
  displayIconLeft,
  NoMargin,
  inversed,
  opacityHeight,
  opacityColor,
}) => {
  const containerRef = useRef(null);

  const [containerHeight, setContainerHeight] = useState(0);

  const handleResize = () => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.scrollHeight;

      setContainerHeight(containerHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [expand]);

  const containerClasses = classNames('Accordion', {
    'Accordion--expanded': expand,
    'Accordion--inversed': inversed,
  });

  const titleClasses = classNames('Accordion__title', {
    'Accordion__title--margin': !NoMargin,
    'Accordion__title--padding': !displayIconLeft,
  });

  const opacityClasses = classNames('Accordion__opacity', {
    'Accordion__opacity--hidden': expand,
  });

  return (
    <div className={containerClasses}>
      <div className="Accordion__container">
        <button
          className={titleClasses}
          type="button"
          onClick={onClick}
          style={{ color: colorLabel }}
        >
          <span
            className={opacityClasses}
            style={{
              height: opacityHeight,
              top: `-${opacityHeight}`,
              background: `linear-gradient(rgba(${opacityColor}, 0.2), rgba(${opacityColor}, 1))`,
            }}
          />
          {displayIconLeft && (
            <div
              className="Accordion__arrow-icon"
              style={{ backgroundColor: bgColor }}
            >
              <div className="Accordion__arrow-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="9"
                  viewBox="0 0 6 9"
                >
                  <path
                    fill={color}
                    fillRule="evenodd"
                    d="M.204 1.47C-.134 1.063-.042.488.408.184c.363-.245.86-.245 1.223 0l4.077 3.673a.854.854 0 0 1 0 1.286L1.631 8.816c-.451.305-1.089.222-1.427-.183a.845.845 0 0 1 0-1.102L3.558 4.5.194 1.47h.01z"
                  />
                </svg>
              </div>
            </div>
          )}
          {buttonLabel}
          <div
            className="Accordion__arrow-icon"
            style={{ backgroundColor: bgColor }}
          >
            <div className="Accordion__arrow-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 6 9"
              >
                <path
                  fill={color}
                  fillRule="evenodd"
                  d="M.204 1.47C-.134 1.063-.042.488.408.184c.363-.245.86-.245 1.223 0l4.077 3.673a.854.854 0 0 1 0 1.286L1.631 8.816c-.451.305-1.089.222-1.427-.183a.845.845 0 0 1 0-1.102L3.558 4.5.194 1.47h.01z"
                />
              </svg>
            </div>
          </div>
        </button>
        <div
          className="Accordion__content"
          ref={containerRef}
          style={{ maxHeight: expand ? `${containerHeight}px` : '0px' }}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

AccordionBlock.propTypes = {
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  expand: PropTypes.bool.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  color: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  colorLabel: PropTypes.string.isRequired,
  displayIconLeft: PropTypes.bool.isRequired,
  NoMargin: PropTypes.bool.isRequired,
  inversed: PropTypes.bool.isRequired,
  opacityHeight: PropTypes.string.isRequired,
  opacityColor: PropTypes.string.isRequired,
};

export default AccordionBlock;
