import { BASE_API_URL } from '../config/api';

export const fetchForgotPassword = async email => {
  const url = new URL(`${BASE_API_URL}/2.0/auth/forgot-password`);

  const response = await fetch(url.href, {
    method: 'POST',
    body: JSON.stringify({ email }),
    credentials: 'include',
  });

  const data = await response.json();

  if (response.ok) {
    return data;
  }

  throw data;
};

export const fetchResetPassword = async ({ uid, token, password }) => {
  const url = new URL(`${BASE_API_URL}/2.0/auth/reset-password`);

  const response = await fetch(url.href, {
    method: 'POST',
    body: JSON.stringify({ uid, token, password }),
    credentials: 'include',
  });

  const data = await response.json();

  if (response.ok) {
    return data;
  }

  throw data;
};

export const fetchSendEmailVerification = async email => {
  const url = new URL(`${BASE_API_URL}/2.0/auth/email-verification`);

  const response = await fetch(url.href, {
    method: 'POST',
    body: JSON.stringify({ email }),
    credentials: 'include',
  });

  const data = await response.json();

  if (response.ok) {
    return data;
  }

  throw data;
};

export const fetchEmailVerification = async token => {
  const url = new URL(`${BASE_API_URL}/2.0/auth/verify-email`);

  const response = await fetch(url.href, {
    method: 'POST',
    body: JSON.stringify({ token }),
    credentials: 'include',
  });

  const data = await response.json();

  if (response.ok) {
    return data;
  }

  throw data;
};

export const createUser = async ({
  email,
  password,
  phoneNumber,
  firstName,
  lastName,
  utmCampaign,
  utmSource,
  utmMedium,
}) => {
  const url = new URL(`${BASE_API_URL}/2.0/auth/create-user`);

  const response = await fetch(url.href, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
      phoneNumber,
      firstName,
      lastName,
      utmCampaign,
      utmSource,
      utmMedium,
    }),
    credentials: 'include',
  });

  const data = await response.json();

  if (response.ok) {
    return data;
  }

  throw data;
};
