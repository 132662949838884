import { Map, List, fromJS } from 'immutable';

import {
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  FETCH_INITIAL_PRODUCT_FUNDS__SUCCESS,
  FETCH_CONTRACT_PREVALIDATION__REQUEST,
  FETCH_CONTRACT_PREVALIDATION__SUCCESS,
  FETCH_CONTRACT_PREVALIDATION__ERROR,
  FETCH_CONTRACT_PREVALIDATION__FAIL,
  UPDATE_FUND,
  REMOVE_FUND,
  VALIDATE_SELECTION__REQUEST,
  VALIDATE_SELECTION__SUCCESS,
  VALIDATE_SELECTION__FAIL,
  VALIDATE_SELECTION__ERROR,
} from '../actions/CartActions';

const initialState = Map({
  products: List([]),
  selectionValidationLoading: false,
  selectionValidationError: false,
  selectionValidationErrors: List(),
});

const cartState = (state = initialState, { type, payload }) => {
  let newProducts = [];

  switch (type) {
    case ADD_PRODUCT_TO_CART:
      if (state.get('products').size < 5) {
        return state.set(
          'products',
          state.get('products').push(fromJS(payload)),
        );
      }

      return initialState.merge(state);

    case REMOVE_PRODUCT_FROM_CART: {
      newProducts = state
        .get('products')
        .filter(product => product.get('id') !== payload);

      return state.set('products', newProducts);
    }

    case FETCH_INITIAL_PRODUCT_FUNDS__SUCCESS:
      return state.set(
        'products',
        state.get('products').map(product => {
          if (product.get('identifier') === payload.productId) {
            if (!product.get('funds') || product.get('funds').size === 0) {
              return product.set('funds', fromJS(payload.funds));
            }
          }

          return product;
        }),
      );

    case UPDATE_FUND:
      return state
        .set(
          'products',
          state.get('products').map(product => {
            if (product.get('id') === payload.productId) {
              if (product.get('funds')) {
                if (
                  product
                    .get('funds')
                    .find(fund => fund.get('id') === payload.fund.id)
                ) {
                  return product.set(
                    'funds',
                    product.get('funds').map(fund => {
                      if (fund.get('id') === payload.fund.id) {
                        return fund.set('amount', payload.value);
                      }

                      return fund;
                    }),
                  );
                }

                return product.set(
                  'funds',
                  product
                    .get('funds')
                    .push(fromJS(payload.fund).set('amount', payload.value)),
                );
              }

              return product.set(
                'funds',
                List([fromJS(payload.fund).set('amount', 0)]),
              );
            }

            return product;
          }),
        )
        .set('selectionPreValidateLoading', true);

    case REMOVE_FUND:
      return state.set(
        'products',
        state.get('products').map(product => {
          if (product.get('id') === payload.productId) {
            return product
              .set(
                'funds',
                product
                  .get('funds')
                  .filter(fund => fund.get('id') !== payload.fundId),
              )
              .set('errors', List())
              .set('lineErrors', List());
          }

          return product;
        }),
      );

    case FETCH_CONTRACT_PREVALIDATION__REQUEST:
      return state.set('selectionPreValidateLoading', true);

    case FETCH_CONTRACT_PREVALIDATION__SUCCESS: {
      return state
        .set('selectionPreValidateLoading', false)
        .set('selectionValidationErrors', List())
        .set(
          'products',
          state.get('products').map(product => {
            if (
              product.get('identifier') === payload &&
              product.get('funds').length !== 0
            ) {
              return product
                .set('valid', true)
                .set('errors', List())
                .set('lineErrors', List());
            }

            return product;
          }),
        );
    }

    case FETCH_CONTRACT_PREVALIDATION__ERROR: {
      return state.set('selectionPreValidateLoading', false).set(
        'products',
        state.get('products').map(product => {
          if (product.get('identifier') === payload.productId) {
            return product
              .set('valid', false)
              .set('errors', fromJS(payload.data.errors))
              .set('lineErrors', List(payload.data.lines));
          }

          return product;
        }),
      );
    }

    case FETCH_CONTRACT_PREVALIDATION__FAIL:
      return state.set('selectionPreValidateLoading', false).set(
        'products',
        state.get('products').map(product => {
          if (product.get('identifier') === payload.productId) {
            return product
              .set('valid', false)
              .set('errors', List())
              .set('lineErrors', List());
          }

          return product;
        }),
      );

    case VALIDATE_SELECTION__REQUEST:
      return state
        .set('selectionValidationLoading', true)
        .set('selectionValidationError', false)
        .set('selectionValidationErrors', List());

    case VALIDATE_SELECTION__SUCCESS:
      return state.set('selectionValidationLoading', false);

    case VALIDATE_SELECTION__FAIL: {
      return state
        .set('selectionValidationLoading', false)
        .set('selectionValidationError', true);
    }

    case VALIDATE_SELECTION__ERROR: {
      return state
        .set('selectionValidationLoading', false)
        .set('selectionValidationErrors', fromJS(payload))
        .set(
          'products',
          state.get('products').map(product => {
            const productIds = Object.keys(payload);
            const productId = product.get('identifier');
            if (productIds.includes(productId)) {
              return product
                .set('valid', false)
                .set('errors', fromJS(payload[productId].errors))
                .set('lineErrors', List(payload[productId].lines));
            }

            return product;
          }),
        );
    }

    default:
      return initialState.merge(state);
  }
};

export default cartState;
