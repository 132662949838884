import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../styles/components/Core/Textarea.css';

class Textarea extends PureComponent {
  state = {
    isFocussed: false,
  };

  handleChange = event => {
    const { name, onChange } = this.props;
    const {
      target: { value },
    } = event;

    onChange(name, value);
  };

  handleBlur = event => {
    const { name, onBlur } = this.props;
    const {
      target: { value },
    } = event;

    onBlur(name, value);
    this.setState({ isFocussed: false });
  };

  handleFocus = () => {
    this.setState({ isFocussed: true });
  };

  render() {
    const {
      value,
      label,
      icon,
      iconClass,
      className,
      disabled,
      name,
      errors,
      rows,
      cols,
      required,
    } = this.props;

    const { isFocussed } = this.state;

    const textareaClasses = classNames('Textarea', {
      'Textarea--is-focussed': isFocussed,
      'Textarea--has-value': value.length > 0,
      'Textarea--disabled': disabled,
      'Textarea--has-errors': errors.length > 0,
      [className]: className,
    });

    const TextareaIcon = classNames('Textarea__icon', {
      [iconClass]: iconClass,
    });

    const iconWithClass = iconClass && <span className={TextareaIcon} />;
    const iconElement = icon && <span className={TextareaIcon}>{icon}</span>;

    return (
      <div className={textareaClasses}>
        {iconWithClass || iconElement}
        <div className="Textarea__container">
          <div htmlFor={name} className="Textarea__label">
            <span className="Textarea__label-text">
              {label}
              {required && <span className="Textarea__asterix">*</span>}
            </span>
            <textarea
              id={name}
              name={name}
              className="Textarea__input"
              disabled={disabled}
              value={value}
              rows={rows}
              cols={cols}
              required={required}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
            />
          </div>
          <div className="Textarea__errors">
            {Array.isArray(errors) ? (
              errors.map((error, index) => (
                <p key={index} className="Textarea__error">
                  {error}
                </p>
              ))
            ) : (
              <p className="Textarea__error">{errors}</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Textarea.defaultProps = {
  value: null,
  label: null,
  icon: null,
  iconClass: null,
  rows: '',
  cols: '',
  className: null,
  disabled: false,
  errors: [],
  required: false,
  onBlur: () => {},
};

Textarea.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.element,
  iconClass: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

export default Textarea;
