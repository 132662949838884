import React from 'react';

import GuideContext from '../../contexts/GuideContext';

/* eslint-disable react/display-name */
const withGuideContext = Component => props => (
  <GuideContext.Consumer>
    {context => <Component {...props} context={context} />}
  </GuideContext.Consumer>
);

export default withGuideContext;
