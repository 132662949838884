import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Accordion } from '@robinfinance/ui';

import '../../../styles/components/Layout/Footer/Corporate.css';

function FooterCorporate({ isVisible }) {
  return (
    <div className="FooterCorporate">
      <Accordion
        defaultOpen={isVisible}
        showLabel="Corporate"
        hideLabel="Corporate"
        togglePosition="top"
        toggleAlign="left"
      >
        <div className="FooterMenu__content">
          <a
            id="Footer__espace-presse"
            href="https://mieuxplacer.tech/espace-presse/"
            target="_blank"
            rel="noopener noreferrer"
            className="FooterMenu__item"
          >
            Presse
          </a>
          <Link
            id="Footer__recrutement"
            className="FooterMenu__item"
            to="/recrutement"
          >
            Recrutement
          </Link>
          <a
            id="Footer__offre-cgp"
            href="https://mieuxplacer.tech/offre-cgp/"
            target="_blank"
            rel="noopener noreferrer"
            className="FooterMenu__item"
          >
            Offre CGP
          </a>
          <a
            id="Footer__offre-asset-management"
            href="https://mieuxplacer.tech/offre-asset-manager/"
            target="_blank"
            rel="noopener noreferrer"
            className="FooterMenu__item"
          >
            Offre Asset Manager
          </a>
          <a
            id="Footer__mieuxplacer-tech"
            href="https://www.elwin.fr"
            target="_blank"
            rel="noopener noreferrer"
            className="FooterMenu__item FooterMenu__item--bold"
          >
            Elwin
          </a>
        </div>
      </Accordion>
    </div>
  );
}

FooterCorporate.defaultProps = {
  isVisible: false,
};

FooterCorporate.propTypes = {
  isVisible: PropTypes.bool,
};

export default FooterCorporate;
