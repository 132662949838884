import React from 'react';
import PropTypes from 'prop-types';

import typeShape from '../../../shapes/typeShape';
import productShape from '../../../shapes/productShape';
import supplierShape from '../../../shapes/supplierShape';

import withProductContext from '../../HOC/withProductContext';
import ProductSimilar from '../../Blocks/Product/ProductSimilar';

const PrismicSimilarProducts = ({
  data,
  context: { clickSimilarProduct, type, suppliers, products },
}) => {
  const similarProducts =
    data && data.items && data.items.length > 0
      ? data.items.map(item =>
          products.find(product => product.id === item.similar_product.id),
        )
      : [];

  if (similarProducts.length > 0) {
    return (
      <ProductSimilar
        type={type}
        suppliers={suppliers}
        similarProducts={similarProducts}
        clickSimilarProduct={clickSimilarProduct}
      />
    );
  }

  return null;
};

PrismicSimilarProducts.defaultProps = {
  data: {},
  context: {
    products: [],
    suppliers: [],
    type: {},
  },
};

PrismicSimilarProducts.propTypes = {
  data: PropTypes.shape({}),
  context: PropTypes.shape({
    clickSimilarProduct: PropTypes.func.isRequired,
    type: PropTypes.shape(typeShape),
    suppliers: PropTypes.arrayOf(PropTypes.shape(supplierShape)),
    products: PropTypes.arrayOf(PropTypes.shape(productShape)),
  }),
};

export default withProductContext(PrismicSimilarProducts);
