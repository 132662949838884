import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import Icon from '../../Core/Icon';

import arrowDropdown from '../../../assets/icons/arrow-dropdown.svg';

import '../../../styles/components/Blocks/Header/SubMenu.css';

function SubMenu({ label, to, items }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleMouseEnter() {
    setIsOpen(true);
  }

  function handleMouseLeave() {
    setIsOpen(false);
  }

  const classes = classNames('SubMenu', {
    'SubMenu--is-open': isOpen,
  });

  return (
    <div className={classes}>
      <NavLink
        to={to}
        className="SubMenu__link SubMenu__link--main"
        activeClassName="SubMenu__link--active"
        onClick={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {label}&nbsp;
        <Icon
          className="SubMenu__link-icon"
          src={arrowDropdown}
          alt="Submenu arrow"
          height="4px"
          width="8px"
        />
      </NavLink>
      <div
        className="SubMenu__submenu"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="SubMenu__container">
          {items.map(item => (
            <NavLink
              exact
              key={item.id}
              to={item.to}
              className="SubMenu__link"
              activeClassName="SubMenu__link--active"
              onClick={handleMouseLeave}
            >
              {item.label}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
}

SubMenu.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      to: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default SubMenu;
