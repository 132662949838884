import { put, call, takeLatest } from 'redux-saga/effects';

import * as TrustpilotAPI from '../api/Trustpilot';

import {
  FETCH_TRUSTPILOT__REQUEST,
  FETCH_TRUSTPILOT__SUCCESS,
  FETCH_TRUSTPILOT__FAIL,
} from '../actions/TrustpilotActions';

function* fetchTrustpilot() {
  try {
    const data = yield call(TrustpilotAPI.fetchTrustpilot);

    yield put({
      type: FETCH_TRUSTPILOT__SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_TRUSTPILOT__FAIL,
      payload: e.message,
    });
  }
}

const trustpilotSagas = [
  takeLatest(FETCH_TRUSTPILOT__REQUEST, fetchTrustpilot),
];

export default trustpilotSagas;
