export const AUTH_MODAL_TYPES = {
  LOGIN_FORM: 'LOGIN_FORM',
  REGISTER_FORM: 'REGISTER_FORM',
  FORGOT_PASSWORD_FORM: 'FORGOT_PASSWORD_FORM',
};

export const EMPTY_ERROR_MESSAGES = {
  email:
    'Votre adresse e-mail est nécessaire pour vous connecter à votre espace personnalisé.',
  password:
    'Votre mot de passe est nécessaire pour vous connecter à votre espace personnalisé.',
  phoneNumber:
    'Votre numéro de télephone est nécessaire pour vous connecter à votre espace personnalisé.',
  firstName:
    'Votre prénom est nécessaire pour vous connecter à votre espace personnalisé.',
  lastName:
    'Votre nom est nécessaire pour vous connecter à votre espace personnalisé.',
};

export const INCORRECT_ERROR_MESSAGES = {
  email: 'Votre adresse e-mail semble incorrecte, pouvez-vous vérifier ?',
  password: 'Votre mot de passe semble incorrect, pouvez-vous vérifier ?',
  phoneNumber:
    'Votre numéro de télephone semble incorrect, pouvez-vous vérifier ?',
};
