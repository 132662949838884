import React from 'react';
import PropTypes from 'prop-types';

import '../../../../styles/components/Blocks/Recommendation/Core/FeesTable.css';

function FeesTable({ title, items }) {
  return (
    <div className="FeesTable">
      <div className="FeesTable__title-container">
        <p className="FeesTable__title">{title}</p>
      </div>
      <div className="FeesTable__items">
        {items.map((item, index) => (
          <div className="FeesTable__item" key={index}>
            <div className="FeesTable__label">{item.label}</div>
            <div className="FeesTable__value">{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

FeesTable.defaultProps = {
  items: [],
};

FeesTable.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
};

export default FeesTable;
