import React from 'react';

import LoadingGradient from '../../../Core/LoadingGradient';

const ProductCardLoading = () => (
  <div className="ProductCard ProductCard--loading">
    <div className="ProductCard__header">
      <LoadingGradient
        width="132px"
        height="132px"
        className="ProductCard__image"
      />
      <LoadingGradient width="100px" height="30px" />
      <LoadingGradient
        width="65%"
        height="35px"
        className="ProductCard__title"
      />
    </div>
    <div className="ProductCard__content">
      <LoadingGradient height="20px" className="ProductCard__paragraph" />
      <LoadingGradient height="20px" className="ProductCard__paragraph" />
      <LoadingGradient
        width="50%"
        height="20px"
        className="ProductCard__paragraph"
      />
    </div>
    <div className="ProductCard__footer">
      <LoadingGradient width="35%" height="15px" />
    </div>
  </div>
);

export default ProductCardLoading;
