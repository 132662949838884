import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import qs from 'qs';

import typeShape from '../../../shapes/typeShape';

import withProductContext from '../../HOC/withProductContext';

const ProductTypeBreadcrumb = props => {
  const {
    context: { type },
    match: { params },
  } = props;

  if (type) {
    const query = qs.stringify(
      { type: [type.id] },
      { arrayFormat: 'brackets' },
    );

    return <NavLink to={`/produits?${query}`}>{type.label}</NavLink>;
  }

  return <span className="Breadcrumbs__item">{params.type}</span>;
};

ProductTypeBreadcrumb.defaultProps = {
  context: {},
};

ProductTypeBreadcrumb.propTypes = {
  context: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.shape(typeShape)),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({ type: PropTypes.string }),
  }).isRequired,
};

export default withProductContext(ProductTypeBreadcrumb);
