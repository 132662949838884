import PropTypes from 'prop-types';

import { imageShape } from './prismicShape';

const objectiveShape = {
  id: PropTypes.string,
  slug: PropTypes.string,
  contentType: PropTypes.string,
  gaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  label: PropTypes.string,
  shortLabel: PropTypes.string,
  icon: PropTypes.shape(imageShape),
  homeIcon: PropTypes.shape(imageShape),
  showInHeader: PropTypes.bool,
  showOnHome: PropTypes.bool,
  blocks: PropTypes.arrayOf(PropTypes.shape({})),
  answerId: PropTypes.string,
};

export default objectiveShape;
