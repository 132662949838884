import { BASE_API_URL } from '../config/api';
import Mieuxplacer from './Mieuxplacer';

export const sendContactEmail = async data => {
  const baseUrl = Mieuxplacer.Api.getBaseUrl();

  const response = await fetch(`${baseUrl}/email/sendContactFormEmail`, {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const sendLeadEmail = async data => {
  const url = new URL(`${BASE_API_URL}/2.0/email/lead`);

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  }

  const res = await response.json();

  throw new Error(JSON.stringify(res.errors));
};

export const sendCallBackEmail = async data => {
  const url = new URL(`${BASE_API_URL}/2.0/email/call-back`);

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  }

  const res = await response.json();

  throw new Error(JSON.stringify(res.errors));
};

export const sendCallBackProductEmail = async data => {
  const url = new URL(`${BASE_API_URL}/2.0/email/call-back-product`);

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  }

  const res = await response.json();

  throw new Error(JSON.stringify(res.errors));
};

export const sendTestingEmail = async data => {
  const url = new URL(`${BASE_API_URL}/2.0/email/testing`);

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  }

  const res = await response.json();

  throw new Error(JSON.stringify(res.errors));
};
