import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { contentShape } from '../../../shapes/prismicShape';

import RichText from '../../Core/RichText';
import PrismicBlockTitle from '../Core/PrismicBlockTitle';

import '../../../styles/components/Prismic/PrismicContent.css';

class PrismicContent extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicContent">
        {data.primary.content_title && data.primary.content_title.length > 0 && (
          <div className="PrismicContent__title">
            <PrismicBlockTitle title={data.primary.content_title} />
          </div>
        )}

        {data.primary.content_content &&
          data.primary.content_content.length > 0 && (
            <div className="PrismicContent__content">
              <RichText paragraphs={data.primary.content_content} />
            </div>
          )}
      </div>
    );
  }
}

PrismicContent.propTypes = {
  data: PropTypes.shape(contentShape).isRequired,
};

export default PrismicContent;
