import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Phone, ArrowRight } from '@robinfinance/ui';

import { RISK_PROFILES, INVESTOR_TYPOLOGIES } from '../../../config/product';

import productShape from '../../../shapes/productShape';
import objectiveShape from '../../../shapes/objectiveShape';

import Tooltip from '../../Core/Tooltip';
import ProgressBar from './ProgressBar';
import ProductAddSelection from './ProductAddSelection';
import ProductProfileLoading from './Loading/ProductProfileLoading';

import '../../../styles/components/Blocks/Product/ProductProfile.css';

class ProductProfile extends PureComponent {
  handleScrollToCallBackForm = () => {
    const target = document.querySelector('.PrismicCallBackForm');

    if (target) {
      target.parentNode.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  renderObjectives = () => {
    const { objectives } = this.props;

    if (objectives && objectives.length > 0) {
      return (
        <div className="ProductProfile__objectives">
          <div className="ProductProfile__objectives-title">Votre Projet</div>
          <div className="ProductProfile__objectives-items">
            {objectives.map(objective => (
              <div
                key={objective.id}
                className="ProductProfile__objectives-item"
              >
                {objective.icon && objective.icon.url && (
                  <div className="ProductProfile__objectives-item-image">
                    <img src={objective.icon.url} alt={objective.icon.alt} />
                  </div>
                )}
                <p className="ProductProfile__objectives-item-label">
                  {objective.label}
                </p>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  render() {
    const { product, loading, showPopup } = this.props;

    if (loading) {
      return <ProductProfileLoading />;
    }

    if (!product) {
      return null;
    }

    return (
      <div className="ProductProfile">
        <h2 className="ProductProfile__title">
          A qui s&#39;adresse ce produit ?
        </h2>
        <div className="ProductProfile__content">
          <div className="ProductProfile__columns">
            <div className="ProductProfile__risk-profiles">
              <div className="ProductProfile__progress-bar-title">
                Attitude face au risque
                <Tooltip
                  className="ProductProfile__progress-bar-tooltip"
                  title="Types d'investisseurs à qui s'adresse ce produit en termes de risque."
                />
              </div>
              <ProgressBar
                labels={RISK_PROFILES}
                definedLabels={product.riskProfiles}
              />
            </div>
            <div className="ProductProfile__investor-typologies">
              <div className="ProductProfile__progress-bar-title">
                Profil d&#39;investisseur
                <Tooltip
                  className="ProductProfile__progress-bar-tooltip"
                  title="Types d'investisseurs à qui s'adresse ce produit en termes d'expérience en placements financiers."
                />
              </div>
              <ProgressBar
                labels={INVESTOR_TYPOLOGIES}
                definedLabels={product.investorTypologies}
              />
            </div>
          </div>

          {this.renderObjectives()}

          <div className="ProductProfile__actions">
            {product.subscribable ? (
              <Fragment>
                {product.availableForSubscription && (
                  <ProductAddSelection className="ProductProfile__action ProductProfile__action--hide-on-mobile" />
                )}
                {product.calandlyLabel &&
                  document.querySelector('.PrismicCallBackForm') && (
                    <Button
                      scope="button"
                      type="ghost"
                      iconSide="left"
                      icon={<Phone color="#ffc501" />}
                      onClick={this.handleScrollToCallBackForm}
                      className="ProductProfile__actions-button"
                    >
                      {product.calandlyLabel}
                    </Button>
                  )}
              </Fragment>
            ) : (
              <Button
                scope="button"
                type="primary"
                iconSide="right"
                icon={<ArrowRight color="#ffc501" width="19" height="14" />}
                onClick={showPopup}
              >
                Être recontacté(e)
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ProductProfile.defaultProps = {
  product: null,
  objectives: [],
  loading: false,
  showPopup: () => {},
};

ProductProfile.propTypes = {
  product: PropTypes.shape(productShape),
  objectives: PropTypes.arrayOf(PropTypes.shape(objectiveShape)),
  loading: PropTypes.bool,
  showPopup: PropTypes.func,
};

export default ProductProfile;
