import React from 'react';

import LoadingGradient from '../../../Core/LoadingGradient';

const ProductListingLoading = () => (
  <div className="ProductListing ProductListing--loading">
    <div className="ProductListing__container">
      <LoadingGradient
        width="115px"
        height="115px"
        className="ProductListing__logo"
      />
      <div className="ProductListing__content">
        <div className="ProductListing__title">
          <LoadingGradient width="200px" height="30px" />
        </div>
        <div className="ProductListing__features">
          {[1, 2, 3, 4, 5].map(key => (
            <div
              className="ProductListing__feature"
              style={{ width: 'calc(18% - 30px)' }}
              key={key}
            >
              <LoadingGradient
                className="ProductListing__feature-title"
                width="75%"
                height="15px"
              />
              <LoadingGradient width="50%" height="20px" />
            </div>
          ))}
          <div className="ProductListing__feature" style={{ width: '10%' }}>
            <LoadingGradient width="45px" height="45px" />
          </div>
        </div>
        <div className="ProductListing__features">
          <div className="ProductListing_riskBar">
            <LoadingGradient
              className="ProductListing__feature-title"
              width="60px"
              height="15px"
            />
            <LoadingGradient width="250px" height="30px" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProductListingLoading;
