import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { filesShape } from '../../../shapes/prismicShape';

import FileDownload from '../../Core/FileDownload';

import '../../../styles/components/Prismic/PrismicFiles.css';

class PrismicFiles extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicFiles">
        {data.items.map((item, index) => (
          <div key={index} className="PrismicFiles__item">
            <FileDownload
              title={item.files_file_name}
              url={item.files_file_link && item.files_file_link.url}
            />
          </div>
        ))}
      </div>
    );
  }
}

PrismicFiles.propTypes = {
  data: PropTypes.shape(filesShape).isRequired,
};

export default PrismicFiles;
