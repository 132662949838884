import PropTypes from 'prop-types';

const trustpilotRewiewsShape = {
  fiveStars: PropTypes.number,
  fourStars: PropTypes.number,
  oneStar: PropTypes.number,
  threeStars: PropTypes.number,
  total: PropTypes.number,
  twoStars: PropTypes.number,
};

const trustpilotbusinessUnitShape = {
  displayName: PropTypes.string,
  newBrand: PropTypes.bool,
  stars: PropTypes.number,
  trustScore: PropTypes.number,
  websiteUrl: PropTypes.string,
  numberOfReviews: PropTypes.shape(trustpilotRewiewsShape),
};

const trustpilotLinksShape = {
  evaluateEmbedUrl: PropTypes.string,
  evaluateUrl: PropTypes.string,
  profileUrl: PropTypes.string,
};

const trustpilotShape = {
  businessUnit: PropTypes.shape(trustpilotbusinessUnitShape),
  reviews: PropTypes.arrayOf(PropTypes.shape({})),
  links: PropTypes.shape(trustpilotLinksShape),
  starsString: PropTypes.string,
};

export default trustpilotShape;
