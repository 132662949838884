import React from 'react';
import PropTypes from 'prop-types';

import routerShape from '../../shapes/routerShape';

import { Routes } from '../../routes';

import '../../styles/components/Layout/Content.css';

const Content = ({ location }) => (
  <main className="Content">
    <Routes location={location} />
  </main>
);

Content.propTypes = {
  location: PropTypes.shape(routerShape).isRequired,
};

export default Content;
