import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../styles/components/Core/Modal.css';

class Modal extends PureComponent {
  state = {
    isHidden: true,
  };

  componentDidMount() {
    const { isOpen } = this.props;

    if (isOpen) {
      document.documentElement.classList.add('no-scroll');
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      document.documentElement.classList.add('no-scroll');

      this.setHidden(false);
    }

    if (prevProps.isOpen && !isOpen) {
      document.documentElement.classList.remove('no-scroll');

      setTimeout(() => {
        this.setHidden(true);
      }, 300);
    }
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('no-scroll');
  }

  setHidden = hidden => {
    this.setState({ isHidden: hidden });
  };

  handleCloseModal = () => {
    const { onClose, isBlockingType } = this.props;

    if (!isBlockingType) {
      onClose();
    }
  };

  render() {
    const { isOpen, children, isBlockingType } = this.props;
    const { isHidden } = this.state;

    const containerClasses = classNames('Modal', {
      'Modal--is-visible': isOpen,
      'Modal--is-hidden': isHidden && !isOpen,
      'Modal--is-blocking': isBlockingType,
    });

    return (
      <div className={containerClasses}>
        <span
          className="Modal__mask"
          role="presentation"
          onClick={this.handleCloseModal}
        />
        <div className="Modal__container">{children}</div>
      </div>
    );
  }
}

Modal.defaultProps = {
  isBlockingType: false,
  onClose: () => {},
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func,
  isBlockingType: PropTypes.bool,
};

export default Modal;
