import { Map, List, fromJS } from 'immutable';

import {
  FETCH_TYPES__REQUEST,
  FETCH_TYPES__SUCCESS,
  FETCH_TYPES__FAIL,
} from '../actions/TypeActions';

const initialState = Map({
  typesLoading: false,
  typesError: false,
  types: List([]),
});

const typeState = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TYPES__REQUEST:
      return state.set('typesLoading', true).set('typesError', false);

    case FETCH_TYPES__SUCCESS:
      return state.set('typesLoading', false).set('types', fromJS(payload));

    case FETCH_TYPES__FAIL:
      return state.set('typesLoading', false).set('typesError', true);

    default:
      return initialState.merge(state);
  }
};

export default typeState;
