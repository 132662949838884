import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import question from '../../../assets/icons/question.svg';
import dot from '../../../assets/icons/dot.svg';

import '../../../styles/components/Blocks/Simulation/SimulationTitle.css';

const SimulationTitle = ({ isMobile }) => {
  const titleContainerClasses = classNames('SimulationTitle', {
    'SimulationTitle--is-mobile': isMobile,
  });

  return (
    <div className={titleContainerClasses}>
      <h3 className="SimulationTitle__title">
        Combien vais-je gagner
        <div className="SimulationTitle__question-icon">
          <img src={question} alt="question" />
          <img src={dot} alt="dot" />
        </div>
      </h3>
    </div>
  );
};

SimulationTitle.defaultProps = {
  isMobile: false,
};

SimulationTitle.propTypes = {
  isMobile: PropTypes.bool,
};

export default SimulationTitle;
