import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Button } from '@robinfinance/ui';

import productShape from '../shapes/productShape';
import supplierShape from '../shapes/supplierShape';
import typeShape from '../shapes/typeShape';
import objectiveShape from '../shapes/objectiveShape';
import routerShape from '../shapes/routerShape';
import routesConfig from '../config/pages';

import {
  openMenu,
  closeMenu,
  openProductsSubmenu,
  closeProductsSubmenu,
  openAboutSubmenu,
  closeAboutSubmenu,
} from '../actions/AppActions';
import {
  removeProductFromCart,
  openCart,
  closeCart,
} from '../actions/CartActions';
import { setOnboardingStartingPoint } from '../actions/OnboardingActions';

import HeaderContext from '../contexts/HeaderContext';

import toJS from '../components/HOC/toJS';
import Link from '../components/Core/Link';
import HeaderLink from '../components/Blocks/Header/HeaderLink';
import DropDownMenu from '../components/Blocks/Header/DropDownMenu';
import Cartheader from '../components/Blocks/Cart/CartHeader';
import MobileMenu from '../components/Blocks/Header/MobileMenu';

import logo from '../assets/images/logo-mieuxplacer.svg';

import '../styles/containers/Header.css';

function Header({
  isAuthenticated,
  products,
  productsLoading,
  types,
  typesLoading,
  objectives,
  objectivesLoading,
  suppliers,
  cartProducts,
  location,
  removeProductFromCart,
  openCart,
  closeCart,
  openMenu,
  closeMenu,
  openProductsSubmenu,
  closeProductsSubmenu,
  openAboutSubmenu,
  closeAboutSubmenu,
  setOnboardingStartingPoint,
}) {
  const [cartIsOpen, setCartIsOpen] = useState(false);

  function handleCartClick() {
    if (cartIsOpen) {
      closeCart();
    } else {
      openCart();
    }

    setCartIsOpen(oldCartState => !oldCartState);
  }

  return (
    <header className="Header">
      <HeaderContext.Provider
        value={{
          isAuthenticated,
          products,
          productsLoading,
          types,
          typesLoading,
          objectives,
          objectivesLoading,
          suppliers,
          cartProducts,
          removeProductFromCart,
          setOnboardingStartingPoint,
        }}
      >
        <div className="Header__main">
          <div className="Header__logo-menu">
            <div className="Header__logo">
              <Link to="/">
                <img src={logo} alt="Logo Mieuxplacer" />
              </Link>
            </div>
            <div className="Header__menu">
              {routesConfig.pages.map(page => {
                if (page.subRoutes) {
                  return (
                    <DropDownMenu
                      key={page.id}
                      label={page.label}
                      to={page.to}
                      items={page.subRoutes}
                    />
                  );
                }

                return (
                  <HeaderLink key={page.id} to={page.to} label={page.label} />
                );
              })}
              <Button
                scope="link"
                type="secondary"
                to="/qui-sommes-nous/contactez-nous"
              >
                Contact
              </Button>
            </div>
            <div className="Header__mobile-menu">
              <MobileMenu
                location={location}
                openCart={openCart}
                closeCart={closeCart}
                openMenu={openMenu}
                closeMenu={closeMenu}
                openProductsSubmenu={openProductsSubmenu}
                closeProductsSubmenu={closeProductsSubmenu}
                openAboutSubmenu={openAboutSubmenu}
                closeAboutSubmenu={closeAboutSubmenu}
              />
            </div>
          </div>
          <div className="Header__actions">
            <div className="Header__cart">
              <Cartheader isOpen={cartIsOpen} onToggle={handleCartClick} />
            </div>
          </div>
        </div>
      </HeaderContext.Provider>
    </header>
  );
}

Header.defaultProps = {
  isAuthenticated: null,
  products: [],
  types: [],
  objectives: [],
  suppliers: [],
  cartProducts: [],
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool,
  suppliers: PropTypes.arrayOf(PropTypes.shape(supplierShape)),
  products: PropTypes.arrayOf(PropTypes.shape(productShape)),
  productsLoading: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape(typeShape)),
  typesLoading: PropTypes.bool.isRequired,
  objectives: PropTypes.arrayOf(PropTypes.shape(objectiveShape)),
  objectivesLoading: PropTypes.bool.isRequired,
  cartProducts: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, identifier: PropTypes.string }),
  ),
  location: PropTypes.shape(routerShape).isRequired,
  removeProductFromCart: PropTypes.func.isRequired,
  openCart: PropTypes.func.isRequired,
  closeCart: PropTypes.func.isRequired,
  openMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  openProductsSubmenu: PropTypes.func.isRequired,
  closeProductsSubmenu: PropTypes.func.isRequired,
  openAboutSubmenu: PropTypes.func.isRequired,
  closeAboutSubmenu: PropTypes.func.isRequired,
  setOnboardingStartingPoint: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.get('userState').get('isAuthenticated'),
  products: state.get('productState').get('products'),
  productsLoading: state.get('productState').get('productsLoading'),
  types: state.get('typeState').get('types'),
  typesLoading: state.get('typeState').get('typesLoading'),
  objectives: state.get('objectiveState').get('objectives'),
  objectivesLoading: state.get('objectiveState').get('objectivesLoading'),
  suppliers: state.get('supplierState').get('suppliers'),
  cartProducts: state.get('cartState').get('products'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeProductFromCart,
      openCart,
      closeCart,
      openMenu,
      closeMenu,
      openProductsSubmenu,
      closeProductsSubmenu,
      openAboutSubmenu,
      closeAboutSubmenu,
      setOnboardingStartingPoint,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(toJS(Header)),
);
