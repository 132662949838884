import React from 'react';

import LoadingGradient from '../../../Core/LoadingGradient';

const ProductDetailsLoading = () => (
  <div className="ProductDetails ProductDetails--loading">
    <div className="ProductDetails__features">
      {[1, 2, 3, 4].map(key => (
        <div key={key} className="ProductDetails__feature">
          <LoadingGradient
            className="ProductDetails__feature-title"
            width="175px"
            height="24px"
          />
          <LoadingGradient
            className="ProductDetails__feature-value"
            width="100px"
            height="30px"
          />
        </div>
      ))}
      <div className="ProductDetails__feature ProductDetails__feature--column">
        <LoadingGradient
          className="ProductDetails__feature-title"
          width="175px"
          height="24px"
        />
        <div className="ProductDetails__objectives">
          {[1, 2, 3].map(key => (
            <div key={key} className="ProductDetails__objective">
              <LoadingGradient
                className="ProductDetails__objective-image"
                width="32px"
                height="32px"
              />
              <LoadingGradient
                className="ProductDetails__objective-label"
                width="200px"
                height="24px"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="ProductDetails__actions">
      <LoadingGradient
        className="ProductDetails__action"
        width="100%"
        height="46px"
      />
    </div>
  </div>
);

export default ProductDetailsLoading;
