import { LAUNCH_CONVERSION_LINKER } from '../actions/OnboardingActions';

const launchConversionLinker = () => ({
  event: 'Launch conversion linker',
});

const recommendationEventsMap = {
  [LAUNCH_CONVERSION_LINKER]: launchConversionLinker,
};

export default recommendationEventsMap;
