import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import withProductsContext from '../../HOC/withProductsContext';

import '../../../styles/components/Blocks/Filter/FilterTags.css';

import {
  INVEST_PERIODS,
  INVEST_PERIOD_FILTER_KEY,
  MINIMUM_INVESTMENT,
  MINIMUM_INVESTMENT_FILTER_KEY,
  TYPE_FILTER_KEY,
  OBJECTIVE_FILTER_KEY,
} from '../../../config/product';

class FilterTags extends PureComponent {
  tags = [];

  filterMinimumInvestment = filters => {
    const minimumInvestmentTags = [];
    MINIMUM_INVESTMENT.forEach(({ id, value, label }) => {
      if (id === filters[MINIMUM_INVESTMENT_FILTER_KEY].id && value !== 'all') {
        minimumInvestmentTags.push({
          ...filters[MINIMUM_INVESTMENT_FILTER_KEY],
          label,
          type: MINIMUM_INVESTMENT_FILTER_KEY,
        });
      }
    });
    return minimumInvestmentTags;
  };

  filterInvestPeriod = filters => {
    const investPeriodTag = [];
    INVEST_PERIODS.forEach(({ id, value, label }) => {
      if (id === filters[INVEST_PERIOD_FILTER_KEY].id && value !== 'all') {
        investPeriodTag.push({
          ...filters[INVEST_PERIOD_FILTER_KEY],
          label,
          type: INVEST_PERIOD_FILTER_KEY,
        });
      }
    });
    return investPeriodTag;
  };

  filterTypes = (filters, types) => {
    const typeTags = [];
    types.forEach(({ id, label }) => {
      filters[TYPE_FILTER_KEY].forEach(filter => {
        if (id === filter.id) {
          typeTags.push({ ...filter, label, type: TYPE_FILTER_KEY });
        }
      });
    });
    return typeTags;
  };

  filterObjectives = (filters, objectives) => {
    const objectiveTags = [];
    objectives.forEach(({ id, label }) => {
      if (id === filters[OBJECTIVE_FILTER_KEY].id) {
        objectiveTags.push({
          ...filters[OBJECTIVE_FILTER_KEY],
          label,
          type: OBJECTIVE_FILTER_KEY,
        });
      }
    });
    return objectiveTags;
  };

  handleRemove = (type, id, value) => {
    const { context } = this.props;
    const { handleSetFilterChange, handleAddFilterChange } = context;
    if (type === 'type') {
      handleAddFilterChange(type, id, value, false);
    } else {
      handleSetFilterChange(type, `all-${type}s`, 'all');
    }
  };

  render() {
    const { context } = this.props;
    const { filters, objectives, types } = context;
    const objectiveTags = this.filterObjectives(filters, objectives);
    const typeTags = this.filterTypes(filters, types);
    const investPeriodTags = this.filterInvestPeriod(filters);
    const minimumInvestmentTags = this.filterMinimumInvestment(filters);

    const allTags = [
      ...typeTags,
      ...objectiveTags,
      ...investPeriodTags,
      ...minimumInvestmentTags,
    ];

    return (
      <div className="FilterTags">
        <div className="FilterTags__background" />
        <TransitionGroup component={null}>
          {allTags.map(({ id, label, type, value }) => (
            <CSSTransition key={id} classNames="scale-from-left" timeout={0}>
              <div className="FilterTags__tag" key={id}>
                <span className="FilterTags__tag-label">{label}</span>
                <button
                  type="button"
                  className="FilterTags__remove-button"
                  onClick={() => this.handleRemove(type, id, value)}
                  onKeyUp={() => this.handleRemove(type, id, value)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 18 18"
                  >
                    <path
                      fill="#223b5d"
                      fillRule="evenodd"
                      d="M17.516 14.683c.72.844.622 2.112-.218 2.833a2.013 2.013 0 0 1-2.614 0L8.999 11.83l-5.684 5.685a2.01 2.01 0 0 1-2.835-.219 2.02 2.02 0 0 1 0-2.614L6.167 9 .48 3.314A2.009 2.009 0 1 1 3.315.482l5.684 5.685L14.684.482A2.011 2.011 0 0 1 17.516.7a2.006 2.006 0 0 1 0 2.614L11.83 9l5.685 5.684z"
                    />
                  </svg>
                </button>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    );
  }
}

FilterTags.propTypes = {
  context: PropTypes.shape({
    handleSetFilterChange: PropTypes.func.isRequired,
    handleAddFilterChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({}),
    objectives: PropTypes.arrayOf(PropTypes.shape({})),
    types: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default withProductsContext(FilterTags);
