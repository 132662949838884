import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { textImageShape } from '../../../shapes/prismicShape';

import RichText from '../../Core/RichText';
import Icon from '../../Core/Icon';
import PrismicBlockTitle from '../Core/PrismicBlockTitle';
import PrismicLink from '../Core/PrismicLink';

import arrow from '../../../assets/icons/arrow-illustration.svg';

import '../../../styles/components/Prismic/PrismicTextImage.css';

class PrismicTextImage extends PureComponent {
  render() {
    const { data } = this.props;

    const containerClass = classNames('PrismicTextImage', {
      'PrismicTextImage--inversed': data.primary.text_image_order === 'Gauche',
      'PrismicTextImage--is-header': data.primary.is_page_header === 'Oui',
    });

    return (
      <div className={containerClass}>
        <div className="PrismicTextImage__content-container">
          <PrismicBlockTitle
            title={data.primary.text_image_title}
            preTitle={data.primary.text_image_pre_title}
            color={data.primary.text_image_color}
          />
          {data.primary.text_image_description_heading &&
            data.primary.text_image_description_heading.length > 0 &&
            data.primary.text_image_description_heading[0].text !== '' && (
              <div className="PrismicTextImage__description-heading">
                <RichText
                  paragraphs={data.primary.text_image_description_heading}
                />
              </div>
            )}
          {data.primary.text_image_description &&
            data.primary.text_image_description.length > 0 && (
              <div className="PrismicTextImage__description">
                <RichText paragraphs={data.primary.text_image_description} />
              </div>
            )}
          {data.primary.text_image_button_label &&
            data.primary.text_image_button_url && (
              <PrismicLink
                className="PrismicTextImage__link"
                button
                link={data.primary.text_image_button_url}
                label={data.primary.text_image_button_label}
              />
            )}
          {data.primary.text_image_annotation && (
            <div className="PrismicTextImage__annotation">
              <Icon src={arrow} alt="icon arrow" height="34px" width="14px" />
              <div className="PrismicTextImage__annotation-text">
                {data.primary.text_image_annotation}
              </div>
            </div>
          )}
        </div>
        <div className="PrismicTextImage__image-container">
          {data.primary.text_image_image &&
            data.primary.text_image_image.url && (
              <img
                className="PrismicTextImage__image"
                src={data.primary.text_image_image.url}
                alt="icon home"
                width={
                  data.primary.text_image_image.dimensions &&
                  data.primary.text_image_image.dimensions.width
                }
              />
            )}
        </div>
      </div>
    );
  }
}

PrismicTextImage.propTypes = {
  data: PropTypes.shape(textImageShape).isRequired,
};

export default PrismicTextImage;
