import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { multiblocksiconsShape } from '../../../shapes/prismicShape';

import Icon from '../../Core/Icon';
import Link from '../../Core/Link';
import RichText from '../../Core/RichText';

import arrowlink from '../../../assets/icons/arrow.svg';

import '../../../styles/components/Prismic/PrismicMultipleBlocks.css';

class PrismicMultipleBlocks extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicMultipleBlocks">
        <div className="PrismicMultipleBlocks__title">
          <RichText paragraphs={data.primary.multi_blocks_title} />
        </div>
        <div className="PrismicMultipleBlocks__blocks">
          {data.items.map((item, index) => (
            <div key={index} className="PrismicMultipleBlocks__block">
              <div className="PrismicMultipleBlocks__image">
                {item.multi_blocks_block_icon &&
                  item.multi_blocks_block_icon.url && (
                    <img
                      src={item.multi_blocks_block_icon.url}
                      alt={item.multi_blocks_block_icon.alt}
                      width={
                        item.multi_blocks_block_icon.dimensions &&
                        item.multi_blocks_block_icon.dimensions.width
                      }
                    />
                  )}
              </div>
              <h3 className="PrismicMultipleBlocks__block-title">
                {item.multi_blocks_block_title}
              </h3>
              <div className="PrismicMultipleBlocks__block-decription">
                <RichText paragraphs={item.multi_blocks_block_description} />
              </div>
              {item.multi_blocks_block_url_title &&
                item.multi_blocks_block_url &&
                item.multi_blocks_block_url.url && (
                  <Link
                    href={item.multi_blocks_block_url.url}
                    target={item.multi_blocks_block_url.target}
                    className="PrismicMultipleBlocks__block-link"
                  >
                    <Fragment>
                      {item.multi_blocks_block_url_title}
                      <Icon
                        src={arrowlink}
                        alt="lien"
                        height="14px"
                        width="19px"
                      />
                    </Fragment>
                  </Link>
                )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

PrismicMultipleBlocks.propTypes = {
  data: PropTypes.shape(multiblocksiconsShape).isRequired,
};

export default PrismicMultipleBlocks;
