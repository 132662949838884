import { Map, List, fromJS } from 'immutable';

const initialCartState = Map({
  products: List([]),
});

const initialAppState = Map({
  visits: List([]),
});

const initialUserState = Map({
  authorizationToken: undefined,
});

export const loadState = () => {
  try {
    const cartProducts = JSON.parse(localStorage.getItem('products'));
    const userVisits = JSON.parse(localStorage.getItem('visits'));
    const authorizationToken =
      localStorage.getItem('authorizationToken') || undefined;

    let cartState = {};
    let appState = {};
    let userState = {};

    if (authorizationToken) {
      userState = initialUserState
        .set('authorizationToken', authorizationToken)
        .set('isAuthenticated', true);
    } else {
      userState = initialUserState.set('isAuthenticated', false);
    }

    if (cartProducts && cartProducts.length > 0) {
      cartState = initialCartState.set('products', List(fromJS(cartProducts)));
    }

    if (userVisits && userVisits.length > 0) {
      appState = initialAppState.set('visits', List(fromJS(userVisits)));
    }

    return {
      cartState,
      appState,
      userState,
    };
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);

    return {};
  }
};

export const saveState = state => {
  try {
    const authorizationToken = state.get('userState').get('authorizationToken');

    if (authorizationToken) {
      localStorage.setItem('authorizationToken', authorizationToken);
    } else {
      localStorage.removeItem('authorizationToken');
    }

    const cartProducts = state.get('cartState').get('products').toJS();

    localStorage.setItem('products', JSON.stringify(cartProducts));

    const userVisits = state.get('appState').get('visits').toJS();

    localStorage.setItem('visits', JSON.stringify(userVisits));
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  }
};
