import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';

import '../../styles/components/Core/Link.css';

const Link = ({ to, href, target, animated, className, onClick, children }) => {
  const linkClass = classNames('Link', {
    'Link--animated': animated,
    [className]: className,
  });

  if (href) {
    return (
      <a
        className={linkClass}
        href={href}
        target={target}
        rel={target ? 'noopener noreferrer' : null}
      >
        {children}
      </a>
    );
  }

  return (
    <RouterLink className={linkClass} to={to} onClick={onClick}>
      {children}
    </RouterLink>
  );
};

Link.defaultProps = {
  to: null,
  href: null,
  target: '_self',
  animated: false,
  className: '',
  onClick: null,
};

Link.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.oneOf(['_self', '_blank']),
  animated: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default Link;
