import PropTypes from 'prop-types';

import { textImageShape, smallBlockListShape } from './prismicShape';

const pageShape = {
  id: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  blocks: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(textImageShape),
      PropTypes.shape(smallBlockListShape),
    ]),
  ),
};

export default pageShape;
