import { API } from '@robinfinance/js-api';

import { MIDDLEWARE_API_ENVIRONMENT } from '../config/api';

class MieuxplacerAPI {
  static instance;

  constructor() {
    if (this.instance) {
      return this.instance;
    }

    this.Api = new API({
      app: 'mieuxplacer',
      universe: 'MIEUXPLACER',
      environment: MIDDLEWARE_API_ENVIRONMENT,
    });
    this.instance = this;
  }
}

export default new MieuxplacerAPI();
