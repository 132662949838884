import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Core/Icon';

import { multiblocksiconsShape } from '../../../shapes/prismicShape';
import '../../../styles/components/Prismic/PrismicFourBlocks.css';

class PrismicFourBlocks extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicFourBlocks">
        <div className="PrismicMultiBlocksicon_title">
          <div>{data.primary.four_blocks_title[0].text}</div>
          <div className="PrismicMultiBlocksicon_title_mp">
            <div>{data.primary.four_blocks_title[1].text}</div>
            <Icon
              src={data.primary.four_blocks_title[2].url}
              alt="dot"
              height="10px"
              width="10px"
            />
            <div>{data.primary.four_blocks_title[3].text}</div>
          </div>
        </div>
        <div className="PrismicFourBlocks_blocks">
          <div className="PrismicFourBlocks_blocks_items">
            {data.items.map(item => (
              <div
                key={item.four_blocks_label_left[0].text}
                className="PrismicFourBlocks_items"
              >
                <Icon
                  src={item.four_blocks_icon_left.url}
                  alt="icon-left"
                  height="77px"
                  width="88px"
                />
                <div className="PrismicFourBlocks_item">
                  <div className="PrismicFourBlocks_item_label">
                    {item.four_blocks_label_left[0].text}
                  </div>
                  <div className="PrismicFourBlocks_item_text">
                    {item.four_blocks_text_left[0].text}
                  </div>
                </div>
                <Icon
                  src={item.four_blocks_icon_right.url}
                  alt="icon-right"
                  height="77px"
                  width="88px"
                />
                <div className="PrismicFourBlocks_item">
                  <div className="PrismicFourBlocks_item_label">
                    {item.four_blocks_label_right[0].text}
                  </div>
                  <div className="PrismicFourBlocks_item_text">
                    {item.four_blocks_text_right[0].text}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <a
            href={data.primary.four_blocks_link_more_info[0].text}
            className="PrismicFourBlocks_link"
          >
            {data.primary.four_blocks_link_text[0].text}
            <div className="PrismicFourBlocks_arrow_background">
              <div className="PrismicFourBlocks_arrow">&nbsp;</div>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

PrismicFourBlocks.propTypes = {
  data: PropTypes.shape(multiblocksiconsShape).isRequired,
};

export default PrismicFourBlocks;
