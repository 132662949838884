import { put, call, takeLatest } from 'redux-saga/effects';

import * as UserAPI from '../api/User';

import {
  FETCH_USER_DETAILS__REQUEST,
  FETCH_USER_DETAILS__SUCCESS,
  FETCH_USER_DETAILS__FAIL,
  FETCH_VERIFY_USER__REQUEST,
  FETCH_VERIFY_USER__SUCCESS,
  FETCH_VERIFY_USER__FAIL,
} from '../actions/UserActions';

function* fetchUserDetails() {
  try {
    const data = yield call(UserAPI.fetchUserDetails);

    yield put({
      type: FETCH_USER_DETAILS__SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_USER_DETAILS__FAIL,
      payload: e.message,
    });
  }
}

function* fetchVerifyUser() {
  try {
    const data = yield call(UserAPI.fetchVerifyUser);

    yield put({
      type: FETCH_VERIFY_USER__SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_VERIFY_USER__FAIL,
      payload: e.message,
    });
  }
}

const userSagas = [
  takeLatest(FETCH_USER_DETAILS__REQUEST, fetchUserDetails),
  takeLatest(FETCH_VERIFY_USER__REQUEST, fetchVerifyUser),
];

export default userSagas;
