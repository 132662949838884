import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { headlineWithBlocksShape } from '../../../shapes/prismicShape';

import RichText from '../../Core/RichText';

import '../../../styles/components/Prismic/PrismicHeadlineWithBlocks.css';

class PrismicHeadlineWithBlocks extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicHeadlineWithBlocks">
        <div className="PrismicHeadlineWithBlocks__header">
          {data.primary.headline_with_blocks_title && (
            <h2 className="PrismicHeadlineWithBlocks__title">
              {data.primary.headline_with_blocks_title}
            </h2>
          )}
          {data.primary.headline_with_blocks_subtitle && (
            <h4 className="PrismicHeadlineWithBlocks__subtitle">
              {data.primary.headline_with_blocks_subtitle}
            </h4>
          )}
        </div>
        <div className="PrismicHeadlineWithBlocks__items">
          {data.items.map((item, index) => {
            if (
              item.headline_with_blocks_block_title ||
              item.headline_with_blocks_block_description.length > 0
            ) {
              const itemClass = classNames('PrismicHeadlineWithBlocks__item', {
                'PrismicHeadlineWithBlocks__item--single':
                  data.items.length === 1,
              });

              return (
                <div className={itemClass} key={index}>
                  {item.headline_with_blocks_block_title && (
                    <h3 className="PrismicHeadlineWithBlocks__item-title">
                      {item.headline_with_blocks_block_title}
                    </h3>
                  )}
                  {item.headline_with_blocks_block_description.length > 0 && (
                    <div className="PrismicHeadlineWithBlocks__item-description">
                      <RichText
                        paragraphs={item.headline_with_blocks_block_description}
                      />
                    </div>
                  )}
                </div>
              );
            }

            return null;
          })}
        </div>
      </div>
    );
  }
}

PrismicHeadlineWithBlocks.propTypes = {
  data: PropTypes.shape(headlineWithBlocksShape).isRequired,
};

export default PrismicHeadlineWithBlocks;
