import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { Button } from '@robinfinance/ui';

import routesConfig from '../../../config/pages';
import { RECOMMENDATION_ENTRY_TYPE } from '../../../config/onboarding';

import withHeaderContext from '../../HOC/withHeaderContext';
import HeaderLink from './HeaderLink';
import MobileDropdownMenu from './MobileDropdownMenu';

import '../../../styles/components/Blocks/Header/MobileMenu.css';

function MobileMenu({
  openMenu,
  closeMenu,
  context: { isAuthenticated, setOnboardingStartingPoint },
}) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  function handleOpenMenu() {
    setIsOpen(true);
    openMenu();

    document.documentElement.classList.add('no-scroll');
  }

  function handleCloseMenu() {
    setIsOpen(false);
    closeMenu();

    document.documentElement.classList.remove('no-scroll');
  }

  function handleToggleMenu() {
    if (isOpen) {
      handleCloseMenu();
    } else {
      handleOpenMenu();
    }
  }

  function handleResize() {
    if (isOpen && document.body.clientWidth > 900) {
      handleCloseMenu();
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      document.documentElement.classList.remove('no-scroll');

      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleCloseMenu();
  }, [location]);

  const menuClasses = classNames('MobileMenu', {
    'MobileMenu--is-open': isOpen,
  });

  return (
    <div className={menuClasses}>
      <button
        id="MobileMenu-Burger"
        className="MobileMenu__burger"
        type="button"
        onClick={handleToggleMenu}
      >
        <span className="MobileMenu__burger-label">Menu</span>
        <span className="MobileMenu__burger-icon">
          <span />
          <span />
          <span />
        </span>
      </button>
      <div className="MobileMenu__container">
        <div className="MobileMenu__main">
          <div className="MobileMenu__menu">
            <HeaderLink
              id="MobileMenu-Link-Home"
              to="/"
              exact
              label="Accueil"
            />
            {routesConfig.pages.map(page => {
              if (page.subRoutes) {
                return (
                  <MobileDropdownMenu
                    key={page.id}
                    label={page.label}
                    to={page.to}
                    items={page.subRoutes}
                  />
                );
              }

              return (
                <HeaderLink key={page.id} to={page.to} label={page.label} />
              );
            })}
            {isAuthenticated ? (
              <HeaderLink
                id="MobileMenu-Link-Dashboard"
                to="/espace-client"
                label="Mon espace"
              />
            ) : (
              <HeaderLink
                id="MobileMenu-Link-Login"
                to="/auth/login"
                label="Connexion"
              />
            )}
          </div>
          <div className="MobileMenu__actions">
            <div className="MobileMenu__action">
              <Button
                id="MobileMenu-StartOnboarding"
                className="MobileMenu__button"
                to="/connaissance-client"
                onClick={() =>
                  setOnboardingStartingPoint(RECOMMENDATION_ENTRY_TYPE)
                }
              >
                Commencer ma simulation
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

MobileMenu.propTypes = {
  context: PropTypes.shape({
    cartProducts: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, identifier: PropTypes.string }),
    ),
    setOnboardingStartingPoint: PropTypes.func.isRequired,
  }).isRequired,
  openMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default withHeaderContext(MobileMenu);
