import React from 'react';
import PropTypes from 'prop-types';

const Reward = ({ reward }) => (
  <div className="ProductSummary__reward">
    <div className="ProductSummary__reward-image">
      {reward.image && reward.image.url && (
        <img src={reward.image.url} alt={reward.image.alt} />
      )}
    </div>
    <p className="ProductSummary__reward-label">{reward.label}</p>
  </div>
);

Reward.propTypes = {
  reward: PropTypes.shape({
    image: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
    label: PropTypes.string,
  }).isRequired,
};

export default Reward;
