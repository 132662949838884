import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip as TippyTooltip } from 'react-tippy';

import questionMark from '../../assets/icons/tooltip-icon-dark.svg';
import questionMarkBlue from '../../assets/icons/tooltipbleu.svg';

import 'react-tippy/dist/tippy.css';

/* eslint-disable */
class Tooltip extends PureComponent {
  render() {
    const { title, position, color, className, html } = this.props;
    let icon;

    if (!html && !title) {
      return null;
    }

    const tooltipClasses = classNames('Tooltip', {
      [className]: className,
      [`Tooltip--${color}`]: true,
    });

    switch (color) {
      case 'blue':
        icon = questionMarkBlue;
        break;

      default:
        icon = questionMark;
        break;
    }

    return (
      <TippyTooltip
        title={title}
        html={html}
        position={position}
        interactiveBorder="10"
        animateFill={false}
      >
        <img className={tooltipClasses} src={icon} alt="question_mark" />
      </TippyTooltip>
    );
  }
}

Tooltip.defaultProps = {
  title: null,
  html: null,
  position: 'top',
  className: '',
  color: 'yellow',
};

Tooltip.propTypes = {
  title: PropTypes.string,
  html: PropTypes.element,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  className: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'yellow']),
};

export default Tooltip;
