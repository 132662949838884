import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import detectiveImage from '../../assets/images/detective.svg';

import '../../styles/components/Layout/Error.css';

class FourOFour extends PureComponent {
  componentDidMount() {
    const { pageIsLoaded } = this.props;

    if (pageIsLoaded) {
      pageIsLoaded('404');
    }
  }

  render() {
    return (
      <div className="Error">
        <Helmet>
          <meta name="prerender-status-code" content="404" />
        </Helmet>
        <div className="Error__container">
          <div className="Error__image-container">
            <img
              src={detectiveImage}
              className="Error__image"
              alt="Detective avec chien"
            />
          </div>
          <div className="Error__content">
            <h1 className="Error__title">Erreur 404</h1>
            <div className="Error__text">
              <p>
                Votre requête n&apos;a pas pu aboutir. Cette page n&apos;existe
                pas.
              </p>
              <p>
                Que faire ? <br />
                Nous vous invitons à nous contacter par téléphone au 01 86 26 06
                06 ou directement via notre{' '}
                <Link
                  className="Error__link"
                  to="/qui-sommes-nous/contactez-nous"
                >
                  formulaire de contact.
                </Link>
              </p>
            </div>
            <Link className="Error__action" to="/">
              <span>Retourner à la page d&apos;accueil</span>
              <ArrowIcon className="Error__action-icon" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

FourOFour.defaultProps = {
  pageIsLoaded: null,
};

FourOFour.propTypes = {
  pageIsLoaded: PropTypes.func,
};

export default FourOFour;
