import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import qs from 'qs';
import { Button } from '@robinfinance/ui';

import typeShape from '../../../shapes/typeShape';

import withGuideContext from '../../HOC/withGuideContext';
import Breadcrumbs from '../../Core/Breadcrumbs';
import PrismicMapper from '../../Prismic/Core/PrismicMapper';

import '../../../styles/components/Blocks/Guide/Project.css';

class Project extends PureComponent {
  componentDidMount() {
    const {
      context: { item, pageIsLoaded },
    } = this.props;

    if (item) {
      pageIsLoaded(item.gaTitle);
    }
  }

  render() {
    const {
      context: { item },
    } = this.props;

    const query = qs.stringify(
      { objective: [item.id] },
      { arrayFormat: 'brackets' },
    );

    const metaDescription =
      item.metaDescription || `${item.description[0].text.substr(0, 120)} ...`;

    return (
      <div className="Project">
        <Helmet>
          <title>{item.label} | Mieuxplacer.com</title>
          <meta name="description" content={metaDescription} />

          <meta
            property="og:title"
            content={`${item.label} | Mieuxplacer.com`}
          />
          <meta property="og:description" content={metaDescription} />
          <meta
            property="og:url"
            content={`https://www.mieuxplacer.com/guide/${item.slug}`}
          />
          <meta property="og:objective" content="objective" />

          <link
            rel="canonical"
            href={`https://www.mieuxplacer.com/guide/${item.slug}`}
          />
        </Helmet>

        <Breadcrumbs />
        <PrismicMapper blocks={item.blocks} />

        <div className="Project__content">
          <div className="Project__actions">
            <div className="Project__action Project__action--secondary">
              <h3 className="Project__action-title">Conseil gratuit</h3>
              <p className="Project__action-text">
                Répondez à notre questionnaire et découvrez en 5 minutes le
                placement le plus adapté à votre situation
              </p>
              <Button scope="button" type="secondary" to="/connaissance-client">
                Commencer ma simulation
              </Button>
            </div>
            <div className="Project__action Project__action--primary">
              <h3 className="Project__action-title">Catalogue produit</h3>
              <p className="Project__action-text">
                Découvrez tout les produits qui vous permettront de valoriser
                votre capital
              </p>
              <Button scope="button" type="primary" to={`/produits?${query}`}>
                Voir les produits
              </Button>
            </div>
          </div>
        </div>
        <span className="Project__mask" />
      </div>
    );
  }
}

Project.propTypes = {
  context: PropTypes.shape({
    item: PropTypes.shape(typeShape).isRequired,
    pageIsLoaded: PropTypes.func.isRequired,
  }).isRequired,
};

export default withGuideContext(Project);
