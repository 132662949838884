module.exports = {
  pages: [
    {
      id: 'W6ikDhEAACEAdDMH',
      to: '/qui-sommes-nous',
      label: 'Qui sommes-nous ?',
      subRoutes: [
        {
          id: 'W6ij7BEAACEAdDJm',
          to: '/qui-sommes-nous/comment-ca-marche',
          label: 'Comment ça marche ?',
        },
        {
          id: 'W6p_SxEAACYAP7Gy',
          to: '/qui-sommes-nous/contactez-nous',
          label: 'Contactez-nous',
        },
        {
          id: 'W6OhkCcAACUA0Jxk',
          to: '/qui-sommes-nous/notre-mission',
          label: 'Notre mission',
        },
        {
          id: 'W7NuSRQAACMAIWa-',
          to: '/qui-sommes-nous/tarifs',
          label: 'Tarifs',
        },
        // {
        //   id: 'XjFP7BIAAB8A9fBO',
        //   to: '/qui-sommes-nous/glossaire-epargne',
        //   label: "Glossaire de l'épargne",
        // },
        // {
        //   id: 'XjFQIhIAACAA9fFD',
        //   to: '/qui-sommes-nous/partenaires',
        //   label: 'Nos partenaires',
        // },
        {
          id: 'XXeDKxQAACAAYm3L',
          to: '/qui-sommes-nous/temoignages',
          label: 'Témoignages',
        },
      ],
    },
    {
      id: 'XgnPvBYAAN2dOKxe',
      to: '/placements-financiers',
      label: 'Placements',
      subRoutes: [
        {
          id: 'XjFOwBIAACAA9er7',
          to: '/placements-financiers/meilleurs-placements-financiers',
          label: 'Meilleurs placements financiers',
          // subRoutes: [
          //   {
          //     id: 'XjFRxxIAAB8A9fiq',
          //     to:
          //       '/placements-financiers/meilleurs-placements-financiers/placements-rentables',
          //     label: 'Placements rentables',
          //   },
          //   {
          //     id: 'XjFR_RIAACEA9fmb',
          //     to:
          //       '/placements-financiers/meilleurs-placements-financiers/placements-securises',
          //     label: 'Placements sécurisés',
          //   },
          // ],
        },
        {
          id: 'XjFO2hIAACIA9et2',
          to: '/placements-financiers/conseiller-en-gestion-de-patrimoine',
          label: 'Conseiller en gestion de patrimoine',
        },
        {
          id: 'XjFO-hIAAB8A9ewF',
          to: '/placements-financiers/alternative-livret-a',
          label: 'Alternative au livret A',
          subRoutes: [
            {
              id: 'XjFRdxIAAB8A9fdB',
              to:
                '/placements-financiers/alternative-livret-a/taux-comptes-epargne',
              label: 'Taux des comptes épargne',
            },
          ],
        },
        // {
        //   id: 'XjFPDBIAACEA9exU',
        //   to: '/placements-financiers/defiscalisation',
        //   label: 'Défiscalisation',
        //   subRoutes: [
        //     {
        //       id: 'XjFRkhIAAB8A9fe6',
        //       to: '/placements-financiers/defiscalisation/fip-defiscalisation',
        //       label: 'FIP défiscalisation',
        //     },
        //     {
        //       id: 'XjFRphIAACIA9fgV',
        //       to: '/placements-financiers/defiscalisation/fcpi-defiscalisation',
        //       label: 'FCPI défiscalisation',
        //     },
        //   ],
        // },
        // {
        //   id: 'XjFPJRIAAB8A9ezJ',
        //   to: '/placements-financiers/nos-partenaires',
        //   label: 'Nos partenaires',
        //   subRoutes: [
        //     {
        //       id: 'XjFSERIAACAA9fn2',
        //       to:
        //         '/placements-financiers/nos-partenaires/spirica-assurance-vie',
        //       label: 'Spirica Assurance Vie',
        //     },
        //   ],
        // },
      ],
    },
    {
      id: 'XfuIIRYAALAx-eS6',
      to: '/assurance-vie',
      label: 'Assurance vie',
      subRoutes: [
        {
          id: 'XgoUdBYAACSgOdss',
          to: '/assurance-vie/assurance-vie-multisupport',
          label: 'Assurance vie multisupport',
          subRoutes: [
            {
              id: 'XjFQ7RIAACAA9fTG',
              to: '/assurance-vie/assurance-vie-multisupport/unites-de-compte',
              label: 'Unités de compte',
            },
            // {
            //   id: 'XjFRBhIAAB8A9fU3',
            //   to: '/assurance-vie/assurance-vie-multisupport/fonds-euro',
            //   label: 'Fonds euro',
            // },
          ],
        },
        {
          id: 'XjFFxxIAACIA9cM8',
          to: '/assurance-vie/fiscalite-assurance-vie',
          label: "Fiscalité de l'assurance vie",
        },
        {
          id: 'XjFF_hIAACEA9cQx',
          to: '/assurance-vie/assurance-vie-en-cas-de-deces',
          label: 'Assurance vie en cas de décès',
        },
        // {
        //   id: 'XjFJ_xIAACIA9dXk',
        //   to: '/assurance-vie/comparatif-assurances-vie',
        //   label: 'Comparatif des assurances vie',
        // },
        {
          id: 'XjFKPhIAAB8A9db_',
          to: '/assurance-vie/rendement-des-assurances-vie',
          label: 'Rendement des assurances vie',
        },
        {
          id: 'XjFKYhIAACIA9deg',
          to: '/assurance-vie/assurance-vie-en-ligne',
          label: 'Assurance vie en ligne',
        },
        // {
        //   id: 'XjFK7RIAACEA9doJ',
        //   to: '/assurance-vie/contrat-de-capitalisation',
        //   label: 'Contrat de capitalisation',
        // },
        // {
        //   id: 'XjFLBBIAACAA9dpy',
        //   to: '/assurance-vie/meilleures-assurances-vie',
        //   label: 'Meilleures assurances vie',
        // },
        // {
        //   id: 'XjFMXRIAACIA9eBm',
        //   to: '/assurance-vie/assurance-vie-apres-70-ans',
        //   label: 'Assurance vie après 70 ans',
        // },
      ],
    },
    {
      id: 'XgncHhYAAN2dOOLt',
      to: '/placements-immobiliers',
      label: 'Immobilier',
      subRoutes: [
        {
          id: 'XjFMfxIAACEA9eD_',
          to: '/placements-immobiliers/rendement-des-scpi',
          label: 'Rendement des SCPI',
        },
        {
          id: 'XjFMyxIAACEA9eJQ',
          to: '/placements-immobiliers/meilleures-scpi',
          label: 'Meilleures SCPI',
        },
        // {
        //   id: 'XjFM4RIAACAA9eK0',
        //   to: '/placements-immobiliers/investir-dans-la-pierre-papier',
        //   label: 'Investir dans la pierre papier',
        // },
        // {
        //   id: 'XjFNGRIAAB8A9eOt',
        //   to: '/placements-immobiliers/scpi-et-defiscalisation',
        //   label: 'SCPI et défiscalisation',
        //   subRoutes: [
        //     {
        //       id: 'XjFRHxIAACIA9fWq',
        //       to: '/placements-immobiliers/scpi-et-defiscalisation/scpi-pinel',
        //       label: 'SCPI Pinel',
        //     },
        //   ],
        // },
        // {
        //   id: 'XjFNMBIAACAA9eQW',
        //   to: '/placements-immobiliers/scpi-de-rendement',
        //   label: 'SCPI de rendement',
        // },
      ],
    },
    {
      id: 'XgoD8BYAACSgOZJ0',
      to: '/epargne-retraite',
      label: 'Retraite',
      // subRoutes: [
      // {
      //   id: 'XjFPOBIAACAA9e0g',
      //   to: '/epargne-retraite/per',
      //   label: 'PER',
      // },
      // {
      //   id: 'XjFPTxIAAB8A9e2H',
      //   to: '/epargne-retraite/placement-retraite',
      //   label: 'Placement retraite',
      // },
      // {
      //   id: 'XjFPnBIAAB8A9e7f',
      //   to: '/epargne-retraite/epargner-pour-la-retraite',
      //   label: 'Epargner pour la retraite',
      // },
      // {
      //   id: 'XjFP0RIAACAA9e_V',
      //   to: '/epargne-retraite/taux-plan-epargne-retraite',
      //   label: 'Taux plan épargne retraite',
      // },
      // ],
    },
  ],
  otherPages: [
    {
      id: 'W6I3zSEAACMA_fGj',
      to: '/guide',
      label: 'Guide',
    },
    {
      id: 'XaTWTRAAAB8ArMUt',
      to: '/recrutement',
      label: 'Recrutement',
    },
    {
      id: 'XYDU8hQAABZji5kC',
      to: '/solutions-btob',
      label: 'Solutions BtoB',
    },
    {
      id: 'XJUFIhEAACIA-e7R',
      to: '/securite-mieuxplacer.com',
      label: 'Sécurité',
    },
    {
      id: 'XFL0MREAACEAb9xd',
      to: '/offre-parrainage',
      label: 'Parrainage',
    },
    {
      id: 'XnHuhBEAACAAEfY7',
      to: '/mieuxplacer-fidele-au-poste',
      label: 'Mieuxplacer.com fidèle au poste !',
    },
  ],
};
