import { Map, List, fromJS } from 'immutable';

import {
  FETCH_QUESTIONS__REQUEST,
  FETCH_QUESTIONS__SUCCESS,
  FETCH_QUESTIONS__FAIL,
} from '../actions/PersonalInformationActions';

const initialState = Map({
  questions: List([]),
  loading: false,
  error: false,
});

const personalInformationState = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_QUESTIONS__REQUEST:
      return state.set('loading', true).set('error', false);

    case FETCH_QUESTIONS__SUCCESS:
      return state
        .set('loading', false)
        .set('questions', fromJS(Object.values(payload.questions)));

    case FETCH_QUESTIONS__FAIL:
      return state.set('loading', false).set('error', true);

    default:
      return initialState.merge(state);
  }
};

export default personalInformationState;
