import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import badCharlieImage from '../../assets/images/bad-charlie.svg';

import '../../styles/components/Layout/Error.css';

class FiveHundred extends PureComponent {
  componentDidMount() {
    const { pageIsLoaded } = this.props;

    if (pageIsLoaded) {
      pageIsLoaded('500');
    }
  }

  render() {
    return (
      <div className="Error">
        <div className="Error__container">
          <div className="Error__image-container">
            <img
              src={badCharlieImage}
              className="Error__image"
              alt="Detective avec chien"
            />
          </div>
          <div className="Error__content">
            <h1 className="Error__title">Whoops…</h1>
            <div className="Error__text">
              <p>
                Vous êtes très nombreux à vouloir accéder à cette page
                actuellement... Malheureusement, nous ne pouvons pas tous vous
                accueillir en même temps ! Notre service client prend le relais.
              </p>
              <p>
                Contactez-nous dès maintenant, ou laissez-nous vos coordonnées :
                nous vous recontacterons dans les meilleurs délais. Nos
                conseillers sont à votre écoute au 06 01 73 09 58 ou par mail à
                l’adresse{' '}
                <a className="Error__link" href="mailto:hello@easycapital.fr">
                  hello@easycapital.fr
                </a>
                .
              </p>
            </div>
            <Link className="Error__action" to="/">
              <span>Retourner à la page d&apos;accueil</span>
              <ArrowIcon className="Error__action-icon" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

FiveHundred.defaultProps = {
  pageIsLoaded: null,
};

FiveHundred.propTypes = {
  pageIsLoaded: PropTypes.func,
};

export default FiveHundred;
