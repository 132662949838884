import { BASE_API_URL } from '../config/api';

export const fetchBlogPosts = async () => {
  const url = new URL(`${BASE_API_URL}/2.0/blog/latest-posts`);

  const response = await fetch(url.href, {
    method: 'GET',
    credentials: 'include',
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  }

  throw response;
};
