import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Core/Icon';

import { sliceShape } from '../../../shapes/prismicShape';

import arrowlink from '../../../assets/icons/arrow.svg';

import '../../../styles/components/Prismic/PrismicArticles.css';

class PrismicArticles extends PureComponent {
  renderArticle = data => {
    if (data.items.length === 1) {
      return 'PrismicArticles_container_1';
    }

    if (data.items.length === 2) {
      return 'PrismicArticles_container_2';
    }

    if (data.items.length === 3) {
      return 'PrismicArticles_container_3';
    }

    return null;
  };

  render() {
    const { data } = this.props;

    return (
      <div className="PrismicArticles">
        <div className="PrismicArticles_title">Nos derniers articles</div>
        <div className="PrismicArticles_container">
          {data.items.map(item => (
            <div
              className={this.renderArticle(data)}
              key={item.article_title[0].text}
            >
              <div className="PrismicArticles_label">
                {item.article_label[0].text}
              </div>
              <div className="PrismicArticles_container_detail">
                <div className="PrismicArticles_title_detail">
                  {item.article_title[0].text}
                </div>
                <div className="PrismicArticles_detail">
                  {item.article_detail[0].text}
                </div>
                <div className="PrismicArticles_footer">
                  <div className="PrismicArticles_date">
                    {item.article_date}
                  </div>
                  <a
                    href={item.article_link.url}
                    target={item.article_link.target}
                    className="PrismicArticles_link"
                  >
                    Lire l&apos;article
                    <Icon
                      src={arrowlink}
                      alt="arrow link"
                      height="10px"
                      width="10px"
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

PrismicArticles.defaultProps = {
  data: {},
};

PrismicArticles.propTypes = {
  data: PropTypes.shape(sliceShape),
};

export default PrismicArticles;
