import { isToday, differenceInMonths } from 'date-fns';

export const shouldOpenTestingForm = visits => {
  const hasSendTestingForm = window.localStorage.getItem('hasSendTestingForm');

  if (hasSendTestingForm) {
    return false;
  }

  let lastVisitIsNotToday = false;

  if (visits.length === 2) {
    const lastVisit = visits[visits.length - 1];

    if (!isToday(new Date(lastVisit))) {
      lastVisitIsNotToday = true;
    }
  }

  if (visits.length >= 3 || lastVisitIsNotToday) {
    const hasClosedTestingForm = window.localStorage.getItem(
      'hasClosedTestingForm',
    );

    if (hasClosedTestingForm) {
      const numberOfMonthsBetweenLastVisit = differenceInMonths(
        new Date(),
        new Date(hasClosedTestingForm),
      );

      if (numberOfMonthsBetweenLastVisit > 0) {
        return true;
      }

      return false;
    }

    return true;
  }

  return false;
};
