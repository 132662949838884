export const FETCH_CUSTOMER_FILES__REQUEST =
  '@@SUPPORTING_DOCUMENTS/FETCH_CUSTOMER_FILES__REQUEST';
export const FETCH_CUSTOMER_FILES__SUCCESS =
  '@@SUPPORTING_DOCUMENTS/FETCH_CUSTOMER_FILES__SUCCESS';
export const FETCH_CUSTOMER_FILES__FAIL =
  '@@SUPPORTING_DOCUMENTS/FETCH_CUSTOMER_FILES__FAIL';

export const ADD_CUSTOMER_FILES = '@@SUPPORTING_DOCUMENTS/ADD_CUSTOMER_FILES';

export const fetchCustomerFiles = () => ({
  type: FETCH_CUSTOMER_FILES__REQUEST,
});

export const addCustomerFiles = files => ({
  type: ADD_CUSTOMER_FILES,
  payload: files,
});
