import * as Joi from 'joi-browser';

const leadFormSchema = Joi.object().keys({
  subject: Joi.string().valid('product-or-service-information').required(),
  source: Joi.string().valid('mieuxplacer-com').required(),
  page: Joi.string().valid('product-page').required(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string()
    .email({
      minDomainAtoms: 2,
    })
    .required(),
  phoneNumber: Joi.string()
    .regex(
      /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm,
    )
    .required(),
  productTitle: Joi.string().required(),
  supplierName: Joi.string().required(),
  productType: Joi.string().required(),
});

export const firstNameSchema = Joi.object().keys({
  firstName: Joi.string().required(),
});

export const lastNameSchema = Joi.object().keys({
  lastName: Joi.string().required(),
});

export const emailSchema = Joi.object().keys({
  email: Joi.string()
    .email({
      minDomainAtoms: 2,
    })
    .required(),
});

export const phoneNumberSchema = Joi.object().keys({
  phoneNumber: Joi.string()
    .min(10)
    .max(10)
    .regex(
      /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm,
    )
    .required(),
});

export default leadFormSchema;
