export const FETCH_USER_DETAILS__REQUEST = '@@USER/FETCH_USER_DETAILS__REQUEST';
export const FETCH_USER_DETAILS__SUCCESS = '@@USER/FETCH_USER_DETAILS__SUCCESS';
export const FETCH_USER_DETAILS__FAIL = '@@USER/FETCH_USER_DETAILS__FAIL';

export const FETCH_VERIFY_USER__REQUEST = '@@USER/FETCH_VERIFY_USER__REQUEST';
export const FETCH_VERIFY_USER__SUCCESS = '@@USER/FETCH_VERIFY_USER__SUCCESS';
export const FETCH_VERIFY_USER__FAIL = '@@USER/FETCH_VERIFY_USER__FAIL';

export const fetchUserDetails = () => ({ type: FETCH_USER_DETAILS__REQUEST });

export const fetchVerifyUser = () => ({ type: FETCH_VERIFY_USER__REQUEST });
