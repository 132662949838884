import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import Icon from '../../Core/Icon';

import arrowDropdown from '../../../assets/icons/arrow-dropdown.svg';

import '../../../styles/components/Blocks/Header/MobileDropdownMenu.css';

function MobileDropdownMenu({ label, to, items }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const routeMatch = useRouteMatch({
    path: to,
    exact: false,
  });

  const menuClasses = classNames('MobileDropdownMenu', {
    'MobileDropdownMenu--is-open': isOpen,
  });

  return (
    <div className={menuClasses}>
      <button
        className={`Header__link${routeMatch ? ' Header__link--active' : ''}`}
        type="button"
        onClick={() => setIsOpen(true)}
      >
        {label}
        <Icon
          className="MobileDropdownMenu__link-icon"
          src={arrowDropdown}
          alt="Submenu arrow"
          height="4px"
          width="8px"
        />
      </button>
      <div className="MobileDropdownMenu__submenu">
        <div className="MobileDropdownMenu__header">
          <button
            className="MobileDropdownMenu__button"
            type="button"
            onClick={() => setIsOpen(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="21"
              viewBox="0 0 27 21"
            >
              <path
                fill="#D6DBE1"
                fillRule="evenodd"
                d="M4.6 9.179h21.05c.744 0 1.35.59 1.35 1.322 0 .728-.606 1.321-1.35 1.321H4.6l7.156 7.004c.48.557.408 1.392-.16 1.862a1.373 1.373 0 0 1-1.758-.012l-9.452-9.25a1.3 1.3 0 0 1 0-1.85L9.838.324a1.368 1.368 0 0 1 1.904.13c.438.493.444 1.221.014 1.72l-7.17 7.004h.013z"
              />
            </svg>
          </button>
        </div>
        <div className="MobileDropdownMenu__content">
          <NavLink
            exact
            to={to}
            className="Header__link"
            activeClassName="Header__link--active"
          >
            {label}
          </NavLink>
          {items.map(item => {
            if (item.subRoutes) {
              return (
                <MobileDropdownMenu
                  key={item.id}
                  label={item.label}
                  to={item.to}
                  items={item.subRoutes}
                />
              );
            }

            return (
              <NavLink
                exact
                key={item.id}
                to={item.to}
                className="Header__link"
                activeClassName="Header__link--active"
              >
                {item.label}
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
}

MobileDropdownMenu.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      to: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default MobileDropdownMenu;
