import React from 'react';
import PropTypes from 'prop-types';

import { paragraphShape } from '../../../shapes/prismicShape';

import RichText from '../../Core/RichText';

import '../../../styles/components/Prismic/Core/PrismicBlockTitle.css';

const PrismicBlockTitle = ({ title, preTitle, color }) => (
  <div className="PrismicBlockTitle">
    {preTitle && (
      <span className="PrismicBlockTitle__pre" style={{ color }}>
        {preTitle}
      </span>
    )}
    <RichText paragraphs={title} />
  </div>
);

PrismicBlockTitle.defaultProps = {
  preTitle: null,
  color: null,
};

PrismicBlockTitle.propTypes = {
  title: PropTypes.arrayOf(PropTypes.shape(paragraphShape)).isRequired,
  preTitle: PropTypes.string,
  color: PropTypes.string,
};

export default PrismicBlockTitle;
