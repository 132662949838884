import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import AccordionBlock from './AccordionBlock';

class Accordion extends PureComponent {
  state = { expand: false };

  componentDidMount() {
    const { initialExpand } = this.props;

    this.setInitialExpand(initialExpand);
  }

  setInitialExpand = value => {
    this.setState({ expand: value });
  };

  toggle = () => {
    const { expand } = this.state;
    const { onChange } = this.props;

    this.setState({ expand: !expand });

    onChange(!expand);
  };

  render() {
    const {
      title,
      content,
      color,
      backgroundColor,
      colorLabel,
      displayIconLeft,
      NoMargin,
      inversed,
      manual,
      opacityHeight,
      opacityColor,
    } = this.props;
    const { expand } = this.state;

    const dynamicTitle = expand ? `Replier ${title}` : `Déplier ${title}`;

    return (
      <AccordionBlock
        buttonLabel={manual ? title : dynamicTitle}
        expand={expand}
        content={content}
        color={color}
        bgColor={backgroundColor}
        onClick={this.toggle}
        colorLabel={colorLabel}
        displayIconLeft={displayIconLeft}
        NoMargin={NoMargin}
        inversed={inversed}
        opacityHeight={opacityHeight}
        opacityColor={opacityColor}
      />
    );
  }
}

Accordion.defaultProps = {
  title: 'Tout afficher',
  color: '#4ecdc4',
  backgroundColor: '#d0f2f0',
  colorLabel: '#495057',
  displayIconLeft: true,
  NoMargin: true,
  inversed: false,
  manual: false,
  opacityHeight: '0px',
  opacityColor: '255, 255, 255',
  initialExpand: false,
  onChange: () => {},
};

Accordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  colorLabel: PropTypes.string,
  displayIconLeft: PropTypes.bool,
  NoMargin: PropTypes.bool,
  inversed: PropTypes.bool,
  manual: PropTypes.bool,
  opacityHeight: PropTypes.string,
  opacityColor: PropTypes.string,
  initialExpand: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Accordion;
