import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import typeShape from '../../../shapes/typeShape';

import withGuideContext from '../../HOC/withGuideContext';
import Breadcrumbs from '../../Core/Breadcrumbs';
import PrismicMapper from '../../Prismic/Core/PrismicMapper';

import '../../../styles/components/Blocks/Guide/Type.css';

class Type extends PureComponent {
  componentDidMount() {
    const {
      context: { item, pageIsLoaded },
    } = this.props;

    if (item) {
      pageIsLoaded(item.gaTitle);
    }
  }

  render() {
    const {
      context: { item },
    } = this.props;

    return (
      <div className="Type">
        <Helmet>
          <title>{item.label} | Mieuxplacer.com</title>
          <meta name="description" content={item.description} />

          <meta
            property="og:title"
            content={`${item.label} | Mieuxplacer.com`}
          />
          <meta property="og:description" content={item.description} />
          <meta
            property="og:url"
            content={`https://www.mieuxplacer.com/guide/${item.slug}`}
          />
          <meta property="og:type" content="product_type" />

          <link
            rel="canonical"
            href={`https://www.mieuxplacer.com/guide/${item.slug}`}
          />
        </Helmet>

        <Breadcrumbs />

        <div className="Type__blocks">
          <PrismicMapper blocks={item.blocks} />
        </div>
      </div>
    );
  }
}

Type.propTypes = {
  context: PropTypes.shape({
    item: PropTypes.shape(typeShape).isRequired,
    pageIsLoaded: PropTypes.func.isRequired,
  }).isRequired,
};

export default withGuideContext(Type);
