import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Joi from 'joi-browser';
import { Button, Input, Email, ArrowRight } from '@robinfinance/ui';

import {
  EMPTY_ERROR_MESSAGES,
  INCORRECT_ERROR_MESSAGES,
} from '../../../constants/AuthConstants';
import { emailSchema } from '../../../helpers/schema/loginFormSchema';

import { fetchForgotPassword } from '../../../api/Auth';

import Alert from '../../Core/Alert';
import ErrorMessage from '../../Core/ErrorMessage';

import '../../../styles/components/Modules/Auth/ForgotPasswordForm.css';

function ForgotPasswordForm({ initialEmail }) {
  const [email, setEmail] = useState(initialEmail);

  const [errors, setErrors] = useState({ email: [], password: [] });

  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const validationSchemas = {
    email: Joi.string().email({ minDomainAtoms: 2 }).required(),
  };

  async function validateInput(name, value) {
    try {
      await Joi.validate(value, validationSchemas[name]);

      setErrors(oldErrors => ({ ...oldErrors, [name]: [] }));
    } catch (error) {
      if (error.details[0].type === 'any.empty') {
        setErrors(oldErrors => ({
          ...oldErrors,
          [name]: [EMPTY_ERROR_MESSAGES[name]],
        }));
      } else {
        setErrors(oldErrors => ({
          ...oldErrors,
          [name]: [INCORRECT_ERROR_MESSAGES[name]],
        }));
      }
    }
  }

  function handleResetErrors() {
    setErrors({ email: [] });
    setHasError(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);
    setIsDone(false);

    try {
      await Joi.validate({ email }, emailSchema, {
        abortEarly: false,
      });

      try {
        await fetchForgotPassword(email);

        handleResetErrors();
        setIsDone(true);
      } catch (e) {
        setHasError(true);
        setErrorMessage(e.error);
      }
    } catch (error) {
      const inputErrors = {};

      error.details.forEach(inputError => {
        const name = inputError.context.key;

        if (inputError.type === 'any.empty') {
          inputErrors[name] = [EMPTY_ERROR_MESSAGES[name]];
        } else if (!inputErrors[name]) {
          inputErrors[name] = [INCORRECT_ERROR_MESSAGES[name]];
        }
      });

      setErrors(inputErrors);
    }

    setIsLoading(false);
  }

  function renderErrorMessage() {
    if (!isLoading && hasError) {
      let errorMessageContent =
        "Il semblerait que nous ayons un problème. Merci de réessayer dans quelques instants ou de nous contacter à l'adresse hello@mieuxplacer.com.";

      if (errorMessage !== undefined) {
        errorMessageContent = (
          <ErrorMessage error={errorMessage} data={{ email }} />
        );
      }

      return (
        <div className="ForgotPasswordForm__messages">
          <Alert type="error">{errorMessageContent}</Alert>
        </div>
      );
    }

    if (isDone) {
      return (
        <div className="ForgotPasswordForm__messages">
          <Alert type="success">
            <p>
              Nous avons bien pris en compte votre demande de nouveau mot de
              passe. Si vous vous êtes déja inscrit avec cette adresse mail,
              vous devriez recevoir très rapidement un lien afin de
              réinitialiser votre mot de passe. Si par hasard vous ne le
              receviez pas, merci de nous contacter à l&apos;adresse
              hello@mieuxplacer.com
            </p>
          </Alert>
        </div>
      );
    }

    return null;
  }

  return (
    <div className="ForgotPasswordForm">
      {renderErrorMessage()}

      <form className="ForgotPasswordForm__form" onSubmit={handleSubmit}>
        <div className="ForgotPasswordForm__form-inputs">
          <Input
            type="email"
            name="email"
            className="ForgotPasswordForm__form-input ym-hide-content"
            label="Votre adresse e-mail"
            icon={<Email color="#FFC501" />}
            initialValue={initialEmail}
            isInvalid={errors.email.length > 0}
            error={errors.email}
            onChange={value => {
              setEmail(value);
              handleResetErrors();
            }}
            onBlur={value => validateInput('email', value)}
          />
        </div>
        <Button
          scope="button"
          type="primary"
          iconSide="right"
          icon={<ArrowRight color="#ffc501" />}
          onClick={handleSubmit}
          loading={isLoading}
          hasLoadingState
          disabled={isLoading}
        >
          Réinitialiser mon mot de passe
        </Button>
      </form>
    </div>
  );
}

ForgotPasswordForm.defaultProps = {
  initialEmail: '',
};

ForgotPasswordForm.propTypes = {
  initialEmail: PropTypes.string,
};

export default ForgotPasswordForm;
