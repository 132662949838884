import { Map, List, fromJS } from 'immutable';

import { LOGOUT_USER } from '../actions/AuthActions';
import {
  FETCH_CONTRACTS__REQUEST,
  FETCH_CONTRACTS__SUCCESS,
  FETCH_CONTRACTS__FAIL,
  DELETE_CONTRACT__REQUEST,
  DELETE_CONTRACT__SUCCESS,
  DELETE_CONTRACT__FAIL,
  SET_CONTRACT,
  FETCH_CONTRACT_FILES__REQUEST,
  FETCH_CONTRACT_FILES__SUCCESS,
  FETCH_CONTRACT_FILES__FAIL,
} from '../actions/ContractsActions';

const initialState = Map({
  contracts: List([]),
  contractsLoading: false,
  contractsError: false,
  files: Map({}),
  filesLoading: false,
  filesError: false,
  contractDeleteLoading: false,
  contractDeleteError: false,
});

const contractsState = (state = initialState, { type, payload }) => {
  let newContracts = [];

  switch (type) {
    case FETCH_CONTRACTS__REQUEST:
      return state.set('contractsLoading', true).set('contractsError', false);

    case FETCH_CONTRACTS__SUCCESS:
      return state
        .set('contractsLoading', false)
        .set('contracts', fromJS(payload));

    case FETCH_CONTRACTS__FAIL:
      return state.set('contractsLoading', false).set('contractsError', true);

    case FETCH_CONTRACT_FILES__REQUEST:
      return state.set('filesLoading', true).set('filesError', false);

    case FETCH_CONTRACT_FILES__SUCCESS:
      return state
        .set('filesLoading', false)
        .set('files', state.get('files').set(payload.contract, payload.files));

    case FETCH_CONTRACT_FILES__FAIL:
      return state.set('filesLoading', false).set('filesError', true);

    case DELETE_CONTRACT__REQUEST:
      return state
        .set('contractDeleteLoading', true)
        .set('contractDeleteError', false);

    case DELETE_CONTRACT__SUCCESS:
      newContracts = state
        .get('contracts')
        .filter(contract => contract.get('id') !== payload.id);

      return state
        .set('contractDeleteLoading', false)
        .set('contracts', newContracts);

    case DELETE_CONTRACT__FAIL:
      return state
        .set('contractDeleteLoading', false)
        .set('contractDeleteError', true);

    case SET_CONTRACT:
      return state.set(
        'contracts',
        state.get('contracts').map(contract => {
          if (contract.get('id') === payload.id) {
            return fromJS(payload);
          }

          return contract;
        }),
      );

    case LOGOUT_USER:
      return initialState;

    default:
      return initialState.merge(state);
  }
};

export default contractsState;
