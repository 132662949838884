import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Button, PartialArrowLeft } from '@robinfinance/ui';

import mieuxplacerAPI from '../../../api/Mieuxplacer';
import { fetchSendEmailVerification } from '../../../api/Auth';
import { LOGOUT_USER } from '../../../actions/AuthActions';

import withAppContext from '../../HOC/withAppContext';

import Modal from '../../Core/Modal';
import Alert from '../../Core/Alert';
import ErrorMessage from '../../Core/ErrorMessage';
import Icon from '../../Core/Icon';

import icon from '../../../assets/icons/porte.svg';

import '../../../styles/components/Modules/Auth/VerificationModal.css';

const logoutIcon = <Icon src={icon} alt="Logout icon" />;

function VerificationModal({
  context: { user, verificationModalIsOpen, closeVerificationModal },
}) {
  const [logoutIsVisible, setLogoutIsVisible] = useState(false);

  const [isVerificationEmailLoading, setIsVerificationEmailLoading] = useState(
    false,
  );
  const [hasVerificationEmailError, setHasVerificationEmailError] = useState(
    false,
  );
  const [
    verificationEmailErrorMessage,
    setVerificationEmailErrorMessage,
  ] = useState(undefined);
  const [hasSentVerificationEmail, setHasSentVerificationEmail] = useState(
    false,
  );

  const dispatch = useDispatch();
  const history = useHistory();

  async function handleConfirmLogout() {
    closeVerificationModal();

    history.push('/');

    await mieuxplacerAPI.Api.logout();
    dispatch({ type: LOGOUT_USER });
  }

  async function handleSendVerificationEmail() {
    setIsVerificationEmailLoading(true);
    setHasVerificationEmailError(false);
    setVerificationEmailErrorMessage(undefined);

    try {
      await fetchSendEmailVerification(user.email);

      setHasSentVerificationEmail(true);
    } catch (e) {
      setHasVerificationEmailError(true);
      setVerificationEmailErrorMessage(e.error || {});
    }

    setIsVerificationEmailLoading(false);
  }

  const classes = classNames('VerificationModal', {
    'VerificationModal--show-logout': logoutIsVisible,
  });

  return (
    <Modal isOpen={verificationModalIsOpen} isBlockingType>
      <div className={classes}>
        <div className="VerificationModal__header">
          <Button
            scope="dashboard"
            type="primary"
            iconSide="left"
            icon={<PartialArrowLeft color="#616c7a" />}
            to="/"
            onClick={closeVerificationModal}
            className="VerificationModal__header-action"
          >
            Retour à la page d&apos;accueil
          </Button>
        </div>
        {hasSentVerificationEmail && !isVerificationEmailLoading && (
          <div className="VerificationModal__messages">
            {hasVerificationEmailError ? (
              <Alert type="error">
                <ErrorMessage error={verificationEmailErrorMessage} />
              </Alert>
            ) : (
              <Alert type="success">
                <p>Un nouvel e-mail vient de vous être envoyé.</p>
              </Alert>
            )}
          </div>
        )}
        <p className="VerificationModal__title">
          Votre adresse e-mail n’est pas vérifiée
        </p>
        <p className="VerificationModal__text">
          Nous vous avons envoyé un mail afin de la confirmer
          {user.lastSentValidationEmail && (
            <span> le {user.lastSentValidationEmail}</span>
          )}
          . Cliquez sur le lien pour la valider, vous pourrez ensuite accéder
          aux différentes fonctionnalités de votre espace client.
        </p>
        <Button
          scope="button"
          type="primary"
          className="VerificationModal__action"
          onClick={handleSendVerificationEmail}
          loading={isVerificationEmailLoading}
          hasLoadingState
          disabled={
            hasSentVerificationEmail ||
            isVerificationEmailLoading ||
            !user.email
          }
        >
          Renvoyer un e-mail de vérification
        </Button>
        <button
          type="button"
          className="VerificationModal__action"
          onClick={() => setLogoutIsVisible(true)}
        >
          Je souhaite me déconnecter
        </button>
        <div className="VerificationModal__logout">
          <span className="VerificationModal__logout-separator" />
          <div className="VerificationModal__logout-actions">
            <Button
              type="danger"
              iconSide="left"
              icon={logoutIcon}
              color="#ef626c"
              className="VerificationModal__logout-action"
              onClick={handleConfirmLogout}
              rounded
              centered
            >
              Me déconnecter
            </Button>
            <Button
              scope="button"
              type="ghost"
              className="VerificationModal__logout-action"
              onClick={() => setLogoutIsVisible(false)}
              rounded
              centered
            >
              Annuler
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

VerificationModal.propTypes = {
  context: PropTypes.shape({
    user: PropTypes.shape({}),
    verificationModalIsOpen: PropTypes.bool.isRequired,
    // Actions
    closeVerificationModal: PropTypes.func.isRequired,
  }).isRequired,
};

export default withAppContext(VerificationModal);
