import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { CARD_SLIDER_SETTINGS } from '../../../config/product';

import typeShape from '../../../shapes/typeShape';
import productShape from '../../../shapes/productShape';
import supplierShape from '../../../shapes/supplierShape';

import ProductCard from './ProductCard';

import '../../../styles/components/Blocks/Product/ProductSimilar.css';

class ProductSimilar extends PureComponent {
  render() {
    const {
      type,
      suppliers,
      similarProducts,
      clickSimilarProduct,
    } = this.props;

    if (similarProducts.length > 0) {
      return (
        <div className="ProductSimilar">
          <div className="ProductSimilar__container">
            <h3 className="ProductSimilar__title">Produits similaires</h3>
            <div className="ProductSimilar__items">
              <Slider {...CARD_SLIDER_SETTINGS}>
                {similarProducts.map(product => {
                  const supplier = suppliers.find(
                    supplier => supplier.id === product.supplier,
                  );

                  return (
                    <ProductCard
                      product={product}
                      type={type}
                      supplier={supplier}
                      key={product.id}
                      onClick={() =>
                        clickSimilarProduct(product.id, product.title)
                      }
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

ProductSimilar.defaultProps = {
  suppliers: [],
  similarProducts: [],
};

ProductSimilar.propTypes = {
  type: PropTypes.shape(typeShape).isRequired,
  suppliers: PropTypes.arrayOf(PropTypes.shape(supplierShape)),
  clickSimilarProduct: PropTypes.func.isRequired,
  similarProducts: PropTypes.arrayOf(PropTypes.shape(productShape)),
};

export default ProductSimilar;
