import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/components/Core/LoadingGradient.css';

const LoadingGradient = ({ width, height, className, style }) => (
  <div
    className={`LoadingGradient ${className}`}
    style={{ ...style, width, height }}
  />
);

LoadingGradient.defaultProps = {
  width: null,
  height: null,
  className: '',
  style: {},
};

LoadingGradient.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

export default LoadingGradient;
