import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as Joi from 'joi-browser';
import { Button, ArrowRight } from '@robinfinance/ui';

import {
  INITIAL_CONTACT_FORM_DATA,
  EMPTY_ERROR_MESSAGES,
  INCORRECT_ERROR_MESSAGES,
  MOTIVE_OPTIONS,
} from '../config/emailForms';

import contactSchema, {
  subjectSchema,
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  phoneNumberSchema,
  messageSchema,
} from '../helpers/schema/contactFormSchema';

import pageShape from '../shapes/pageShape';

import {
  sendContactEmail,
  resetContactStatus,
} from '../actions/ContactActions';
import { fetchPage } from '../actions/PageActions';
import { pageIsLoaded } from '../actions/AppActions';

import PageContext from '../contexts/PageContext';

import toJS from '../components/HOC/toJS';
import Breadcrumbs from '../components/Core/Breadcrumbs';
import Select from '../components/Core/Select';
import Input from '../components/Core/Input';
import Textarea from '../components/Core/Textarea';
import Icon from '../components/Core/Icon';
import Alert from '../components/Core/Alert';
import FiveHundred from '../components/Layout/FiveHundred';
import FourOFour from '../components/Layout/FourOFour';
import DisclaimerBlock from '../components/Blocks/DisclaimerBlock/DisclaimerBlock';
import PrismicTextImageLoading from '../components/Prismic/Slices/Loading/PrismicTextImageLoading';

import image from '../assets/images/illustration-boulier.svg';
import curvedArrow from '../assets/icons/fl-che-illus.svg';

import '../styles/containers/Contact.css';

class Contact extends PureComponent {
  schemas = {
    subject: subjectSchema,
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    email: emailSchema,
    phoneNumber: phoneNumberSchema,
    message: messageSchema,
  };

  state = {
    errors: {},
    submitted: false,
    formData: INITIAL_CONTACT_FORM_DATA,
    clickSubmit: false,
  };

  componentDidMount() {
    const { loading, page, fetchPage, pageIsLoaded } = this.props;

    if (!loading && !page) {
      fetchPage({ slug: 'contactez-nous' });
    }

    if (page) {
      pageIsLoaded(page.gaTitle);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      loading,
      page,
      pageIsLoaded,
      contactLoading,
      contactError,
    } = this.props;

    if (prevProps.loading && !loading && page) {
      pageIsLoaded(page.gaTitle);
    }

    if (prevProps.contactLoading && !contactLoading && !contactError) {
      this.handleSubmitted();
    }
  }

  componentWillUnmount() {
    const { resetContactStatus } = this.props;

    resetContactStatus();
  }

  validateInput = async (name, value) => {
    const { errors } = this.state;
    const data = { [name]: value };
    const schema = this.schemas[name];

    try {
      await Joi.validate(data, schema);
      this.setState({ errors: { ...errors, [name]: [] } });
    } catch (error) {
      if (error.details[0].type === 'any.empty') {
        this.setState({
          errors: { ...errors, [name]: [EMPTY_ERROR_MESSAGES[name]] },
        });
      } else {
        this.setState({
          errors: { ...errors, [name]: [INCORRECT_ERROR_MESSAGES[name]] },
        });
      }
    }
  };

  handleSubmitted = () => {
    this.setState({
      submitted: true,
      formData: INITIAL_CONTACT_FORM_DATA,
      errors: {},
    });
  };

  handleChange = (name, value) => {
    const { formData } = this.state;

    this.setState({ formData: { ...formData, [name]: value } });
  };

  handleBlur = (name, value) => {
    this.validateInput(name, value);
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { utmCampaign, utmSource, utmMedium, sendContactEmail } = this.props;
    const { formData } = this.state;

    this.setState({ submitted: false, clickSubmit: true });

    try {
      const data = await Joi.validate(formData, contactSchema, {
        abortEarly: false,
      });
      sendContactEmail({
        ...data,
        utmCampaign,
        utmSource,
        utmMedium,
      });
    } catch (error) {
      const inputErrors = {};

      error.details.forEach(inputError => {
        const name = inputError.context.key;

        if (inputError.type === 'any.empty') {
          inputErrors[name] = [EMPTY_ERROR_MESSAGES[name]];
        } else if (!inputErrors[name]) {
          inputErrors[name] = [INCORRECT_ERROR_MESSAGES[name]];
        }
      });

      this.setState({ errors: inputErrors });
    }
  };

  renderLoading = () => (
    <div className="Page Page--loading">
      <PageContext.Provider>
        <Breadcrumbs />
        <div className="PrismicBlock">
          <div className="PrismicBlock__container">
            <PrismicTextImageLoading />
          </div>
        </div>
        <div className="PrismicBlock">
          <div className="PrismicBlock__container">
            <PrismicTextImageLoading inverted />
          </div>
        </div>
      </PageContext.Provider>
    </div>
  );

  render() {
    const {
      page,
      loading,
      error,
      notFound,
      contactLoading,
      contactError,
      contactErrorMessage,
    } = this.props;
    const { errors, submitted, formData, clickSubmit } = this.state;

    if (loading && !page) {
      return this.renderLoading();
    }

    if (error) {
      return <FiveHundred pageIsLoaded={pageIsLoaded} />;
    }

    if (notFound || !page) {
      return <FourOFour pageIsLoaded={pageIsLoaded} />;
    }

    return (
      <Fragment>
        <Helmet>
          <title>{page.title}</title>
          <meta name="description" content={page.description} />

          <meta property="og:title" content={page.title} />
          <meta property="og:description" content={page.description} />
          <meta
            property="og:url"
            content={`https://www.mieuxplacer.com/${page.slug}`}
          />
          <meta property="og:type" content="page" />

          <link
            rel="canonical"
            href={`https://www.mieuxplacer.com/${page.slug}`}
          />
        </Helmet>

        <PageContext.Provider
          value={{
            page,
          }}
        >
          <Breadcrumbs />

          <div className="Contact">
            <div className="Contact__content">
              <div className="Contact__container">
                <div className="Contact__title-image">
                  <p className="Contact__title">
                    Contactez-nous{' '}
                    <span className="Contact__title-bullet">.</span>
                  </p>
                  <p className="Contact__text">
                    Une question sur notre service ou sur nos produits ? Nos
                    conseillers vous répondent du lundi au vendredi&nbsp;!
                  </p>
                  <img
                    src={image}
                    alt="boulier"
                    className="Contact__image-desk"
                    height="303px"
                    width="417px"
                  />
                </div>
                <form className="Contact__form" onSubmit={this.handleSubmit}>
                  <div className="Contact__info">
                    <p>
                      Afin de traiter au mieux votre demande, nous vous
                      demandons de remplir tous les champs ci-dessous.
                    </p>
                  </div>

                  <Select
                    name="subject"
                    label="Motif de votre demande"
                    options={MOTIVE_OPTIONS}
                    className="Contact__select"
                    iconClass="iconStyle motifIcon"
                    value={formData.subject}
                    errors={errors.subject}
                    disabled={contactLoading}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                  <Input
                    type="text"
                    name="firstName"
                    label="Votre prénom"
                    iconClass="iconStyle profilIcon"
                    hideOnYandex
                    value={formData.firstName}
                    errors={errors.firstName}
                    disabled={contactLoading}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                  <Input
                    type="text"
                    name="lastName"
                    label="Votre nom"
                    iconClass="iconStyle profilIcon"
                    hideOnYandex
                    value={formData.lastName}
                    errors={errors.lastName}
                    disabled={contactLoading}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                  <Input
                    type="email"
                    name="email"
                    label="Votre e-mail"
                    iconClass="iconStyle emailIcon"
                    hideOnYandex
                    value={formData.email}
                    errors={errors.email}
                    disabled={contactLoading}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                  <Input
                    type="tel"
                    name="phoneNumber"
                    label="Votre téléphone portable"
                    iconClass="iconStyle phoneIcon"
                    hideOnYandex
                    value={formData.phoneNumber}
                    errors={errors.phoneNumber}
                    disabled={contactLoading}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                  <Textarea
                    name="message"
                    label="Votre message"
                    rows="4"
                    cols="20"
                    iconClass="iconStyle messageIcon"
                    value={formData.message}
                    errors={errors.message}
                    disabled={contactLoading}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                  {submitted && (
                    <div className="Contact__messages">
                      <Alert type="success">
                        Votre message a bien été envoyé.
                      </Alert>
                    </div>
                  )}
                  {contactError && (
                    <div className="Contact__messages">
                      <Alert type="error">
                        {contactErrorMessage ||
                          'Merci de vérifier que vous avez correctement rempli tous les champs du formulaire ci-dessous.'}
                      </Alert>
                    </div>
                  )}
                  {clickSubmit &&
                    !submitted &&
                    !contactError &&
                    ((errors.email && errors.email.length > 0) ||
                      (errors.lastName && errors.lastName.length > 0) ||
                      (errors.firstName && errors.firstName.length > 0) ||
                      (errors.phoneNumber && errors.phoneNumber.length > 0) ||
                      (errors.subject && errors.subject.length > 0) ||
                      (errors.message && errors.message.length > 0)) && (
                      <div className="Contact__messages">
                        <Alert type="error">
                          <p>
                            Merci de vérifier que vous avez correctement rempli
                            tous les champs du formulaire ci-dessous.
                          </p>
                        </Alert>
                      </div>
                    )}
                  <Button
                    scope="button"
                    type="secondary"
                    iconSide="right"
                    icon={<ArrowRight color="#223b5d" />}
                    onClick={this.handleSubmit}
                    disabled={contactLoading}
                    loading={contactLoading}
                    hasLoadingState
                  >
                    {contactLoading ? 'Patienter' : 'Envoyer mon message'}
                  </Button>
                  <div className="Conctact__sticker_container">
                    <div className="Contact__handwritten_text">
                      Nous vous répondrons très très vite !
                    </div>
                    <Icon
                      src={curvedArrow}
                      alt="curvedArrow"
                      width="20px"
                      height="30px"
                      className="Contact__arrowIcon"
                    />
                  </div>
                </form>
                <img
                  src={image}
                  alt="boulier"
                  className="Contact__image-mob"
                  height="303px"
                  width="417px"
                />
              </div>
              <div className="Contact__disclaimer">
                <DisclaimerBlock />
              </div>
            </div>
          </div>
        </PageContext.Provider>
      </Fragment>
    );
  }
}

Contact.defaultProps = {
  page: null,
  loading: false,
  error: false,
  notFound: false,
  contactErrorMessage: null,
};

Contact.propTypes = {
  page: PropTypes.shape(pageShape),
  loading: PropTypes.bool,
  error: PropTypes.bool,
  notFound: PropTypes.bool,
  contactLoading: PropTypes.bool.isRequired,
  contactError: PropTypes.bool.isRequired,
  contactErrorMessage: PropTypes.string,
  utmCampaign: PropTypes.string.isRequired,
  utmSource: PropTypes.string.isRequired,
  utmMedium: PropTypes.string.isRequired,
  sendContactEmail: PropTypes.func.isRequired,
  resetContactStatus: PropTypes.func.isRequired,
  fetchPage: PropTypes.func.isRequired,
  pageIsLoaded: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  page: state.get('pageState').get('pages').get('contactez-nous'),
  loading:
    state
      .get('pageState')
      .get('loading')
      .find(item => item === 'contactez-nous') !== undefined,
  error:
    state
      .get('pageState')
      .get('error')
      .find(item => item === 'contactez-nous') !== undefined,
  notFound:
    state
      .get('pageState')
      .get('notFound')
      .find(item => item === 'contactez-nous') !== undefined,
  contactLoading: state.get('contactState').get('contact').get('loading'),
  contactError: state.get('contactState').get('contact').get('error'),
  contactErrorMessage: state
    .get('contactState')
    .get('contact')
    .get('errorMessage'),
  utmCampaign: state.get('appState').get('utmCampaign'),
  utmSource: state.get('appState').get('utmSource'),
  utmMedium: state.get('appState').get('utmMedium'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPage,
      sendContactEmail,
      resetContactStatus,
      pageIsLoaded,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Contact));
