import PropTypes from 'prop-types';

const baseSliceShape = {
  slice_type: PropTypes.string,
  slice_label: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
};

export const sliceShape = {
  ...baseSliceShape,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  primary: PropTypes.shape({}),
};

export const imageShape = {
  url: PropTypes.string,
  alt: PropTypes.string,
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

export const fileShape = {
  link_type: PropTypes.string,
  name: PropTypes.string,
  kind: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.string,
};

export const selectionShape = {
  options: PropTypes.arrayOf(PropTypes.string),
};

export const paragraphShape = {
  type: PropTypes.string,
  text: PropTypes.string,
  formatting: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number,
      stop: PropTypes.number,
      type: PropTypes.string,
    }),
  ),
};

export const sliceParagraphShape = {
  type: PropTypes.string,
  text: PropTypes.string,
  spans: PropTypes.arrayOf(PropTypes.shape({})),
};

export const urlShape = {
  link_type: PropTypes.oneOf(['Any', 'Web', 'Document', 'Media']),
  uid: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string,
};

export const headlineWithBlocksShape = {
  ...baseSliceShape,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      headline_with_blocks_block_title: PropTypes.string,
      headline_with_blocks_block_description: PropTypes.arrayOf(
        PropTypes.shape(sliceParagraphShape),
      ),
    }),
  ),
  primary: PropTypes.shape({
    headline_with_blocks_title: PropTypes.string,
    headline_with_blocks_subtitle: PropTypes.string,
    headline_with_block_catchphrase: PropTypes.string,
    headline_with_blocks_catchphrase_logo: PropTypes.shape(imageShape),
  }),
};

export const downloadsShape = {
  ...baseSliceShape,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      download_icon: PropTypes.shape(imageShape),
      download_title: PropTypes.string,
      download_file: PropTypes.shape(fileShape),
    }),
  ),
};

export const bonusShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    bonus_title: PropTypes.string,
    bonus_text: PropTypes.arrayOf(PropTypes.shape(sliceParagraphShape)),
    bonus_icon: PropTypes.shape(imageShape),
    bonus_price: PropTypes.number,
    bonus_prime: PropTypes.string,
  }),
};

export const importantInfoShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    important_information_title: PropTypes.string,
    important_information_description: PropTypes.arrayOf(
      PropTypes.shape(sliceParagraphShape),
    ),
    important_information_icon: PropTypes.shape(imageShape),
  }),
};

export const textImageShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    is_page_header: PropTypes.oneOf(['Oui', 'Non']),
    text_image_annotation: PropTypes.string,
    text_image_button_label: PropTypes.string,
    text_image_button_url: PropTypes.shape(urlShape),
    text_image_color: PropTypes.color,
    text_image_description: PropTypes.arrayOf(
      PropTypes.shape(sliceParagraphShape),
    ),
    text_image_description_heading: PropTypes.arrayOf(
      PropTypes.shape(sliceParagraphShape),
    ),
    text_image_image: PropTypes.shape(imageShape),
    text_image_order: PropTypes.oneOf(['Gauche', 'Droite']),
    text_image_pre_title: PropTypes.string,
    text_image_title: PropTypes.arrayOf(PropTypes.shape(sliceParagraphShape)),
  }),
};

export const multiblocksiconsShape = {
  ...baseSliceShape,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  primary: PropTypes.shape({}),
};

export const objectivesAndTypesShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    listing_products_title_left: PropTypes.string,
    listing_products_title_right: PropTypes.string,
  }),
};

export const featuredProductsShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    featured_products_link: PropTypes.string,
    featured_products_title: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      featured_products_product: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  ),
};

export const smallBlockListShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    small_block_list_color: PropTypes.string,
    small_block_list_pre_title: PropTypes.string,
    small_block_list_title: PropTypes.arrayOf(
      PropTypes.shape(sliceParagraphShape),
    ),
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      small_block_list_block_description: PropTypes.arrayOf(
        PropTypes.shape(sliceParagraphShape),
      ),
      small_block_list_block_icon: PropTypes.shape(imageShape),
      small_block_list_block_title: PropTypes.string,
      small_block_list_url: PropTypes.shape(imageShape),
      small_block_list_url_label: PropTypes.string,
    }),
  ),
};

export const trustpilotShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    trustpilot_title: PropTypes.string,
  }),
};

export const contentShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    content_title: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
    content_content: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
  }),
};

export const textNumberShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    text_number_title: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
    text_number_content: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
    text_image_statistic_value: PropTypes.string,
    text_image_statistic_label: PropTypes.string,
  }),
};

export const hightlightShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    highlight_type: PropTypes.oneOf(['Positif', 'Négatif']),
    highlight_title: PropTypes.string,
    highlight_content: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
    highlight_button_label: PropTypes.string,
    highlight_button_link: PropTypes.shape(urlShape),
  }),
};

export const stepsShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    steps_title: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      steps_step_label: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
    }),
  ),
};

export const filesShape = {
  ...baseSliceShape,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      files_file_name: PropTypes.string,
      files_file_link: PropTypes.shape(fileShape),
    }),
  ),
};

export const pageTitleShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    page_title: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
  }),
};

export const tableTwoColulmnsShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    table_column_1_title: PropTypes.string,
    table_column_2_title: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      table_line_column_1_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
      table_line_column_2_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
    }),
  ),
};

export const tableThreeColulmnsShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    table_column_1_title: PropTypes.string,
    table_column_2_title: PropTypes.string,
    table_column_3_title: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      table_line_column_1_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
      table_line_column_2_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
      table_line_column_3_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
    }),
  ),
};

export const tableFourColulmnsShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    table_column_1_title: PropTypes.string,
    table_column_2_title: PropTypes.string,
    table_column_3_title: PropTypes.string,
    table_column_4_title: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      table_line_column_1_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
      table_line_column_2_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
      table_line_column_3_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
      table_line_column_4_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
    }),
  ),
};

export const tableFiveColulmnsShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    table_column_1_title: PropTypes.string,
    table_column_2_title: PropTypes.string,
    table_column_3_title: PropTypes.string,
    table_column_4_title: PropTypes.string,
    table_column_5_title: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      table_line_column_1_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
      table_line_column_2_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
      table_line_column_3_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
      table_line_column_4_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
      table_line_column_5_value: PropTypes.arrayOf(
        PropTypes.shape(paragraphShape),
      ),
    }),
  ),
};

export const commissionFeesShape = {
  ...baseSliceShape,
  primary: PropTypes.shape({
    column_one_title: PropTypes.string,
    column_two_title: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      outstanding_average_fee: PropTypes.number,
      outstanding_maximum_fee: PropTypes.number,
      outstanding_minimum_fee: PropTypes.number,
      outstanding_value: PropTypes.number,
      product_type_label: PropTypes.string,
      subscription_average_fee: PropTypes.number,
      subscription_maximum_fee: PropTypes.number,
      subscription_minimum_fee: PropTypes.number,
      subscription_value: PropTypes.number,
    }),
  ),
};
