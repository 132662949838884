import React from 'react';
import PropTypes from 'prop-types';

import typeShape from '../../../shapes/typeShape';
import objectiveShape from '../../../shapes/objectiveShape';

import withGuideContext from '../../HOC/withGuideContext';

const GuideItemBreadcrumb = ({ context }) => {
  const { item } = context;

  return <span className="Breadcrumbs__item">{item && item.label}</span>;
};

GuideItemBreadcrumb.defaultProps = {
  context: {
    item: {},
  },
};

GuideItemBreadcrumb.propTypes = {
  context: PropTypes.shape({
    item: PropTypes.oneOfType([
      PropTypes.shape(typeShape),
      PropTypes.shape(objectiveShape),
    ]),
  }),
};

export default withGuideContext(GuideItemBreadcrumb);
