import React, { useRef, useEffect, useState } from 'react';
import { Alert, Accordion } from '@robinfinance/ui';

import '../../../styles/components/Blocks/DisclaimerBlock/DisclaimerBlock.css';

function DisclaimerBlock() {
  const containerRef = useRef(null);

  const [isMobile, setIsMobile] = useState(false);

  function handleResize() {
    if (containerRef.current && containerRef.current.clientWidth <= 450) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderContent() {
    return (
      <ul ref={containerRef} className="DisclaimerBlock">
        <li className="DisclaimerBlock__item">
          Vos données à caractère personnel sont collectées par la société
          Robin’Finance (Mieuxplacer.com) à des fins de communication et
          d’échange d’informations avec vous en vue de répondre à vos demandes.
          Robin’Finance s’engage à ne jamais vendre ni louer vos données à des
          tiers, quels qu’ils soient.
        </li>
        <li className="DisclaimerBlock__item">
          Conformément à la réglementation sur la protection des données, vous
          disposez d’un droit d’accès, de rectification, de portabilité et
          d’effacement des données vous concernant. Pour plus d’informations sur
          le traitement de vos données à caractère personnel et l’exercice de
          vos droits, consultez notre{' '}
          <a
            className="DisclaimerBlock__link"
            href="/documents/Charte-donnees-personnelles-MP.pdf"
            target="_blank"
          >
            charte Données Personnelles
          </a>
          .
        </li>
      </ul>
    );
  }

  return (
    <Alert>
      {isMobile ? (
        <Accordion showPartial showOpacity backgroundColor="#eceff2">
          {renderContent()}
        </Accordion>
      ) : (
        renderContent()
      )}
    </Alert>
  );
}

export default DisclaimerBlock;
