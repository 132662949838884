import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@robinfinance/ui';

import { urlShape } from '../../../shapes/prismicShape';

import Link from '../../Core/Link';

import '../../../styles/components/Prismic/Core/PrismicLink.css';

class PrismicLink extends PureComponent {
  render() {
    const { link, button, label, className, children } = this.props;
    let url;

    const classes = classNames('PrismicLink', {
      'PrismicLink--button': button,
      [className]: className,
    });

    const linkType = link.linkType || link.link_type;

    switch (linkType) {
      case 'Document':
        switch (link.type) {
          case 'type':
            url = `/guide/${link.uid}`;
            break;

          case 'products':
            url = `/produits/${link.uid}`;
            break;

          default:
            url = `/${link.uid}`;
            break;
        }

        if (button) {
          return (
            <Button scope="button" type="secondary" to={url}>
              {label}
            </Button>
          );
        }

        return (
          <Link to={url} className={classes}>
            {children}
          </Link>
        );

      case 'Web':
        url = new URL(link.url);

        if (url.hostname === 'www.mieuxplacer.com') {
          if (button) {
            return (
              <Button
                scope="button"
                type="secondary"
                to={`${url.pathname}${url.search}`}
              >
                {label}
              </Button>
            );
          }

          return (
            <Link to={`${url.pathname}${url.search}`} className={classes}>
              {children}
            </Link>
          );
        }

        if (button) {
          return (
            <Button
              className={classes}
              scope="button"
              type="secondary"
              href={link.url}
              target={link.target}
            >
              {label}
            </Button>
          );
        }

        return (
          <Link href={link.url} target={link.target} className={classes}>
            {children}
          </Link>
        );

      default:
        return null;
    }
  }
}

PrismicLink.defaultProps = {
  button: false,
  label: '',
  className: '',
  children: null,
};

PrismicLink.propTypes = {
  link: PropTypes.shape(urlShape).isRequired,
  button: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default PrismicLink;
