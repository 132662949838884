import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import HeaderLink from './HeaderLink';
import SubMenu from './SubMenu';

import '../../../styles/components/Blocks/Header/DropDownMenu.css';

function DropDownMenu({ label, to, items }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleMouseEnter() {
    setIsOpen(true);
  }

  function handleMouseLeave() {
    setIsOpen(false);
  }

  const classes = classNames('DropDownMenu', {
    'DropDownMenu--is-open': isOpen,
  });

  return (
    <div className={classes}>
      <HeaderLink
        to={to}
        label={label}
        dropdown
        onClick={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <div
        className="DropDownMenu__submenu"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="DropDownMenu__container">
          <NavLink
            exact
            to={to}
            className="DropDownMenu__link"
            activeClassName="DropDownMenu__link--active"
            onClick={handleMouseLeave}
          >
            {label}
          </NavLink>
          {items.map(item => {
            if (item.subRoutes) {
              return (
                <SubMenu
                  key={item.id}
                  label={item.label}
                  to={item.to}
                  items={item.subRoutes}
                />
              );
            }

            return (
              <NavLink
                exact
                key={item.id}
                to={item.to}
                className="DropDownMenu__link"
                activeClassName="DropDownMenu__link--active"
                onClick={handleMouseLeave}
              >
                {item.label}
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
}

DropDownMenu.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      to: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default DropDownMenu;
