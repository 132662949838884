import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../styles/components/Core/Switch.css';

class Switch extends PureComponent {
  state = {
    checked: false,
  };

  handleChange = () => {
    const { checked } = this.state;
    const { onChange } = this.props;

    const newCheckedValue = !checked;

    this.setState({ checked: newCheckedValue });

    onChange(newCheckedValue);
  };

  render() {
    const { leftLabel, rightLabel } = this.props;
    const { checked } = this.state;

    const switchClasses = classNames('Switch', {
      'Switch--checked': checked,
    });

    return (
      <button
        type="button"
        className={switchClasses}
        onClick={this.handleChange}
      >
        {leftLabel && (
          <span className="Switch__label Switch__label--left">{leftLabel}</span>
        )}
        {rightLabel && (
          <span className="Switch__label Switch__label--right">
            {rightLabel}
          </span>
        )}
      </button>
    );
  }
}

Switch.defaultProps = {
  leftLabel: '',
  rightLabel: '',
};

Switch.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Switch;
