import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { pageIsLoaded } from '../actions/AppActions';

import typeShape from '../shapes/typeShape';
import objectiveShape from '../shapes/objectiveShape';

import GuideContext from '../contexts/GuideContext';

import toJS from '../components/HOC/toJS';
import FourOFour from '../components/Layout/FourOFour';
import Breadcrumbs from '../components/Core/Breadcrumbs';
import Type from '../components/Blocks/Guide/Type';
import Project from '../components/Blocks/Guide/Project';
import PrismicContentLoading from '../components/Prismic/Slices/Loading/PrismicContentLoading';
import FiveHundred from '../components/Layout/FiveHundred';

class Guide extends PureComponent {
  renderLoading = () => (
    <div className="Guide Guide--loading">
      <GuideContext.Provider>
        <Breadcrumbs />

        <div className="PrismicBlock">
          <PrismicContentLoading />
        </div>
        <div className="PrismicBlock">
          <PrismicContentLoading />
        </div>
      </GuideContext.Provider>
    </div>
  );

  render() {
    const {
      typesLoading,
      typesError,
      types,
      objectivesLoading,
      objectivesError,
      objectives,
      match: {
        params: { slug },
      },
      pageIsLoaded,
    } = this.props;

    const type = types.find(type => type.slug === slug);
    const objective = objectives.find(objective => objective.slug === slug);

    if (type) {
      return (
        <GuideContext.Provider value={{ item: type, pageIsLoaded }}>
          <Type />
        </GuideContext.Provider>
      );
    }

    if (objective) {
      return (
        <GuideContext.Provider value={{ item: objective, pageIsLoaded }}>
          <Project />
        </GuideContext.Provider>
      );
    }

    if (typesLoading || objectivesLoading) {
      return this.renderLoading();
    }

    if (typesError || objectivesError) {
      return <FiveHundred pageIsLoaded={pageIsLoaded} />;
    }

    return <FourOFour pageIsLoaded={pageIsLoaded} />;
  }
}

Guide.propTypes = {
  typesLoading: PropTypes.bool.isRequired,
  typesError: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape(typeShape)).isRequired,
  objectivesLoading: PropTypes.bool.isRequired,
  objectivesError: PropTypes.bool.isRequired,
  objectives: PropTypes.arrayOf(PropTypes.shape(objectiveShape)).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ slug: PropTypes.string }),
  }).isRequired,
  pageIsLoaded: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  typesLoading: state.get('typeState').get('typesLoading'),
  typesError: state.get('typeState').get('typesError'),
  types: state.get('typeState').get('types'),
  objectivesLoading: state.get('objectiveState').get('objectivesLoading'),
  objectivesError: state.get('objectiveState').get('objectivesError'),
  objectives: state.get('objectiveState').get('objectives'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ pageIsLoaded }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Guide));
