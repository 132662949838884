import { put, call, takeLatest } from 'redux-saga/effects';

import MieuxplacerAPI from '../api/Mieuxplacer';

import {
  FETCH_PRODUCTS__REQUEST,
  FETCH_PRODUCTS__SUCCESS,
  FETCH_PRODUCTS__FAIL,
  FETCH_FILTERED_PRODUCTS__REQUEST,
  FETCH_FILTERED_PRODUCTS__SUCCESS,
  FETCH_FILTERED_PRODUCTS__FAIL,
} from '../actions/ProductActions';

function* fetchProducts({ payload }) {
  let params = {};

  if (payload) {
    params = {
      ...payload,
      filters: payload.filters
        ? { ...payload.filters, only_for_cgp: '!Oui' }
        : { only_for_cgp: '!Oui' },
    };
  } else {
    params = { filters: { only_for_cgp: '!Oui' } };
  }

  try {
    const apiCall = () => MieuxplacerAPI.Api.getPrismicProducts(params);
    const data = yield call(apiCall);

    yield put({
      type: FETCH_PRODUCTS__SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_PRODUCTS__FAIL,
      payload: e.message,
    });
  }
}

function* fetchFilteredProducts({ payload }) {
  let params = {};

  if (payload) {
    params = {
      ...payload,
      fields: ['id'],
      filters: payload.filters
        ? { ...payload.filters, only_for_cgp: '!Oui' }
        : { only_for_cgp: '!Oui' },
    };
  } else {
    params = { fields: ['id'], filters: { only_for_cgp: '!Oui' } };
  }

  try {
    const apiCall = () => MieuxplacerAPI.Api.getPrismicProducts(params);
    const data = yield call(apiCall);

    yield put({
      type: FETCH_FILTERED_PRODUCTS__SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_FILTERED_PRODUCTS__FAIL,
      payload: e.message,
    });
  }
}

const productSagas = [
  takeLatest(FETCH_PRODUCTS__REQUEST, fetchProducts),
  takeLatest(FETCH_FILTERED_PRODUCTS__REQUEST, fetchFilteredProducts),
];

export default productSagas;
