import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { Button } from '@robinfinance/ui';

import logoSwissLife from '../../../assets/images/logo-swisslife.png';
import logoSwissLife2X from '../../../assets/images/logo-swisslife@2x.png';
import logoPrimonial from '../../../assets/images/logo-primonial.png';
import logoPrimonial2X from '../../../assets/images/logo-primonial@2x.png';
import logoLFDE from '../../../assets/images/logo-lfde.png';
import logoLFDE2X from '../../../assets/images/logo-lfde@2x.png';
import logoACM from '../../../assets/images/logo-acm.png';
import logoACM2X from '../../../assets/images/logo-acm@2x.png';
import logoCardif from '../../../assets/images/logo-cardif.png';
import logoCardif2X from '../../../assets/images/logo-cardif@2x.png';
import logoSpirica from '../../../assets/images/logo-spirica.png';
import logoSpirica2X from '../../../assets/images/logo-spirica@2x.png';
import logoVoisin from '../../../assets/images/logo-voisin.png';
import logoVoisin2X from '../../../assets/images/logo-voisin@2x.png';
import logoPerial from '../../../assets/images/logo-perial.png';
import logoPerial2X from '../../../assets/images/logo-perial@2x.png';

import '../../../styles/components/Blocks/Home/OurProducts.css';

function OurProducts() {
  const contractsRef = useRef(null);

  const [screenSize, setScreenSize] = useState(0);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  const [animation, setAnimation, stopAnimation] = useSpring(() => ({
    from: { scroll: 0 },
  }));

  function handleResize() {
    setScreenSize(window.innerWidth);
  }

  function handleStopAnimation() {
    setShouldAnimate(false);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (contractsRef.current) {
      contractsRef.current.addEventListener('wheel', handleStopAnimation);

      return () => {
        contractsRef.current.removeEventListener('wheel', handleStopAnimation);
      };
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (screenSize <= 1150 && shouldAnimate) {
      const interval = setInterval(() => {
        setAnimation({ to: { scroll: 20 } });

        setTimeout(() => {
          setAnimation({ to: { scroll: 0 } });
        }, 500);
      }, 4000);

      return () => {
        clearInterval(interval);
        stopAnimation();
      };
    }

    return () => {};
  }, [screenSize, shouldAnimate]);

  return (
    <div className="OurProducts">
      <div className="OurProducts__container">
        <div className="OurProducts__content-container">
          <div className="OurProducts__header">
            <h2 className="OurProducts__title">
              Les meilleurs contrats du marché
            </h2>
            <Button
              id="Home-OurProducts-CTA-1"
              scope="link"
              type="primary"
              to="/produits"
              className="OurProducts__header-link"
            >
              Voir tous nos contrats
            </Button>
          </div>
          <div className="OurProducts__content">
            <p className="OurProducts__content-paragraph">
              <b>Assurance vie, SCPI ou Défiscalisation ?</b>
            </p>
            <p className="OurProducts__content-paragraph">
              Nos experts en gestion de patrimoine ont sélectionné <br /> plus
              de 150 contrats et 60 fournisseurs.
            </p>
          </div>
          <animated.div
            ref={contractsRef}
            className="OurProducts__contracts"
            scrollLeft={animation.scroll}
            onTouchStart={handleStopAnimation}
          >
            <div className="OurProducts__contract">
              <div className="OurProducts__contract-container">
                <img
                  src={logoSwissLife}
                  srcSet={`${logoSwissLife2X} 2x`}
                  alt="Logo SwissLife"
                />
              </div>
            </div>
            <div className="OurProducts__contract">
              <div className="OurProducts__contract-container">
                <img
                  src={logoPrimonial}
                  srcSet={`${logoPrimonial2X} 2x`}
                  alt="Logo Primonial"
                />
              </div>
            </div>
            <div className="OurProducts__contract">
              <div className="OurProducts__contract-container">
                <img
                  src={logoLFDE}
                  srcSet={`${logoLFDE2X} 2x`}
                  alt="Logo LA Financière de l'Echiquier"
                />
              </div>
            </div>
            <div className="OurProducts__contract">
              <div className="OurProducts__contract-container">
                <img
                  src={logoACM}
                  srcSet={`${logoACM2X} 2x`}
                  alt="Logo Assurances Crédit Mutuel"
                />
              </div>
            </div>
            <div className="OurProducts__contract">
              <div className="OurProducts__contract-container">
                <img
                  src={logoCardif}
                  srcSet={`${logoCardif2X} 2x`}
                  alt="Logo BNP Cardif"
                />
              </div>
            </div>
            <div className="OurProducts__contract">
              <div className="OurProducts__contract-container">
                <img
                  src={logoSpirica}
                  srcSet={`${logoSpirica2X} 2x`}
                  alt="Logo Spirica"
                />
              </div>
            </div>
            <div className="OurProducts__contract">
              <div className="OurProducts__contract-container">
                <img
                  src={logoVoisin}
                  srcSet={`${logoVoisin2X} 2x`}
                  alt="Logo Voisin"
                />
              </div>
            </div>
            <div className="OurProducts__contract">
              <div className="OurProducts__contract-container">
                <img
                  src={logoPerial}
                  srcSet={`${logoPerial2X} 2x`}
                  alt="Logo Perial"
                />
              </div>
            </div>
          </animated.div>
        </div>
      </div>
    </div>
  );
}

export default OurProducts;
