import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function LazyImage({ observer, className, src, alt }) {
  const imageRef = useRef(null);

  useEffect(() => {
    if (observer !== null && imageRef.current) {
      observer.observe(imageRef.current);

      return () => {
        observer.unobserve(imageRef.current);
      };
    }

    return () => {};
  }, [observer]);

  return <img ref={imageRef} className={className} data-src={src} alt={alt} />;
}

LazyImage.defaultProps = {
  observer: null,
  className: null,
};

LazyImage.propTypes = {
  observer: PropTypes.shape({}),
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default LazyImage;
