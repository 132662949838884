import {
  LAUNCH_CONVERSION_LINKER,
  LAUNCH_SERVICES,
  SHOW_TESTING_OVERLAY,
  SHOW_TESTING_FORM,
} from '../actions/AppActions';

const launchConversionLinker = () => ({
  event: 'Launch conversion linker',
});

const launchServices = () => ({
  event: 'Launch Services',
});

const showTestingOverlay = (action, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Show testing overlay',
    eventCategory: 'User testing',
    eventAction: 'Show overlay',
    page: nextRouterState.location.pathname,
    pageName,
  };
};

const showTestingForm = (action, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Show testing form',
    eventCategory: 'User testing',
    eventAction: 'Show form',
    page: nextRouterState.location.pathname,
    pageName,
  };
};

const appEventsMap = {
  [LAUNCH_CONVERSION_LINKER]: launchConversionLinker,
  [LAUNCH_SERVICES]: launchServices,
  [SHOW_TESTING_OVERLAY]: showTestingOverlay,
  [SHOW_TESTING_FORM]: showTestingForm,
};

export default appEventsMap;
