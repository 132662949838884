import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as Joi from 'joi-browser';
import { Button, ArrowRight } from '@robinfinance/ui';

import {
  INITIAL_LEAD_FORM_DATA,
  EMPTY_ERROR_MESSAGES,
  INCORRECT_ERROR_MESSAGES,
} from '../../../config/emailForms';

import productShape from '../../../shapes/productShape';
import supplierShape from '../../../shapes/supplierShape';
import typeShape from '../../../shapes/typeShape';

import leadFormSchema, {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  phoneNumberSchema,
} from '../../../helpers/schema/leadFormSchema';

import Input from '../../Core/Input';
import Alert from '../../Core/Alert';
import Modal from '../../Core/Modal';
import CloseModal from '../../Core/CloseModal';

import '../../../styles/components/Blocks/Product/ContactPopup.css';

class ContactPopup extends PureComponent {
  schemas = {
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    email: emailSchema,
    phoneNumber: phoneNumberSchema,
  };

  state = {
    errors: {},
    submitted: false,
    formData: INITIAL_LEAD_FORM_DATA,
    clickSubmit: false,
  };

  componentDidMount() {
    const { isPoppedUp } = this.props;

    if (isPoppedUp) {
      document.documentElement.classList.add('no-scroll');
    }
  }

  componentDidUpdate(prevProps) {
    const { leadLoading, leadError } = this.props;

    if (prevProps.leadLoading && !leadLoading && !leadError) {
      this.handleSubmitted();
    }
  }

  componentWillUnmount() {
    const { resetContactStatus } = this.props;

    resetContactStatus();
    document.documentElement.classList.remove('no-scroll');
  }

  handleClose = () => {
    const { closeModal } = this.props;

    closeModal();
  };

  validateInput = async (name, value) => {
    const { errors } = this.state;
    const data = { [name]: value };
    const schema = this.schemas[name];

    try {
      await Joi.validate(data, schema);
      this.setState({ errors: { ...errors, [name]: [] } });
    } catch (error) {
      if (error.details[0].type === 'any.empty') {
        this.setState({
          errors: { ...errors, [name]: [EMPTY_ERROR_MESSAGES[name]] },
        });
      } else {
        this.setState({
          errors: { ...errors, [name]: [INCORRECT_ERROR_MESSAGES[name]] },
        });
      }
    }
  };

  handleSubmitted = () => {
    this.setState({
      submitted: true,
      formData: INITIAL_LEAD_FORM_DATA,
    });
  };

  handleChange = (name, value) => {
    const { formData } = this.state;

    this.setState({ formData: { ...formData, [name]: value } });
  };

  handleBlur = (name, value) => {
    this.validateInput(name, value);
  };

  handleSubmit = async e => {
    e.preventDefault();
    const {
      product,
      supplier,
      type,
      utmCampaign,
      utmSource,
      utmMedium,
      sendLeadEmail,
    } = this.props;
    const { formData } = this.state;
    const data = {
      ...formData,
      productTitle: product.title,
      supplierName: supplier.name,
      productType: type.label,
    };

    this.setState({ submitted: false, clickSubmit: true });

    try {
      const validatedData = await Joi.validate(data, leadFormSchema, {
        abortEarly: false,
      });

      sendLeadEmail({
        ...validatedData,
        utmCampaign,
        utmSource,
        utmMedium,
      });
    } catch (error) {
      const inputErrors = {};

      error.details.forEach(inputError => {
        const name = inputError.context.key;

        if (inputError.type === 'any.empty') {
          inputErrors[name] = [EMPTY_ERROR_MESSAGES[name]];
        } else if (!inputErrors[name]) {
          inputErrors[name] = [INCORRECT_ERROR_MESSAGES[name]];
        }
      });

      this.setState({ errors: inputErrors });
    }
  };

  render() {
    const {
      isPoppedUp,
      closeModal,
      product,
      supplier,
      leadLoading,
      leadError,
      leadErrorMessage,
    } = this.props;
    const { errors, formData, submitted, clickSubmit } = this.state;

    return (
      <Modal isOpen={isPoppedUp} onClose={closeModal}>
        <div className="ContactPopup_Box">
          <div className="ContactPopup_header">
            <span>Être recontacté(e) pour ce produit</span>
            <CloseModal label="Fermer" onClick={closeModal} />
          </div>
          <div className="ContactPopup_LogoContainer">
            <div className="ContactPopup_Logo">
              {supplier && supplier.logo && (
                <img src={supplier.logo.url} alt="supplier-logo" />
              )}
            </div>
            <div>
              <div className="ContactPopup_title">{product.title}</div>
              <div className="ContactPopup_supplier">
                Proposé par <a href={supplier.link}>{supplier.name}</a>
              </div>
            </div>
          </div>
          <p className="ContactPopup_text">
            Ce produit n&#39;étant pas encore ouvert à la souscription, nous
            vous proposons de nous laisser vos coordonnées afin que nous
            puissions vous recontacter dès qu&#39;il le sera.
          </p>
          <form className="ContactPopup_Form" onSubmit={this.validateForm}>
            <div className="ContactPopup__info">
              <p>
                Afin de traiter au mieux votre demande, nous vous demandons de
                remplir tous les champs ci-dessous.
              </p>
            </div>

            <Input
              type="text"
              name="firstName"
              label="Votre prénom"
              iconClass="iconStyle profilIcon"
              hideOnYandex
              value={formData.firstName}
              errors={errors.firstName}
              disabled={leadLoading}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            <Input
              type="text"
              name="lastName"
              label="Votre nom"
              iconClass="iconStyle profilIcon"
              hideOnYandex
              value={formData.lastName}
              errors={errors.lastName}
              disabled={leadLoading}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            <Input
              type="email"
              name="email"
              label="Votre e-mail"
              iconClass="iconStyle emailIcon"
              hideOnYandex
              value={formData.email}
              errors={errors.email}
              disabled={leadLoading}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            <Input
              type="tel"
              name="phoneNumber"
              label="Votre téléphone portable"
              iconClass="iconStyle phoneIcon"
              hideOnYandex
              value={formData.phoneNumber}
              errors={errors.phoneNumber}
              disabled={leadLoading}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              className="ContactPopup__input"
            />
            {submitted && (
              <div className="ContactPopup__messages">
                <Alert type="success">Votre message a bien été envoyé.</Alert>
              </div>
            )}
            {leadError && (
              <div className="ContactPopup__messages">
                <Alert type="error">
                  {leadErrorMessage ||
                    'Merci de vérifier que vous avez correctement rempli tous les champs du formulaire ci-dessous.'}
                </Alert>
              </div>
            )}
            {clickSubmit &&
              !submitted &&
              !leadError &&
              ((errors.email && errors.email.length > 0) ||
                (errors.lastName && errors.lastName.length > 0) ||
                (errors.firstName && errors.firstName.length > 0) ||
                (errors.phoneNumber && errors.phoneNumber.length > 0)) && (
                <div className="ContactPopup__messages">
                  <Alert type="error">
                    <p>
                      Merci de vérifier que vous avez correctement rempli tous
                      les champs du formulaire ci-dessous.
                    </p>
                  </Alert>
                </div>
              )}
            <Button
              scope="button"
              type="secondary"
              iconSide="right"
              icon={<ArrowRight color="#223b5d" />}
              onClick={this.handleSubmit}
              disabled={leadLoading}
              loading={leadLoading}
              hasLoadingState
            >
              {leadLoading ? 'Patienter' : 'Envoyer mon message'}
            </Button>
          </form>
        </div>
      </Modal>
    );
  }
}

ContactPopup.defaultProps = {
  leadErrorMessage: null,
};

ContactPopup.propTypes = {
  isPoppedUp: PropTypes.bool.isRequired,
  product: PropTypes.shape(productShape).isRequired,
  supplier: PropTypes.shape(supplierShape).isRequired,
  type: PropTypes.shape(typeShape).isRequired,
  leadLoading: PropTypes.bool.isRequired,
  leadError: PropTypes.bool.isRequired,
  leadErrorMessage: PropTypes.string,
  utmCampaign: PropTypes.string.isRequired,
  utmSource: PropTypes.string.isRequired,
  utmMedium: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  sendLeadEmail: PropTypes.func.isRequired,
  resetContactStatus: PropTypes.func.isRequired,
};

export default ContactPopup;
