import { put, call, takeLatest } from 'redux-saga/effects';

import * as BlogPostsAPI from '../api/BlogPosts';

import {
  FETCH_BLOG_POSTS__REQUEST,
  FETCH_BLOG_POSTS__SUCCESS,
  FETCH_BLOG_POSTS__FAIL,
} from '../actions/BlogPostsActions';

function* fetchBlogPosts() {
  try {
    const data = yield call(BlogPostsAPI.fetchBlogPosts);

    yield put({
      type: FETCH_BLOG_POSTS__SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_BLOG_POSTS__FAIL,
      payload: e,
    });
  }
}

const blogPostsSagas = [takeLatest(FETCH_BLOG_POSTS__REQUEST, fetchBlogPosts)];

export default blogPostsSagas;
