import { TRUSTPILOT_URL } from '../config/api';

export const fetchTrustpilot = async () => {
  const url = new URL(`${TRUSTPILOT_URL}`);

  const response = await fetch(url.href);

  if (response.ok) {
    const data = await response.json();

    return data;
  }

  throw new Error(response.statusText);
};
