import {
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
} from '../actions/CartActions';
import {
  CLICK_FEATURED_PRODUCT,
  CLICK_SIMILAR_PRODUCT,
  OPEN_LEAD_POPUP,
  CLOSE_LEAD_POPUP,
} from '../actions/ProductActions';

const addProductToCart = ({ payload }, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const products = nextState.get('productState').get('products').toJS();
  const pageName = nextState.get('appState').get('pageName');
  const product = products.find(product => product.id === payload.id);

  return {
    event: 'Add product to cart',
    eventCategory: 'Cart',
    eventAction: 'Add Product',
    page: nextRouterState.location.pathname,
    pageName,
    productId: payload.id,
    productName: product.title,
  };
};

const removeProductFromCart = ({ payload }, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const products = nextState.get('productState').get('products').toJS();
  const pageName = nextState.get('appState').get('pageName');
  const product = products.find(product => product.id === payload);

  return {
    event: 'Remove product from cart',
    eventCategory: 'Cart',
    eventAction: 'Remove Product',
    page: nextRouterState.location.pathname,
    pageName,
    productId: payload,
    productName: product.title,
  };
};

const clickFeaturedProduct = ({ payload }, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Click featured product',
    eventCategory: 'Product',
    eventAction: 'Click featured product',
    page: nextRouterState.location.pathname,
    pageName,
    productId: payload.id,
    productName: payload.title,
  };
};

const clickSimilarProduct = ({ payload }, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Click similar product',
    eventCategory: 'Product',
    eventAction: 'Click similar product',
    page: nextRouterState.location.pathname,
    pageName,
    productId: payload.id,
    productName: payload.title,
  };
};

const openLeadPopup = ({ payload }, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Toggle lead popup',
    eventCategory: 'Product',
    eventAction: 'Open lead popup',
    page: nextRouterState.location.pathname,
    pageName,
    productId: payload.id,
    productName: payload.title,
  };
};

const closeLeadPopup = ({ payload }, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Toggle lead popup',
    eventCategory: 'Product',
    eventAction: 'Close lead popup',
    page: nextRouterState.location.pathname,
    pageName,
    productId: payload.id,
    productName: payload.title,
  };
};

const productEventsMap = {
  [ADD_PRODUCT_TO_CART]: addProductToCart,
  [REMOVE_PRODUCT_FROM_CART]: removeProductFromCart,
  [CLICK_FEATURED_PRODUCT]: clickFeaturedProduct,
  [CLICK_SIMILAR_PRODUCT]: clickSimilarProduct,
  [OPEN_LEAD_POPUP]: openLeadPopup,
  [CLOSE_LEAD_POPUP]: closeLeadPopup,
};

export default productEventsMap;
