import PropTypes from 'prop-types';

import { imageShape, paragraphShape } from './prismicShape';

const typeShape = {
  id: PropTypes.string,
  slug: PropTypes.string,
  contentType: PropTypes.string,
  gaTitle: PropTypes.string,
  label: PropTypes.string,
  labelSingular: PropTypes.string,
  labelPlural: PropTypes.string,
  gender: PropTypes.string,
  description: PropTypes.string,
  tooltip: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  mandatoryInformation: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
  explanation: PropTypes.string,
  showInHeader: PropTypes.bool,
  icon: PropTypes.shape(imageShape),
  blocks: PropTypes.arrayOf(PropTypes.shape({})),
  attentionPoints: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
  recommandationExplanations: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
  ),
};

export default typeShape;
