export const RISK_PROFILES = [
  'Prudent',
  'Curieux',
  'Modéré',
  'Dynamique',
  'Aventureux',
];

export const INVESTOR_TYPOLOGIES = [
  'Débutant',
  'Intermédiaire',
  'Averti',
  'Expert',
];

export const PRODUCT_TYPES = {
  ASSURANCE_VIE: 'W1Cv1SUAACQAFgdg',
  ASSURANCE_LUX: 'W2hBiCQAACUAOxli',
  FIP: 'W2L7pSQAACUAI-Cu',
  OPCI: 'W2hCPyQAACUAOxxW',
  PEA: 'W2hDZyQAACUAOyF8',
  GIRARDIN: 'W7cRXBIAACsAK1wj',
  FCPI: 'W8ih-BIAACkA3Zur',
  CROWDFUNDING: 'W2hDzSQAACUAOyNV',
  CONTRAT_DE_CAPITALISATION: 'W2sEciQAACcARz8O',
  PERP: 'W2hBuiQAACYAOxpJ',
  SCPI: 'W2FigikAACkAd9Uw',
  SCPI_FISCALE: 'W2hCWyQAACUAOxzV',
  PRODUIT_INNOVANT: 'W2hDpSQAACUAOyKe',
  MADELIN: 'W2hBxiQAACUAOxqC',
  FIA: 'W35pXiMAACIAW4DK',
};

export const ALLOCATION_EXPLANATION_TYPES = {
  ALL_EURO_FUNDS: 'ALL_EURO_FUNDS',
  XX_EURO_FUNDS: 'XX_EURO_FUNDS',
  HALF_EURO_FUNDS: 'HALF_EURO_FUNDS',
  NO_EURO_FUNDS: 'NO_EURO_FUNDS',
  XX_SCPI_FUNDS: 'XX_SCPI_FUNDS',
  RISK_ALL_EURO_FUNDS: 'RISK_ALL_EURO_FUNDS',
  RISK_1_3: 'RISK_1_3',
  RISK_3_5: 'RISK_3_5',
  RISK_5_7: 'RISK_5_7',
};

export const TYPE_FILTER_KEY = 'type';
export const OBJECTIVE_FILTER_KEY = 'objective';
export const INVEST_PERIOD_FILTER_KEY = 'invest_period';
export const MINIMUM_INVESTMENT_FILTER_KEY = 'minimum_investment';

export const INVEST_PERIODS = [
  {
    label: 'Tous',
    id: 'all-invest-periods',
    value: 'all',
  },
  {
    label: "Moins d'un an",
    id: 'less-than-one-year',
    value: '[0, 1]',
  },
  {
    label: 'De 1 an à 4 ans',
    id: 'from-one-to-four-years',
    value: '[1, 4]',
  },
  {
    label: 'De 4 à 7 ans',
    id: 'from-four-to-seven-years',
    value: '[4, 7]',
  },
  {
    label: 'De 7 à 10 ans',
    id: 'from-seven-to-ten-years',
    value: '[7, 10]',
  },
  {
    label: '10 ans et plus',
    id: 'more-than-ten-years',
    value: '[10, 100]',
  },
];

export const MINIMUM_INVESTMENT = [
  {
    label: 'Tous',
    id: 'all-minimum-investments',
    value: 'all',
  },
  {
    label: 'Moins de 3 000 €',
    id: 'less-than-3000',
    value: '[0, 3000]',
  },
  {
    label: 'Moins de 10 000 €',
    id: 'less-than-10000',
    value: '[0, 10000]',
  },
  {
    label: 'Moins de 50 000 €',
    id: 'less-than-50000',
    value: '[0, 50000]',
  },
];

export const CARD_SLIDER_SETTINGS = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const TARGET_PLUS_BACKEND_KEY = 'PRIMONIAL__ASSURANCE_VIE__TARGET_PLUS';
