import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { simulationShape } from '../../../shapes/simulationShape';

import Tag from '../../Core/Tag';
import SimulationFees from './SimulationFees';

import '../../../styles/components/Blocks/Simulation/SimulationProfitFees.css';

class SimulationProfitFees extends PureComponent {
  renderValue = (number, devise) => (
    <div className="SimulationProfitFees__value">
      <Tag
        label={`${number} ${devise}`}
        color="#223b5d"
        backgroundColor="#f8f9fa"
        size="normal"
      />
    </div>
  );

  renderValueProfit = () => {
    const { item, isPercent } = this.props;

    if (
      item.public_profit_euro_value !== null &&
      item.public_profit_purcent_value !== null &&
      item.public_profit_label !== null
    ) {
      const number = isPercent
        ? item.public_profit_purcent_value
        : item.public_profit_euro_value;
      const devise = isPercent ? '%' : ' €';

      return (
        <div className="SimulationProfitFees__item">
          <p className="SimulationProfitFees__label">
            {item.public_profit_label}
          </p>
          {this.renderValue(number, devise)}
        </div>
      );
    }

    return null;
  };

  renderNetOfProfitFees = () => {
    const { item, isPercent } = this.props;

    if (
      item.net_of_profit_fees_label !== null &&
      item.fee_1_euro_value !== null &&
      item.fee_1_purcent_value !== null &&
      item.fee_1_label !== null
    ) {
      return (
        <div className="SimulationProfitFees__fees">
          <p className="SimulationProfitFees__fees-label">
            Or il faut bien payer certains frais
          </p>
          <SimulationFees item={item} isPercent={isPercent} />
        </div>
      );
    }

    return null;
  };

  renderFees = () => {
    const { item, isPercent } = this.props;

    if (
      item.net_of_profit_fees_euro_value !== null &&
      item.net_of_profit_fees_purcent_value !== null &&
      item.net_of_profit_fees_label !== null
    ) {
      const number = isPercent
        ? item.net_of_profit_fees_purcent_value
        : item.net_of_profit_fees_euro_value;
      const devise = isPercent ? '%' : ' €';

      return (
        <div className="SimulationProfitFees__item">
          <p className="SimulationProfitFees__label">
            {item.net_of_profit_fees_label}
          </p>
          {this.renderValue(number, devise)}
        </div>
      );
    }

    return null;
  };

  renderBonus = () => {
    const { item, isPercent } = this.props;

    if (
      item.bonus_block_euro_value !== null &&
      item.bonus_block_purcent_value !== null &&
      item.bonus_block_label !== null
    ) {
      const number = isPercent
        ? item.bonus_block_purcent_value
        : item.bonus_block_euro_value;
      const devise = isPercent ? '%' : ' €';

      return (
        <div className="SimulationProfitFees__bonus">
          <div className="SimulationProfitFees__bonus-label">
            <Tag
              label="Le + de mieuxplacer.com"
              color="#0cce6b"
              backgroundColor="#e1f9ed"
              size="small"
            />
          </div>
          <div className="SimulationProfitFees__item">
            <p className="SimulationProfitFees__label">
              {item.bonus_block_label}
            </p>
            <div className="SimulationProfitFees__value">
              <Tag
                label={`+ ${number} ${devise}`}
                color="#0cce6b"
                backgroundColor="#e1f9ed"
                size="normal"
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  render() {
    return (
      <div className="SimulationProfitFees">
        {this.renderValueProfit()}
        {this.renderNetOfProfitFees()}
        {this.renderFees()}
        {this.renderBonus()}
      </div>
    );
  }
}

SimulationProfitFees.propTypes = {
  item: PropTypes.shape(simulationShape).isRequired,
  isPercent: PropTypes.bool.isRequired,
};

export default SimulationProfitFees;
