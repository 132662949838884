const withdrawLimitMapper = {
  'Liquide - vous pouvez retirer votre argent à tout moment.': 'Liquide',
  'Liquide sous certaines conditions.': 'Liquide sous conditions',
  "La somme placée est bloquée pour un nombre d'années défini.": 'Bloqué',
  Liquide: 'Liquide - vous pouvez retirer votre argent à tout moment.',
  'Liquide sous conditions': 'Liquide sous certaines conditions.',
  Bloqué: "La somme placée est bloquée pour un nombre d'années défini.",
};

export const getShortWithdrawLimitLabel = label => withdrawLimitMapper[label];
export const getFullWithdrawLimitLabel = label => withdrawLimitMapper[label];
