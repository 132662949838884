import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { simulationShape } from '../../../shapes/simulationShape';
import productShape from '../../../shapes/productShape';

import Tag from '../../Core/Tag';
import Icon from '../../Core/Icon';
import Button from '../../Core/Button';
import withProductContext from '../../HOC/withProductContext';
import ProductAddSelection from '../Product/ProductAddSelection';

import phone from '../../../assets/icons/phone.svg';

import '../../../styles/components/Blocks/Simulation/SimulationBlockFinal.css';

const phoneIcon = <Icon src={phone} alt="phone-icon" />;

class SimulationBlockFinal extends PureComponent {
  handleScrollToCallBackForm = () => {
    const target = document.querySelector('.PrismicCallBackForm');

    if (target) {
      target.parentNode.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  render() {
    const {
      item,
      isPercent,
      context: { product },
    } = this.props;

    return (
      <div className="SimulationBlockFinal">
        {item.final_block_label && (
          <div className="SimulationBlockFinal__content">
            <div className="SimulationBlockFinal__title">
              <p>{item.final_block_label}</p>
            </div>
            <div className="SimulationBlockFinal__actions">
              {product.subscribable &&
                (product.availableForSubscription ? (
                  <ProductAddSelection className="SimulationBlockFinal__action" />
                ) : (
                  product.calandlyLabel &&
                  document.querySelector('.PrismicCallBackForm') && (
                    <div>
                      <Button
                        label={product.calandlyLabel}
                        type="white"
                        iconSide="left"
                        size="large"
                        icon={phoneIcon}
                        className="SimulationBlockFinal__action"
                        href={this.handleScrollToCallBackForm}
                      />
                      ok
                    </div>
                  )
                ))}
            </div>
          </div>
        )}
        {item.final_block_euro_value && item.final_block_purcent_value && (
          <div className="SimulationBlockFinal__tag">
            <Tag
              label={
                isPercent
                  ? `${item.final_block_purcent_value} %`
                  : `${item.final_block_euro_value} €`
              }
              color="#ffc501"
              backgroundColor="transparent"
              size="large"
            />
          </div>
        )}
      </div>
    );
  }
}

SimulationBlockFinal.propTypes = {
  context: PropTypes.shape({
    product: PropTypes.shape(productShape).isRequired,
  }).isRequired,
  item: PropTypes.shape(simulationShape).isRequired,
  isPercent: PropTypes.bool.isRequired,
};

export default withProductContext(SimulationBlockFinal);
