import React from 'react';

import LoadingGradient from '../../../Core/LoadingGradient';

const PrismicContentLoading = () => (
  <div className="PrismicContent PrismicContent--loading">
    <div className="PrismicContent__title">
      <LoadingGradient width="300px" height="42px" />
    </div>
    <div className="PrismicContent__content">
      <div className="RichText__paragraph">
        <LoadingGradient className="LoadingGradient--text-line" height="20px" />
        <LoadingGradient className="LoadingGradient--text-line" height="20px" />
        <LoadingGradient className="LoadingGradient--text-line" height="20px" />
        <LoadingGradient className="LoadingGradient--text-line" height="20px" />
        <LoadingGradient
          className="LoadingGradient--text-line"
          width="calc((100% / 3) * 2)"
          height="20px"
        />
      </div>
      <div className="RichText__paragraph">
        <LoadingGradient className="LoadingGradient--text-line" height="18px" />
        <LoadingGradient className="LoadingGradient--text-line" height="18px" />
        <LoadingGradient
          className="LoadingGradient--text-line"
          width="calc((100% / 3) * 2)"
          height="18px"
        />
      </div>
      <div className="RichText__paragraph">
        <LoadingGradient className="LoadingGradient--text-line" height="18px" />
        <LoadingGradient className="LoadingGradient--text-line" height="18px" />
        <LoadingGradient className="LoadingGradient--text-line" height="18px" />
        <LoadingGradient
          className="LoadingGradient--text-line"
          width="calc((100% / 3) * 2)"
          height="18px"
        />
      </div>
    </div>
  </div>
);

export default PrismicContentLoading;
