import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Cart from './Cart';

import '../../../styles/components/Blocks/Cart/CartHeader.css';

class CartHeader extends PureComponent {
  render() {
    const { isOpen, onToggle } = this.props;

    const cartContainerClasses = classNames('CartHeader__container', {
      'CartHeader__container--is-open': isOpen,
    });

    const cartMaskClasses = classNames('CartHeader__mask', {
      'CartHeader__mask--is-visible': isOpen,
    });

    return (
      <div className="CartHeader">
        <div className={cartContainerClasses}>
          <Cart onToggle={onToggle} />
        </div>
        <div
          className={cartMaskClasses}
          onClick={onToggle}
          onKeyPress={onToggle}
          role="button"
          tabIndex={0}
        />
      </div>
    );
  }
}

CartHeader.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default CartHeader;
