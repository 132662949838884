import { CHANGE_ONBOARDING_ANSWER } from '../actions/OnboardingActions';

const changeOnboardingAnswer = ({ payload }, prevState, nextState) => {
  const nextRouterState = nextState.get('router').toJS();
  const pageName = nextState.get('appState').get('pageName');

  return {
    event: 'Change Onboarding Answer',
    eventCategory: 'Onboarding',
    eventAction: 'Change Answer',
    page: nextRouterState.location.pathname,
    pageName,
    key: payload.key,
    step: payload.step,
  };
};

const onboardingEventsMap = {
  [CHANGE_ONBOARDING_ANSWER]: changeOnboardingAnswer,
};

export default onboardingEventsMap;
