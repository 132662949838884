export const ANSWER_TYPES = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  SELECT: 'SELECT',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  PASSWORD: 'PASSWORD',
  DATE: 'DATE',
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
  CHART: 'CHART',
  OBJECTIVE: 'OBJECTIVE',
};

export const CONDITION_TYPES = {
  EQUAL: 'EQUAL',
  DIFFERENT: 'DIFFERENT',
  SUPERIOR: 'SUPERIOR',
  INFERIOR: 'INFERIOR',
  INCLUDE: 'INCLUDE',
  EXCLUDE: 'EXCLUDE',
};

export const DISPLAY_TYPES = {
  BLOCK: 'BLOCK',
  BLOCKS: 'BLOCKS',
};

export const ERROR_TYPES = {
  EMPTY: 'EMPTY',
  INVALID: 'INVALID',
  MINOR: 'MINOR_ERROR_TYPE',
  FOREIGN: 'FOREIGN_ERROR_TYPE',
  EMAIL_USED: 'EMAIL_USED',
  FAKE_EMAIL: 'FAKE_EMAIL',
  PASSWORD_TOO_SHORT: 'PASSWORD_TOO_SHORT',
  PASSWORD_TOO_COMMON: 'PASSWORD_TOO_COMMON',
  PASSWORD_ONLY_NUMERIC: 'PASSWORD_ONLY_NUMERIC',
  PASSWORD_TOO_SIMILAR_TO_EMAIL: 'PASSWORD_TOO_SIMILAR_TO_EMAIL',
  PHONE_NOT_MOBILE: 'PHONE_NOT_MOBILE',
};

export const ADVICE_TYPES = {
  NONE: 'NONE',
  PRODUCT: 'PRODUCT',
  RISK: 'RISK',
};

export const FUND_TYPES = {
  FCPI: 'FCPI',
  FIP: 'FIP',
  EURO: 'EURO',
  UC: 'UC',
  SCPI: 'SCPI',
  PEA: 'PEA',
  OPCI: 'OPCI',
};

export const MULTIPLE_FUNDS_TABLE_COLUMNS = [
  {
    label: 'Type',
    // tooltip:
    //   'Il existe 4 grandes classes de fonds : actions, obligations, diversifiés et monétaires.',
    className: 'Funds__head-column--1 Funds__head-column--life-insurance',
  },
  {
    label: 'Nom du fonds',
    tooltip: 'La dénomination du fonds.',
    className: 'Funds__head-column--2 Funds__head-column--life-insurance',
  },
  {
    label: 'ISIN',
    tooltip:
      "C'est l'identifiant unique, propre à chaque produit financier autrement dit c'est le code-barres du produit financier.",
    className: 'Funds__head-column--3 Funds__head-column--life-insurance',
  },
  {
    label: 'Perf. annualisée (3 ans)',
    tooltip:
      "Performance moyenne sur 3 ans glissants (performance moyenne annuelle entre aujourd'hui et il y a trois ans le même jour).",
    className: 'Funds__head-column--4 Funds__head-column--life-insurance',
  },
  {
    label: 'SRRI',
    tooltip:
      "C'est la position du fonds sur une échelle de risque croissante allant de 1 à 7 (1 étant le risque le plus faible, 7 étant le risque le plus élevé).",
    className: 'Funds__head-column--5 Funds__head-column--life-insurance',
  },
  {
    label: 'Note Morning star',
    tooltip:
      'MorningStar est une société indépendante qui note objectivement les fonds sur 5.',
    className: 'Funds__head-column--6 Funds__head-column--life-insurance',
  },
  {
    label: 'Montant',
    tooltip: 'Part du capital investi sur le fonds.',
    className: 'Funds__head-column--7 Funds__head-column--life-insurance',
  },
];

export const SINGLE_FUND_START_TABLE_COLUMNS = [
  {
    label: 'Nom du fonds',
    tooltip: 'La dénomination du fonds.',
    className: 'Funds__head-column--1 Funds__head-column--single-fund',
  },
];

export const SINGLE_FUND_END_TABLE_COLUMNS = [
  {
    label: 'SRRI',
    tooltip:
      "C'est la position du fonds sur une échelle de risque croissante allant de 1 à 7 (1 étant le risque le plus faible, 7 étant le risque le plus élevé).",
    className: 'Funds__head-column--5 Funds__head-column--single-fund',
  },
  {
    label: 'Montant',
    tooltip: 'Part du capital investi sur le fonds.',
    className: 'Funds__head-column--6 Funds__head-column--single-fund',
  },
];

export const RECAPITULATIVE_FUNDS_TABLE_COLUMNS = [
  {
    label: 'Type',
    // tooltip:
    //   'Il existe 4 grandes classes de fonds : actions, obligations, diversifiés et monétaires.',
  },
  {
    label: 'Nom du fonds',
    tooltip: 'La dénomination du fonds.',
  },
  {
    label: 'SRRI',
    tooltip:
      "C'est la position du fonds sur une échelle de risque croissante allant de 1 à 7 (1 étant le risque le plus faible, 7 étant le risque le plus élevé).",
  },
  {
    label: 'Montant',
    tooltip: 'Part du capital investi sur le fonds.',
  },
];

export const RECOMMENDATION_ENTRY_TYPE = 'recommendation';
export const SELECTION_ENTRY_TYPE = 'selection';

export const PLACEHOLDER_VALUE = 'placeholder';

export const EMAIL_KEY = 'email';
export const PASSWORD_KEY = 'password';
export const OBJECTIVES_KEY = 'sub_contract_goal1';
export const HORIZON_KEY = 'sub_horizon1';
export const INVESTMENT_AMOUNT_KEY = 'sub_investment_amount1';
export const LIQUIDITY_KEY = 'sub_liquidity1';
export const TRANSACTIONS_KEY = 'sub_financial_experience_buy1';
export const TRANSACTIONS_NONE_KEY = '6';
export const CGU_KEY = 'CGU1';
export const ENGAGEMENT_LETTER_KEY = 'CGU3';

export const ANSWER_KEYS_TO_IGNORE = [
  EMAIL_KEY,
  PASSWORD_KEY,
  CGU_KEY,
  ENGAGEMENT_LETTER_KEY,
];

export const MAX_SELECTED_OBJECTIVES = 3;

export const VALIDATION_ERRORS = {
  general:
    'Il semblerait que nous ayons un problème... Nos équipes sont sur le coup, nous essayons de résoudre cela au plus vite.',
  settings:
    "Nous avons trouvé une erreur de paramétrage dans un produit, vous trouverez ci-dessous l'erreur en question et un lien pour la résoudre.",
};

export const TARGET_ROUND_PERCENT_MESSAGE = `
  Attention, le fournisseur nous impose des pourcentages sans virgule dans l'allocation.
  Nous avons donc arrondi tous vos pourcentages, par conséquent, les montants sont légèrement différents.
  Merci de vérifier que ceux-ci vous conviennent toujours.
`;

export const MAX_INVESTMENT_FOR_RECOMMENDATION = 500000;

export const MIN_INVESTMENT_FOR_RECOMMENDATION = 1000;

export const SUB_RISK_GRAPH_CHART_DATA = {
  xAxis: {
    stroke: '#eaedf0',
    label: {
      value: 'an(s)',
      stroke: '#616c7a',
      dx: 20,
      dy: -10,
    },
  },
  yAxis: {
    stroke: '#eaedf0',
    label: {
      value: 'Investissement',
      stroke: '#616c7a',
      dx: 40,
      dy: -45,
    },
  },
  data: [
    {
      name: 'C',
      value: '3',
      data: [
        { category: '1', value: 100 },
        { category: '2', value: 108 },
        { category: '3', value: 92 },
        { category: '4', value: 85 },
        { category: '5', value: 115 },
        { category: '6', value: 143.75 },
        { category: '7', value: 151.25 },
        { category: '8', value: 162.5 },
        { category: '9', value: 107 },
        { category: '10', value: 130 },
        { category: '11', value: 132 },
        { category: '12', value: 156.25 },
        { category: '13', value: 165 },
        { category: '14', value: 160 },
        { category: '15', value: 171.25 },
      ],
      stroke: '#223b5d',
    },
    {
      name: 'B',
      value: '2',
      data: [
        { category: '1', value: 100 },
        { category: '2', value: 105 },
        { category: '3', value: 98 },
        { category: '4', value: 95 },
        { category: '5', value: 108 },
        { category: '6', value: 115 },
        { category: '7', value: 121 },
        { category: '8', value: 130 },
        { category: '9', value: 119 },
        { category: '10', value: 121 },
        { category: '11', value: 119 },
        { category: '12', value: 125 },
        { category: '13', value: 132 },
        { category: '14', value: 140 },
        { category: '15', value: 137 },
      ],
      stroke: '#27b4d1',
    },
    {
      name: 'A',
      value: '1',
      data: [
        { category: '1', value: 100 },
        { category: '2', value: 101 },
        { category: '3', value: 102.01 },
        { category: '4', value: 103.0301 },
        { category: '5', value: 104.060401 },
        { category: '6', value: 105.101005 },
        { category: '7', value: 106.1520151 },
        { category: '8', value: 107.2135352 },
        { category: '9', value: 108.2856706 },
        { category: '10', value: 109.3685273 },
        { category: '11', value: 110.4622125 },
        { category: '12', value: 111.5668347 },
        { category: '13', value: 112.682503 },
        { category: '14', value: 113.809328 },
        { category: '15', value: 114.9474213 },
      ],
      stroke: '#ffc501',
    },
  ],
};

export const SUB_RISK_GRAPH_KEY = 'sub_risk_graph1';
