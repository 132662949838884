import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { CARD_SLIDER_SETTINGS } from '../../../config/product';

import productShape from '../../../shapes/productShape';
import typeShape from '../../../shapes/typeShape';
import supplierShape from '../../../shapes/supplierShape';

import withHomeContext from '../../HOC/withHomeContext';
import ProductCard from '../Product/ProductCard';

import '../../../styles/components/Blocks/Products/FeaturedProductsList.css';

class FeaturedProductsList extends PureComponent {
  renderProductCards = () => {
    const {
      products,
      types,
      suppliers,
      loading,
      context: { clickFeaturedProduct },
    } = this.props;

    if (loading) {
      return (
        <div className="FeaturedProductsList FeaturedProductsList--loading">
          {[1, 2, 3].map(key => (
            <div className="FeaturedProductsList__item" key={key}>
              <ProductCard loading />
            </div>
          ))}
        </div>
      );
    }

    return (
      <Fragment>
        <Slider {...CARD_SLIDER_SETTINGS}>
          {products.map(product => {
            const supplier = suppliers.find(
              supplier => supplier.id === product.supplier,
            );
            const type = types.find(type => type.id === product.type);

            return (
              <ProductCard
                key={product.id}
                product={product}
                type={type}
                supplier={supplier}
                onClick={() => clickFeaturedProduct(product.id, product.title)}
              />
            );
          })}
        </Slider>
      </Fragment>
    );
  };

  render() {
    return (
      <div className="FeaturedProductsList">{this.renderProductCards()}</div>
    );
  }
}

FeaturedProductsList.defaultProps = {
  products: [],
  types: [],
  suppliers: [],
  loading: false,
};

FeaturedProductsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape(productShape)),
  types: PropTypes.arrayOf(PropTypes.shape(typeShape)),
  suppliers: PropTypes.arrayOf(PropTypes.shape(supplierShape)),
  loading: PropTypes.bool,
  context: PropTypes.shape({
    clickFeaturedProduct: PropTypes.func.isRequired,
  }).isRequired,
};

export default withHomeContext(FeaturedProductsList);
