import { Map, fromJS } from 'immutable';

import {
  FETCH_TRUSTPILOT__REQUEST,
  FETCH_TRUSTPILOT__SUCCESS,
  FETCH_TRUSTPILOT__FAIL,
} from '../actions/TrustpilotActions';

const initialState = Map({
  trustpilotLoading: false,
  trustpilotError: false,
  trustpilot: null,
});

const trustpilotState = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TRUSTPILOT__REQUEST:
      return state.set('trustpilotLoading', true).set('trustpilotError', false);

    case FETCH_TRUSTPILOT__SUCCESS:
      return state
        .set('trustpilotLoading', false)
        .set('trustpilot', fromJS(payload));

    case FETCH_TRUSTPILOT__FAIL:
      return state.set('trustpilotLoading', false).set('trustpilotError', true);

    default:
      return initialState.merge(state);
  }
};

export default trustpilotState;
