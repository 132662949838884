import { Map, fromJS } from 'immutable';

import {
  SEND_CONTACT_EMAIL__REQUEST,
  SEND_CONTACT_EMAIL__SUCCESS,
  SEND_CONTACT_EMAIL__FAIL,
  SEND_LEAD_EMAIL__REQUEST,
  SEND_LEAD_EMAIL__SUCCESS,
  SEND_LEAD_EMAIL__FAIL,
  SEND_CALL_BACK_EMAIL__REQUEST,
  SEND_CALL_BACK_EMAIL__SUCCESS,
  SEND_CALL_BACK_EMAIL__FAIL,
  SEND_CALL_BACK_PRODUCT_EMAIL__REQUEST,
  SEND_CALL_BACK_PRODUCT_EMAIL__SUCCESS,
  SEND_CALL_BACK_PRODUCT_EMAIL__FAIL,
  SEND_TESTING_EMAIL__REQUEST,
  SEND_TESTING_EMAIL__SUCCESS,
  SEND_TESTING_EMAIL__FAIL,
  RESET_CONTACT_STATUS,
} from '../actions/ContactActions';

const initialState = Map({
  contact: Map({
    loading: false,
    error: false,
    errorMessage: null,
  }),
  lead: Map({
    loading: false,
    error: false,
    errorMessage: null,
  }),
  callBack: Map({
    loading: false,
    error: false,
    errorMessage: null,
  }),
  testing: Map({
    loading: false,
    error: false,
    errorMessage: null,
  }),
  callBackProduct: Map({
    loading: false,
    error: false,
    errorMessage: null,
  }),
});

const contactState = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEND_CONTACT_EMAIL__REQUEST:
      return state
        .setIn(['contact', 'loading'], true)
        .setIn(['contact', 'error'], false);

    case SEND_CONTACT_EMAIL__SUCCESS:
      return state.setIn(['contact', 'loading'], false);

    case SEND_CONTACT_EMAIL__FAIL:
      return state
        .setIn(['contact', 'loading'], false)
        .setIn(['contact', 'error'], true)
        .setIn(['contact', 'errorMessage'], fromJS(payload.errorMessage));

    case SEND_LEAD_EMAIL__REQUEST:
      return state
        .setIn(['lead', 'loading'], true)
        .setIn(['lead', 'error'], false);

    case SEND_LEAD_EMAIL__SUCCESS:
      return state.setIn(['lead', 'loading'], false);

    case SEND_LEAD_EMAIL__FAIL:
      return state
        .setIn(['lead', 'loading'], false)
        .setIn(['lead', 'error'], true)
        .setIn(['lead', 'errorMessage'], fromJS(payload.errorMessage));

    case SEND_CALL_BACK_EMAIL__REQUEST:
      return state
        .setIn(['callBack', 'loading'], true)
        .setIn(['callBack', 'error'], false);

    case SEND_CALL_BACK_EMAIL__SUCCESS:
      return state.setIn(['callBack', 'loading'], false);

    case SEND_CALL_BACK_EMAIL__FAIL:
      return state
        .setIn(['callBack', 'loading'], false)
        .setIn(['callBack', 'error'], true)
        .setIn(['callBack', 'errorMessage'], fromJS(payload.errorMessage));

    case SEND_CALL_BACK_PRODUCT_EMAIL__REQUEST:
      return state
        .setIn(['callBackProduct', 'loading'], true)
        .setIn(['callBackProduct', 'error'], false);

    case SEND_CALL_BACK_PRODUCT_EMAIL__SUCCESS:
      return state.setIn(['callBackProduct', 'loading'], false);

    case SEND_CALL_BACK_PRODUCT_EMAIL__FAIL:
      return state
        .setIn(['callBackProduct', 'loading'], false)
        .setIn(['callBackProduct', 'error'], true)
        .setIn(
          ['callBackProduct', 'errorMessage'],
          fromJS(payload.errorMessage),
        );

    case SEND_TESTING_EMAIL__REQUEST:
      return state
        .setIn(['testing', 'loading'], true)
        .setIn(['testing', 'error'], false);

    case SEND_TESTING_EMAIL__SUCCESS:
      return state.setIn(['testing', 'loading'], false);

    case SEND_TESTING_EMAIL__FAIL:
      return state
        .setIn(['testing', 'loading'], false)
        .setIn(['testing', 'error'], true)
        .setIn(['testing', 'errorMessage'], fromJS(payload.errorMessage));

    case RESET_CONTACT_STATUS:
      return initialState;

    default:
      return initialState.merge(state);
  }
};

export default contactState;
