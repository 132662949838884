import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import productShape from '../../../shapes/productShape';

import Tooltip from '../../Core/Tooltip';
import RiskBar from './RiskBar';
import ProductFeaturesLoading from './Loading/ProductFeaturesLoading';

import '../../../styles/components/Blocks/Product/ProductFeatures.css';

class ProductFeatures extends PureComponent {
  renderFeature = (label, value, tooltip) => (
    <div className="ProductFeatures__feature">
      <div className="ProductFeatures__feature-title">
        {label}
        {tooltip && (
          <Tooltip
            className="ProductFeatures__feature-tooltip"
            title={tooltip}
          />
        )}
      </div>
      <div className="ProductFeatures__feature-value">{value}</div>
    </div>
  );

  renderRiskLevel = () => {
    const { product } = this.props;

    if (product.riskLevelEnabled && product.riskLevel) {
      return (
        <div className="ProductFeatures__feature ProductFeatures__feature--risks">
          <div className="ProductFeatures__feature-title">
            Niveau de risque
            <Tooltip
              className="ProductFeatures__feature-tooltip"
              title="Niveau de risque auquel ce placement vous expose."
            />
          </div>
          <div className="ProductFeatures__feature-value">
            <RiskBar number={product.riskLevel} />
          </div>
        </div>
      );
    }

    return null;
  };

  renderInvestPeriod = () => {
    const { product } = this.props;

    if (product.investPeriodLabel) {
      return this.renderFeature(
        'Durée recommandée de placement',
        `${product.investPeriodLabel}`,
        'Combien de temps nous vous recommandons de garder ce placement (au minimum).',
      );
    }

    return null;
  };

  renderWithdrawLimit = () => {
    const { product } = this.props;

    if (product.withdrawLimit) {
      return this.renderFeature(
        'Liquidité',
        product.withdrawLimit,
        'Indique si votre placement est bloqué ou si vous pouvez récupérer facilement votre argent.',
      );
    }

    return null;
  };

  renderTaxSystems = () => {
    const { product } = this.props;

    if (product.taxSystems && product.taxSystems.length > 0) {
      return (
        <div className="ProductFeatures__feature">
          <div className="ProductFeatures__feature-title">
            Fiscalité
            <Tooltip
              className="ProductFeatures__feature-tooltip"
              title="Quels impôts allez-vous payer ? (hors prélèvements sociaux)."
            />
          </div>
          <div className="ProductFeatures__feature-value ProductFeatures__feature-value--multiple">
            {product.taxSystems.map((taxSystem, index) => (
              <div key={index} className="ProductFeatures__feature-item">
                {taxSystem}
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  renderPerformances = () => {
    const { product } = this.props;

    if (
      product.performancesLabel &&
      product.performances &&
      product.performances.length > 0 &&
      product.performances[0].label &&
      product.performances[0].value
    ) {
      return (
        <div className="ProductFeatures__feature">
          <div className="ProductFeatures__feature-title">
            {product.performancesLabel}
            {product.performancesTooltip && (
              <Tooltip
                className="ProductFeatures__feature-tooltip"
                title={product.performancesTooltip}
              />
            )}
          </div>
          <div className="ProductFeatures__feature-value ProductFeatures__feature-value--multiple">
            {product.performances.map((performance, index) => (
              <div key={index} className="ProductFeatures__feature-item">
                <div className="ProductFeatures__feature-item-label">
                  {performance.label}
                </div>
                <div className="ProductFeatures__feature-item-value">
                  {performance.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  renderMaximumRate = () => {
    const { product } = this.props;

    if (product.maximumRate) {
      return this.renderFeature(
        'Taux Maximum de fonds Euros accepté',
        `${product.maximumRate} %`,
        'Part maximum que vous pouvez placer sur le fonds euro.',
      );
    }

    return null;
  };

  renderTdvms = () => {
    const { product } = this.props;

    if (
      product.tdvms &&
      product.tdvms.length > 0 &&
      product.tdvms[0].label &&
      product.tdvms[0].value
    ) {
      return (
        <div className="ProductFeatures__feature">
          <div className="ProductFeatures__feature-title">
            Le taux de distribution sur la valeur de marché (DVM)
            <Tooltip
              className="ProductFeatures__feature-tooltip"
              title="Rapport entre l’ensemble des dividendes versés au titre de l’année N et le prix moyen de la part au titre de l’année N. Il donne une indication du rendement de la SCPI."
            />
          </div>
          <div className="ProductFeatures__feature-value ProductFeatures__feature-value--multiple">
            {product.tdvms.map((tdvm, index) => (
              <div key={index} className="ProductFeatures__feature-item">
                <div className="ProductFeatures__feature-item-label">
                  {tdvm.label}
                </div>
                <div className="ProductFeatures__feature-item-value">
                  {tdvm.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  renderOccupancyRates = () => {
    const { product } = this.props;

    if (
      product.occupancyRates &&
      product.occupancyRates.length > 0 &&
      product.occupancyRates[0].label &&
      product.occupancyRates[0].value
    ) {
      return (
        <div className="ProductFeatures__feature">
          <div className="ProductFeatures__feature-title">
            Taux d&#39;occupation
            <Tooltip
              className="ProductFeatures__feature-tooltip"
              title="Rapport entre les loyers perçus et les loyers qui seraient facturés si les locaux étaient intégralement loués. Il donne une indication sur le du taux de vacance locative du parc immobilier."
            />
          </div>
          <div className="ProductFeatures__feature-value ProductFeatures__feature-value--multiple">
            {product.occupancyRates.map((occupancyRate, index) => (
              <div key={index} className="ProductFeatures__feature-item">
                <div className="ProductFeatures__feature-item-label">
                  {occupancyRate.label}
                </div>
                <div className="ProductFeatures__feature-item-value">
                  {occupancyRate.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  renderManagementModes = () => {
    const { product } = this.props;

    if (product.managementModes && product.managementModes.length > 0) {
      return (
        <div className="ProductFeatures__feature">
          <div className="ProductFeatures__feature-title">
            Mode de gestion
            <Tooltip
              className="ProductFeatures__feature-tooltip"
              title="Comment est géré mon placement."
            />
          </div>
          <div className="ProductFeatures__feature-value ProductFeatures__feature-value--multiple ProductFeatures__feature-value--columns">
            {product.managementModes.map((managementMode, index) => (
              <div key={index} className="ProductFeatures__feature-item">
                {managementMode}
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  render() {
    const { product, loading } = this.props;

    if (loading) {
      return <ProductFeaturesLoading />;
    }

    if (!product) {
      return null;
    }

    return (
      <div className="ProductFeatures">
        <h2 className="ProductFeatures__title">Caractéristiques du produit</h2>
        <div className="ProductFeatures__features">
          {this.renderRiskLevel()}
          {this.renderInvestPeriod()}
          {this.renderWithdrawLimit()}
          {this.renderTaxSystems()}
          {this.renderPerformances()}
          {this.renderMaximumRate()}
          {this.renderTdvms()}
          {this.renderOccupancyRates()}
          {this.renderManagementModes()}
        </div>
      </div>
    );
  }
}

ProductFeatures.defaultProps = {
  product: {},
  loading: false,
};

ProductFeatures.propTypes = {
  product: PropTypes.shape(productShape),
  loading: PropTypes.bool,
};

export default ProductFeatures;
