import fetchIntercept from 'fetch-intercept';

import store from '../store';

import {
  LOGOUT_USER,
  USER_IS_VERIFIED,
  USER_IS_NOT_VERIFIED,
} from '../actions/AuthActions';
import { BASE_API_URL } from '../config/api';

export const unregister = fetchIntercept.register({
  request: (url, config) => {
    const configCopy = { ...config };

    if (url.includes(BASE_API_URL)) {
      const authorizationToken = store
        .getState()
        .get('userState')
        .get('authorizationToken');

      if (config.headers) {
        configCopy.headers.set('Authorization', authorizationToken);
      } else {
        configCopy.headers = new Headers({ Authorization: authorizationToken });
      }
    }

    return [url, configCopy];
  },

  response: response => {
    if (response.headers.has('Verified')) {
      const verified = response.headers.get('Verified') === 'true';
      const stateVerified = store.getState().get('userState').get('isVerified');

      if (verified !== stateVerified) {
        if (verified === true) {
          store.dispatch({ type: USER_IS_VERIFIED });
        } else {
          store.dispatch({ type: USER_IS_NOT_VERIFIED });
        }
      }
    }

    if (response.status === 401) {
      store.dispatch({ type: LOGOUT_USER });
    }
    return response;
  },
});
