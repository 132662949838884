import { Map } from 'immutable';

import {
  VALIDATE_SIGNATURE_TASK__REQUEST,
  VALIDATE_SIGNATURE_TASK__SUCCESS,
  VALIDATE_SIGNATURE_TASK__FAIL,
} from '../actions/SignatureActions';

const initialState = Map({
  validateLoading: false,
  validateError: false,
});

const signatureState = (state = initialState, { type }) => {
  switch (type) {
    case VALIDATE_SIGNATURE_TASK__REQUEST:
      return state.set('validateLoading', true).set('validateError', false);

    case VALIDATE_SIGNATURE_TASK__SUCCESS:
      return state.set('validateLoading', false);

    case VALIDATE_SIGNATURE_TASK__FAIL:
      return state.set('validateLoading', false).set('validateError', true);

    default:
      return initialState.merge(state);
  }
};

export default signatureState;
