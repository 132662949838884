import React, { useEffect, useRef, useState } from 'react';

import Obs from '../../../assets/images/obs.png';
import Obs2X from '../../../assets/images/obs@2x.png';
import Forbes from '../../../assets/images/forbes.png';
import Forbes2X from '../../../assets/images/forbes@2x.png';
import ViePratique from '../../../assets/images/viepratique.png';
import ViePratique2X from '../../../assets/images/viepratique@2x.png';
import Echos from '../../../assets/images/les-echos.png';
import Echos2X from '../../../assets/images/les-echos@2x.png';
import Figaro from '../../../assets/images/le-figaro.png';
import Figaro2X from '../../../assets/images/le-figaro@2x.png';
import Jdd from '../../../assets/images/jdd.png';
import Jdd2X from '../../../assets/images/jdd@2x.png';
import Boursorama from '../../../assets/images/boursorama.png';
import Boursorama2X from '../../../assets/images/boursorama@2x.png';
import CBanque from '../../../assets/images/cbanque.png';
import CBanque2X from '../../../assets/images/cbanque@2x.png';

import '../../../styles/components/Blocks/Home/Publications.css';

const images = [
  {
    name: 'OBS',
    width: 99,
    height: 30,
    src: {
      small: Obs,
      large: Obs2X,
    },
  },
  {
    name: 'Vie pratique féminin',
    width: 100,
    height: 32,
    src: {
      small: ViePratique,
      large: ViePratique2X,
    },
  },
  {
    name: 'Le Figaro',
    width: 160,
    height: 35,
    src: {
      small: Figaro,
      large: Figaro2X,
    },
  },
  {
    name: 'Le Journal du Dimanche',
    width: 95,
    height: 23,
    src: {
      small: Jdd,
      large: Jdd2X,
    },
  },
  {
    name: 'Les Echos',
    width: 143,
    height: 27,
    src: {
      small: Echos,
      large: Echos2X,
    },
  },
  {
    name: 'Forbes',
    width: 110,
    height: 27,
    src: {
      small: Forbes,
      large: Forbes2X,
    },
  },
  {
    name: 'Boursorama',
    width: 166,
    height: 34,
    src: {
      small: Boursorama,
      large: Boursorama2X,
    },
  },
  {
    name: 'cBanque',
    width: 100,
    height: 29,
    src: {
      small: CBanque,
      large: CBanque2X,
    },
  },
];

function Publications() {
  const containerRef = useRef(null);
  const imagesRef = useRef(null);

  const [hasScrolled, setHasScrolled] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [translateAmount, setTranslateAmount] = useState(0);
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    images.forEach(image => {
      new Image().src = image.src;
    });
  }, []);

  function handleTestIfShouldAnimate() {
    if (containerRef.current && imagesRef.current) {
      let imagesContainerWidth = imagesRef.current.scrollWidth;

      if (shouldAnimate) {
        imagesContainerWidth /= 2;
      }

      if (imagesContainerWidth - 20 >= containerRef.current.clientWidth) {
        setShouldAnimate(true);
      } else {
        setShouldAnimate(false);
        setTranslateAmount(0);
      }
    }
  }

  useEffect(() => {
    handleTestIfShouldAnimate();
  }, []);

  useEffect(() => {
    if (hasScrolled && shouldAnimate && imagesRef.current) {
      const interval = setInterval(() => {
        setTranslateAmount(amount => {
          if (amount === imagesRef.current.scrollWidth / 2 + 20) {
            return 0;
          }

          return amount + 1;
        });
      }, 45);

      return () => clearInterval(interval);
    }

    return () => {};
  }, [hasScrolled, shouldAnimate, screenSize]);

  function handleResize() {
    setScreenSize(window.innerWidth);
    handleTestIfShouldAnimate();
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [shouldAnimate]);

  function handleScroll() {
    if (!hasScrolled && window.scrollY > 0) {
      setHasScrolled(true);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [shouldAnimate]);

  return (
    <div className="Publications">
      <div className="Publications__container" ref={containerRef}>
        <div
          className="Publications__images"
          ref={imagesRef}
          style={{ transform: `translate3D(-${translateAmount}px, 0, 0)` }}
        >
          {images.map((image, imageIndex) => (
            <img
              key={`1-${imageIndex}`}
              src={image.src.small}
              srcSet={`${image.src.large} 2x`}
              alt={`Logo ${image.name}`}
              width={`${image.width}px`}
              height={`${image.height}px`}
              className="Publications__logo"
            />
          ))}
          {shouldAnimate &&
            images.map((image, imageIndex) => (
              <img
                key={`2-${imageIndex}`}
                src={image.src.small}
                srcSet={`${image.src.large} 2x`}
                alt={`Logo ${image.name}`}
                width={`${image.width}px`}
                height={`${image.height}px`}
                className="Publications__logo"
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Publications;
