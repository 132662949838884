import { BASE_API_URL } from '../config/api';

export const fetchProductLinesByType = async (
  productId,
  lineType,
  filter,
  page,
  pageSize,
) => {
  const url = new URL(
    `${BASE_API_URL}/2.0/product/${productId}/lines/${lineType}?page=${page}&pageSize=${pageSize}&filter=${filter}`,
  );

  const response = await fetch(url.href, {
    credentials: 'include',
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  }

  throw response;
};

export const fetchProductLines = async productId => {
  const url = new URL(`${BASE_API_URL}/2.0/product/${productId}/lines`);

  const response = await fetch(url.href, {
    credentials: 'include',
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  }

  throw response;
};
