import React from 'react';

import HomeContext from '../../contexts/HomeContext';

/* eslint-disable react/display-name */
const withHomeContext = Component => props => (
  <HomeContext.Consumer>
    {context => <Component {...props} context={context} />}
  </HomeContext.Consumer>
);

export default withHomeContext;
