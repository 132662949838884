import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import MieuxplacerAPI from '../api/Mieuxplacer';
import * as ContractAPI from '../api/Contract';

import {
  FETCH_CONTRACTS__REQUEST,
  FETCH_CONTRACTS__SUCCESS,
  FETCH_CONTRACTS__FAIL,
  DELETE_CONTRACT__REQUEST,
  DELETE_CONTRACT__SUCCESS,
  DELETE_CONTRACT__FAIL,
  FETCH_CONTRACT_FILES__REQUEST,
  FETCH_CONTRACT_FILES__SUCCESS,
  FETCH_CONTRACT_FILES__FAIL,
} from '../actions/ContractsActions';

function* fetchContracts() {
  try {
    const apiCall = () => MieuxplacerAPI.Api.getContracts();
    const data = yield call(apiCall);

    yield put({
      type: FETCH_CONTRACTS__SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_CONTRACTS__FAIL,
    });
  }
}

function* fetchFiles({ payload }) {
  try {
    const apiCall = () => MieuxplacerAPI.Api.getFiles(payload);
    const data = yield call(apiCall);

    yield put({
      type: FETCH_CONTRACT_FILES__SUCCESS,
      payload: { contract: payload, files: data },
    });
  } catch (e) {
    yield put({
      type: FETCH_CONTRACT_FILES__FAIL,
    });
  }
}

function* deleteContract({ payload }) {
  try {
    const data = yield call(ContractAPI.deleteContract, payload);

    yield put({
      type: DELETE_CONTRACT__SUCCESS,
      payload: { id: payload, data },
    });

    yield put(push('/espace-client/mes-contrats'));
  } catch (e) {
    yield put({
      type: DELETE_CONTRACT__FAIL,
      payload: e.error,
    });
  }
}

const contractsSagas = [
  takeLatest(FETCH_CONTRACTS__REQUEST, fetchContracts),
  takeLatest(FETCH_CONTRACT_FILES__REQUEST, fetchFiles),
  takeLatest(DELETE_CONTRACT__REQUEST, deleteContract),
];

export default contractsSagas;
