import React from 'react';

import AppContext from '../../contexts/AppContext';

/* eslint-disable react/display-name */
const withAppContext = Component => props => (
  <AppContext.Consumer>
    {context => <Component {...props} context={context} />}
  </AppContext.Consumer>
);

export default withAppContext;
