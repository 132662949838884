import PropTypes from 'prop-types';

import rewardShape from './rewardShape';
import {
  paragraphShape,
  urlShape,
  headlineWithBlocksShape,
  bonusShape,
  downloadsShape,
  trustpilotShape,
  importantInfoShape,
} from './prismicShape';

const productShape = {
  id: PropTypes.string,
  slug: PropTypes.string,
  identifier: PropTypes.string,
  contentType: PropTypes.string,
  gaTitle: PropTypes.string,
  title: PropTypes.string,
  metaDescription: PropTypes.string,
  summary: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
  description: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
  type: PropTypes.string,
  supplier: PropTypes.string,
  subscribable: PropTypes.bool,
  riskLevel: PropTypes.number,
  riskLevelEnabled: PropTypes.bool,
  order: PropTypes.number,
  investPeriod: PropTypes.number,
  withdrawLimit: PropTypes.string,
  guaranteedCapital: PropTypes.string,
  subscriptionPeriod: PropTypes.string,
  minimumInvestment: PropTypes.number,
  calandlyLabel: PropTypes.string,
  calendlyUrl: PropTypes.string,
  listingFirstFeatureLabel: PropTypes.string,
  listingFirstFeatureValue: PropTypes.string,
  objectives: PropTypes.arrayOf(PropTypes.string),
  riskProfiles: PropTypes.arrayOf(PropTypes.string),
  investorTypologies: PropTypes.arrayOf(PropTypes.string),
  rewards: PropTypes.arrayOf(PropTypes.shape(rewardShape)),
  managementModes: PropTypes.arrayOf(PropTypes.string),
  taxSystems: PropTypes.arrayOf(PropTypes.string),
  performancesLabel: PropTypes.string,
  performances: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  tdvms: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  occupancyRates: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  distributedIncomesPerUnit: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  yield: PropTypes.string,
  maximumRate: PropTypes.number,
  levelOfTaxAdvantage: PropTypes.string,
  blocks: PropTypes.arrayOf(
    PropTypes.shape(headlineWithBlocksShape),
    PropTypes.shape(bonusShape),
    PropTypes.shape(downloadsShape),
    PropTypes.shape(trustpilotShape),
    PropTypes.shape(importantInfoShape),
  ),
  returnedBonusesAlert: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
  availableForSubscription: PropTypes.bool,
  recommendation: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
  supplierConditions: PropTypes.arrayOf(PropTypes.shape(paragraphShape)),
  subscriptionDocument: PropTypes.shape(urlShape),
  subscriptionCalendly: PropTypes.shape(urlShape),
};

export default productShape;
