import { Map, fromJS, List } from 'immutable';

import {
  FETCH_PAGE__REQUEST,
  FETCH_PAGE__SUCCESS,
  FETCH_PAGE__FAIL,
  FETCH_PAGE__NOTFOUND,
} from '../actions/PageActions';

const initialState = Map({
  pages: Map({}),
  loading: List([]),
  error: List([]),
  notFound: List([]),
});

const pageState = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PAGE__REQUEST:
      return state
        .set('loading', state.get('loading').push(payload.id || payload.slug))
        .set(
          'error',
          state.get('error').filter(id => id !== payload.id || payload.slug),
        )
        .set(
          'notFound',
          state.get('notFound').filter(id => id !== payload.id || payload.slug),
        );

    case FETCH_PAGE__SUCCESS:
      return state
        .set(
          'loading',
          state.get('loading').filter(id => id !== payload.id),
        )
        .setIn(['pages', payload.id], fromJS(payload.data));

    case FETCH_PAGE__FAIL:
      return state
        .set(
          'loading',
          state.get('loading').filter(id => id !== payload.id),
        )
        .set('error', state.get('error').push(payload.id));

    case FETCH_PAGE__NOTFOUND:
      return state
        .set(
          'loading',
          state.get('loading').filter(id => id !== payload.id),
        )
        .set('notFound', state.get('notFound').push(payload.id));

    default:
      return initialState.merge(state);
  }
};

export default pageState;
