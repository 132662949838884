import { Map, List, fromJS } from 'immutable';

import {
  FETCH_BLOG_POSTS__REQUEST,
  FETCH_BLOG_POSTS__SUCCESS,
  FETCH_BLOG_POSTS__FAIL,
} from '../actions/BlogPostsActions';

const initialState = Map({
  blogPosts: List([]),
  blogPostsLoading: false,
  blogPostsError: false,
});

const blogPostsState = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BLOG_POSTS__REQUEST:
      return state.set('blogPostsLoading', true).set('blogPostsError', false);

    case FETCH_BLOG_POSTS__SUCCESS:
      return state
        .set('blogPostsLoading', false)
        .set('blogPosts', fromJS(payload));

    case FETCH_BLOG_POSTS__FAIL:
      return state.set('blogPostsLoading', false).set('blogPostsError', true);

    default:
      return initialState.merge(state);
  }
};

export default blogPostsState;
