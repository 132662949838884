import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { pageTitleShape } from '../../../shapes/prismicShape';

import PrismicBlockTitle from '../Core/PrismicBlockTitle';

class PrismicPageTitle extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicPageTitle">
        <PrismicBlockTitle title={data.primary.page_title} />
      </div>
    );
  }
}

PrismicPageTitle.propTypes = {
  data: PropTypes.shape(pageTitleShape).isRequired,
};

export default PrismicPageTitle;
