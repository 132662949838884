import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { textNumberShape } from '../../../shapes/prismicShape';

import RichText from '../../Core/RichText';
import PrismicBlockTitle from '../Core/PrismicBlockTitle';

import '../../../styles/components/Prismic/PrismicTextNumber.css';

class PrismicTextNumber extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicTextNumber">
        {data.primary.text_number_title &&
          data.primary.text_number_title.length > 0 && (
            <div className="PrismicTextNumber__title">
              <PrismicBlockTitle title={data.primary.text_number_title} />
            </div>
          )}
        <div className="PrismicTextNumber__container">
          <div className="PrismicTextNumber__content-container">
            {data.primary.text_number_content &&
              data.primary.text_number_content.length > 0 && (
                <div className="PrismicTextNumber__content">
                  <RichText paragraphs={data.primary.text_number_content} />
                </div>
              )}
          </div>
          <div className="PrismicTextNumber__statistics-container">
            <div className="PrismicTextNumber__statistics">
              {data.primary.text_image_statistic_value && (
                <div className="PrismicTextNumber__statistics-value">
                  {data.primary.text_image_statistic_value}
                </div>
              )}
              {data.primary.text_image_statistic_label && (
                <div className="PrismicTextNumber__statistics-label">
                  {data.primary.text_image_statistic_label}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrismicTextNumber.propTypes = {
  data: PropTypes.shape(textNumberShape).isRequired,
};

export default PrismicTextNumber;
