import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import validation from '../../../assets/icons/validation.png';

const ProgressBar = ({ labels, definedLabels }) => {
  const totalItems = labels.length;
  let highestIndex = 0;

  definedLabels.forEach(label => {
    const labelIndex = labels.indexOf(label);

    if (labelIndex > highestIndex) {
      highestIndex = labelIndex;
    }
  });

  const progressBarHeight =
    highestIndex > 0 ? 100 - (100 / (totalItems - 1)) * highestIndex : 100;

  return (
    <div className="ProductProfile__progress-bar-items">
      <span className="ProductProfile__progress-bar-empty" />
      <span
        className="ProductProfile__progress-bar-full"
        style={{
          height:
            progressBarHeight > 50
              ? `calc(${progressBarHeight}% - 18px)`
              : `${progressBarHeight}%`,
        }}
      />

      {labels.map((label, index) => {
        const itemClass = classNames('ProductProfile__progress-bar-item', {
          'ProductProfile__progress-bar-item--checked': index >= highestIndex,
        });

        return (
          <div key={index} className={itemClass}>
            <div className="ProductProfile__progress-bar-item-check">
              <img src={validation} alt={label} />
            </div>
            <div className="ProductProfile__progress-bar-item-label">
              {label}
            </div>
          </div>
        );
      })}
    </div>
  );
};

ProgressBar.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  definedLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProgressBar;
