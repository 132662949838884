import React from 'react';

import LoadingGradient from '../../../Core/LoadingGradient';

import '../../../../styles/components/Blocks/Home/HeaderForm.css';

const HeaderFormLoading = () => (
  <div className="HeaderForm HeaderForm--loading">
    <div className="HeaderForm__question">
      <LoadingGradient
        className="LoadingGradient--text-line"
        width="25%"
        height="14px"
      />
      <LoadingGradient
        className="HeaderForm__input"
        width="30%"
        height="24px"
      />
      <LoadingGradient
        className="LoadingGradient--text-line"
        width="30%"
        height="14px"
      />
    </div>
    <div className="HeaderForm__question HeaderForm__question--objectives">
      <div className="MP-OnboardingIconCheckboxList">
        <div className="MP-OnboardingIconCheckboxList__input-container">
          <div className="MP-OnboardingIconCheckboxList__items">
            {[1, 2, 3, 4, 5, 6].map(key => (
              <div key={key} className="MP-OnboardingIconCheckbox">
                <LoadingGradient
                  className="MP-OnboardingIconCheckbox__icon-container"
                  width="32px"
                  height="24px"
                />
                <div className="MP-OnboardingIconCheckbox__label">
                  <LoadingGradient
                    className="LoadingGradient--text-line"
                    width="80%"
                    height="14px"
                  />
                  <LoadingGradient
                    className="LoadingGradient--text-line"
                    width="60%"
                    height="14px"
                  />
                </div>
                <LoadingGradient
                  className="MP-OnboardingIconCheckbox__check"
                  width="20px"
                  height="20px"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="HeaderForm__action">
      <LoadingGradient width="220px" height="58px" />
    </div>
  </div>
);

export default HeaderFormLoading;
