import { put, call, takeLatest } from 'redux-saga/effects';

import MieuxplacerAPI from '../api/Mieuxplacer';

import {
  FETCH_TYPES__REQUEST,
  FETCH_TYPES__SUCCESS,
  FETCH_TYPES__FAIL,
} from '../actions/TypeActions';

function* fetchTypes() {
  const params = {
    orderBy: 'label',
  };

  try {
    const apiCall = () => MieuxplacerAPI.Api.getPrismicProductTypes(params);
    const data = yield call(apiCall);

    yield put({
      type: FETCH_TYPES__SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_TYPES__FAIL,
      payload: e.message,
    });
  }
}

const typeSagas = [takeLatest(FETCH_TYPES__REQUEST, fetchTypes)];

export default typeSagas;
