import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@robinfinance/ui';
import { Question } from '@robinfinance/onboarding';

import {
  INVESTMENT_AMOUNT_KEY,
  OBJECTIVES_KEY,
  RECOMMENDATION_ENTRY_TYPE,
} from '../../../config/onboarding';

import {
  setAnswer,
  changeOnboardingAnswer,
} from '../../../actions/OnboardingActions';

import { getQuestionAnswer } from '../../../helpers/questionHelpers';

import withAppContext from '../../HOC/withAppContext';
import HeaderFormLoading from './Loading/HeaderFormLoading';

import '../../../styles/components/Blocks/Home/HeaderForm.css';

function HeaderForm({ isLoading, context: { setOnboardingStartingPoint } }) {
  const [initalPlacementPlaceholder, setInitialPlacementPlaceholder] = useState(
    '2 500 000',
  );

  const { loading, error } = useSelector(state =>
    state.get('onboardingState').toJS(),
  );
  const answers = [];
  const questions = [];

  const dispatch = useDispatch();

  function handleChange(key, value) {
    dispatch(setAnswer(key, value));
    dispatch(changeOnboardingAnswer(key, 'widget-home'));
  }

  let placeholdersCount = 0;
  let placeholderCount = 0;

  let timeout;

  function type() {
    const placeholders = ['15 000', '375 800', '25 000', '46 500', '125 000'];

    if (placeholdersCount === placeholders.length) {
      placeholdersCount = 0;
    }

    const selectedPlaceholder = placeholders[placeholdersCount];

    placeholderCount += 1;

    const newPlaceholder = selectedPlaceholder.slice(0, placeholderCount);
    setInitialPlacementPlaceholder(newPlaceholder);

    if (newPlaceholder.length === selectedPlaceholder.length) {
      placeholdersCount += 1;
      placeholderCount = 0;

      timeout = setTimeout(type, 2000);
    } else if (/\s$/.test(newPlaceholder)) {
      timeout = setTimeout(type, 0);
    } else {
      timeout = setTimeout(type, 100);
    }
  }

  useEffect(() => {
    if (!answers[INVESTMENT_AMOUNT_KEY]) {
      type();

      return () => {
        setInitialPlacementPlaceholder('15 000');
        clearTimeout(timeout);
      };
    }
    return () => { };
  }, [answers[INVESTMENT_AMOUNT_KEY]]);

  if (isLoading || loading || error) {
    return <HeaderFormLoading />;
  }

  return (
    <div className="HeaderForm">
      <div className="HeaderForm__question">
        <span className="HeaderForm__text">Je souhaite placer </span>{' '}
        <span className="HeaderForm__text--amount">
          {initalPlacementPlaceholder}
        </span>{' '}
        <span className="HeaderForm__text">€</span>
      </div>
      <div className="HeaderForm__question HeaderForm__question--objectives">
        {questions[OBJECTIVES_KEY] && (
          <Question
            isVisible
            question={questions[OBJECTIVES_KEY]}
            answer={getQuestionAnswer(questions[OBJECTIVES_KEY], answers)}
            withErrors={false}
            onChange={handleChange}
          />
        )}
      </div>
      <div className="HeaderForm__action">
        <Button
          id="Home-StartSimulation"
          scope="button"
          type="secondary"
          to="/connaissance-client/1"
          onClick={() => setOnboardingStartingPoint(RECOMMENDATION_ENTRY_TYPE)}
        >
          Commencer ma simulation
        </Button>
      </div>
    </div>
  );
}

HeaderForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  context: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    setOnboardingStartingPoint: PropTypes.func,
  }).isRequired,
};

export default withAppContext(HeaderForm);
