export const shuffle = array => {
  const randomizedArray = array;
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = randomizedArray[currentIndex];
    randomizedArray[currentIndex] = array[randomIndex];
    randomizedArray[randomIndex] = temporaryValue;
  }

  return randomizedArray;
};
