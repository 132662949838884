import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../styles/components/Core/Input.css';

class Input extends PureComponent {
  errorContainer = React.createRef();

  state = {
    isFocussed: false,
  };

  getErrorHeight = () => {
    if (this.errorContainer && this.errorContainer.offsetHeight) {
      return `${this.errorContainer.offsetHeight}px`;
    }

    return '20px';
  };

  handleChange = event => {
    const { name, onChange } = this.props;
    const {
      target: { value },
    } = event;

    onChange(name, value);
  };

  handleBlur = event => {
    const { name, onBlur } = this.props;
    const {
      target: { value },
    } = event;

    onBlur(name, value);
    this.setState({ isFocussed: false });
  };

  handleFocus = () => {
    this.setState({ isFocussed: true });
  };

  handleMouseWheel = event => {
    const { type } = this.props;

    if (type === 'number') {
      event.preventDefault();
    }
  };

  render() {
    const {
      label,
      icon,
      iconClass,
      type,
      pattern,
      className,
      disabled,
      name,
      errors,
      value,
      required,
      hideOnYandex,
    } = this.props;

    const { isFocussed } = this.state;

    const containerClasses = classNames('Input', {
      'Input--is-focussed': isFocussed,
      'Input--has-value': value.length > 0,
      'Input--disabled': disabled,
      'Input--has-errors': errors.length > 0,
      'ym-hide-content': hideOnYandex,
      [className]: className,
    });

    const iconClasses = classNames('Input__icon', {
      [iconClass]: iconClass,
    });

    const iconWithClass = iconClass ? <span className={iconClasses} /> : null;
    const iconElement = icon ? (
      <span className={iconClasses}>{icon}</span>
    ) : null;

    return (
      <div
        className={containerClasses}
        style={{
          paddingBottom: errors.length > 0 ? this.getErrorHeight() : '0',
        }}
      >
        {iconWithClass || iconElement}
        <div className="Input__container">
          <label htmlFor={name} className="Input__label">
            <span className="Input__label-text">
              {label}
              {required && <span className="Input__asterix">*</span>}
            </span>
            <input
              className="Input__input"
              id={name}
              name={name}
              type={type}
              disabled={disabled}
              value={value}
              pattern={pattern}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onWheel={this.handleMouseWheel}
            />
          </label>
          <div
            className="Input__errors"
            ref={ref => {
              this.errorContainer = ref;
            }}
          >
            {Array.isArray(errors) ? (
              errors.map((error, index) => (
                <p key={index} className="Input__error">
                  {error}
                </p>
              ))
            ) : (
              <p className="Input__error">{errors}</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Input.defaultProps = {
  label: '',
  icon: null,
  iconClass: null,
  type: 'text',
  className: null,
  disabled: false,
  errors: [],
  pattern: null,
  required: false,
  hideOnYandex: false,
  onChange: () => {},
  onBlur: () => {},
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.element,
  iconClass: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'tel']),
  pattern: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  hideOnYandex: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Input;
