import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, PartialArrowLeft } from '@robinfinance/ui';

import { AUTH_MODAL_TYPES } from '../../../config/authForms';

import withAppContext from '../../HOC/withAppContext';
import Modal from '../../Core/Modal';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import CloseModal from '../../Core/CloseModal';

import '../../../styles/components/Modules/Auth/AuthModal.css';

function AuthModal({
  location,
  context: {
    authModalIsOpen,
    initialLoginEmail,
    authModalIsBlocking,
    updateAnswersOnLogin,
    // recommendationToFetchOnLogin,
    closeAuthModal,
    updateAnswers,
    // fetchRecommendationByToken,
  },
}) {
  const [formType, setFormType] = useState(AUTH_MODAL_TYPES.LOGIN_FORM);

  useEffect(() => {
    closeAuthModal();
  }, [location]);

  function handleOnLogin() {
    if (updateAnswersOnLogin) {
      updateAnswers({ generateRecommendation: true });
    }

    // if (recommendationToFetchOnLogin !== '') {
    //   fetchRecommendationByToken(recommendationToFetchOnLogin);
    // }
  }

  function renderForm() {
    switch (formType) {
      case AUTH_MODAL_TYPES.LOGIN_FORM:
        return (
          <Fragment>
            <LoginForm
              initialEmail={initialLoginEmail}
              onLogin={handleOnLogin}
            />
            <div className="AuthModal__links">
              <Button
                scope="link"
                type="secondary"
                onClick={() => {
                  setFormType(AUTH_MODAL_TYPES.FORGOT_PASSWORD_FORM);
                }}
              >
                Vous avez oublié votre mot de passe ?
              </Button>
            </div>
          </Fragment>
        );

      case AUTH_MODAL_TYPES.FORGOT_PASSWORD_FORM:
        return (
          <Fragment>
            <ForgotPasswordForm />
            <div className="AuthModal__links">
              <Button
                scope="link"
                type="secondary"
                onClick={() => {
                  setFormType(AUTH_MODAL_TYPES.LOGIN_FORM);
                }}
              >
                Vous avez déjà un compte ? Connectez-vous en cliquant ici.
              </Button>
            </div>
          </Fragment>
        );

      default:
        return null;
    }
  }

  return (
    <Modal isOpen={authModalIsOpen} isBlockingType onClose={closeAuthModal}>
      <div className="AuthModal">
        <div className="AuthModal__actions">
          <Button
            scope="dashboard"
            type="primary"
            iconSide="left"
            icon={<PartialArrowLeft color="#223b5d" />}
            to="/"
            onClick={closeAuthModal}
          >
            Retour à la page d&apos;accueil
          </Button>
          {!authModalIsBlocking && (
            <CloseModal label="Fermer" onClick={closeAuthModal} />
          )}
        </div>
        <div className="AuthModal__header">
          <p className="AuthModal__title">Identifiez-vous !</p>
          <p className="AuthModal__text">
            Connectez-vous à votre compte afin d&apos;avoir accès à cette page.
          </p>
        </div>
        <div className="AuthModal__content">{renderForm()}</div>
      </div>
    </Modal>
  );
}

AuthModal.propTypes = {
  location: PropTypes.string.isRequired,
  context: PropTypes.shape({
    authModalIsOpen: PropTypes.bool.isRequired,
    initialLoginEmail: PropTypes.string.isRequired,
    authModalIsBlocking: PropTypes.bool.isRequired,
    updateAnswersOnLogin: PropTypes.bool.isRequired,
    // recommendationToFetchOnLogin: PropTypes.string.isRequired,
    closeAuthModal: PropTypes.func.isRequired,
    updateAnswers: PropTypes.func.isRequired,
    // fetchRecommendationByToken: PropTypes.func.isRequired,
  }).isRequired,
};

export default withAppContext(AuthModal);
