import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Joi from 'joi-browser';
import {
  Button,
  ArrowRight,
  Input,
  Email,
  Password,
  DEFAULT_ERROR_MESSAGES,
  EmailValidationError,
} from '@robinfinance/ui';
import { ErrorTypes } from '@robinfinance/js-api';

import {
  EMPTY_ERROR_MESSAGES,
  INCORRECT_ERROR_MESSAGES,
} from '../../../config/authForms';
import loginSchema from '../../../helpers/schema/loginFormSchema';
import MieuxplacerAPI from '../../../api/Mieuxplacer';
import { loginUser } from '../../../actions/AuthActions';

import toJS from '../../HOC/toJS';
import Alert from '../../Core/Alert';

import '../../../styles/components/Modules/Auth/LoginForm.css';

function LoginForm({ initialEmail, onLogin, loginUser }) {
  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState('');

  const [errors, setErrors] = useState({ email: [], password: [] });

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const validationSchemas = {
    email: Joi.string().email({ minDomainAtoms: 2 }).required(),
    password: Joi.string().min(8).required(),
  };

  async function validateInput(name, value) {
    try {
      await Joi.validate(value, validationSchemas[name]);

      setErrors(oldErrors => ({ ...oldErrors, [name]: [] }));
    } catch (error) {
      if (error.details[0].type === 'any.empty') {
        setErrors(oldErrors => ({
          ...oldErrors,
          [name]: [EMPTY_ERROR_MESSAGES[name]],
        }));
      } else {
        setErrors(oldErrors => ({
          ...oldErrors,
          [name]: [INCORRECT_ERROR_MESSAGES[name]],
        }));
      }
    }
  }

  function handleResetErrors() {
    setErrors({ email: [], password: [] });
    setHasError(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    try {
      await Joi.validate({ email, password }, loginSchema, {
        abortEarly: false,
      });

      try {
        await loginUser({ email, password });

        handleResetErrors();
        onLogin();
      } catch (exception) {
        setHasError(true);

        if (typeof exception.json === 'function') {
          const error = await exception.json();

          setErrorMessage(error.error);
        } else {
          setErrorMessage(undefined);
        }
      }
    } catch (error) {
      const inputErrors = {};

      error.details.forEach(inputError => {
        const name = inputError.context.key;

        if (inputError.type === 'any.empty') {
          inputErrors[name] = [EMPTY_ERROR_MESSAGES[name]];
        } else if (!inputErrors[name]) {
          inputErrors[name] = [INCORRECT_ERROR_MESSAGES[name]];
        }
      });

      setErrors(inputErrors);
    }

    setIsLoading(false);
  }

  function renderErrorMessage() {
    if (!isLoading && hasError) {
      let errorMessageContent =
        "Il semblerait que nous ayons un problème. Merci de réessayer dans quelques instants ou de nous contacter à l'adresse hello@mieuxplacer.com.";

      if (errorMessage && errorMessage.global) {
        if (errorMessage.global === ErrorTypes.EMAIL_NOT_VERIFIED) {
          errorMessageContent = (
            <EmailValidationError email={email} api={MieuxplacerAPI.Api} />
          );
        } else if (DEFAULT_ERROR_MESSAGES.global[errorMessage.global]) {
          errorMessageContent =
            DEFAULT_ERROR_MESSAGES.global[errorMessage.global];
        }
      }

      return (
        <div className="LoginForm__messages">
          <Alert type="error">{errorMessageContent}</Alert>
        </div>
      );
    }

    return null;
  }

  return (
    <div className="LoginForm">
      {renderErrorMessage()}

      <form className="LoginForm__form" onSubmit={handleSubmit}>
        <div className="LoginForm__form-inputs">
          <Input
            type="email"
            name="email"
            className="LoginForm__form-input ym-hide-content"
            label="Votre adresse e-mail"
            icon={<Email color="#FFC501" />}
            initialValue={initialEmail}
            isInvalid={errors.email.length > 0}
            error={errors.email}
            onChange={value => {
              setEmail(value);
              handleResetErrors();
            }}
            onBlur={value => validateInput('email', value)}
          />
          <Input
            type="password"
            name="password"
            className="LoginForm__form-input ym-hide-content"
            label="Votre mot de passe"
            hasPasswordToggle
            isInvalid={errors.password.length > 0}
            error={errors.password}
            icon={<Password color="#ffc501" />}
            onChange={value => {
              setPassword(value);
              handleResetErrors();
            }}
            onBlur={value => validateInput('password', value)}
          />
        </div>
        <Button
          scope="button"
          type="primary"
          iconSide="right"
          icon={<ArrowRight color="#ffc501" />}
          onClick={handleSubmit}
          loading={isLoading}
          hasLoadingState
          disabled={isLoading}
        >
          Me connecter
        </Button>
      </form>
    </div>
  );
}

LoginForm.defaultProps = {
  initialEmail: '',
  onLogin: () => {},
};

LoginForm.propTypes = {
  initialEmail: PropTypes.string,
  onLogin: PropTypes.func,
  loginUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loginUser,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(toJS(LoginForm));
