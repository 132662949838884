import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import Icon from '../../Core/Icon';

import arrowDropdown from '../../../assets/icons/arrow-dropdown.svg';

const HeaderLink = ({
  id,
  to,
  href,
  exact,
  label,
  target,
  dropdown,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  if (href) {
    return (
      <a
        id={id}
        href={href}
        target={target}
        rel={target ? 'noopener noreferrer' : null}
        className="Header__link"
      >
        {label}
      </a>
    );
  }

  if (to) {
    return (
      <NavLink
        id={id}
        exact={exact}
        to={to}
        className="Header__link"
        activeClassName="Header__link--active"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {label}
        {dropdown && (
          <Icon
            src={arrowDropdown}
            alt="Dropdown arrow"
            height="4px"
            width="8px"
          />
        )}
      </NavLink>
    );
  }

  if (onClick) {
    return (
      <button id={id} className="Header__link" onClick={onClick} type="button">
        {label}
        {dropdown && (
          <Icon
            src={arrowDropdown}
            alt="Dropdown arrow"
            height="4px"
            width="8px"
          />
        )}
      </button>
    );
  }

  return (
    <span className="Header__link">
      {label}
      {dropdown && (
        <Icon
          src={arrowDropdown}
          alt="Dropdown arrow"
          height="4px"
          width="8px"
        />
      )}
    </span>
  );
};

export default HeaderLink;

HeaderLink.defaultProps = {
  id: null,
  to: null,
  href: null,
  exact: false,
  target: '_self',
  dropdown: false,
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
};

HeaderLink.propTypes = {
  id: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  exact: PropTypes.bool,
  label: PropTypes.string.isRequired,
  target: PropTypes.string,
  dropdown: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};
