import React from 'react';
import PropTypes from 'prop-types';

import productShape from '../../../shapes/productShape';

import withProductContext from '../../HOC/withProductContext';

const ProductBreadcrumb = ({ context }) => {
  const { product } = context;

  return <span className="Breadcrumbs__item">{product && product.title}</span>;
};

ProductBreadcrumb.defaultProps = {
  context: {
    product: {},
  },
};

ProductBreadcrumb.propTypes = {
  context: PropTypes.shape({
    product: PropTypes.shape(productShape),
  }),
};

export default withProductContext(ProductBreadcrumb);
