import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { importantInfoShape } from '../../../shapes/prismicShape';

import RichText from '../../Core/RichText';

import '../../../styles/components/Prismic/PrismicImportantInfo.css';

class PrismicImportantInfo extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicImportantInfo">
        <div className="PrismicImportantInfo__block">
          {data.primary.important_information_icon &&
            data.primary.important_information_icon.url && (
              <div className="PrismicImportantInfo_icon">
                <img
                  src={data.primary.important_information_icon.url}
                  alt={data.primary.important_information_icon.alt}
                />
              </div>
            )}
          <div className="PrismicImportantInfo__content">
            <p className="PrismicImportantInfo__title">
              {data.primary.important_information_title}
            </p>
            <div className="PrismicImportantInfo__description">
              <RichText
                paragraphs={data.primary.important_information_description}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrismicImportantInfo.propTypes = {
  data: PropTypes.shape(importantInfoShape).isRequired,
};

export default PrismicImportantInfo;
