import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import homeShape from '../shapes/homeShape';
import productShape from '../shapes/productShape';
import typeShape from '../shapes/typeShape';
import supplierShape from '../shapes/supplierShape';

import { pageIsLoaded } from '../actions/AppActions';
import { fetchHome } from '../actions/HomeActions';
import { clickFeaturedProduct } from '../actions/ProductActions';

import HomeContext from '../contexts/HomeContext';

import toJS from '../components/HOC/toJS';
import FourOFour from '../components/Layout/FourOFour';
import PrismicMapper from '../components/Prismic/Core/PrismicMapper';
import FiveHundred from '../components/Layout/FiveHundred';
import Banner from '../components/Blocks/Home/Banner';
import Header from '../components/Blocks/Home/Header';
import HowDoesItWork from '../components/Blocks/Home/HowDoesItWork';
import OurProducts from '../components/Blocks/Home/OurProducts';
import Why from '../components/Blocks/Home/Why';
import Publications from '../components/Blocks/Home/Publications';

import '../styles/containers/Home.css';

class Home extends PureComponent {
  componentDidMount() {
    const { home, loading, fetchHome, pageIsLoaded } = this.props;

    if (home && Object.keys(home).length > 0) {
      pageIsLoaded(home.gaTitle);
    } else if (!loading) {
      fetchHome();
    }
  }

  componentDidUpdate(prevProps) {
    const { loading, home, pageIsLoaded } = this.props;

    if (prevProps.loading && !loading && home && Object.keys(home).length > 0) {
      pageIsLoaded(home.gaTitle);
    }
  }

  render() {
    const {
      home,
      loading,
      error,
      products,
      productsLoading,
      productsError,
      types,
      typesLoading,
      suppliers,
      clickFeaturedProduct,
      notFound,
      pageIsLoaded,
    } = this.props;

    const isLoading = loading && (!home || Object.keys(home).length === 0);

    if (!isLoading && error) {
      return <FiveHundred pageIsLoaded={pageIsLoaded} />;
    }

    if (!isLoading && notFound) {
      return <FourOFour pageIsLoaded={pageIsLoaded} />;
    }

    const {
      bannerColor,
      bannerLink,
      bannerText,
      bannerIsActivated,
      bannerLinkText,
      bannerIcon,
    } = home;

    return (
      <Fragment>
        {bannerIsActivated && (
          <Banner
            bannerColor={bannerColor}
            bannerLink={bannerLink}
            bannerText={bannerText}
            bannerLinkText={bannerLinkText}
            bannerIcon={bannerIcon}
          />
        )}
        <div className="Home">
          <Helmet>
            <title>{home.title}</title>
            <meta name="description" content={home.description} />

            <meta property="og:title" content={home.title} />
            <meta property="og:description" content={home.description} />
          </Helmet>

          <HomeContext.Provider
            value={{
              products,
              productsLoading,
              productsError,
              types,
              typesLoading,
              suppliers,
              clickFeaturedProduct,
            }}
          >
            <Header isLoading={isLoading} />
            <Publications />
            <HowDoesItWork />
            <OurProducts />
            <Why />
            <PrismicMapper blocks={home.blocks} />
          </HomeContext.Provider>
        </div>
      </Fragment>
    );
  }
}

Home.defaultProps = {
  home: {},
  products: [],
  types: [],
  suppliers: [],
};

Home.propTypes = {
  home: PropTypes.shape(homeShape),
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape(productShape)),
  productsLoading: PropTypes.bool.isRequired,
  productsError: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape(typeShape)),
  typesLoading: PropTypes.bool.isRequired,
  suppliers: PropTypes.arrayOf(PropTypes.shape(supplierShape)),
  fetchHome: PropTypes.func.isRequired,
  pageIsLoaded: PropTypes.func.isRequired,
  clickFeaturedProduct: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  home: state.get('homeState').get('home'),
  loading: state.get('homeState').get('homeLoading'),
  error: state.get('homeState').get('homeError'),
  products: state.get('productState').get('products'),
  productsLoading: state.get('productState').get('productsLoading'),
  productsError: state.get('productState').get('productsError'),
  types: state.get('typeState').get('types'),
  typesLoading: state.get('typeState').get('typesLoading'),
  suppliers: state.get('supplierState').get('suppliers'),
  notFound: state.get('homeState').get('homeNotFound'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchHome,
      pageIsLoaded,
      clickFeaturedProduct,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Home));
