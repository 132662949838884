import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import qs from 'qs';
import Cookies from 'js-cookie';

import MieuxplacerApi from '../api/Mieuxplacer';

import AppContext from '../contexts/AppContext';

import productShape from '../shapes/productShape';
import objectiveShape from '../shapes/objectiveShape';
import typeShape from '../shapes/typeShape';
import supplierShape from '../shapes/supplierShape';
import routerShape from '../shapes/routerShape';
import homeShape from '../shapes/homeShape';

import { fetchObjectives } from '../actions/ObjectiveActions';
import { fetchTypes } from '../actions/TypeActions';
import { fetchSuppliers } from '../actions/SupplierActions';
import { fetchProducts } from '../actions/ProductActions';
import { fetchHome } from '../actions/HomeActions';
import { fetchUserDetails } from '../actions/UserActions';

import {
  setTrackingSource,
  setUniverse,
  pageIsScrolled,
  launchServices,
  addUserVisit,
  showTestingOverlay,
  showTestingForm,
} from '../actions/AppActions';
import { closeAuthModal, closeVerificationModal } from '../actions/AuthActions';
import {
  fetchOnboarding,
  setOnboardingStartingPoint,
  updateAnswers,
} from '../actions/OnboardingActions';
import {
  sendTestingEmail,
  resetContactStatus,
  sendCallBackEmail,
} from '../actions/ContactActions';

import toJS from '../components/HOC/toJS';
import ScrollManager from '../components/HOC/ScrollManager';
import Header from './Header';
import Content from '../components/Layout/Content';
import Footer from '../components/Layout/Footer/Footer';
import CookieConsent from '../components/Core/CookieConsent';
import AuthModal from '../components/Modules/Auth/AuthModal';
import VerificationModal from '../components/Modules/Auth/VerificationModal';
import Enroll from '../components/Blocks/Enroll/Enroll';
// import CallbackSticky from '../components/Blocks/CallbackSticky/CallbackSticky';

import '../styles/containers/App.css';

class App extends PureComponent {
  state = {
    hasCookieConsent: Cookies.getJSON('cookieConsent'),
  };

  componentDidMount() {
    const {
      location: { search },
      isAuthenticated,
      authorizationToken,
      objectives,
      objectivesLoading,
      types,
      typesLoading,
      suppliers,
      suppliersLoading,
      products,
      productsLoading,
      onboardingLoading,
      home,
      fetchObjectives,
      fetchTypes,
      fetchSuppliers,
      fetchProducts,
      fetchOnboarding,
      fetchUserDetails,
      fetchHome,
      setTrackingSource,
      setUniverse,
      launchServices,
    } = this.props;
    const { hasCookieConsent } = this.state;

    // Preload JS chunks
    import(/* webpackChunkName: 'Onboarding' */ './Onboarding');
    import(/* webpackChunkName: 'Auth' */ './Auth');

    if (isAuthenticated) {
      import(/* webpackChunkName: 'Dashboard' */ './Dashboard');
    }

    if (authorizationToken) {
      MieuxplacerApi.Api.setAuthorizationToken(authorizationToken);
      fetchUserDetails();
    }

    let universeCookie = Cookies.get('MPUniverse');

    if (search.length > 0) {
      const params = qs.parse(search.substring(1));

      if (params.utm_campaign || params.utm_source || params.utm_medium) {
        setTrackingSource(
          params.utm_campaign,
          params.utm_source,
          params.utm_medium,
        );
      }

      if (params.universe) {
        universeCookie = params.universe;

        Cookies.set('MPUniverse', params.universe, {
          expires: 14,
        });
      }
    }

    if (universeCookie !== undefined) {
      setUniverse(universeCookie);
    }

    if (!objectivesLoading && objectives.length === 0) {
      fetchObjectives();
    }

    if (!typesLoading && types.length === 0) {
      fetchTypes();
    }

    if (!suppliersLoading && suppliers.length === 0) {
      fetchSuppliers();
    }

    if (!productsLoading && products.length <= 1) {
      fetchProducts();
    }

    if (!onboardingLoading) {
      fetchOnboarding();
    }

    if (Object.keys(home).length === 0) {
      fetchHome();
    }

    if (hasCookieConsent === true) {
      launchServices();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
      isAuthenticated,
      authorizationToken,
      fetchOnboarding,
      fetchUserDetails,
    } = this.props;
    const { hasCookieConsent } = this.state;

    if (hasCookieConsent == null && pathname !== prevProps.location.pathname) {
      this.handleConsent();
    }

    if (prevProps.authorizationToken !== authorizationToken) {
      MieuxplacerApi.Api.setAuthorizationToken(authorizationToken);
      if (authorizationToken) {
        fetchUserDetails();
      }
    }

    if (!prevProps.isAuthenticated && isAuthenticated) {
      import(/* webpackChunkName: 'Dashboard' */ './Dashboard');

      fetchOnboarding();
    }
  }

  handleConsent = () => {
    const { launchServices } = this.props;

    Cookies.set('cookieConsent', true, {
      expires: 365,
    });

    this.setState({ hasCookieConsent: true });
    launchServices();
  };

  handleNoConsent = () => {
    Cookies.set('cookieConsent', false, {
      expires: 365,
    });

    this.setState({ hasCookieConsent: true });
  };

  render() {
    const {
      router,
      location,
      universe,
      pageIsScrolled,
      authModalIsOpen,
      authModalIsBlocking,
      updateAnswersOnLogin,
      recommendationToFetchOnLogin,
      initialLoginEmail,
      verificationModalIsOpen,
      setOnboardingStartingPoint,
      isAuthenticated,
      isVerified,
      user,
      utmCampaign,
      utmSource,
      utmMedium,
      visits,
      closeAuthModal,
      closeVerificationModal,
      sendTestingEmail,
      testingLoading,
      testingError,
      testingErrorMessage,
      resetContactStatus,
      addUserVisit,
      showTestingOverlay,
      showTestingForm,
      callBackLoading,
      callBackError,
      callBackErrorMessage,
      sendCallBackEmail,
      leadModalIsOpen,
      updateAnswers,
      answers,
      objectives,
    } = this.props;
    const { hasCookieConsent } = this.state;

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    const classes = classNames('App', {
      'App--is-ie11': isIE11,
      [`App--${universe}`]: universe,
    });

    return (
      <div className={classes}>
        <Helmet>
          <title>Mieuxplacer.com</title>
          <meta
            name="description"
            content="Assurance-vie, compte-titre, FIP, FCPI, FCPR : Mieuxplacer.com c'est l'accompagnement d'experts certifiés pour une épargne en ligne adaptée à vos besoins."
          />

          <meta property="og:title" content="Mieuxplacer.com" />
          <meta
            property="og:description"
            content="Assurance-vie, compte-titre, FIP, FCPI, FCPR : Mieuxplacer.com c'est l'accompagnement d'experts certifiés pour une épargne en ligne adaptée à vos besoins."
          />
          <meta property="og:url" content="https://www.mieuxplacer.com" />
          <meta property="og:locale" content="fr_FR" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Mieuxplacer.com" />

          <meta
            property="og:image"
            content="https://www.mieuxplacer.com/img/social-sharing.png"
          />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="200" />
          <meta property="og:image:height" content="200" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@Mieux_placer" />

          <link rel="canonical" href="https://www.mieuxplacer.com" />
        </Helmet>

        <AppContext.Provider
          value={{
            isAuthenticated,
            isVerified,
            authModalIsOpen,
            authModalIsBlocking,
            updateAnswersOnLogin,
            recommendationToFetchOnLogin,
            initialLoginEmail,
            verificationModalIsOpen,
            closeAuthModal,
            closeVerificationModal,
            user,
            callBackLoading,
            callBackError,
            callBackErrorMessage,
            universe,
            utmCampaign,
            utmSource,
            utmMedium,
            sendCallBackEmail,
            updateAnswers,
            answers,
            objectives,
            setOnboardingStartingPoint,
          }}
        >
          <ScrollManager
            location={router && router.pathname}
            onScroll={pageIsScrolled}
          />

          <Header />
          <Content location={location} />
          <Footer setOnboardingStartingPoint={setOnboardingStartingPoint} />

          <AuthModal location={router && router.pathname} />
          <VerificationModal />

          <Enroll
            loading={testingLoading}
            error={testingError}
            errorMessage={testingErrorMessage}
            visits={visits}
            sendEmail={sendTestingEmail}
            resetForm={resetContactStatus}
            addUserVisit={addUserVisit}
            showTestingOverlay={showTestingOverlay}
            showTestingForm={showTestingForm}
            location={location}
            authModalIsOpen={authModalIsOpen}
            verificationModalIsOpen={verificationModalIsOpen}
            leadModalIsOpen={leadModalIsOpen}
          />

          {/* <CallbackSticky /> */}

          <CookieConsent
            consent={hasCookieConsent}
            handleConsent={this.handleConsent}
            handleNoConsent={this.handleNoConsent}
          />
        </AppContext.Provider>
      </div>
    );
  }
}

App.defaultProps = {
  universe: null,
  products: [],
  objectives: [],
  types: [],
  suppliers: [],
  home: {},
  user: {},
  isAuthenticated: null,
  authorizationToken: '',
  isVerified: null,
  router: {},
  testingErrorMessage: null,
  callBackErrorMessage: null,
};

App.propTypes = {
  // State
  universe: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape(productShape)),
  productsLoading: PropTypes.bool.isRequired,
  objectives: PropTypes.arrayOf(PropTypes.shape(objectiveShape)),
  objectivesLoading: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape(typeShape)),
  typesLoading: PropTypes.bool.isRequired,
  suppliers: PropTypes.arrayOf(PropTypes.shape(supplierShape)),
  suppliersLoading: PropTypes.bool.isRequired,
  onboardingLoading: PropTypes.bool.isRequired,
  home: PropTypes.shape(homeShape),
  user: PropTypes.shape({}),
  isVerified: PropTypes.bool,
  verificationModalIsOpen: PropTypes.bool.isRequired,
  testingLoading: PropTypes.bool.isRequired,
  testingError: PropTypes.bool.isRequired,
  testingErrorMessage: PropTypes.string,
  utmCampaign: PropTypes.string.isRequired,
  utmSource: PropTypes.string.isRequired,
  utmMedium: PropTypes.string.isRequired,
  visits: PropTypes.arrayOf(PropTypes.string).isRequired,
  isAuthenticated: PropTypes.bool,
  authorizationToken: PropTypes.string,
  authModalIsOpen: PropTypes.bool.isRequired,
  authModalIsBlocking: PropTypes.bool.isRequired,
  updateAnswersOnLogin: PropTypes.bool.isRequired,
  recommendationToFetchOnLogin: PropTypes.string.isRequired,
  initialLoginEmail: PropTypes.string.isRequired,
  callBackLoading: PropTypes.bool.isRequired,
  callBackError: PropTypes.bool.isRequired,
  callBackErrorMessage: PropTypes.string,
  leadModalIsOpen: PropTypes.bool.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // Props
  router: PropTypes.shape(routerShape),
  location: PropTypes.shape(routerShape).isRequired,
  // Actions
  fetchProducts: PropTypes.func.isRequired,
  fetchObjectives: PropTypes.func.isRequired,
  fetchTypes: PropTypes.func.isRequired,
  fetchSuppliers: PropTypes.func.isRequired,
  fetchHome: PropTypes.func.isRequired,
  fetchOnboarding: PropTypes.func.isRequired,
  fetchUserDetails: PropTypes.func.isRequired,
  setTrackingSource: PropTypes.func.isRequired,
  setUniverse: PropTypes.func.isRequired,
  pageIsScrolled: PropTypes.func.isRequired,
  launchServices: PropTypes.func.isRequired,
  setOnboardingStartingPoint: PropTypes.func.isRequired,
  closeAuthModal: PropTypes.func.isRequired,
  closeVerificationModal: PropTypes.func.isRequired,
  sendTestingEmail: PropTypes.func.isRequired,
  resetContactStatus: PropTypes.func.isRequired,
  addUserVisit: PropTypes.func.isRequired,
  showTestingOverlay: PropTypes.func.isRequired,
  showTestingForm: PropTypes.func.isRequired,
  sendCallBackEmail: PropTypes.func.isRequired,
  updateAnswers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  universe: state.get('appState').get('universe'),
  products: state.get('productState').get('products'),
  productsLoading: state.get('productState').get('productsLoading'),
  objectives: state.get('objectiveState').get('objectives'),
  objectivesLoading: state.get('objectiveState').get('objectivesLoading'),
  types: state.get('typeState').get('types'),
  typesLoading: state.get('typeState').get('typesLoading'),
  suppliers: state.get('supplierState').get('suppliers'),
  suppliersLoading: state.get('supplierState').get('suppliersLoading'),
  onboardingLoading: state.get('onboardingState').get('loading'),
  home: state.get('homeState').get('home'),
  router: state.get('router').get('location'),
  user: state.get('userState').get('user'),
  isAuthenticated: state.get('userState').get('isAuthenticated'),
  authorizationToken: state.get('userState').get('authorizationToken'),
  isVerified: state.get('userState').get('isVerified'),
  authModalIsOpen: state.get('authState').get('authModalIsOpen'),
  authModalIsBlocking: state.get('authState').get('authModalIsBlocking'),
  updateAnswersOnLogin: state.get('authState').get('updateAnswersOnLogin'),
  recommendationToFetchOnLogin: state
    .get('authState')
    .get('recommendationToFetchOnLogin'),
  initialLoginEmail: state.get('authState').get('initialEmail'),
  verificationModalIsOpen: state
    .get('authState')
    .get('verificationModalIsOpen'),
  testingLoading: state.get('contactState').get('testing').get('loading'),
  testingError: state.get('contactState').get('testing').get('error'),
  testingErrorMessage: state
    .get('contactState')
    .get('testing')
    .get('errorMessage'),
  utmCampaign: state.get('appState').get('utmCampaign'),
  utmSource: state.get('appState').get('utmSource'),
  utmMedium: state.get('appState').get('utmMedium'),
  visits: state.get('appState').get('visits'),
  callBackLoading: state.get('contactState').get('callBack').get('loading'),
  callBackError: state.get('contactState').get('callBack').get('error'),
  callBackErrorMessage: state
    .get('contactState')
    .get('callBack')
    .get('errorMessage'),
  leadModalIsOpen: state.get('productState').get('leadModalIsOpen'),
  answers: state.get('onboardingState').get('answers'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProducts,
      fetchTypes,
      fetchObjectives,
      fetchSuppliers,
      fetchOnboarding,
      fetchUserDetails,
      fetchHome,
      setTrackingSource,
      setUniverse,
      pageIsScrolled,
      launchServices,
      setOnboardingStartingPoint,
      closeAuthModal,
      closeVerificationModal,
      sendTestingEmail,
      resetContactStatus,
      addUserVisit,
      showTestingOverlay,
      showTestingForm,
      sendCallBackEmail,
      updateAnswers,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(toJS(App)),
);
