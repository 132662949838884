import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Route } from 'react-router-dom';

import store, { history } from './store';

import './helpers/fetchHelpers';
import './helpers/numeralHelpers';

import App from './containers/App';

import 'sanitize.css/sanitize.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/vendor/slick-slider.css';

import '@robinfinance/ui/lib/styles/colors.css';
import '@robinfinance/ui/lib/styles/fonts/larsseit.css';
import '@robinfinance/ui/lib/styles/fonts/authenia.css';

import './styles/helpers/variables.css';
import './styles/helpers/colors.css';
import './styles/helpers/transitions.css';
import './styles/themes/themes.css';
import './styles/index.css';

const target = document.querySelector('#cms-root');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route component={App} />
    </ConnectedRouter>
  </Provider>,
  target,
);
