import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { pageIsLoaded } from '../../../actions/AppActions';

import coupleAccueil from '../../../assets/images/couple-sur-canape-v2.svg';

import '../../../styles/components/Blocks/Onboarding/OnboardingStop.css';

function OnboardingStop() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pageIsLoaded('MIF - Service plus disponible'));
  }, []);

  return (
    <div className="OnboardingStop">
      <div className="OnboardingStop__container">
        <div className="OnboardingStop__image">
          <img src={coupleAccueil} alt="Illustration" />
        </div>
        <div className="OnboardingStop__content">
          <p>
            Cher utilisateur, merci pour l’intérêt que vous portez à
            Mieuxplacer.com !
          </p>
          <p>
            Une modernisation de notre site est en cours, veuillez nous
            contacter au 06 01 73 09 58 pour prendre rendez-vous.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OnboardingStop;
