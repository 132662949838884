import { Map, List, fromJS } from 'immutable';

import {
  FETCH_SUPPLIERS__REQUEST,
  FETCH_SUPPLIERS__SUCCESS,
  FETCH_SUPPLIERS__FAIL,
} from '../actions/SupplierActions';

const initialState = Map({
  suppliersLoading: false,
  suppliersError: false,
  suppliers: List([]),
});

const typeState = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SUPPLIERS__REQUEST:
      return state.set('suppliersLoading', true).set('suppliersError', false);

    case FETCH_SUPPLIERS__SUCCESS:
      return state
        .set('suppliersLoading', false)
        .set('suppliers', fromJS(payload));

    case FETCH_SUPPLIERS__FAIL:
      return state.set('suppliersLoading', false).set('suppliersError', true);

    default:
      return initialState.merge(state);
  }
};

export default typeState;
