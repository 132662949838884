import { all } from 'redux-saga/effects';

import productSagas from './ProductSagas';
import typeSagas from './TypeSagas';
import objectiveSagas from './ObjectiveSagas';
import supplierSagas from './SupplierSagas';
import homeSagas from './HomeSagas';
import pageSagas from './PageSagas';
import trustpilotSagas from './TrustpilotSagas';
import contactSagas from './ContactSagas';
import onboardingSagas from './OnboardingSagas';
import userSagas from './UserSagas';
import authSagas from './AuthSagas';
import contractsSagas from './ContractsSagas';
import supportingDocumentsSagas from './SupportingDocumentsSagas';
import signatureSagas from './SignatureSagas';
import personalInformationSagas from './PersonalInformationSagas';
import blogPostsSagas from './BlogPostsSagas';

export default function* rootSaga() {
  yield all([
    ...productSagas,
    ...typeSagas,
    ...objectiveSagas,
    ...supplierSagas,
    ...homeSagas,
    ...pageSagas,
    ...trustpilotSagas,
    ...contactSagas,
    ...onboardingSagas,
    ...userSagas,
    ...authSagas,
    ...contractsSagas,
    ...supportingDocumentsSagas,
    ...signatureSagas,
    ...personalInformationSagas,
    ...blogPostsSagas,
  ]);
}
