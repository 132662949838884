import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip as TippyTooltip } from 'react-tippy';
import { Button, ArrowRight } from '@robinfinance/ui';

import productShape from '../../../shapes/productShape';

import withProductContext from '../../HOC/withProductContext';

import '../../../styles/components/Blocks/Product/ProductAddSelection.css';

class ProductAddSelection extends PureComponent {
  handleClick = () => {
    const {
      context: { addProductToCart, product },
    } = this.props;

    addProductToCart({ id: product.id, identifier: product.identifier });
  };

  render() {
    const {
      className,
      context: { cartProducts, product },
    } = this.props;

    const cartProductIds = cartProducts
      ? cartProducts.map(product => product.id)
      : [];

    const productHasAlreadyBeenAdded =
      cartProductIds.length > 0 ? cartProductIds.includes(product.id) : false;

    const maxItemsHaveBeenSelected = cartProductIds.length >= 5;

    const buttonClasses = classNames(
      'ProductAddSelection ProductAddSelection__action',
      {
        [className]: className,
      },
    );

    if (productHasAlreadyBeenAdded) {
      return (
        <TippyTooltip
          title="Vous avez déjà ajouté ce produit à votre sélection."
          position="top"
          interactiveBorder="10"
          animateFill={false}
          className={buttonClasses}
        >
          <Button
            scope="button"
            type="primary"
            icon={<ArrowRight color="#ffc501" width="19" height="14" />}
            disabled
            id="AddedSelectionButtonProductDetails"
          >
            Ajouté à ma sélection
          </Button>
        </TippyTooltip>
      );
    }

    if (maxItemsHaveBeenSelected) {
      return (
        <TippyTooltip
          title="Vous ne pouvez pas ajouter plus de 5 produits à votre sélection."
          position="top"
          interactiveBorder="10"
          animateFill={false}
          className={buttonClasses}
        >
          <Button
            scope="button"
            type="primary"
            disabled
            id="AddSelectionButtonProductDetails"
          >
            Ajouter à ma sélection
          </Button>
        </TippyTooltip>
      );
    }

    return (
      <Button
        scope="button"
        type="primary"
        iconSide="right"
        icon={<ArrowRight color="#ffc501" width="19" height="14" />}
        onClick={this.handleClick}
        className="ProductAddSelection__action"
        id="AddSelectionButtonProductDetails"
      >
        Ajouter à ma sélection
      </Button>
    );
  }
}

ProductAddSelection.defaultProps = {
  context: {
    cartProducts: [],
  },
  className: '',
};

ProductAddSelection.propTypes = {
  context: PropTypes.shape({
    cartProducts: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, identifier: PropTypes.string }),
    ),
    addProductToCart: PropTypes.func.isRequired,
    product: PropTypes.shape(productShape).isRequired,
  }),
  className: PropTypes.string,
};

export default withProductContext(ProductAddSelection);
