import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { simulationShape } from '../../../shapes/simulationShape';

import Tag from '../../Core/Tag';

import '../../../styles/components/Blocks/Simulation/SimulationFees.css';

class SimulationFees extends PureComponent {
  renderFee = (number, devise, label) => (
    <div className="SimulationFees__fee">
      <p className="SimulationFees__fee-label">{label}</p>
      <div className="SimulationFees__value">
        <Tag
          label={`- ${number} ${devise}`}
          color="#db5461"
          backgroundColor="#fdf6f7"
          size="normal"
        />
      </div>
    </div>
  );

  renderFeeOne = (item, isPercent) => {
    if (
      item.fee_1_euro_value !== null &&
      item.fee_1_purcent_value !== null &&
      item.fee_1_label !== null
    ) {
      const number = isPercent
        ? item.fee_1_purcent_value
        : item.fee_1_euro_value;
      const devise = isPercent ? '%' : ' €';
      const label = item.fee_1_label;

      return this.renderFee(number, devise, label);
    }

    return null;
  };

  renderFeeTwo = (item, isPercent) => {
    if (item.fee_2_euro_value !== null && item.fee_2_purcent_value !== null) {
      const number = isPercent
        ? item.fee_2_purcent_value
        : item.fee_2_euro_value;
      const devise = isPercent ? '%' : ' €';
      const label = item.fee_2_label;

      return this.renderFee(number, devise, label);
    }

    return null;
  };

  renderFeeThree = (item, isPercent) => {
    if (item.fee_3_euro_value !== null && item.fee_3_purcent_value !== null) {
      const number = isPercent
        ? item.fee_3_purcent_value
        : item.fee_3_euro_value;
      const devise = isPercent ? '%' : ' €';
      const label = item.fee_3_label;

      return this.renderFee(number, devise, label);
    }

    return null;
  };

  renderFeeFour = (item, isPercent) => {
    if (item.fee_4_euro_value !== null && item.fee_4_purcent_value !== null) {
      const number = isPercent
        ? item.fee_4_purcent_value
        : item.fee_4_euro_value;
      const devise = isPercent ? '%' : ' €';
      const label = item.fee_4_label;

      return this.renderFee(number, devise, label);
    }

    return null;
  };

  renderFeeFive = (item, isPercent) => {
    if (item.fee_5_euro_value !== null && item.fee_5_purcent_value !== null) {
      const number = isPercent
        ? item.fee_5_purcent_value
        : item.fee_5_euro_value;
      const devise = isPercent ? '%' : ' €';
      const label = item.fee_5_label;

      return this.renderFee(number, devise, label);
    }

    return null;
  };

  renderFeeSix = (item, isPercent) => {
    if (item.fee_6_euro_value !== null && item.fee_6_purcent_value !== null) {
      const number = isPercent
        ? item.fee_6_purcent_value
        : item.fee_6_euro_value;
      const devise = isPercent ? '%' : ' €';
      const label = item.fee_6_label;

      return this.renderFee(number, devise, label);
    }

    return null;
  };

  render() {
    const { item, isPercent } = this.props;

    return (
      <div className="SimulationFees">
        {this.renderFeeOne(item, isPercent)}
        {this.renderFeeTwo(item, isPercent)}
        {this.renderFeeThree(item, isPercent)}
        {this.renderFeeFour(item, isPercent)}
        {this.renderFeeFive(item, isPercent)}
        {this.renderFeeSix(item, isPercent)}
      </div>
    );
  }
}

SimulationFees.propTypes = {
  item: PropTypes.shape(simulationShape).isRequired,
  isPercent: PropTypes.bool.isRequired,
};

export default SimulationFees;
