import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  paragraphShape,
  urlShape,
  imageShape,
} from '../../../shapes/prismicShape';

import RichText from '../../Core/RichText';
import PrismicLink from '../../Prismic/Core/PrismicLink';

import '../../../styles/components/Blocks/Home/Banner.css';

const Banner = ({
  bannerText,
  bannerLink,
  bannerIcon,
  bannerColor,
  bannerLinkText,
}) => (
  <div className="Banner" style={{ backgroundColor: bannerColor }}>
    <div className="Banner__container">
      <RichText paragraphs={bannerText} />
      <div className="Banner__picto-link-container">
        <PrismicLink className="Banner__link" link={bannerLink}>
          <Fragment>
            {bannerLinkText}
            {bannerIcon.url ? (
              <img
                className="Banner__picto"
                src={bannerIcon.url}
                alt={bannerIcon.alt}
              />
            ) : (
              <div className="Banner__picto Banner__picto-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="9"
                  viewBox="0 0 6 9"
                >
                  <path
                    fill="#ffffff"
                    fillRule="evenodd"
                    d="M.204 1.47C-.134 1.063-.042.488.408.184c.363-.245.86-.245 1.223 0l4.077 3.673a.854.854 0 0 1 0 1.286L1.631 8.816c-.451.305-1.089.222-1.427-.183a.845.845 0 0 1 0-1.102L3.558 4.5.194 1.47h.01z"
                  />
                </svg>
              </div>
            )}
          </Fragment>
        </PrismicLink>
      </div>
    </div>
  </div>
);

Banner.defaultProps = {
  bannerIcon: {},
  bannerColor: '#172b4d',
  bannerLinkText: 'En savoir plus',
};

Banner.propTypes = {
  bannerText: PropTypes.arrayOf(PropTypes.shape(paragraphShape)).isRequired,
  bannerLink: PropTypes.shape(urlShape).isRequired,
  bannerIcon: PropTypes.shape(imageShape),
  bannerColor: PropTypes.string,
  bannerLinkText: PropTypes.string,
};

export default Banner;
