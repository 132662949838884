import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { downloadsShape } from '../../../shapes/prismicShape';

import PrismicBlockTitle from '../Core/PrismicBlockTitle';
import FileDownload from '../../Core/FileDownload';
import Icon from '../../Core/Icon';

import phone from '../../../assets/icons/icon-phone.svg';
import arrow from '../../../assets/icons/arrow-download.svg';

import '../../../styles/components/Prismic/PrismicDownloads.css';

class PrismicDownloads extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="PrismicDownloads">
        {data.primary.downloads_title &&
          data.primary.downloads_title.length > 0 && (
            <div className="PrismicDownloads__title">
              <PrismicBlockTitle title={data.primary.downloads_title} />
              <div className="PrismicDownloads__annotation">
                <Icon src={arrow} alt="icon arrow" height="14px" width="33px" />
                <p>Attention, sortez vos lunettes !</p>
              </div>
            </div>
          )}
        <div className="PrismicDownloads__items">
          {data.items.map((item, index) => (
            <div key={index} className="PrismicDownloads__item">
              <FileDownload
                title={item.download_title}
                url={item.download_file && item.download_file.url}
                icon={item.download_icon && item.download_icon.url}
              />
            </div>
          ))}
        </div>
        <div className="PrismicDownloads__contact">
          <p className="PrismicDownloads__contact_text">
            Si vous souhaitez plus d’information et que vous n’avez pas envie de
            lire toute cette documentation technique, appelez-nous ! Nous serons
            ravis de vous expliquer simplement tout cela.
          </p>
          <div className="PrismicDownloads__action">
            <a className="PrismicDownloads__call-link" href="tel:0186260606">
              <img src={phone} title="icon_phone" alt="icon_phone" />
              Appelez-nous au 01 86 26 06 06
            </a>
          </div>
        </div>
      </div>
    );
  }
}

PrismicDownloads.propTypes = {
  data: PropTypes.shape(downloadsShape).isRequired,
};

export default PrismicDownloads;
