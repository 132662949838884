import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../styles/components/Core/Tag.css';

class Tag extends PureComponent {
  render() {
    const { label, color, backgroundColor, size } = this.props;

    const tagClass = classNames('Tag', {
      [`Tag--${size}`]: true,
    });

    return (
      <span className={tagClass} style={{ backgroundColor, color }}>
        {label}
      </span>
    );
  }
}

Tag.defaultProps = {
  color: '#f1f3f5',
  backgroundColor: '#f1f3f5',
  size: 'normal',
  label: '',
};

Tag.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
};

export default Tag;
