import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Link from './Link';

import '../../styles/components/Core/RichText.css';

const prismicKeysAndClasses = {
  '{yellow}': 'RichText__dot-yellow',
  '{blue}': 'RichText__dot-blue',
  '{green}': 'RichText__dot-green',
  '{purple}': 'RichText__dot-purple',
  '{br}': <br />,
};

class RichText extends PureComponent {
  replaceKeysInText = (text, type) => {
    const newText = [];
    if (text) {
      const words = text.split(' ');
      if (words.length > 0) {
        words.forEach(word => {
          const prismicKeys = Object.keys(prismicKeysAndClasses);
          if (prismicKeys.includes(word) && word !== '{br}') {
            let dotClass = {};
            switch (type) {
              case 'heading3':
                dotClass = 'RichText__dot-heading3';
                break;
              case 'heading2':
                dotClass = 'RichText__dot-heading2';
                break;
              case 'heading1':
                dotClass = 'RichText__dot-heading1';
                break;

              default:
                break;
            }
            newText.push(
              <span
                key={word}
                className={`${prismicKeysAndClasses[word]} ${dotClass}`}
              />,
            );
          } else if (word === '{br}') {
            newText.push(<span key={word}>{prismicKeysAndClasses[word]}</span>);
          } else {
            newText.push(` ${word} `);
          }
        });
        return newText;
      }
      return text;
    }
    return null;
  };

  formatText = paragraph => {
    let formats = null;

    if (paragraph.formatting) {
      formats = paragraph.formatting;
    } else if (paragraph.spans && paragraph.spans.length > 0) {
      formats = paragraph.spans;
    }

    if (formats) {
      const tags = [];
      let tag = paragraph.text;
      let lastEnd = 0;

      formats.forEach(({ start, end, type, data }, index) => {
        let url;

        switch (type) {
          case 'hyperlink':
            switch (data.link_type) {
              case 'Document':
                switch (data.type) {
                  case 'type':
                    tag = (
                      <Link key={index} to={`/guide/${data.uid}`}>
                        {paragraph.text.slice(start, end)}
                      </Link>
                    );
                    break;

                  case 'products':
                    tag = (
                      <Link key={index} to={`/products/${data.uid}`}>
                        {paragraph.text.slice(start, end)}
                      </Link>
                    );
                    break;

                  case 'page':
                    tag = (
                      <Link key={index} to={`/${data.uid}`}>
                        {paragraph.text.slice(start, end)}
                      </Link>
                    );
                    break;

                  default:
                    break;
                }

                break;

              case 'Web':
                url = new URL(data.url);

                if (url.hostname === 'www.mieuxplacer.com') {
                  if (/^\/documents\//g.test(url.pathname)) {
                    tag = (
                      <Link key={index} href={data.url} target={data.target}>
                        {paragraph.text.slice(start, end)}
                      </Link>
                    );
                  } else {
                    tag = (
                      <Link key={index} to={`${url.pathname}${url.search}`}>
                        {paragraph.text.slice(start, end)}
                      </Link>
                    );
                  }
                } else {
                  tag = (
                    <Link key={index} href={data.url} target={data.target}>
                      {paragraph.text.slice(start, end)}
                    </Link>
                  );
                }

                break;

              default:
                tag = (
                  <Link key={index} href={data.url} target={data.target}>
                    {paragraph.text.slice(start, end)}
                  </Link>
                );
                break;
            }

            break;

          default:
            tag = (
              <span key={index} className={type}>
                {paragraph.text.slice(start, end)}
              </span>
            );
            break;
        }

        tags.push(paragraph.text.slice(lastEnd, start));
        tags.push(tag);

        lastEnd = end;
      });

      tags.push(paragraph.text.slice(lastEnd, paragraph.length));

      return tags;
    }

    return this.replaceKeysInText(paragraph.text, paragraph.type);
  };

  renderParagraph = (paragraph, index) => (
    <p key={index} className="RichText__paragraph">
      {this.formatText(paragraph)}
    </p>
  );

  renderImage = (image, index) => (
    <div key={index} className="RichText__image">
      <img src={image.url} alt={image.alt} />
      {image.copyright && (
        <p className="RichText__image-caption">{image.copyright}</p>
      )}
    </div>
  );

  renderHeadingOne = (heading, index) => (
    <h1 key={index} className="RichText__heading-one">
      {this.formatText(heading)}
    </h1>
  );

  renderHeadingTwo = (heading, index) => (
    <h2 key={index} className="RichText__heading-two">
      {this.formatText(heading)}
    </h2>
  );

  renderHeadingThree = (heading, index) => (
    <h3 key={index} className="RichText__heading-three">
      {this.formatText(heading)}
    </h3>
  );

  renderListItem = (listItem, index) => (
    <p key={index} className="RichText__list-item">
      <span className="RichText__list-item-bullet" />
      <span>{this.formatText(listItem)}</span>
    </p>
  );

  render() {
    const { paragraphs } = this.props;

    return (
      <div className="RichText">
        {paragraphs.map((paragraph, index) => {
          switch (paragraph.type) {
            case 'paragraph':
              return this.renderParagraph(paragraph, index);
            case 'image':
              return this.renderImage(paragraph, index);
            case 'heading1':
              return this.renderHeadingOne(paragraph, index);
            case 'heading2':
              return this.renderHeadingTwo(paragraph, index);
            case 'heading3':
              return this.renderHeadingThree(paragraph, index);
            case 'list-item':
              return this.renderListItem(paragraph, index);
            default:
              return null;
          }
        })}
      </div>
    );
  }
}

RichText.defaultProps = {
  paragraphs: [],
};

RichText.propTypes = {
  paragraphs: PropTypes.arrayOf(PropTypes.shape({})),
};

export default RichText;
