import { put, call, takeLatest } from 'redux-saga/effects';
import { TaskTypes, TaskStatuses } from '@robinfinance/js-api';

import MieuxplacerAPI from '../api/Mieuxplacer';

import {
  VALIDATE_SIGNATURE_TASK__REQUEST,
  VALIDATE_SIGNATURE_TASK__SUCCESS,
  VALIDATE_SIGNATURE_TASK__FAIL,
} from '../actions/SignatureActions';
import {
  SET_CONTRACT,
  FETCH_CONTRACT_FILES__REQUEST,
} from '../actions/ContractsActions';

function* validateSignatureTask({ payload }) {
  try {
    const apiCall = () => MieuxplacerAPI.Api.validateSignature(payload);
    const contract = yield call(apiCall);

    yield put({
      type: SET_CONTRACT,
      payload: contract,
    });

    const questionsTask = contract.tasks.find(
      task => task.subject.type === TaskTypes.SIGNATURE_DIGITAL,
    );

    if (
      questionsTask &&
      questionsTask.status &&
      questionsTask.status === TaskStatuses.OK
    ) {
      yield put({ type: FETCH_CONTRACT_FILES__REQUEST, payload });

      yield put({
        type: VALIDATE_SIGNATURE_TASK__SUCCESS,
      });
    } else {
      yield put({
        type: VALIDATE_SIGNATURE_TASK__FAIL,
      });
    }
  } catch (e) {
    yield put({
      type: VALIDATE_SIGNATURE_TASK__FAIL,
    });
  }
}

const signatureSagas = [
  takeLatest(VALIDATE_SIGNATURE_TASK__REQUEST, validateSignatureTask),
];

export default signatureSagas;
