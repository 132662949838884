import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { pageIsLoaded } from '../../../actions/AppActions';

import coupleAccueil from '../../../assets/images/couple-sur-canape-v2.svg';

import '../../../styles/components/Modules/Auth/LoginStop.css';

function LoginStop() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pageIsLoaded('MIF - Service plus disponible'));
  }, []);

  return (
    <div className="LoginStop">
      <div className="LoginStop__container">
        <div className="LoginStop__image">
          <img src={coupleAccueil} alt="Illustration" />
        </div>
        <div className="LoginStop__content">
          <p>
            Cher utilisateur, merci pour l’intérêt que vous portez à
            Mieuxplacer.com !
          </p>
          <p>
            Nous sommes au regret de vous annoncer qu’il est désormais
            impossible de vous connecter à votre espace personnel sur
            Mieuxplacer.com.
          </p>
          <p>
            En effet, nous cessons notre activité de gestion de patrimoine pour
            mieux nous concentrer sur la diffusion au plus grand nombre de
            conseils autour de l’épargne.
          </p>
          <p>
            Vous pourrez découvrir notre nouveau site dans le courant de l’année
            2021 !
          </p>
          <p>
            Si vous avez des questions au sujet de vos placements, n’hésitez pas
            à nous contacter par tous les moyens habituels.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginStop;
