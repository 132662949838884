import * as Joi from 'joi-browser';

const loginSchema = Joi.object().keys({
  email: Joi.string()
    .email({
      minDomainAtoms: 2,
    })
    .required(),
  password: Joi.string().required(),
});

export const emailSchema = Joi.object().keys({
  email: Joi.string()
    .email({
      minDomainAtoms: 2,
    })
    .required(),
});

export const passwordSchema = Joi.object().keys({
  password: Joi.string().required(),
});

export default loginSchema;
