import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@robinfinance/ui';

import { hightlightShape } from '../../../shapes/prismicShape';

import RichText from '../../Core/RichText';
import Alert from '../../Core/Alert';

class PrismicHighlight extends PureComponent {
  render() {
    const { data } = this.props;
    let type;

    if (data.primary.highlight_type === 'Positif') {
      type = 'success';
    }

    if (data.primary.highlight_type === 'Négatif') {
      type = 'info';
    }

    let button = null;

    if (
      data.primary.highlight_button_label &&
      data.primary.highlight_button_link
    ) {
      let props = null;

      if (data.primary.highlight_button_link.url) {
        props = {
          href: data.primary.highlight_button_link.url,
          target: data.primary.highlight_button_link.target,
        };
      }

      if (
        data.primary.highlight_button_link.type &&
        data.primary.highlight_button_link.type === 'page' &&
        data.primary.highlight_button_link.uid
      ) {
        props = { to: data.primary.highlight_button_link.uid };
      }

      if (props) {
        button = (
          <Button scope="button" type="primary" {...props}>
            {data.primary.highlight_button_label}
          </Button>
        );
      }
    }

    if (
      data.primary.highlight_content &&
      data.primary.highlight_content.length > 0
    ) {
      return (
        <Alert type={type} title={data.primary.highlight_title} action={button}>
          <RichText paragraphs={data.primary.highlight_content} />
        </Alert>
      );
    }

    return null;
  }
}

PrismicHighlight.propTypes = {
  data: PropTypes.shape(hightlightShape).isRequired,
};

export default PrismicHighlight;
