import { put, call, takeLatest } from 'redux-saga/effects';

import MieuxplacerAPI from '../api/Mieuxplacer';

import {
  FETCH_HOME__REQUEST,
  FETCH_HOME__SUCCESS,
  FETCH_HOME__FAIL,
  FETCH_HOME__NOTFOUND,
} from '../actions/HomeActions';

function* fetchHome() {
  try {
    const apiCall = () => MieuxplacerAPI.Api.getPrismicHome();
    const data = yield call(apiCall);

    yield put({
      type: FETCH_HOME__SUCCESS,
      payload: data,
    });
  } catch (e) {
    if (e.status === 404) {
      yield put({
        type: FETCH_HOME__NOTFOUND,
        payload: e.message,
      });
    } else {
      yield put({
        type: FETCH_HOME__FAIL,
        payload: e.message,
      });
    }
  }
}

const homeSagas = [takeLatest(FETCH_HOME__REQUEST, fetchHome)];

export default homeSagas;
