import React from 'react';

import LoadingGradient from '../../../Core/LoadingGradient';
import ProductListing from '../../Product/ProductListing';

const ProductsTypeLoading = () => (
  <div className="ProductsType ProductsType--loading">
    <div className="ProductsType__header">
      <div className="ProductsType__title-container">
        <div className="ProductsType__title-container-dot">
          <LoadingGradient width="16px" height="16px" />
        </div>
        <LoadingGradient
          className="ProductsType__title"
          width="200px"
          height="35px"
        />
      </div>
      <div className="ProductsType__explanation">
        <LoadingGradient width="150px" height="20px" />
      </div>
    </div>
    <div className="ProductsListing__items">
      {[1, 2].map(key => (
        <div className="ProductsListing__item" key={key}>
          <ProductListing loading />
        </div>
      ))}
    </div>
  </div>
);

export default ProductsTypeLoading;
