import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function LazyVideo({ observer, className, sources }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (observer !== null && videoRef.current) {
      observer.observe(videoRef.current);

      return () => {
        observer.unobserve(videoRef.current);
      };
    }

    return () => {};
  }, [observer]);

  return (
    <video ref={videoRef} className={className} autoPlay loop muted playsInline>
      {sources.map((source, index) => (
        <source key={index} data-src={source.src} type={source.type} />
      ))}
    </video>
  );
}

LazyVideo.defaultProps = {
  observer: null,
  className: null,
};

LazyVideo.propTypes = {
  observer: PropTypes.shape({}),
  className: PropTypes.string,
  sources: PropTypes.arrayOf(
    PropTypes.shape({ src: PropTypes.string, type: PropTypes.string }),
  ).isRequired,
};

export default LazyVideo;
