import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { tableTwoColulmnsShape } from '../../../shapes/prismicShape';

import RichText from '../../Core/RichText';

import '../../../styles/components/Prismic/PrismicTable.css';

class PrismicTableTwoColumns extends PureComponent {
  renderLine = (line, index) => {
    const { data } = this.props;

    return (
      <div key={index} className="PrismicTable__line">
        <div className="PrismicTable__column">
          {data.primary.table_column_1_title && (
            <div className="PrismicTable__column-title">
              {data.primary.table_column_1_title}
            </div>
          )}
          <RichText paragraphs={line.table_line_column_1_value} />
        </div>
        <div className="PrismicTable__column">
          {data.primary.table_column_2_title && (
            <div className="PrismicTable__column-title">
              {data.primary.table_column_2_title}
            </div>
          )}
          <RichText paragraphs={line.table_line_column_2_value} />
        </div>
      </div>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <div className="PrismicTable PrismicTable--two">
        <div className="PrismicTable__header">
          <div className="PrismicTable__header-item">
            {data.primary.table_column_1_title}
          </div>
          <div className="PrismicTable__header-item">
            {data.primary.table_column_2_title}
          </div>
        </div>
        <div className="PrismicTable__content">
          {data.items.map((item, index) => this.renderLine(item, index))}
        </div>
      </div>
    );
  }
}

PrismicTableTwoColumns.propTypes = {
  data: PropTypes.shape(tableTwoColulmnsShape).isRequired,
};

export default PrismicTableTwoColumns;
