import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@robinfinance/ui';

import { RECOMMENDATION_ENTRY_TYPE } from '../../../config/onboarding';

import productShape from '../../../shapes/productShape';
import typeShape from '../../../shapes/typeShape';
import supplierShape from '../../../shapes/supplierShape';

import ProductsType from './ProductsType';
import ProductsListingLoading from './Loading/ProductsListingLoading';

import '../../../styles/components/Blocks/Products/ProductsListing.css';

class ProductsListing extends PureComponent {
  render() {
    const {
      products,
      types,
      suppliers,
      loading,
      error,
      searching,
      setOnboardingStartingPoint,
    } = this.props;

    if (loading) {
      return <ProductsListingLoading />;
    }

    if (error || products.length === 0) {
      return (
        <div className="ProductsListing">
          <div className="ProductsListing__container">
            <p className="ProductsListing__empty">
              Aucun produit correspondant à votre recherche a été trouvé.
            </p>
          </div>
        </div>
      );
    }

    const allTypesId = [];

    products.forEach(product => {
      if (product.type && !allTypesId.includes(product.type)) {
        allTypesId.push(product.type);
      }
    });

    const filteredTypes = types.filter(type => allTypesId.includes(type.id));

    return (
      <div className="ProductsListing">
        <div className="ProductsListing__container">
          {filteredTypes.map(type => (
            <div className="ProductsListing__type" key={type.id}>
              <ProductsType
                type={type}
                suppliers={suppliers}
                products={products}
                noAccordion={filteredTypes.length === 1}
                searching={searching}
              />
            </div>
          ))}
          <div className="ProductsListing__message">
            <div className="ProductsListing__message-bar" />
            <p className="ProductsListing__message-paragraph">
              Vous ne savez pas quel produit choisir ? Nous sommes là pour vous
              aider. Répondez à notre questionnaire et découvrez les produits
              les plus adaptés à votre profil et vos projets.
            </p>
            <Button
              scope="button"
              type="secondary"
              to="/connaissance-client"
              onClick={() =>
                setOnboardingStartingPoint(RECOMMENDATION_ENTRY_TYPE)
              }
              id="SimulationButtonProductsListings"
            >
              Commencer ma simulation
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ProductsListing.defaultProps = {
  products: [],
  types: [],
  suppliers: [],
  loading: false,
  error: false,
};

ProductsListing.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape(productShape)),
  types: PropTypes.arrayOf(PropTypes.shape(typeShape)),
  suppliers: PropTypes.arrayOf(PropTypes.shape(supplierShape)),
  loading: PropTypes.bool,
  error: PropTypes.bool,
  searching: PropTypes.bool.isRequired,
  setOnboardingStartingPoint: PropTypes.func.isRequired,
};

export default ProductsListing;
