import React from 'react';
import PropTypes from 'prop-types';

import { sliceShape } from '../../../shapes/prismicShape';

import SimulationDescription from '../../Blocks/Simulation/SimulationDescription';
import SimulationCalculator from '../../Blocks/Simulation/SimulationCalculator';

import '../../../styles/components/Prismic/PrismicSimulation.css';

const PrismicSimulation = ({ data }) => (
  <div className="PrismicSimulation">
    <SimulationDescription data={data} />
    <SimulationCalculator data={data} />
  </div>
);

PrismicSimulation.propTypes = {
  data: PropTypes.shape(sliceShape).isRequired,
};

export default PrismicSimulation;
