import React from 'react';

import PageContext from '../../contexts/PageContext';

/* eslint-disable react/display-name */
const withPageContext = Component => props => (
  <PageContext.Consumer>
    {context => <Component {...props} context={context} />}
  </PageContext.Consumer>
);

export default withPageContext;
