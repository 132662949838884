import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createSelector } from 'reselect';
import classNames from 'classnames';

import { pageIsLoaded } from '../actions/AppActions';
import { fetchPage } from '../actions/PageActions';

import PageContext from '../contexts/PageContext';

import FourOFour from '../components/Layout/FourOFour';
import FiveHundred from '../components/Layout/FiveHundred';
import PrismicMapper from '../components/Prismic/Core/PrismicMapper';
import Breadcrumbs from '../components/Core/Breadcrumbs';
import PrismicTextImageLoading from '../components/Prismic/Slices/Loading/PrismicTextImageLoading';

import '../styles/containers/Page.css';

const selectPage = createSelector(
  state => state.get('pageState').get('pages').toJS(),
  (_, id) => id,
  (pages, id) => pages[id],
);

function Page({ id, url }) {
  const page = useSelector(state => selectPage(state, id));
  const { loading, error, notFound } = useSelector(state => ({
    loading:
      state
        .get('pageState')
        .get('loading')
        .find(item => item === id) !== undefined,
    error:
      state
        .get('pageState')
        .get('error')
        .find(item => item === id) !== undefined,
    notFound:
      state
        .get('pageState')
        .get('notFound')
        .find(item => item === id) !== undefined,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!page && !loading && !error && !notFound) {
      dispatch(fetchPage({ id }));
    }
  }, [id, page, loading]);

  useEffect(() => {
    if (!loading && page) {
      dispatch(pageIsLoaded(page.gaTitle));
    }
  }, [loading]);

  if (loading && !page) {
    return (
      <div className={`Page Page--loading Page--${id}`}>
        <PageContext.Provider>
          <Breadcrumbs />

          <div className="PrismicBlock">
            <div className="PrismicBlock__container">
              <PrismicTextImageLoading />
            </div>
          </div>
          <div className="PrismicBlock">
            <div className="PrismicBlock__container">
              <PrismicTextImageLoading inverted />
            </div>
          </div>
        </PageContext.Provider>
      </div>
    );
  }

  if (error) {
    return (
      <PageContext.Provider>
        <Breadcrumbs />
        <FiveHundred pageIsLoaded={pageIsLoaded} />
      </PageContext.Provider>
    );
  }

  if (notFound) {
    return (
      <PageContext.Provider>
        <Breadcrumbs />
        <FourOFour pageIsLoaded={pageIsLoaded} />
      </PageContext.Provider>
    );
  }

  if (!page) {
    return null;
  }

  const classes = classNames('Page', {
    [`Page--${page.slug}`]: true,
  });

  return (
    <div className={classes}>
      <Helmet>
        <title>{page.title}</title>
        <meta name="description" content={page.description} />

        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta
          property="og:url"
          content={`https://www.mieuxplacer.com/${url}`}
        />
        <meta property="og:type" content="page" />

        <link rel="canonical" href={`https://www.mieuxplacer.com/${url}`} />
      </Helmet>

      <PageContext.Provider
        value={{
          page,
        }}
      >
        <Fragment>
          <Breadcrumbs />

          <PrismicMapper blocks={page.blocks} />
        </Fragment>
      </PageContext.Provider>
    </div>
  );
}

Page.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Page;
