export const SET_TRACKING_SOURCE = '@@TRACKING/SET_TRACKING_SOURCE';
export const SET_UNIVERSE = '@@TRACKING/SET_UNIVERSE';

export const PAGE_LOADED = '@@GTM/PAGE_LOADED';
export const PAGE_VIEW = '@@GTM/PAGE_VIEW';
export const PAGE_SCROLLED = '@@GTM/PAGE_SCROLLED';
export const OPEN_BURGER_MENU = '@@GTM/OPEN_BURGER_MENU';
export const CLOSE_BURGER_MENU = '@@GTM/CLOSE_BURGER_MENU';
export const OPEN_PRODUCTS_SUBMENU = '@@GTM/OPEN_PRODUCTS_SUBMENU';
export const CLOSE_PRODUCTS_SUBMENU = '@@GTM/CLOSE_PRODUCTS_SUBMENU';
export const OPEN_ABOUT_SUBMENU = '@@GTM/OPEN_ABOUT_SUBMENU';
export const CLOSE_ABOUT_SUBMENU = '@@GTM/CLOSE_ABOUT_SUBMENU';

export const LAUNCH_CONVERSION_LINKER = '@@GTM/LAUNCH_CONVERSION_LINKER';
export const LAUNCH_SERVICES = '@@GTM/LAUNCH_SERVICES';

export const ADD_USER_VISIT = '@@APP/ADD_USER_VISIT';

export const SHOW_TESTING_OVERLAY = '@@GTM/SHOW_TESTING_OVERLAY';
export const SHOW_TESTING_FORM = '@@GTM/SHOW_TESTING_FORM';

export const setTrackingSource = (campaign, source, medium) => ({
  type: SET_TRACKING_SOURCE,
  payload: { campaign, source, medium },
});

export const setUniverse = universe => ({
  type: SET_UNIVERSE,
  payload: universe,
});

export const pageIsLoaded = pageName => ({
  type: PAGE_LOADED,
  payload: { pageName },
});

export const sendPageView = (pageName, page) => ({
  type: PAGE_VIEW,
  payload: { pageName, page },
});

export const pageIsScrolled = percent => ({
  type: PAGE_SCROLLED,
  payload: percent,
});

export const openMenu = () => ({ type: OPEN_BURGER_MENU });

export const closeMenu = () => ({ type: CLOSE_BURGER_MENU });

export const openProductsSubmenu = () => ({ type: OPEN_PRODUCTS_SUBMENU });

export const closeProductsSubmenu = () => ({ type: CLOSE_PRODUCTS_SUBMENU });

export const openAboutSubmenu = () => ({ type: OPEN_ABOUT_SUBMENU });

export const closeAboutSubmenu = () => ({ type: CLOSE_ABOUT_SUBMENU });

export const launchServices = () => ({ type: LAUNCH_SERVICES });

export const addUserVisit = visit => ({
  type: ADD_USER_VISIT,
  payload: visit,
});

export const showTestingOverlay = () => ({ type: SHOW_TESTING_OVERLAY });

export const showTestingForm = () => ({ type: SHOW_TESTING_FORM });
