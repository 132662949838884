import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Joi from 'joi-browser';
import { Button, ArrowRight } from '@robinfinance/ui';

import {
  INITIAL_CALL_BACK_PRODUCT_FORM_DATA,
  EMPTY_ERROR_MESSAGES,
  INCORRECT_ERROR_MESSAGES,
  TIME_OPTIONS,
} from '../../../config/emailForms';

import productShape from '../../../shapes/productShape';
import supplierShape from '../../../shapes/supplierShape';
import typeShape from '../../../shapes/typeShape';

import callBackFormSchema, {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  phoneNumberSchema,
  timeSchema,
} from '../../../helpers/schema/callBackFormSchema';

import withProductContext from '../../HOC/withProductContext';
import Input from '../../Core/Input';
import Alert from '../../Core/Alert';
import DisclaimerBlock from '../../Blocks/DisclaimerBlock/DisclaimerBlock';

import '../../../styles/components/Prismic/PrismicCallBackForm.css';

class PrismicCallBackForm extends PureComponent {
  schemas = {
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    email: emailSchema,
    phoneNumber: phoneNumberSchema,
    time: timeSchema,
  };

  state = {
    errors: {},
    submitted: false,
    formData: INITIAL_CALL_BACK_PRODUCT_FORM_DATA,
    clickSubmit: false,
  };

  componentDidUpdate(prevProps) {
    const {
      context: { callBackProductLoading, callBackProductError },
    } = this.props;

    if (
      prevProps.context.callBackProductLoading &&
      !callBackProductLoading &&
      !callBackProductError
    ) {
      this.handleSubmitted();
    }
  }

  componentWillUnmount() {
    const {
      context: { resetContactStatus },
    } = this.props;

    resetContactStatus();
  }

  validateInput = async (name, value) => {
    const { errors } = this.state;
    const data = { [name]: value };
    const schema = this.schemas[name];

    try {
      await Joi.validate(data, schema);
      this.setState({ errors: { ...errors, [name]: [] } });
    } catch (error) {
      if (
        error.details[0].type === 'any.empty' ||
        error.details[0].type === 'array.min'
      ) {
        this.setState({
          errors: { ...errors, [name]: [EMPTY_ERROR_MESSAGES[name]] },
        });
      } else {
        this.setState({
          errors: { ...errors, [name]: [INCORRECT_ERROR_MESSAGES[name]] },
        });
      }
    }
  };

  handleSubmitted = () => {
    this.setState({
      submitted: true,
      formData: INITIAL_CALL_BACK_PRODUCT_FORM_DATA,
    });
  };

  handleChange = (name, value) => {
    const { formData } = this.state;

    this.setState({ formData: { ...formData, [name]: value } });
  };

  handleChangeCheckbox = value => {
    const { formData } = this.state;
    let newValues;

    if (formData.time.includes(value)) {
      newValues = formData.time.filter(key => key !== value);
    } else {
      newValues = [...formData.time, value];
    }

    this.setState({
      formData: {
        ...formData,
        time: newValues,
      },
    });

    this.validateInput('time', newValues);
  };

  handleBlur = (name, value) => {
    this.validateInput(name, value);
  };

  handleSubmit = async e => {
    e.preventDefault();

    const {
      context: {
        product,
        supplier,
        type,
        utmCampaign,
        utmSource,
        utmMedium,
        sendCallBackProductEmail,
      },
    } = this.props;
    const { formData } = this.state;
    const data = {
      ...formData,
      productTitle: product.title,
      supplierName: supplier.name,
      productType: type.label,
    };

    this.setState({ submitted: false, clickSubmit: true });

    try {
      const validatedData = await Joi.validate(data, callBackFormSchema, {
        abortEarly: false,
      });

      sendCallBackProductEmail({
        ...validatedData,
        utmCampaign,
        utmSource,
        utmMedium,
      });
    } catch (error) {
      const inputErrors = {};

      error.details.forEach(inputError => {
        const name = inputError.context.key;

        if (
          inputError.type === 'any.empty' ||
          inputError.type === 'array.min'
        ) {
          inputErrors[name] = [EMPTY_ERROR_MESSAGES[name]];
        } else if (!inputErrors[name]) {
          inputErrors[name] = [INCORRECT_ERROR_MESSAGES[name]];
        }
      });

      this.setState({ errors: inputErrors });
    }
  };

  renderMessages = () => {
    const {
      context: { callBackProductError, callBackProductErrorMessage },
    } = this.props;
    const { submitted, clickSubmit, errors } = this.state;

    if (submitted) {
      return (
        <div className="PrismicCallBackForm__messages">
          <Alert type="success">Votre message a bien été envoyé.</Alert>
        </div>
      );
    }

    if (callBackProductError) {
      return (
        <div className="ContactPopup__messages">
          <Alert type="error">
            {callBackProductErrorMessage ||
              'Merci de vérifier que vous avez correctement rempli tous les champs du formulaire ci-dessous.'}
          </Alert>
        </div>
      );
    }

    if (
      clickSubmit &&
      ((errors.email && errors.email.length > 0) ||
        (errors.lastName && errors.lastName.length > 0) ||
        (errors.firstName && errors.firstName.length > 0) ||
        (errors.phoneNumber && errors.phoneNumber.length > 0) ||
        (errors.time && errors.time.length > 0))
    ) {
      return (
        <div className="PrismicCallBackForm__messages">
          <Alert type="error">
            Merci de vérifier que vous avez correctement rempli tous les champs
            du formulaire.
          </Alert>
        </div>
      );
    }

    return null;
  };

  renderCheckboxes = () => {
    const {
      context: { callBackProductLoading },
    } = this.props;
    const {
      formData: { time },
    } = this.state;

    return TIME_OPTIONS.map(option => {
      const checkboxClasses = classNames('PrismicCallBackForm__checkbox', {
        'PrismicCallBackForm__checkbox--checked': time.includes(option.value),
        'PrismicCallBackForm__checkbox--disabled': callBackProductLoading,
      });

      return (
        <label
          key={option.value}
          className={checkboxClasses}
          htmlFor={option.name}
        >
          <input
            className="PrismicCallBackForm__checkbox-input"
            type="checkbox"
            onChange={() => this.handleChangeCheckbox(option.value)}
            checked={time.includes(option.value)}
            disabled={callBackProductLoading}
            name={option.value}
            id={option.name}
          />
          <span className="PrismicCallBackForm__checkbox-check" />
          <span
            className="PrismicCallBackForm__checkbox-label"
            htmlFor={option.value}
          >
            {option.label}
          </span>
        </label>
      );
    });
  };

  render() {
    const { formData, errors } = this.state;
    const {
      context: { callBackProductLoading },
    } = this.props;

    return (
      <div className="PrismicCallBackForm">
        <div className="PrismicCallBackForm__container">
          <div className="PrismicCallBackForm__content">
            <h2 className="PrismicCallBackForm__title">Demande de rappel</h2>
            <div className="PrismicCallBackForm__description">
              <p>
                Vous souhaitez en savoir plus sur un produit, sur une catégorie
                ou encore sur notre entreprise ?
              </p>
              <p>
                <b>
                  Remplissez ce formulaire afin d’être recontacté gratuitement
                  par l’un de nos conseillers.
                </b>
              </p>
            </div>
            <div className="PrismicCallBackForm__checkboxes-container">
              <div className="PrismicCallBackForm__checkboxes-label">
                Le moment où vous préférez être rappelé(e) :
              </div>
              <div className="PrismicCallBackForm__checkboxes">
                {this.renderCheckboxes()}
              </div>
              {errors.time && errors.time.length > 0 && (
                <div className="PrismicCallBackForm__checkbox-errors">
                  {errors.time.map((error, index) => (
                    <p
                      key={index}
                      className="PrismicCallBackForm__checkbox-error"
                    >
                      {error}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="PrismicCallBackForm__form">
            <form onSubmit={this.handleSubmit}>
              <Input
                name="firstName"
                value={formData.firstName}
                errors={errors.firstName}
                type="text"
                label="Votre prénom"
                iconClass="iconStyle profilIcon"
                hideOnYandex
                disabled={callBackProductLoading}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              <Input
                name="lastName"
                value={formData.lastName}
                errors={errors.lastName}
                type="text"
                label="Votre nom"
                iconClass="iconStyle profilIcon"
                hideOnYandex
                disabled={callBackProductLoading}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              <Input
                name="email"
                value={formData.email}
                errors={errors.email}
                type="text"
                label="Votre e-mail"
                iconClass="iconStyle emailIcon"
                hideOnYandex
                disabled={callBackProductLoading}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              <Input
                name="phoneNumber"
                value={formData.phoneNumber}
                errors={errors.phoneNumber}
                type="tel"
                label="Votre numéro de téléphone"
                iconClass="iconStyle phoneIcon"
                hideOnYandex
                disabled={callBackProductLoading}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              {this.renderMessages()}
              <Button
                scope="button"
                type="primary"
                iconSide="right"
                icon={<ArrowRight color="#ffc501" />}
                onClick={this.handleSubmit}
                disabled={callBackProductLoading}
                loading={callBackProductLoading}
                hasLoadingState
              >
                Je me fais recontacter
              </Button>
            </form>
          </div>
        </div>
        <div className="PrismicCallBackForm__disclaimer">
          <DisclaimerBlock />
        </div>
      </div>
    );
  }
}

PrismicCallBackForm.propTypes = {
  context: PropTypes.shape({
    product: PropTypes.shape(productShape).isRequired,
    supplier: PropTypes.shape(supplierShape).isRequired,
    type: PropTypes.shape(typeShape).isRequired,
    utmCampaign: PropTypes.string.isRequired,
    utmSource: PropTypes.string.isRequired,
    utmMedium: PropTypes.string.isRequired,
    callBackProductLoading: PropTypes.bool.isRequired,
    callBackProductError: PropTypes.bool.isRequired,
    callBackProducterrorMessage: PropTypes.string,
    sendCallBackProductEmail: PropTypes.func.isRequired,
    resetContactStatus: PropTypes.func.isRequired,
  }).isRequired,
};

export default withProductContext(PrismicCallBackForm);
