import React, { PureComponent } from 'react';
import { Button, ArrowRight } from '@robinfinance/ui';

import { fetchSubscribeNewsletter } from '../../../api/HubSpot';

import Input from '../../Core/Input';
import Alert from '../../Core/Alert';
import Link from '../../Core/Link';
import DisclaimerBlock from '../../Blocks/DisclaimerBlock/DisclaimerBlock';

import '../../../styles/components/Prismic/PrismicNewsletter.css';

const REQUIRED_ERRORS = {
  email: 'A quelle adresse pourrions-nous vous envoyer la newsletter ?',
  firstname: 'Quel est votre prénom ?',
  lastname: 'Quel est votre nom ?',
};

const INVALID_ERRORS = {
  email: 'Votre adresse e-mail semble incorrecte, pouvez-vous vérifier ?',
};

class PrismicNewsletter extends PureComponent {
  state = {
    firstname: '',
    lastname: '',
    email: '',
    loading: false,
    error: false,
    isValid: false,
    errors: {
      firstname: [],
      lastname: [],
      email: [],
    },
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { firstname, lastname, email, errors } = this.state;

    this.setState({ loading: true, error: false });

    try {
      await fetchSubscribeNewsletter(firstname, lastname, email);

      this.setState({
        firstname: '',
        lastname: '',
        email: '',
        isValid: true,
      });
    } catch (e) {
      try {
        const error = await e.json();

        error.errors.forEach(error => {
          if (error.errorType === 'REQUIRED_FIELD') {
            const regex = /'fields\.(\w*)'/g;
            const type = regex.exec(error.message)[1];

            if (REQUIRED_ERRORS[type]) {
              this.setState({
                errors: { ...errors, [type]: REQUIRED_ERRORS[type] },
              });
            } else {
              this.setState({ error: true });
            }
          }

          if (error.errorType === 'INVALID_EMAIL') {
            this.setState({
              errors: { ...errors, email: INVALID_ERRORS.email },
            });
          }
        });
      } catch (error) {
        this.setState({ error: true });
      }
    }

    this.setState({ loading: false });
  };

  handleChange = (name, value) => {
    const { errors } = this.state;

    this.setState({
      [name]: value,
      isValid: false,
      errors: { ...errors, [name]: [] },
    });
  };

  renderMessage = () => {
    const { error, errors, isValid } = this.state;

    if (isValid) {
      return (
        <div className="PrismicNewsletter__message">
          <Alert type="success">
            Votre inscription a bien été prise en compte.
          </Alert>
        </div>
      );
    }

    if (error) {
      return (
        <div className="PrismicNewsletter__message">
          <Alert type="error">
            Une erreur est survenue lors de l&apos;inscription. Veuillez essayer
            plus tard ou contacter le{' '}
            <Link to="contactez-nous">service client</Link>.
          </Alert>
        </div>
      );
    }

    let hasErrors = false;

    Object.keys(errors).forEach(key => {
      if (errors[key].length > 0) {
        hasErrors = true;
      }
    });

    if (hasErrors) {
      return (
        <div className="PrismicNewsletter__message">
          <Alert type="error">
            Merci de vérifier que vous avez bien renseigné votre adresse e-mail
            afin de recevoir notre newsletter.
          </Alert>
        </div>
      );
    }

    return null;
  };

  render() {
    const { firstname, lastname, email, loading, errors } = this.state;

    return (
      <div className="PrismicNewsletter">
        <div className="PrismicNewsletter__container">
          <div className="PrismicNewsletter__content">
            <h2 className="PrismicNewsletter__title">
              Inscrivez-vous à notre newsletter
            </h2>
            <div className="PrismicNewsletter__text">
              <p>
                Des sujets décryptés, des chiffres expliqués et des conseils
                d’experts avisés.
              </p>
              <p>
                <b>Une fois par mois, dans votre boîte mail.</b>
              </p>
            </div>
          </div>
          <div className="PrismicNewsletter__form">
            <form onSubmit={this.handleSubmit}>
              <Input
                name="firstname"
                value={firstname}
                type="text"
                label="Votre prénom"
                iconClass="iconStyle profilIcon"
                hideOnYandex
                disabled={loading}
                errors={errors.firstname}
                onChange={this.handleChange}
              />
              <Input
                name="lastname"
                value={lastname}
                type="text"
                label="Votre nom"
                iconClass="iconStyle profilIcon"
                hideOnYandex
                disabled={loading}
                errors={errors.lastname}
                onChange={this.handleChange}
              />
              <Input
                name="email"
                value={email}
                type="text"
                label="Votre e-mail"
                iconClass="iconStyle emailIcon"
                hideOnYandex
                disabled={loading}
                errors={errors.email}
                onChange={this.handleChange}
              />
              {this.renderMessage()}
              <Button
                id="SubscribleToNewsletter"
                scope="button"
                type="primary"
                iconSide="right"
                icon={<ArrowRight color="#ffc501" width="19" height="14" />}
                onClick={this.handleSubmit}
                loading={loading}
                hasLoadingState
                disabled={loading}
              >
                Je m’inscris
              </Button>
            </form>
          </div>
        </div>
        <div className="PrismicNewsletter__disclaimer">
          <DisclaimerBlock />
        </div>
      </div>
    );
  }
}

export default PrismicNewsletter;
