export const EMPTY_ERROR_MESSAGES = {
  email: 'Votre adresse e-mail est nécessaire pour réinitialisation.',
  password:
    'Quel mot de passe souhaitez-vous utiliser pour votre espace personnalisé ?',
};

export const INCORRECT_ERROR_MESSAGES = {
  email: 'Votre adresse e-mail semble incorrect, pouvez-vous vérifier ?',
  password:
    "Le mot de passe semble incorrect ou n'a pas minimum 8 caractères, pouvez-vous vérifier ?",
};

export const CONFIRMATION_BLOCK_TITLE = 'Nouveau mot de passe';
export const CONFIRMATION_BLOCK_MESSAGE =
  'Votre nouveau mot de passe a bien été pris en compte. Vous pouvez désormais vous connecter pour accéder à votre espace client.';
