import { Map, List, fromJS } from 'immutable';

import {
  FETCH_PRODUCTS__REQUEST,
  FETCH_PRODUCTS__SUCCESS,
  FETCH_PRODUCTS__FAIL,
  FETCH_FILTERED_PRODUCTS__REQUEST,
  FETCH_FILTERED_PRODUCTS__SUCCESS,
  FETCH_FILTERED_PRODUCTS__FAIL,
  OPEN_LEAD_POPUP,
  CLOSE_LEAD_POPUP,
} from '../actions/ProductActions';

const initialState = Map({
  productsLoading: false,
  productsError: false,
  productsFilterLoading: false,
  productsFilterError: false,
  products: List([]),
  filteredProducts: List([]),
  leadModalIsOpen: false,
});

const productState = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PRODUCTS__REQUEST:
      return state.set('productsLoading', true).set('productsError', false);

    case FETCH_PRODUCTS__SUCCESS:
      return state
        .set('productsLoading', false)
        .set('products', fromJS(payload));

    case FETCH_PRODUCTS__FAIL:
      return state.set('productsLoading', false).set('productsError', true);

    case FETCH_FILTERED_PRODUCTS__REQUEST:
      return state
        .set('productsFilterLoading', true)
        .set('productsFilterError', false);

    case FETCH_FILTERED_PRODUCTS__SUCCESS:
      return state
        .set('productsFilterLoading', false)
        .set('filteredProducts', fromJS(payload.map(item => item.id)));

    case FETCH_FILTERED_PRODUCTS__FAIL:
      return state
        .set('productsFilterLoading', false)
        .set('productsFilterError', true);

    case OPEN_LEAD_POPUP:
      return state.set('leadModalIsOpen', true);

    case CLOSE_LEAD_POPUP:
      return state.set('leadModalIsOpen', false);

    default:
      return initialState.merge(state);
  }
};

export default productState;
