const PORTAL_ID = '4781654';
const FORM_GUID = '253802aa-0097-45de-9dac-20266b46ee99';

export const fetchSubscribeNewsletter = async (firstName, lastName, email) => {
  const url = new URL(
    `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_GUID}`,
  );

  const body = {
    fields: [
      {
        name: 'firstname',
        value: firstName,
      },
      {
        name: 'lastname',
        value: lastName,
      },
      {
        name: 'email',
        value: email,
      },
    ],
  };

  const response = await fetch(url.href, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  }

  throw response;
};
