import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../styles/components/Core/Alert.css';

const Alert = ({ type, title, children, action }) => {
  const containerClass = classNames('Alert', {
    [`Alert--${type}`]: true,
  });

  return (
    <div className={containerClass}>
      <div className="Alert__content-container">
        {title && <p className="Alert__title">{title}</p>}
        <div className="Alert__content">{children}</div>
      </div>
      {action && <div className="Alert__action">{action}</div>}
    </div>
  );
};

Alert.defaultProps = {
  type: 'success',
  title: null,
  action: null,
};

Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'error', 'info', 'standard']),
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    ),
  ]).isRequired,
  action: PropTypes.element,
};

export default Alert;
