import { ANSWER_KEYS_TO_IGNORE } from '../config/onboarding';

import mieuxplacerAPI from '../api/Mieuxplacer';

export const FETCH_ONBOARDING__REQUEST =
  '@@ONBOARDING/FETCH_ONBOARDING__REQUEST';
export const FETCH_ONBOARDING__SUCCESS =
  '@@ONBOARDING/FETCH_ONBOARDING__SUCCESS';
export const FETCH_ONBOARDING__FAIL = '@@ONBOARDING/FETCH_ONBOARDING__FAIL';

export const FETCH_GENERATE_PROPOSITION__REQUEST =
  '@@ONBOARDING/FETCH_GENERATE_PROPOSITION__REQUEST';
export const FETCH_GENERATE_PROPOSITION__SUCCESS =
  '@@ONBOARDING/FETCH_GENERATE_PROPOSITION__SUCCESS';
export const FETCH_GENERATE_PROPOSITION__FAIL =
  '@@ONBOARDING/FETCH_GENERATE_PROPOSITION__FAIL';

export const FETCH_PROPOSITION__REQUEST =
  '@@ONBOARDING/FETCH_PROPOSITION__REQUEST';
export const FETCH_PROPOSITION__SUCCESS =
  '@@ONBOARDING/FETCH_PROPOSITION__SUCCESS';
export const FETCH_PROPOSITION__FAIL = '@@ONBOARDING/FETCH_PROPOSITION__FAIL';
export const FETCH_PROPOSITION__NOT_FOUND =
  '@@ONBOARDING/FETCH_PROPOSITION__NOT_FOUND';
export const FETCH_PROPOSITION__UNAUTHORIZED =
  '@@ONBOARDING/FETCH_PROPOSITION__UNAUTHORIZED';
export const FETCH_PROPOSITION__WRONG_USER =
  '@@ONBOARDING/FETCH_PROPOSITION__WRONG_USER';

export const SET_ONBOARDING_STARTING_POINT =
  '@@BOARDING/SET_ONBOARDING_STARTING_POINT';

export const SET_ANSWER = '@@ONBOARDING/SET_ANSWER';

export const FETCH_UPDATE_ANSWERS__REQUEST =
  '@@ONBOARDING/FETCH_UPDATE_ANSWERS__REQUEST';
export const FETCH_UPDATE_ANSWERS__SUCCESS =
  '@@ONBOARDING/FETCH_UPDATE_ANSWERS__SUCCESS';
export const FETCH_UPDATE_ANSWERS__FAIL =
  '@@ONBOARDING/FETCH_UPDATE_ANSWERS__FAIL';

export const RESET_ONBOARDING = '@@ONBOARDING/RESET_ONBOARDING';

export const LAUNCH_CONVERSION_LINKER =
  '@@/ONBOARDING/LAUNCH_CONVERSION_LINKER';

export const CHANGE_ONBOARDING_ANSWER = '@@GTM/CHANGE_ONBOARDING_ANSWER';

export const fetchOnboarding = (
  withAuthentication = false,
) => async dispatch => {
  dispatch({ type: FETCH_ONBOARDING__REQUEST });

  try {
    const data = await mieuxplacerAPI.Api.getOnboarding(withAuthentication);

    dispatch({ type: FETCH_ONBOARDING__SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_ONBOARDING__FAIL });
  }
};

export const fetchProposition = () => async dispatch => {
  dispatch({ type: FETCH_PROPOSITION__REQUEST });

  try {
    const data = await mieuxplacerAPI.Api.getLatestProposition();

    dispatch({ type: FETCH_PROPOSITION__SUCCESS, payload: data });
  } catch (error) {
    if (error.status === 404) {
      dispatch({ type: FETCH_PROPOSITION__NOT_FOUND });
    } else {
      dispatch({ type: FETCH_PROPOSITION__FAIL });
    }
  }
};

export const generateProposition = (answers, prospectId) => async dispatch => {
  dispatch({ type: FETCH_GENERATE_PROPOSITION__REQUEST });

  try {
    const data = await mieuxplacerAPI.Api.generateProposition(
      answers,
      prospectId,
    );

    dispatch({ type: FETCH_GENERATE_PROPOSITION__SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_GENERATE_PROPOSITION__FAIL });
  }
};

export const setAnswer = (key, value) => ({
  type: SET_ANSWER,
  payload: { key, value },
});

export const updateAnswers = ({ generateRecommendation }) => (
  dispatch,
  getState,
) => {
  const onboardingState = getState().get('onboardingState');
  const noRecommendation = getState().get('authState').get('noRecommendation');

  const answers = {};

  onboardingState.get('steps').forEach(step => {
    step.get('answers').forEach(answer => {
      const formattedAnswer = answer.toJS();

      Object.keys(formattedAnswer).forEach(key => {
        if (!ANSWER_KEYS_TO_IGNORE.includes(key)) {
          answers[key] = formattedAnswer[key];
        }
      });
    });
  });

  dispatch({
    type: FETCH_UPDATE_ANSWERS__REQUEST,
    payload: { answers, noRecommendation, generateRecommendation },
  });
};

export const resetOnboarding = () => ({ type: RESET_ONBOARDING });

export const setOnboardingStartingPoint = type => ({
  type: SET_ONBOARDING_STARTING_POINT,
  payload: type,
});

export const changeOnboardingAnswer = (key, step) => ({
  type: CHANGE_ONBOARDING_ANSWER,
  payload: { key, step },
});
