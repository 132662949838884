import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@robinfinance/ui';

import objectiveShape from '../../../shapes/objectiveShape';
import typeShape from '../../../shapes/typeShape';

import {
  INVEST_PERIODS,
  MINIMUM_INVESTMENT,
  MINIMUM_INVESTMENT_FILTER_KEY,
  INVEST_PERIOD_FILTER_KEY,
  TYPE_FILTER_KEY,
  OBJECTIVE_FILTER_KEY,
} from '../../../config/product';

import withProductsContext from '../../HOC/withProductsContext';
import Tooltip from '../../Core/Tooltip';
import FilterMultipleBlock from './FilterMultipleBlock';
import FilterSingleBlock from './FilterSingleBlock';

import dogIllustration from '../../../assets/images/charlie-pot.png';

import '../../../styles/components/Blocks/Filter/FilterSidebar.css';

class FilterSidebar extends PureComponent {
  handleAddChange = (type, id, value, checked) => {
    const {
      context: { handleAddFilterChange },
    } = this.props;

    handleAddFilterChange(type, id, value, checked);
  };

  handleSetChange = (type, id, value) => {
    const {
      context: { handleSetFilterChange },
    } = this.props;

    handleSetFilterChange(type, id, value);
  };

  render() {
    const {
      context: {
        objectives,
        objectivesLoading,
        types,
        typesLoading,
        filters,
        reinitializeFilters,
      },
      onToggle,
    } = this.props;

    return (
      <div className="FilterSidebar">
        <div className="FilterSidebar__filter">
          <div className="FilterSidebar__block">
            <div className="FilterSidebar__block-label">Type de produits</div>
            <FilterMultipleBlock
              type={TYPE_FILTER_KEY}
              list={types}
              checkedItems={filters.type}
              loading={typesLoading}
              onChange={this.handleAddChange}
            />
          </div>
          <div className="FilterSidebar__block">
            <div className="FilterSidebar__block-label">Objectifs</div>
            <FilterSingleBlock
              type={OBJECTIVE_FILTER_KEY}
              list={[
                { label: 'Tous', id: 'all-objectives', value: 'all' },
                ...objectives,
              ]}
              checkedItem={filters.objective}
              loading={objectivesLoading}
              onChange={this.handleSetChange}
            />
          </div>
          <div className="FilterSidebar__block">
            <div className="FilterSidebar__block-label">
              Durée idéale de placement
              <Tooltip
                className="FilterSidebar__block-tooltip"
                title="Combien de temps nous vous recommandons de garder ce placement (au minimum)."
              />
            </div>
            <FilterSingleBlock
              type={INVEST_PERIOD_FILTER_KEY}
              list={INVEST_PERIODS}
              checkedItem={filters.invest_period}
              onChange={this.handleSetChange}
            />
          </div>
          <div className="FilterSidebar__block">
            <p className="FilterSidebar__block-label">
              Montant minimum du premier placement
            </p>
            <FilterSingleBlock
              type={MINIMUM_INVESTMENT_FILTER_KEY}
              list={MINIMUM_INVESTMENT}
              checkedItem={filters.minimum_investment}
              onChange={this.handleSetChange}
            />
          </div>
          <div className="FilterSidebar__block FilterSidebar__block--reinitialize">
            <div className="FilterSidebar__reinitialize">
              <div
                onClick={reinitializeFilters}
                onKeyUp={reinitializeFilters}
                role="button"
                tabIndex="0"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                  >
                    <path
                      fill="#4A90E2"
                      fillRule="evenodd"
                      d="M7.785 6.526a.894.894 0 0 1-1.259 1.259L4 5.258 1.473 7.785a.893.893 0 0 1-1.26-1.26L2.741 4 .213 1.473A.894.894 0 0 1 1.473.214L4 2.741 6.526.214a.894.894 0 0 1 1.259 1.26L5.258 4l2.527 2.526z"
                    />
                  </svg>
                </span>
                Réinitialiser les filtres
              </div>
            </div>
          </div>
          <div className="FilterSidebar__block FilterSidebar__block--validate">
            <Button
              scope="button"
              type="secondary"
              onClick={onToggle}
              id="FiltersValidationButtonProductsListings"
            >
              Valider les filtres
            </Button>
          </div>
        </div>
        <div className="FilterSidebar__illustration">
          <img src={dogIllustration} alt="Dog illustration" />
        </div>
      </div>
    );
  }
}

FilterSidebar.defaultProps = {
  context: {
    objectives: [],
    types: [],
    filters: {},
  },
  onToggle: () => {},
};

FilterSidebar.propTypes = {
  context: PropTypes.shape({
    objectives: PropTypes.arrayOf(PropTypes.shape(objectiveShape)),
    objectivesLoading: PropTypes.bool.isRequired,
    types: PropTypes.arrayOf(PropTypes.shape(typeShape)),
    typesLoading: PropTypes.bool.isRequired,
    filters: PropTypes.shape({}),
    reinitializeFilters: PropTypes.func.isRequired,
    handleAddFilterChange: PropTypes.func.isRequired,
    handleSetFilterChange: PropTypes.func.isRequired,
  }),
  onToggle: PropTypes.func,
};

export default withProductsContext(FilterSidebar);
