import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as OnboardingAPI from '../api/Onboarding';
import * as ProductAPI from '../api/Product';
import * as ContractAPI from '../api/Contract';

import { PAGE_VIEW } from '../actions/AppActions';

import {
  FETCH_UPDATE_ANSWERS__REQUEST,
  FETCH_UPDATE_ANSWERS__SUCCESS,
  FETCH_UPDATE_ANSWERS__FAIL,
} from '../actions/OnboardingActions';

import {
  FETCH_INITIAL_PRODUCT_FUNDS__REQUEST,
  FETCH_INITIAL_PRODUCT_FUNDS__SUCCESS,
  FETCH_INITIAL_PRODUCT_FUNDS__FAIL,
  FETCH_CONTRACT_PREVALIDATION__REQUEST,
  FETCH_CONTRACT_PREVALIDATION__SUCCESS,
  FETCH_CONTRACT_PREVALIDATION__ERROR,
  FETCH_CONTRACT_PREVALIDATION__FAIL,
  VALIDATE_SELECTION__REQUEST,
  VALIDATE_SELECTION__SUCCESS,
  VALIDATE_SELECTION__ERROR,
  VALIDATE_SELECTION__FAIL,
} from '../actions/CartActions';

function* fetchUpdateAnswers({ payload }) {
  try {
    yield call(OnboardingAPI.updateAnswers, payload.answers);

    yield put({ type: FETCH_UPDATE_ANSWERS__SUCCESS });

    if (payload.noRecommendation) {
      yield put({
        type: PAGE_VIEW,
        payload: { pageName: 'Etape fin', page: '/questionnaire/final' },
      });

      yield put(push('/'));
    } else if (payload.generateRecommendation) {
      // yield put({ type: FETCH_GENERATE_RECOMMENDATION__REQUEST });

      yield put({
        type: PAGE_VIEW,
        payload: {
          pageName: 'Etape fin',
          page: '/connaissance-client/final',
        },
      });

      yield put(push('/connaissance-client/recommandation'));
    }
  } catch (e) {
    yield put({
      type: FETCH_UPDATE_ANSWERS__FAIL,
      payload: e.message,
    });

    yield put(push('/connaissance-client/new'));
  }
}

function* fetchInitialProductFunds({ payload }) {
  try {
    const data = yield call(ProductAPI.fetchProductLines, payload);

    yield put({
      type: FETCH_INITIAL_PRODUCT_FUNDS__SUCCESS,
      payload: { productId: payload, funds: data.results },
    });
  } catch (e) {
    yield put({
      type: FETCH_INITIAL_PRODUCT_FUNDS__FAIL,
      payload: e.message,
    });
  }
}

function* fetchPrevalidateContract({ payload }) {
  try {
    yield call(ContractAPI.prevalidateContract, payload);

    yield put({
      type: FETCH_CONTRACT_PREVALIDATION__SUCCESS,
      payload: payload.productId,
    });
  } catch (e) {
    if (e.errors) {
      yield put({
        type: FETCH_CONTRACT_PREVALIDATION__ERROR,
        payload: { productId: payload.productId, data: e },
      });
    } else {
      yield put({
        type: FETCH_CONTRACT_PREVALIDATION__FAIL,
        payload: payload.productId,
      });
    }
  }
}

function* validateSelection({ payload }) {
  try {
    const data = yield call(ContractAPI.validateContract, payload);

    if (data.redirect && data.redirect === 'dashboard') {
      yield put({
        type: VALIDATE_SELECTION__SUCCESS,
      });

      localStorage.removeItem('products');

      yield put(push('/espace-client'));
    } else {
      yield put({
        type: VALIDATE_SELECTION__FAIL,
      });
    }
  } catch (e) {
    if (e.errors) {
      yield put({
        type: VALIDATE_SELECTION__ERROR,
        payload: e.errors,
      });
    } else {
      yield put({
        type: VALIDATE_SELECTION__FAIL,
      });
    }
  }
}

const onboardingSagas = [
  takeLatest(FETCH_UPDATE_ANSWERS__REQUEST, fetchUpdateAnswers),
  takeLatest(FETCH_INITIAL_PRODUCT_FUNDS__REQUEST, fetchInitialProductFunds),
  takeLatest(FETCH_CONTRACT_PREVALIDATION__REQUEST, fetchPrevalidateContract),
  takeLatest(VALIDATE_SELECTION__REQUEST, validateSelection),
];

export default onboardingSagas;
