export function onImageInView(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const element = entry.target;
      const imageSrc = element.getAttribute('data-src');

      element.removeAttribute('data-src');
      element.setAttribute('src', imageSrc);

      observer.unobserve(element);
    }
  });
}

export function onVideoInView(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const element = entry.target;

      element.children.forEach((source, index) => {
        const videoSource = element.children[index];

        if (
          typeof videoSource.tagName === 'string' &&
          videoSource.tagName === 'SOURCE'
        ) {
          videoSource.src = videoSource.dataset.src;
        }
      });

      element.load();

      observer.unobserve(element);
    }
  });
}

export function createObserver(inViewCallback, newOptions = {}) {
  const defaultOptions = {
    root: null,
    rootMargin: '0px 0px 200px 0px',
    threshold: 0.3,
  };
  return new IntersectionObserver(
    inViewCallback,
    Object.assign(defaultOptions, newOptions),
  );
}
