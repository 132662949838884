import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import productShape from '../../../shapes/productShape';
import typeShape from '../../../shapes/typeShape';
import supplierShape from '../../../shapes/supplierShape';

import Link from '../../Core/Link';
import ProductListing from '../Product/ProductListing';
import ProductsTypeLoading from './Loading/ProductsTypeLoading';
import Tooltip from '../../Core/Tooltip';
import Accordion from '../../Core/Accordion/Accordion';

import '../../../styles/components/Blocks/Products/ProductsType.css';

class ProductsType extends PureComponent {
  renderTypeExplanation = () => {
    const { type } = this.props;

    if (type.explanation) {
      return (
        <Link
          className="ProductsType__explanation"
          to={`/guide/${type.slug}`}
          animated
        >
          <Fragment>
            <span className="ProductsType__explanation-text">
              {type.explanation}
            </span>
            <span className="ProductsType__explanation-text ProductsType__explanation-text--is-mobile">
              + d&apos;info
            </span>
            <div
              className="ProductsType__explanation-icon"
              style={{
                backgroundColor: type.backgroundColor,
              }}
            >
              <div className="ProductsType__explanation-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="9"
                  viewBox="0 0 6 9"
                >
                  <path
                    fill={type.color}
                    fillRule="evenodd"
                    d="M.204 1.47C-.134 1.063-.042.488.408.184c.363-.245.86-.245 1.223 0l4.077 3.673a.854.854 0 0 1 0 1.286L1.631 8.816c-.451.305-1.089.222-1.427-.183a.845.845 0 0 1 0-1.102L3.558 4.5.194 1.47h.01z"
                  />
                </svg>
              </div>
            </div>
          </Fragment>
        </Link>
      );
    }

    return null;
  };

  renderProduct = (product, index) => {
    const { type, suppliers } = this.props;

    const supplier = suppliers.find(
      supplier => supplier.id === product.supplier,
    );

    return (
      <ProductListing
        key={product.id}
        product={product}
        supplier={supplier}
        type={type}
        index={index}
      />
    );
  };

  renderProducts = (subscribable = true) => {
    const { products, type } = this.props;
    const sortedProducts = [];
    const otherProducts = [];
    const filteredProducts = [];

    products.forEach(product => {
      if (
        product.type &&
        product.type === type.id &&
        product.subscribable === subscribable
      ) {
        filteredProducts.push(product);
      }
    });

    filteredProducts.forEach(product => {
      if (product.order == null) {
        otherProducts.push(product);
      } else {
        sortedProducts.push(product);
      }
    });

    sortedProducts.sort((a, b) => a.order - b.order);
    otherProducts.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }

      if (a.title > b.title) {
        return 1;
      }

      return 0;
    });

    return [...sortedProducts, ...otherProducts].map((product, index) =>
      this.renderProduct(product, index),
    );
  };

  render() {
    const { type, loading, searching, noAccordion } = this.props;

    if (loading) {
      return <ProductsTypeLoading />;
    }

    let subscribableProducts = this.renderProducts();
    const nonSubscribableProducts = this.renderProducts(false);

    if (subscribableProducts.length === 0) {
      subscribableProducts = nonSubscribableProducts.splice(0, 2);
    }

    return (
      <div className="ProductsType">
        <div className="ProductsType__header">
          <div className="ProductsType__title-container">
            <div
              className="ProductsType__title-container-dot"
              style={{
                backgroundColor: type.backgroundColor,
              }}
            >
              <div
                style={{ backgroundColor: type.color }}
                className="ProductsType__title-dot"
              />
            </div>
            <h3 className="ProductsType__title">{type.label}</h3>
            {type.tooltip && (
              <Tooltip
                className="ProductsType__value-tooltip"
                title={type.tooltip}
              />
            )}
          </div>
          {this.renderTypeExplanation()}
        </div>
        <div className="ProductsType__subscribable_products">
          {subscribableProducts}
        </div>
        {nonSubscribableProducts.length > 0 && (
          <div className="ProductsType__nonsubscribable_products">
            {noAccordion || searching ? (
              nonSubscribableProducts
            ) : (
              <Accordion
                title={type.labelPlural}
                content={nonSubscribableProducts}
                color={type.color}
                backgroundColor={type.backgroundColor}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

ProductsType.defaultProps = {
  type: {},
  products: [],
  suppliers: [],
  loading: false,
  noAccordion: false,
  searching: false,
};

ProductsType.propTypes = {
  type: PropTypes.shape(typeShape),
  suppliers: PropTypes.arrayOf(PropTypes.shape(supplierShape)),
  products: PropTypes.arrayOf(PropTypes.shape(productShape)),
  loading: PropTypes.bool,
  noAccordion: PropTypes.bool,
  searching: PropTypes.bool,
};

export default ProductsType;
