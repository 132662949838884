import React from 'react';
import PropTypes from 'prop-types';

import productShape from '../../../shapes/productShape';

import withProductContext from '../../HOC/withProductContext';
import FeesTable from '../../Blocks/Recommendation/Core/FeesTable';

import '../../../styles/components/Prismic/PrismicProductFees.css';

const PrismicProductFees = ({ context: { product } }) => {
  if (
    (product && product.oneTimeFees.length > 0) ||
    product.annualFees.length > 0
  ) {
    return (
      <div className="PrismicProductFees">
        <h4 className="PrismicProductFees__title">
          Qu&apos;en est-il des frais ?
        </h4>
        <div className="PrismicProductFees__content">
          {product.oneTimeFees.length > 0 && (
            <FeesTable title="Frais ponctuels" items={product.oneTimeFees} />
          )}
          {product.annualFees.length > 0 && (
            <FeesTable title="Frais annuels" items={product.annualFees} />
          )}
        </div>
      </div>
    );
  }

  return null;
};

PrismicProductFees.propTypes = {
  context: PropTypes.shape({
    product: PropTypes.shape(productShape),
  }).isRequired,
};

export default withProductContext(PrismicProductFees);
