/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import qs from 'qs';

import productShape from '../shapes/productShape';
import typeShape from '../shapes/typeShape';
import supplierShape from '../shapes/supplierShape';
import objectiveShape from '../shapes/objectiveShape';

import ProductContext from '../contexts/ProductContext';

import { addProductToCart } from '../actions/CartActions';
import {
  sendLeadEmail,
  sendCallBackProductEmail,
  resetContactStatus,
} from '../actions/ContactActions';
import { pageIsLoaded } from '../actions/AppActions';
import {
  clickSimilarProduct,
  openLeadPopup,
  closeLeadPopup,
} from '../actions/ProductActions';

import toJS from '../components/HOC/toJS';
import PrismicMapper from '../components/Prismic/Core/PrismicMapper';
import ProductSummary from '../components/Blocks/Product/ProductSummary';
import ProductDetails from '../components/Blocks/Product/ProductDetails';
import Breadcrumbs from '../components/Core/Breadcrumbs';
import ContactPopup from '../components/Blocks/Product/ContactPopup';
import ProductFeatures from '../components/Blocks/Product/ProductFeatures';
import ProductProfile from '../components/Blocks/Product/ProductProfile';
import FourOFour from '../components/Layout/FourOFour';
import FiveHundred from '../components/Layout/FiveHundred';

import '../styles/containers/Product.css';

class Product extends PureComponent {
  state = {
    isPoppedUp: false,
  };

  componentDidMount() {
    const { product, pageIsLoaded } = this.props;

    if (product) {
      pageIsLoaded(product.gaTitle);
    }
  }

  componentDidUpdate(prevProps) {
    const { loading, product, pageIsLoaded } = this.props;

    if (prevProps.loading && !loading && product) {
      pageIsLoaded(product.gaTitle);
    }
  }

  togglePopup = () => {
    const { product, openLeadPopup, closeLeadPopup } = this.props;
    const { isPoppedUp } = this.state;

    if (isPoppedUp) {
      closeLeadPopup(product.id, product.title);
    } else {
      openLeadPopup(product.id, product.title);
    }
    this.setState({ isPoppedUp: !isPoppedUp });
  };

  renderProductLoading = () => (
    <div className="Product Product--loading">
      <ProductContext.Provider>
        <Breadcrumbs />

        <div className="Product__header">
          <ProductSummary loading />
          <ProductDetails loading />
        </div>
        <div className="Product__subheader">
          <ProductFeatures loading />
          <ProductProfile loading />
        </div>
      </ProductContext.Provider>
    </div>
  );

  render() {
    const {
      loading,
      error,
      product,
      products,
      typesLoading,
      type,
      types,
      suppliersLoading,
      supplier,
      suppliers,
      objectives,
      cartProducts,
      addProductToCart,
      leadLoading,
      leadError,
      leadErrorMessage,
      callBackProductLoading,
      callBackProductError,
      callBackProductErrorMessage,
      utmCampaign,
      utmSource,
      utmMedium,
      match: { params },
      sendLeadEmail,
      sendCallBackProductEmail,
      resetContactStatus,
      clickSimilarProduct,
      pageIsLoaded,
    } = this.props;
    const { isPoppedUp, inputErrors } = this.state;

    if (
      (!product && loading) ||
      (!type && typesLoading) ||
      (!supplier && suppliersLoading)
    ) {
      return this.renderProductLoading();
    }

    if (error) {
      return <FiveHundred pageIsLoaded={pageIsLoaded} />;
    }

    if (!product || !type || !supplier) {
      if (!params.type) {
        const typeFromSlug = types.find(type => type.slug === params.slug);

        if (typeFromSlug) {
          const query = qs.stringify(
            { type: [typeFromSlug.id] },
            { arrayFormat: 'brackets' },
          );

          return <Redirect to={`/produits?${query}`} />;
        }
      }

      return <FourOFour pageIsLoaded={pageIsLoaded} />;
    }

    if (!params.type) {
      return <Redirect to={`/produits/${type.slug}/${product.slug}`} />;
    }

    const metaDescription =
      product.metaDescription ||
      `${product.description[0].text.substr(0, 120)} ...`;

    return (
      <div className="Product">
        <Helmet>
          <title>{`${product.title} | ${type.label} | ${supplier.name}`}</title>
          <meta name="description" content={metaDescription} />

          <meta
            property="og:title"
            content={`${product.title} | ${type.label} | ${supplier.name}`}
          />
          <meta property="og:description" content={metaDescription} />
          <meta
            property="og:url"
            content={`https://www.mieuxplacer.com/produits/${type.slug}/${product.slug}`}
          />
          <meta property="og:type" content="product" />

          <link
            rel="canonical"
            href={`https://www.mieuxplacer.com/produits/${type.slug}/${product.slug}`}
          />
        </Helmet>

        <ProductContext.Provider
          value={{
            products,
            suppliers,
            cartProducts,
            addProductToCart,
            product,
            type,
            supplier,
            utmCampaign,
            utmSource,
            utmMedium,
            clickSimilarProduct,
            sendLeadEmail,
            sendCallBackProductEmail,
            resetContactStatus,
            callBackProductLoading,
            callBackProductError,
            callBackProductErrorMessage,
          }}
        >
          <Breadcrumbs />

          <div className="Product__header">
            <ProductSummary product={product} type={type} supplier={supplier} />
            <ProductDetails
              product={product}
              objectives={objectives}
              showPopup={this.togglePopup}
              addToCart={addProductToCart}
              showActions
            />
          </div>
          <div className="Product__subheader">
            <ProductFeatures product={product} />
            <ProductProfile
              product={product}
              objectives={objectives}
              showPopup={this.togglePopup}
              addToCart={addProductToCart}
            />
          </div>
          <div className="Product__blocks">
            <PrismicMapper blocks={product.blocks} />
          </div>
          <div className="Product__modal-contact">
            <ContactPopup
              isPoppedUp={isPoppedUp}
              closeModal={this.togglePopup}
              product={product}
              supplier={supplier}
              type={type}
              inputErrors={inputErrors}
              leadLoading={leadLoading}
              leadError={leadError}
              leadErrorMessage={leadErrorMessage}
              utmCampaign={utmCampaign}
              utmSource={utmSource}
              utmMedium={utmMedium}
              sendLeadEmail={sendLeadEmail}
              resetContactStatus={resetContactStatus}
            />
          </div>
          <span
            className="Product__mask"
            style={{ backgroundColor: type && type.backgroundColor }}
          />
        </ProductContext.Provider>
      </div>
    );
  }
}

Product.defaultProps = {
  product: null,
  type: null,
  supplier: null,
  products: [],
  types: [],
  suppliers: [],
  objectives: [],
  cartProducts: [],
  leadErrorMessage: null,
  callBackProductErrorMessage: null,
};

Product.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape(productShape)),
  product: PropTypes.shape(productShape),
  typesLoading: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape(typeShape)),
  type: PropTypes.shape(typeShape),
  suppliersLoading: PropTypes.bool.isRequired,
  supplier: PropTypes.shape(supplierShape),
  suppliers: PropTypes.arrayOf(PropTypes.shape(supplierShape)),
  objectives: PropTypes.arrayOf(PropTypes.shape(objectiveShape)),
  cartProducts: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, identifier: PropTypes.string }),
  ),
  leadLoading: PropTypes.bool.isRequired,
  leadError: PropTypes.bool.isRequired,
  leadErrorMessage: PropTypes.string,
  callBackProductLoading: PropTypes.bool.isRequired,
  callBackProductError: PropTypes.bool.isRequired,
  callBackProductErrorMessage: PropTypes.string,
  utmCampaign: PropTypes.string.isRequired,
  utmSource: PropTypes.string.isRequired,
  utmMedium: PropTypes.string.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ type: PropTypes.string, slug: PropTypes.string }),
  }).isRequired,
  sendLeadEmail: PropTypes.func.isRequired,
  sendCallBackProductEmail: PropTypes.func.isRequired,
  resetContactStatus: PropTypes.func.isRequired,
  pageIsLoaded: PropTypes.func.isRequired,
  clickSimilarProduct: PropTypes.func.isRequired,
  openLeadPopup: PropTypes.func.isRequired,
  closeLeadPopup: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const {
    match: { params },
  } = props;

  const product = state
    .get('productState')
    .get('products')
    .find(product => product.get('slug') === params.slug);

  return {
    loading: state.get('productState').get('productsLoading'),
    error: state.get('productState').get('productsError'),
    product,
    products: state.get('productState').get('products'),
    typesLoading: state.get('typeState').get('typesLoading'),
    type: state
      .get('typeState')
      .get('types')
      .find(type => product && type.get('id') === product.get('type')),
    types: state.get('typeState').get('types'),
    suppliersLoading: state.get('supplierState').get('suppliersLoading'),
    supplier: state
      .get('supplierState')
      .get('suppliers')
      .find(
        supplier => product && supplier.get('id') === product.get('supplier'),
      ),
    suppliers: state.get('supplierState').get('suppliers'),
    objectives: state
      .get('objectiveState')
      .get('objectives')
      .filter(
        objective =>
          product && product.get('objectives').includes(objective.get('id')),
      ),
    cartProducts: state.get('cartState').get('products'),
    leadLoading: state.get('contactState').get('lead').get('loading'),
    leadError: state.get('contactState').get('lead').get('error'),
    leadErrorMessage: state.get('contactState').get('lead').get('errorMessage'),
    callBackProductLoading: state
      .get('contactState')
      .get('callBackProduct')
      .get('loading'),
    callBackProductError: state
      .get('contactState')
      .get('callBackProduct')
      .get('error'),
    callBackProductErrorMessage: state
      .get('contactState')
      .get('callBackProduct')
      .get('errorMessage'),
    utmCampaign: state.get('appState').get('utmCampaign'),
    utmSource: state.get('appState').get('utmSource'),
    utmMedium: state.get('appState').get('utmMedium'),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addProductToCart,
      pageIsLoaded,
      sendLeadEmail,
      sendCallBackProductEmail,
      resetContactStatus,
      clickSimilarProduct,
      openLeadPopup,
      closeLeadPopup,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Product));
