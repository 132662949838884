import { Component } from 'react';
import PropTypes from 'prop-types';

const BASE_PERCENTAGES = [0.25, 0.5, 0.75, 0.9, 1];

class ScrollManager extends Component {
  state = {
    percentages: [...BASE_PERCENTAGES],
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      this.handleNavigation();
    }
  }

  handleNavigation = () => {
    this.setState({ percentages: [...BASE_PERCENTAGES] });

    window.scrollTo({ top: 0 });
  };

  handleScroll = () => {
    const { onScroll } = this.props;
    const { percentages } = this.state;

    const scrollRatio =
      document.documentElement.scrollTop /
      (document.documentElement.scrollHeight -
        document.documentElement.clientHeight);

    percentages.forEach((point, index) => {
      if (scrollRatio >= point) {
        const newPercentages = [...percentages];
        newPercentages.splice(index, 1);

        onScroll(`${point * 100}%`);

        this.setState({ percentages: newPercentages });
      }
    });
  };

  render() {
    return null;
  }
}

ScrollManager.defaultProps = {
  location: '',
};

ScrollManager.propTypes = {
  location: PropTypes.string,
  onScroll: PropTypes.func.isRequired,
};

export default ScrollManager;
