import { put, call, takeLatest } from 'redux-saga/effects';
import { PERSONAL_DOCUMENTS } from '@robinfinance/js-api';

import MieuxplacerAPI from '../api/Mieuxplacer';

import {
  FETCH_CUSTOMER_FILES__REQUEST,
  FETCH_CUSTOMER_FILES__SUCCESS,
  FETCH_CUSTOMER_FILES__FAIL,
} from '../actions/SupportingDocumentsActions';

function* fetchCustomerFiles() {
  try {
    const apiCall = () =>
      MieuxplacerAPI.Api.getFiles(undefined, { type: PERSONAL_DOCUMENTS });

    const data = yield call(apiCall);

    yield put({
      type: FETCH_CUSTOMER_FILES__SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_CUSTOMER_FILES__FAIL,
    });
  }
}

const supportingDocumentsSagas = [
  takeLatest(FETCH_CUSTOMER_FILES__REQUEST, fetchCustomerFiles),
];

export default supportingDocumentsSagas;
