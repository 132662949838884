import React from 'react';
import PropTypes from 'prop-types';

import LoadingGradient from '../../../Core/LoadingGradient';

const CartLoading = ({ items }) => (
  <div className="Cart Cart--loading">
    <div className="Cart__products">
      {items.map((item, index) => (
        <div className="Cart__product" key={index}>
          <LoadingGradient
            width="94px"
            height="94px"
            className="Cart__supplier"
          />
          <div className="Cart__product-container">
            <LoadingGradient
              width="130px"
              height="27px"
              className="Cart__product-title"
            />
            <LoadingGradient
              width="160px"
              height="19px"
              className="Cart__product-delete"
            />
          </div>
        </div>
      ))}
    </div>
    <div className="Cart__explanation">
      <LoadingGradient width="300px" height="19px" />
      <LoadingGradient width="300px" height="19px" />
      <LoadingGradient width="100px" height="19px" />
    </div>
    <LoadingGradient width="100%" height="46px" className="Cart__action" />
  </div>
);

CartLoading.defaultProps = {
  items: [],
};

CartLoading.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CartLoading;
