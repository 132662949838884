import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import VerifyEmailErrorMessage from '../Modules/Auth/VerifyEmailErrorMessage';

import '../../styles/components/Core/ErrorMessage.css';

class ErrorMessage extends PureComponent {
  formatText = paragraph => {
    const { error, data } = this.props;

    if (paragraph.text) {
      let modifiedText = paragraph.text;

      if (error.data && Object.keys(error.data).length > 0) {
        Object.keys(error.data).forEach(key => {
          const keyInText = `{${key}}`;

          if (modifiedText.includes(keyInText)) {
            modifiedText = modifiedText.replace(keyInText, error.data[key]);
          }
        });
      }

      if (modifiedText.includes('{SEND_VERIFICATION_EMAIL}')) {
        return (
          <Fragment>
            {modifiedText.replace('{SEND_VERIFICATION_EMAIL}', '')}
            <VerifyEmailErrorMessage email={data.email} />
          </Fragment>
        );
      }

      return modifiedText;
    }

    return null;
  };

  renderMessage = (paragraph, index) => (
    <p key={index} className="ErrorMessage__message">
      {this.formatText(paragraph)}
    </p>
  );

  render() {
    const {
      error: { message },
    } = this.props;

    return (
      <div className="ErrorMessage">
        {message.map((paragraph, index) =>
          this.renderMessage(paragraph, index),
        )}
      </div>
    );
  }
}

ErrorMessage.defaultProps = {
  data: {},
};

ErrorMessage.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.shape({}),
  }).isRequired,
  data: PropTypes.shape({}),
};

export default ErrorMessage;
