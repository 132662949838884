export const SEND_CONTACT_EMAIL__REQUEST =
  '@@CONTACT/SEND_CONTACT_EMAIL__REQUEST';
export const SEND_CONTACT_EMAIL__SUCCESS =
  '@@CONTACT/SEND_CONTACT_EMAIL__SUCCESS';
export const SEND_CONTACT_EMAIL__FAIL = '@@CONTACT/SEND_CONTACT_EMAIL__FAIL';

export const SEND_LEAD_EMAIL__REQUEST = '@@CONTACT/SEND_LEAD_EMAIL__REQUEST';
export const SEND_LEAD_EMAIL__SUCCESS = '@@CONTACT/SEND_LEAD_EMAIL__SUCCESS';
export const SEND_LEAD_EMAIL__FAIL = '@@CONTACT/SEND_LEAD_EMAIL__FAIL';

export const SEND_CALL_BACK_EMAIL__REQUEST =
  '@@CONTACT/SEND_CALL_BACK_EMAIL__REQUEST';
export const SEND_CALL_BACK_EMAIL__SUCCESS =
  '@@CONTACT/SEND_CALL_BACK_EMAIL__SUCCESS';
export const SEND_CALL_BACK_EMAIL__FAIL =
  '@@CONTACT/SEND_CALL_BACK_EMAIL__FAIL';

export const SEND_CALL_BACK_PRODUCT_EMAIL__REQUEST =
  '@@CONTACT/SEND_CALL_BACK_PRODUCT_EMAIL__REQUEST';
export const SEND_CALL_BACK_PRODUCT_EMAIL__SUCCESS =
  '@@CONTACT/SEND_CALL_BACK_PRODUCT_EMAIL__SUCCESS';
export const SEND_CALL_BACK_PRODUCT_EMAIL__FAIL =
  '@@CONTACT/SEND_CALL_BACK_PRODUCT_EMAIL__FAIL';

export const SEND_TESTING_EMAIL__REQUEST =
  '@@CONTACT/SEND_TESTING_EMAIL__REQUEST';
export const SEND_TESTING_EMAIL__SUCCESS =
  '@@CONTACT/SEND_TESTING_EMAIL__SUCCESS';
export const SEND_TESTING_EMAIL__FAIL = '@@CONTACT/SEND_TESTING_EMAIL__FAIL';

export const RESET_CONTACT_STATUS = '@@CONTACT/RESET_CONTACT_STATUS';

export const sendContactEmail = data => ({
  type: SEND_CONTACT_EMAIL__REQUEST,
  payload: data,
});

export const sendLeadEmail = data => ({
  type: SEND_LEAD_EMAIL__REQUEST,
  payload: data,
});

export const sendCallBackEmail = data => ({
  type: SEND_CALL_BACK_EMAIL__REQUEST,
  payload: data,
});

export const sendCallBackProductEmail = data => ({
  type: SEND_CALL_BACK_PRODUCT_EMAIL__REQUEST,
  payload: data,
});

export const sendTestingEmail = data => ({
  type: SEND_TESTING_EMAIL__REQUEST,
  payload: data,
});

export const resetContactStatus = () => ({
  type: RESET_CONTACT_STATUS,
});
