import { put, call, takeLatest } from 'redux-saga/effects';

import MieuxplacerAPI from '../api/Mieuxplacer';

import {
  FETCH_PAGE__REQUEST,
  FETCH_PAGE__SUCCESS,
  FETCH_PAGE__FAIL,
  FETCH_PAGE__NOTFOUND,
} from '../actions/PageActions';

function* fetchPage({ payload }) {
  try {
    let apiCall = () => {};

    if (payload.id) {
      apiCall = () => MieuxplacerAPI.Api.getPrismicPageById(payload.id);
    } else if (payload.slug) {
      apiCall = () => MieuxplacerAPI.Api.getPrismicPageBySlug(payload.slug);
    }

    const data = yield call(apiCall);

    yield put({
      type: FETCH_PAGE__SUCCESS,
      payload: { data, id: payload.id || payload.slug },
    });
  } catch (e) {
    if (e.status === 404) {
      yield put({
        type: FETCH_PAGE__NOTFOUND,
        payload: { message: e.message, id: payload.id || payload.slug },
      });
    } else {
      yield put({
        type: FETCH_PAGE__FAIL,
        payload: { message: e.message, id: payload.id || payload.slug },
      });
    }
  }
}

const pageSagas = [takeLatest(FETCH_PAGE__REQUEST, fetchPage)];

export default pageSagas;
