import numeral from 'numeral';

if (numeral.locales.fr === undefined) {
  numeral.register('locale', 'fr', {
    delimiters: {
      thousands: ' ',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal: number => (number === 1 ? 'er' : 'ème'),
    currency: {
      symbol: ' €',
    },
  });
}

numeral.locale('fr');
