import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { selectionShape } from '../../shapes/prismicShape';

import '../../styles/components/Core/Select.css';

class Select extends PureComponent {
  handleChange = event => {
    const { name, onChange } = this.props;
    const {
      target: { value },
    } = event;

    onChange(name, value);
  };

  handleBlur = event => {
    const { name, onBlur } = this.props;
    const {
      target: { value },
    } = event;

    onBlur(name, value);
  };

  render() {
    const {
      className,
      name,
      value,
      options,
      label,
      iconClass,
      icon,
      disabled,
      errors,
    } = this.props;

    const selectClasses = classNames('Select', {
      'Select--disabled': disabled,
      'Select--has-errors': errors.length > 0,
      [className]: className,
    });

    const iconWithClass = iconClass && <span className={iconClass} />;

    const iconElement = icon && <span className="Select__icon">{icon}</span>;

    return (
      <div className={selectClasses}>
        <div className="Select__container">
          {iconWithClass || iconElement}
          <select
            className="Select__input"
            type="select"
            name={name}
            value={value}
            disabled={disabled}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          >
            <option value="" disabled hidden>
              {label}
            </option>
            {options.map((item, index) => (
              <option key={index} value={item.value}>
                {Array.isArray(item.label) ? item.label[0].text : item.label}
              </option>
            ))}
          </select>
        </div>
        <div className="Select__errors">
          {Array.isArray(errors) ? (
            errors.map((error, index) => (
              <p key={index} className="Select__error">
                {error}
              </p>
            ))
          ) : (
            <p className="Select__error">{errors}</p>
          )}
        </div>
      </div>
    );
  }
}

Select.defaultProps = {
  className: '',
  label: '',
  errors: [],
  icon: null,
  iconClass: null,
  disabled: false,
  onBlur: () => {},
};

Select.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  options: PropTypes.arrayOf(PropTypes.shape(selectionShape)).isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  icon: PropTypes.element,
  iconClass: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

export default Select;
