import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { format } from 'date-fns';

import { sliceShape } from '../../../shapes/prismicShape';

import Switch from '../../Core/Switch';
import SimulationTitle from './SimulationTitle';
import SimulationProfitFees from './SimulationProfitFees';
import SimulationBlockFinal from './SimulationBlockFinal';

import '../../../styles/components/Blocks/Simulation/SimulationCalculator.css';

class SimulationCalculator extends PureComponent {
  state = {
    isPercent: false,
    selectedDate: null,
    selectedAmount: null,
  };

  componentDidMount() {
    const { data } = this.props;

    if (data.primary && data.primary.amount_of_investment_1) {
      this.setState({ selectedAmount: data.primary.amount_of_investment_1 });
    }

    if (data.primary && data.primary.date_of_investment_1) {
      this.setState({ selectedDate: data.primary.date_of_investment_1 });
    }
  }

  handleChange = checked => {
    this.setState({ isPercent: checked });
  };

  handleChangeAmount = event => {
    const {
      target: { value },
    } = event;

    this.setState({ selectedAmount: value });
  };

  handleChangeDate = event => {
    const {
      target: { value },
    } = event;

    this.setState({ selectedDate: value });
  };

  renderResult = (item, isPercent) => (
    <div className="SimulationCalculator__result">
      <SimulationProfitFees item={item} isPercent={isPercent} />
      <SimulationBlockFinal item={item} isPercent={isPercent} />
    </div>
  );

  renderResults = () => {
    const { data } = this.props;
    const { selectedAmount, selectedDate, isPercent } = this.state;

    if (
      data.primary.amount_of_investment_1 === Number(selectedAmount) &&
      data.primary.date_of_investment_1 === selectedDate &&
      data.items[0]
    ) {
      return this.renderResult(data.items[0], isPercent);
    }

    if (
      data.primary.amount_of_investment_1 === Number(selectedAmount) &&
      data.primary.date_of_investment_2 === selectedDate &&
      data.items[1]
    ) {
      return this.renderResult(data.items[1], isPercent);
    }

    if (
      data.primary.amount_of_investment_2 === Number(selectedAmount) &&
      data.primary.date_of_investment_1 === selectedDate &&
      data.items[2]
    ) {
      return this.renderResult(data.items[2], isPercent);
    }

    if (
      data.primary.amount_of_investment_2 === Number(selectedAmount) &&
      data.primary.date_of_investment_2 === selectedDate &&
      data.items[3]
    ) {
      return this.renderResult(data.items[3], isPercent);
    }

    return <div />;
  };

  render() {
    const { data } = this.props;

    const selectOptionsAmount = [];

    if (data.primary.amount_of_investment_1) {
      selectOptionsAmount.push({
        value: data.primary.amount_of_investment_1,
        label: `${numeral(data.primary.amount_of_investment_1).format()} €`,
      });
    }

    if (data.primary.amount_of_investment_2) {
      selectOptionsAmount.push({
        value: data.primary.amount_of_investment_2,
        label: `${numeral(data.primary.amount_of_investment_2).format()} €`,
      });
    }

    const selectOptionsDate = [];

    if (data.primary.date_of_investment_1) {
      selectOptionsDate.push({
        value: data.primary.date_of_investment_1,
        label: format(
          new Date(data.primary.date_of_investment_1),
          'dd/MM/yyyy',
        ),
      });
    }

    if (data.primary.date_of_investment_2) {
      selectOptionsDate.push({
        value: data.primary.date_of_investment_2,
        label: format(
          new Date(data.primary.date_of_investment_2),
          'dd/MM/yyyy',
        ),
      });
    }

    return (
      <div className="SimulationCalculator">
        <SimulationTitle isMobile />
        <div className="SimulationCalculator__header">
          <p>Si j&apos;avais placé</p>
          <div className="SimulationCalculator__select">
            <div className="Select__container">
              <select
                className="Select__input"
                type="select"
                onChange={this.handleChangeAmount}
              >
                {selectOptionsAmount.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <p>sur ce produit le</p>
          <div className="SimulationCalculator__select">
            <div className="Select__container">
              <select
                className="Select__input"
                type="select"
                onChange={this.handleChangeDate}
              >
                {selectOptionsDate.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="SimulationCalculator__container">
          <div className="SimulationCalculator__container-header">
            <p className="SimulationCalculator__switch-label">Affichage en</p>
            <Switch onChange={this.handleChange} leftLabel="€" rightLabel="%" />
          </div>
          {this.renderResults()}
        </div>
      </div>
    );
  }
}

SimulationCalculator.propTypes = {
  data: PropTypes.shape(sliceShape).isRequired,
};

export default SimulationCalculator;
