export const ADD_FILTER = '@@FILTER/ADD_FILTER';
export const SET_FILTER = '@@FILTER/SET_FILTER';
export const SET_FILTERS = '@@FILTER/SET_FILTERS';
export const REMOVE_FILTER = '@@FILTER/REMOVE_FILTER';
export const INIT_FILTERS = '@@FILTER/INIT_FILTERS';

export const addFilter = (type, id, value) => ({
  type: ADD_FILTER,
  payload: { type, id, value },
});

export const setFilter = (type, id, value) => ({
  type: SET_FILTER,
  payload: { type, id, value },
});

export const setFilters = (filters = {}) => ({
  type: SET_FILTERS,
  payload: filters,
});

export const removeFilter = (type, id, value) => ({
  type: REMOVE_FILTER,
  payload: { type, id, value },
});

export const initFilters = () => ({ type: INIT_FILTERS });
