import React from 'react';
import PropTypes from 'prop-types';

import HeaderForm from './HeaderForm';

import HeaderVideoMP4 from '../../../assets/images/plante.mp4';
import HeaderVideoOGV from '../../../assets/images/plante.ogv';
import HeaderVideoWebM from '../../../assets/images/plante.webm';

import '../../../styles/components/Blocks/Home/Header.css';

function HomeHeader({ isLoading }) {
  return (
    <div className="HomeHeader">
      <div className="HomeHeader__container">
        <div className="HomeHeader__content-container">
          <h1 className="HomeHeader__title">
            Marre de votre livret A bloqué à 0,5%&nbsp;?
          </h1>
          <div className="HomeHeader__subtitle">
            <p>Il est temps de faire fructifier votre épargne&nbsp;!</p>
            <p>
              Obtenez un conseil
              <span className="HomeHeader__subtitle-color">
                <b>immédiat</b> et <b>gratuit</b>.
                <span className="HomeHeader__subtitle-color-background" />
              </span>
            </p>
          </div>
          <div className="HomeHeader__image-container">
            <video
              className="HomeHeader__image"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={HeaderVideoMP4} type="video/mp4" />
              <source src={HeaderVideoWebM} type="video/webm" />
              <source src={HeaderVideoOGV} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="HomeHeader__question-container">
          <HeaderForm isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
}

HomeHeader.defaultProps = {
  isLoading: false,
};

HomeHeader.propTypes = {
  isLoading: PropTypes.bool,
};

export default HomeHeader;
