import { Map, fromJS } from 'immutable';

import {
  FETCH_USER_DETAILS__REQUEST,
  FETCH_USER_DETAILS__SUCCESS,
  FETCH_USER_DETAILS__FAIL,
} from '../actions/UserActions';

import {
  LOGIN_USER,
  LOGOUT_USER,
  USER_IS_VERIFIED,
  USER_IS_NOT_VERIFIED,
} from '../actions/AuthActions';

const initialState = Map({
  detailsLoading: false,
  detailsError: false,
  isAuthenticated: null,
  authorizationToken: undefined,
  isVerified: null,
  user: Map({}),
});

const userState = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER_DETAILS__REQUEST:
      return state.set('detailsLoading', true).set('detailsError', false);

    case FETCH_USER_DETAILS__SUCCESS:
      return state.set('detailsLoading', false).set('user', fromJS(payload));

    case FETCH_USER_DETAILS__FAIL:
      return state.set('detailsLoading', false).set('detailsError', true);

    case LOGIN_USER:
      return state
        .set('isAuthenticated', true)
        .set('authorizationToken', payload.token);

    case LOGOUT_USER:
      return state
        .set('isAuthenticated', false)
        .set('isVerified', null)
        .set('authorizationToken', undefined)
        .set('user', Map({}));

    case USER_IS_VERIFIED:
      return state.set('isVerified', true);

    case USER_IS_NOT_VERIFIED:
      return state.set('isVerified', false);

    default:
      return initialState.merge(state);
  }
};

export default userState;
