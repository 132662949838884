import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';
import Link from './Link';

import '../../styles/components/Core/CookieConsent.css';

const CookieConsent = ({ consent, handleConsent, handleNoConsent }) => {
  const containerClass = classNames('CookieConsent', {
    'CookieConsent--hidden': consent !== null,
  });

  return (
    <div id="CookieConsent" className={containerClass}>
      <div className="CookieConsent__content">
        <p className="CookieConsent__text">
          En poursuivant votre navigation sur notre site internet, vous acceptez
          l’utilisation de cookies pour faciliter votre navigation, vous
          proposer des offres adaptées et permettre l’élaboration de
          statistiques.
          <Link
            className="CookieConsent__link"
            target="_blank"
            href="https://www.mieuxplacer.com/documents/Charte-donnees-personnelles-MP.pdf"
          >
            En savoir plus.
          </Link>
        </p>
        <div className="CookieConsent__actions">
          <Button
            id="CookieConsent-Accept"
            className="CookieConsent__button"
            onClick={handleConsent}
            label="Accepter"
            type="white"
            bold
          />

          <Button
            id="CookieConsent-Decline"
            className="CookieConsent__button"
            onClick={handleNoConsent}
            label="Refuser"
            type="white"
            bold
          />
        </div>
      </div>
    </div>
  );
};

CookieConsent.defaultProps = {
  consent: null,
};

CookieConsent.propTypes = {
  consent: PropTypes.bool,
  handleConsent: PropTypes.func.isRequired,
  handleNoConsent: PropTypes.func.isRequired,
};

export default CookieConsent;
