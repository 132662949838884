import { BASE_API_URL } from '../config/api';

export const fetchUserDetails = async () => {
  const url = new URL(`${BASE_API_URL}/2.0/user/details`);

  const response = await fetch(url.href, {
    credentials: 'include',
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  }

  throw response;
};

export const fetchVerifyUser = async () => {
  const url = new URL(`${BASE_API_URL}/2.0/user/verify`);

  const response = await fetch(url.href, {
    credentials: 'include',
  });

  const data = await response.json();

  if (response.ok) {
    return data;
  }

  throw data;
};

export const updateUserEmail = async email => {
  const url = new URL(`${BASE_API_URL}/2.0/user/change/email`);

  const response = await fetch(url.href, {
    method: 'PATCH',
    body: JSON.stringify({ email }),
    credentials: 'include',
  });

  const data = await response.json();

  if (response.ok) {
    return data;
  }

  throw data;
};

export const updatePassword = async (oldPassword, newPassword) => {
  const url = new URL(`${BASE_API_URL}/2.0/user/change/password`);

  const response = await fetch(url.href, {
    method: 'POST',
    body: JSON.stringify({ oldPassword, newPassword }),
    credentials: 'include',
  });

  const data = await response.json();

  if (response.ok) {
    return data;
  }

  throw data;
};
