import { put, call, takeLatest } from 'redux-saga/effects';

import MieuxplacerAPI from '../api/Mieuxplacer';

import {
  FETCH_QUESTIONS__REQUEST,
  FETCH_QUESTIONS__SUCCESS,
  FETCH_QUESTIONS__FAIL,
} from '../actions/PersonalInformationActions';

function* fetchQuestions() {
  try {
    const apiCall = () =>
      MieuxplacerAPI.Api.getQuestions([
        'DQ5',
        'DQ6',
        'DQ7',
        'DQ8',
        'DQ9',
        'DQ10',
        'DQ11',
        'DQ13',
        'DQ14',
        'DQ15',
        'mobile_number',
      ]);
    const data = yield call(apiCall);

    yield put({
      type: FETCH_QUESTIONS__SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_QUESTIONS__FAIL,
    });
  }
}

const personalInformationSagas = [
  takeLatest(FETCH_QUESTIONS__REQUEST, fetchQuestions),
];

export default personalInformationSagas;
